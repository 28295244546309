import React from 'react';
import { connect } from 'react-redux';
import SignUpManualCheckEmail from './SignUpManualCheckEmail';
import SignUpManualUserInfo from './SignUpManualUserInfo';
import { getCinemas, getSignUpManualPreFillInfo, getSignUpManualStep } from '../../reducers';
import BackButton from '../common/BackButton';

class SignUpManual extends React.Component {
    render() {
        return <div className="container py-4">
            <div>
                <BackButton onBackClick={this.props.showRegister}>
                    <h1 className='title-1 mt-3 mb-5' style={{maxWidth:'60%'}}>Completa tu registro</h1>
                </BackButton>
            </div>
            <div>
                {this.renderContent()}
            </div>
        </div>;
    }

    renderContent() {
        if (this.props.step === "1" && !this.props.apple) {
            return <SignUpManualCheckEmail onSubmit={this.props.checkEmailPost} onLoginClick={this.props.onLoginClick}/>;
        } else {
            return <SignUpManualUserInfo cinemas={this.props.cinemas} info={this.props.info} onSubmit={this.props.registerPost} apple={this.props.apple} />;
        }
    }
}

const mapStateToProps = state => ({
    cinemas: getCinemas(state),
    info: getSignUpManualPreFillInfo(state),
    step: getSignUpManualStep(state),
});

export default connect(
    mapStateToProps
)(SignUpManual);