import React from 'react';
import ErrorPage from '../ErrorPage';
import NoContentMessage from '../common/NoContentMessage';
import Button from '../Button';
import styled from 'styled-components';
import { breakpoints } from '../../utils/styles';

const StyledButton = styled(Button)``;

const Buttons = styled.div`
  & > ${StyledButton} {
    margin-bottom: 1em;

    @media screen and (min-width: ${breakpoints.desktop}px) {
      margin-right: 1em;
      &:last-child { margin-right: 0; }
    }
  }
`;

const reload = (e) => {
  e.preventDefault();
  document.location.reload();
};

function TimedOut() {
  return <ErrorPage title="Checkout">
    <NoContentMessage bigTitle='Ups!' icon='fal fa-clock'>
      <p>
        Has superado el tiempo necesario para poder realizar tu compra.<br/>
      </p>
      <Buttons>
        <StyledButton primary blockSmall onClick={reload}>Comenzar de nuevo</StyledButton>
        <StyledButton blockSmall to='/'>Volver a la cartelera</StyledButton>
      </Buttons>
    </NoContentMessage>
  </ErrorPage>;
}

export default TimedOut;