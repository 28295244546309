import React from 'react';
import BackButton from '../common/BackButton';
import styled from 'styled-components';
import { breakpoints } from '../../utils/styles';

const StyledBackButton = styled.div`
  body.theme-platinum & { --text-primary: #FFFFFF; }
`;

const Info = styled.dl`
  margin: 2rem 0 0;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    padding: 0 0 1rem 0;
  }
`;

const InfoItem = styled.div`
  display: flex;
  & > dt { font-weight: 700; }
  & > dt { width: 20% }
  & > dd { width: 80%; }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    display: block;
    & > dt, & > dd { display: inline-block; }
    & > dt:after { content: ":"; }
  }

  body.theme-platinum & {
    font-size: 0.8em;
    
    & > dt {
      font-weight: 400;
      text-transform: uppercase;
    }
  }
`;

class BillboardCinemaHeading extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.cinema.id !== nextProps.cinema.id;
  }

  render() {
    const {cinema} = this.props;

    return (
        <hgroup className='pt-3'>
          <StyledBackButton>
            <BackButton label={cinema.name} onBackClick={() => { window.history.back(); }} />
          </StyledBackButton>
          <Info>
            {cinema.info.address && <InfoItem><dt>Dirección</dt> <dd>{cinema.info.address}</dd></InfoItem>}
            {cinema.info.phone && <InfoItem><dt>Teléfono</dt> <dd>{cinema.info.phone}</dd></InfoItem>}
          </Info>
        </hgroup>
    )
  }
}

export default BillboardCinemaHeading;