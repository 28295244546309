import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';

interface Props {
  children: React.ReactNode,
  selected: boolean,
  onClick: MouseEventHandler<HTMLSpanElement>,
};

const Option = styled.span<Props>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--text-primary);
  padding: 1.5em 1em;
  margin-bottom: 1em;

  ${props => props.selected && 'border: 2px solid var(--primary); color: var(--primary);' }
`;

const PaymentOptionButton = (props:Props) => (
    <li>
      <Option
          selected={props.selected}
          onClick={props.onClick}
      >
        {props.children}
      </Option>
    </li>
);

export default PaymentOptionButton;