import { Elements } from "@stripe/react-stripe-js";
import React from "react";
import { connect } from 'react-redux';
import StripeForm from "./StripeForm";
import { paymentFormSubmit } from "../../../actions/creators/checkout/payment";
import { parseOrderAmountByPaymentMethod } from "../../../utils/checkout";
import { getCheckoutPaymentConstraints, getCheckoutSelectedPaymentOption, getUserInfo } from "../../../reducers";
import { PaymentConstraint } from "./interfaces";

interface Props {
    cash: number,
    constraints: Array<PaymentConstraint>,
    onCancel: Function,
    onPaymentSubmit: Function,
    user: object,
}

declare const window: {localConfig: any};

class StripeElements extends React.Component<Props> {
    render() {
        const stripePromise = window.localConfig.stripe.promise;

        return <>
            {stripePromise && (
                <Elements stripe={stripePromise} options={{locale: 'es', captureMethod: 'manual', mode: 'payment', amount: this.props.cash, currency: 'mxn',
                    fonts: [{
                        family: 'MaisonNeue',
                        src: 'url(https://statics.cinemex.com/uploads/cms/attachments/672a8237bcc5a.woff)',
                        weight: '400',
                    }],
                    appearance: {
                        variables: {
                            fontFamily: 'Maison Neue, sans-serif'
                        }
                    }}}>
                    <StripeForm constraints={this.props.constraints} onPaymentSubmit={this.props.onPaymentSubmit} user={this.props.user}/>
                </Elements>
            )}
        </>;
    }
}

const mapStateToProps = state => {
    const amountByMethod = parseOrderAmountByPaymentMethod(getCheckoutSelectedPaymentOption(state));
    const cash = ('cash' in amountByMethod) ? amountByMethod.cash : 0;

    return {
        cash,
        constraints: getCheckoutPaymentConstraints(state),
        user: getUserInfo(state),
    };
};

const mapDispatchToProps = dispatch => ({
    onPaymentSubmit: params => dispatch(paymentFormSubmit(params))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StripeElements);