import { loginPostSuccess, renewAuthToken } from '../actions/creators/auth';
import { userCouponsActivateError, userCouponsActivateSuccess, userCouponsFetch, userCouponsFetchSuccess } from '../actions/creators/user/coupons';
import { purchaseHistoryFetch, updateInfoError, updateInfoSuccess, verifyPhoneSuccess } from '../actions/creators/user';
import * as actions from '../actions/types';
import CMX from '../api';
import { isUserLogged } from '../reducers';
import { onApiError } from './misc';

export const userMiddleware = ({ getState, dispatch }) => next => async action => {
    pre(action, getState, dispatch);
    next(action);
    post(action, getState, dispatch);
};

function pre(action, getState, dispatch) {
    switch (action.type) {
        case actions.USER_IE_CARD_FETCH: return userIeCardFetch(action, getState, dispatch);
        case actions.USER_UPDATE_INFO_SUCCESS: return onUserInfoUpdateSuccess(action, getState, dispatch);
    }
}

function post(action, getState, dispatch) {
    switch (action.type) {
        case actions.APP_LOADED: return onAppLoaded(action, getState, dispatch);
        case actions.USER_INFO_FETCH: return userInfoFetch(action, getState, dispatch);
        case actions.AUTH_FB_LOGIN_SUCCESS:
        case actions.AUTH_LOGIN_POST_SUCCESS: return dispatch(purchaseHistoryFetch({future: true}));
        case actions.IE_SIGN_UP_SUCCESS: return userIeCardFetch(action, getState, dispatch);
        case actions.USER_COUPONS_FETCH: return onUserCouponsFetch(action, getState, dispatch);
        case actions.USER_COUPONS_ACTIVATE: return onUserCouponsActivate(action, getState, dispatch);
        case actions.USER_UPDATE_INFO_PUT: return userInfoUpdate(action, getState, dispatch);
        case actions.USER_UPDATE_INFO_ERROR: return onApiError(dispatch, Object.assign({context: 'phone_verification'}, action));
    }
}

function userIeCardFetch(action, getState, dispatch) {
    CMX.get('/me/ie/card')
        .then(data => dispatch({type: actions.USER_IE_CARD_FETCH_SUCCESS, data}))
        .catch(error => dispatch({type: actions.USER_IE_CARD_FETCH_ERROR, error}));
}

function userInfoFetch(action, getState, dispatch) {
    CMX.get('/me/')
        .then(data => dispatch({type: actions.USER_INFO_FETCH_SUCCESS, data: {user_info: data}}))
        .catch(error => dispatch({type: actions.USER_INFO_FETCH_ERROR, error}));
}

function onAppLoaded(action, getState, dispatch) {
    const state = getState();
    
    checkSignInToken(action, getState, dispatch);

    if (isUserLogged(state)) {
        dispatch(purchaseHistoryFetch({future: true}));
    }
}

function checkSignInToken(action, getState, dispatch) {
    const str = document.location.search;
    if (str.substr(0, 1) === '?') {
        const parts = str.substr(1).split('&');
        const obj = {};
        parts.forEach(part => {
            part = part.split('=');
            obj[part[0]] = part[1];
        });
        
        if (('si_token' in obj)) {
            dispatch(renewAuthToken(obj['si_token'], obj['app_id']));
        }
    }
}

function onUserCouponsFetch(action, getState, dispatch) {
    CMX.get('/me/coupons/')
        .then(res => dispatch(userCouponsFetchSuccess(res)))
        .catch(error => dispatch(userCouponsActivateError(error)));
}

function onUserCouponsActivate(action, getState, dispatch) {
    CMX.post(`/me/coupons/${action.id}/activate`)
        .then(res => dispatch(userCouponsActivateSuccess(res)))
        .then(() => dispatch(userCouponsFetch()))
        .catch(error => dispatch(userCouponsActivateError(error)));
}

function userInfoUpdate(action, getState, dispatch) {
    let req;
    const {values, auth_pin} = action;

    if (auth_pin) values.auth_pin = auth_pin;

    if ('phone' in values) {
        return CMX.post('/me/phone', values)
            .then(data => dispatch(verifyPhoneSuccess(data)))
            .catch(error => dispatch(updateInfoError(error, action)));
    } else {
        req = CMX.put('/me/', values)
            .then(data => dispatch(updateInfoSuccess(data)))
            .catch(error => dispatch(updateInfoError(error, action)));
    }
}

function onUserInfoUpdateSuccess(action, getState, dispatch) {
    let userInfo;
    const {response} = action;

    if (('access_token' in response)) {
        userInfo = response.user_info;
        dispatch(loginPostSuccess(response));
    } else {
        userInfo = response;
    }

    action.userInfo = response;
}

export default userMiddleware;