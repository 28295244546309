import React, { useState } from 'react';
import Button from '../Button';
import FieldRow from '../FieldRow';
import styled from 'styled-components';
import * as validators from '../../utils/validation';

const Icon = styled.p`
    font-size: 2.2em;
`;

const BottomLink = styled.p`
    font-size: 0.9em;
`;

function SignUpManualCheckEmail(props) {
    const [email, setEmail] = useState('');

    const onSubmit = e => {
        e.preventDefault();
        props.onSubmit({email});
    };

    const onLogin = e => {
        e.preventDefault();
        props.onLoginClick();
    };

    const validEmail = validators.validateEmail(email);
    
    return <div className='text-center mb-5' style={{flexGrow:1}}>
        <form onSubmit={onSubmit}>
            <Icon>📧</Icon>
            <p>Por favor escribe tu correo electrónico.</p>
            <div className='py-3'><input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" className='form-control text-center'/></div>
            <p className='my-4'><Button submit block large primary={true /*validEmail*/} disabled={!validEmail}>Validar</Button></p>
            <BottomLink>¿Ya tienes cuenta? <a href="/login" onClick={onLogin}>Inicia sesión aquí</a>.</BottomLink>
        </form>
    </div>;
}

export default SignUpManualCheckEmail;