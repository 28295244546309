import React from 'react';
import UserCinemaNavLinks from './UserCinemaNavLinks';
import styled from 'styled-components';

interface Props {
  cinema?: object, // @todo Define model
  cinemaSelector?: boolean,
  negative?: boolean,
  padding?: boolean,
  showInMobile?: boolean,
}

const Menu = styled.ul<{negative?: boolean}>`
  ${props => props.negative && `
    & > li > a,
    & > li > span {
      --text-primary: var(--text-tertiary);
    }
  `}
`;

function UserCinemaNav(props:Props) {
  return <nav className={(props.padding !== false ? 'container pad-y' : '') + (props.showInMobile === false ? ' d-none d-md-block' : '')}>
    <Menu className="nav flex-nowrap justify-content-between align-items-center c-nav" negative={props.negative}>
      <UserCinemaNavLinks cinema={props.cinema} cinemaSelector={props.cinemaSelector !== false}/>
    </Menu>
  </nav>;
}

export default UserCinemaNav;