import React from 'react';
import {withRouter} from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import Button from "./Button";
import MoviePoster from "./MoviePoster";
import {extractYouTubeId,getYouTubeEmbedUrl} from '../utils/misc';
import {fixMovieUrl} from '../utils/urls';
import {breakpoints} from '../utils/styles';
import scrollArrSvg from '../assets/svg/scroll-arr.svg';

const Container = styled.section`
  color: #fff;
  height: 75vh;
  overflow: hidden;
  position: relative;
  text-align: center;
  top: 0px;
  transition: 0.25s ease-out top;

  iframe { display: none; }
  .c-card { display: inline-block; margin: 0 auto 2rem; }
  .btn-outline-secondary{color:#f8f9fa;}

  @media screen and (min-width: ${breakpoints.tablet}px) {
    height: 60vh;
    text-align: left;

    iframe { display: block; margin: 0 calc(var(--pad-x) * -1); }
    .c-card { margin: auto; }
  }

  @media screen and (max-width: ${breakpoints.tablet - 1}px) { .fade-opacity-out { visibility: visible !important; opacity: 1 !important; } }
`;

const Content = styled.div`
  align-items: flex-end;
  background: ${props => props.obscure ? 'black' : 'none'};
  display: flex;
  height: 100%;
  left: 0;
  max-width: 100%;
  padding: var(--pad-y) var(--pad-x);
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  ${props => props.obscure && 'body.theme-platinum & { background: var(--primary-b); }'}  

  & > .row { flex-grow: 1; }

  @media screen and (min-width: ${breakpoints.tablet}px) {
    align-items: center;
    background: ${props => props.obscure ? 'rgba(0,0,0,0.7)' : 'none'};
    ${props => props.obscure && 'body.theme-platinum & { background: rgba(0,0,0,0.7); }'}

    & > .row { flex-grow: 0; }
  }

`;

const Row = styled.div`
  width: 100%;

  @media screen and (min-width: ${breakpoints.tablet}px) {
    display: flex;
    align-items: flex-end;
  }
`;

const PosterColumn = styled.div`
  & .c-card__image {
    height: 38vh;
    width: auto;
  }

  @media screen and (min-width: ${breakpoints.tablet}px) {
    margin-right: 2rem;
    
    & .c-card__image { height: 34vh; }
    & .c-card { display: block; }
  }
`;

const InfoColumn = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}px) { max-width: 55%; font-size: 0.95em; }
  @media screen and (min-width: ${breakpoints.desktopLarge}px) { max-width: 40%; font-size: 1em; }
`;

const marquee1 = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(-200%); }
`;
const marquee2 = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(-200%); }
`;
const marquee3 = keyframes`
  0% { transform: translateX(0%); }
  100% { transform: translateX(-200%); }
`;

const TagLineBase = css`
  color: #cccccc;
  font-size: ${(11/12.6).toFixed(2)}rem;
  margin-bottom: 2em;
  text-transform: uppercase;
  white-space: nowrap;
`;

const TagLineMarquee = styled.h3`
  ${TagLineBase}

  & > :nth-child(1) {
    display: inline-block;
    animation: ${marquee1} 20s linear infinite;
  }

  & > :nth-child(2) {
    display: inline-block;
    animation: ${marquee2} 20s linear infinite;
    // animation-delay: 3s;
  }

  & > :nth-child(3) {
    display: inline-block;
    animation: ${marquee3} 20s linear infinite;
    // animation-delay: 6s;
  }

  body.theme-market & {
    background: var(--primary);
    color: var(--page-bg);
    margin-left: calc(var(--pad-x)* -1);
    margin-right: calc(var(--pad-x)* -1);
    padding: 0.5em 0px 0.35em;
  }

  body.theme-platinum & {
    color: var(--text-primary);
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    display: none;
  }
`;

const TagLine = styled.h3`
  ${TagLineBase}
  
  display: none;
  
  @media screen and (min-width: ${breakpoints.desktop}px) {
    display: block;
  }
`;

const TitleBlock = styled.div`
  @media screen and (min-width: ${breakpoints.desktop}px) {
    transform: translateY(${props => !props.buttonsVisible ? '6.15rem' : '0'});
    transition: transform 0.25s ease-out;
  }
`;

const Title = styled.h1`
  color: var(--primary) !important;
  font-size: 45px;
  line-height: 1;
  max-height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;

  body.theme-market & {
    color: #FFFFFF !important;
  }

  body.theme-platinum & {
    font-size: 40px;
  }

  @media screen and (min-width: ${breakpoints.desktopLarge}px) {
    margin: 0.5em 0;
  }
`;

const Text = styled.p`
  display: none;
  font-weight: 200;
  font-size: 1.1em;
  line-height: 1.5em;
  max-height: 6em;
  overflow: hidden;
  
  @media screen and (min-width: ${breakpoints.tablet}px) {
    display: block;
    font-size: 1em;
    line-height: 1.75em;
    max-height: 7em;
  }
  
  @media screen and (min-width: ${breakpoints.desktopLarge}px) {
    font-size: 1.1em;
  }
  
  @media screen and (min-width: 1921px) {
    font-size: 1.15em;
  }
`;

const PosterBg = styled.div`
  background-size: cover;
  height: 100%;
  width: 100%;
  
  @media screen and (min-width: ${breakpoints.tablet}px) { display: none; }
`;

const BtnsRow = styled.div`
  display: flex;
  margin-top: 2rem;

  & > button {
    flex: 1 1 0px;
    position: relative;
    z-index: 1;
  }

  @media screen and (min-width: ${breakpoints.tablet}px) { display: block; margin-top: 1.5rem; }
  @media screen and (min-width: ${breakpoints.desktopLarge}px) { display: block; margin-top: 2.5rem; }
`;

const ScrollBt = styled.div`
  display: none;
  cursor: pointer;
  position: absolute;
  right: 3rem;
  bottom: 3rem;

  @media screen and (min-width: ${breakpoints.tablet}px) {  
    display: block;
    bottom: 0px;
    width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    color: rgba(255,255,255,0.5);
  }
  @media screen and (min-width: ${breakpoints.desktopLarge}px) {
    padding: 1em 0.5em;
  }
`;

const ScrollText = styled.span`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}px) { display: inline; }
`;

const ScrollArrAnimation = keyframes`
  from {
    transform: translateY(-0.35em);
  }

  to {
    transform: translateY(0.15em);
  }
`;

const ScrollArr = styled.img`
  width: 1em;
  margin-left: 0.5em;
  animation: ${ScrollArrAnimation} 1s linear infinite alternate;
`;

const scrollTo = scrollToOptions => {
  const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

  if (isSmoothScrollSupported) {
    window.scroll(scrollToOptions);
  } else {
    window.scroll(scrollToOptions.left || 0, scrollToOptions.top || 0);
  }
};

const onScrollClick = () => {
  const t = document.getElementById('home-feat-movie').getBoundingClientRect().height - 72;
  scrollTo({top:t,behavior:'smooth'});
};

class HomeFeaturedMovie extends React.Component {
  constructor(props) {
    super(props);

    this.onBuyClick = this.onBuyClick.bind(this);
    this.onPlayTrailerClick = this.onPlayTrailerClick.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  render() {
    const {movie} = this.props;

    if (!movie) {
      return '';
    }

    const youtubeId = extractYouTubeId(movie.info.trailer);
    const youtubeUrl = getYouTubeEmbedUrl(movie);
    const buttonsVisible = !(this.props.movieTabOpen && document.location.pathname.match(new RegExp('^/pelicula/' + movie.id)));

    return <Container id="home-feat-movie" /*style={{top:this.props.hidden?'-65vh':0}}*/><Content>
      <iframe id="ytplayer" type="text/html" width="135%" height="200%"
                   src={youtubeUrl + '?playlist=' + youtubeId + '&loop=1&autoplay=1&controls=0&showinfo=0&mute=1&disablekb=1&modestbranding=1'}
                   frameBorder="0" allowFullScreen/>
      {/* <PosterBg style={{backgroundImage: 'url(' + (movie.featured_bg || movie.poster_featured_web || movie.poster_medium) + ')'}} /> */}
      <Content obscure>
        <Row className="mt-5">
          <PosterColumn>
            <MoviePoster movie={movie} noTitle noRibbons alone size="large" />
          </PosterColumn>
          <InfoColumn>
            <TagLineMarquee>
              <span>Destacada de la semana ⭐ Destacada de la semana ⭐ Destacada de la semana ⭐ </span>
              <span>Destacada de la semana ⭐ Destacada de la semana ⭐ Destacada de la semana ⭐ </span>
              <span>Destacada de la semana ⭐ Destacada de la semana ⭐ Destacada de la semana ⭐ </span>
            </TagLineMarquee>
            <TitleBlock buttonsVisible={buttonsVisible}>
              <TagLine>Destacada de la semana ⭐</TagLine>
              <Title className='title-1'>{movie.name}</Title>
            </TitleBlock>
            <BtnsRow className={'fade-opacity fade-opacity-' + (buttonsVisible ? 'in' : 'out')}>
              <Button primary large className="mr-3" onClick={this.onBuyClick}>Comprar boletos</Button>
              <Button transparent outline large onClick={this.onPlayTrailerClick}>Ver trailer</Button>
            </BtnsRow>
          </InfoColumn>
        </Row>
      </Content>
      <ScrollBt onClick={onScrollClick}><ScrollText>Ver cartelera</ScrollText> <ScrollArr src={scrollArrSvg}/></ScrollBt>
    </Content></Container>;
  }

  onBuyClick() {
    this.onButtonClick();
  }

  onPlayTrailerClick() {
    if (document.location.hash === '#trailer') {
      document.location.hash = '';
    }

    setTimeout(() => this.onButtonClick('#trailer'), 100);
  }

  onButtonClick(args) {
    this.props.onMovieSelect(
        this.props.movie.id,
        fixMovieUrl(this.props.movie.url) + (args ? args : ''),
        this.props.history
    );
  }
}

export default withRouter(HomeFeaturedMovie);