import React from 'react';
import FloatingPost from '../FloatingPost';
import FieldRow from '../FieldRow';

class SignUpTermsCheckbox extends React.Component {
    constructor(props) {
        super(props);
    
        this.onTermsLinkClick = this.onTermsLinkClick.bind(this);
        this.onIETermsLinkClick = this.onIETermsLinkClick.bind(this);
        this.onPrivacyLinkClick = this.onPrivacyLinkClick.bind(this);
        this.onTermsModalClose = this.onModalClose.bind(this);
        this.onChange = this.onChange.bind(this);

        this.state = {
          termsOpen: false,
          ieTermsOpen: false,
          privacyOpen: false
        };
    }

    onTermsLinkClick(e) {
        e.preventDefault();
        this.setState({ termsOpen: true });
    }

    onIETermsLinkClick(e) {
        e.preventDefault();
        this.setState({ ieTermsOpen: true });
    }

    onPrivacyLinkClick(e) {
        e.preventDefault();
        this.setState({ privacyOpen: true });
    }

    onModalClose(e) {
        e.preventDefault();
        this.setState({ privacyOpen: false, ieTermsOpen: false, termsOpen: false });
    }

    onChange(e) {
        this.props.onChange(e.target.checked);
    }
    
    render() {
        return <React.Fragment>
            <FieldRow label={this.getLabel()} type="checkbox" onChange={this.onChange} checked={this.props.checked}
                valid={this.props.valid} validationError={this.props.validationError} />
            {this.state.termsOpen ? <FloatingPost slug="terminos-y-condiciones-de-compra" onClose={this.onModalClose}/> : ''}
            {this.state.ieTermsOpen ? <FloatingPost slug="invitado-especial-terminos" onClose={this.onModalClose}/> : ''}
            {this.state.privacyOpen ? <FloatingPost slug="aviso-de-privacidad" onClose={this.onModalClose}/> : ''}
        </React.Fragment>;
    }

    getLabel() {
        return <React.Fragment>
            Acepto los <a href="/terminos" target="_blank" style={{ textDecoration: 'underline' }} onClick={this.onTermsLinkClick}>Términos y Condiciones</a> y el <a href="/privacidadvisitantes" target="_blank" style={{ textDecoration: 'underline' }} onClick={this.onPrivacyLinkClick}>Aviso de Privacidad</a> de Cinemex.com
            y los <a href="/loop/terminos" target="_blank" style={{ textDecoration: 'underline' }} onClick={this.onIETermsLinkClick}>Términos y Condiciones</a> del programa Cinemex Loop
        </React.Fragment>;
    }
}

export default SignUpTermsCheckbox;