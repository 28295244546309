import React from 'react';
import styled from 'styled-components';
import * as vars from '../utils/styles';

const Blocker = styled.div`
  background: ${props => props.solidBg ? 'var(--page-bg)' : 'rgba(' + (props.color || '0,0,0') + ',0.75)'};
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: ${props => props.modal ? props.theme.zIndexes.dialogs : (
      (props.overSidebars || props.overSidebarOnly) ? 1055 : 1035
  )};
  
  ${props => props.overSidebarOnly ?
    `
      right: 0;
      @media (min-width: ${vars.breakpoints.tablet}px) { width: 40%; }
      @media (min-width: ${vars.breakpoints.desktop}px) { width: 35%; }
    `
    :
    `left: 0;`
  }
`;
class BodyBlocker extends React.Component {
  componentDidMount() {
    if (!this.props.overflow) {
      document.body.classList.add('overflow-h');
    }
  }
  
  componentWillUnmount() {
    document.body.classList.remove('overflow-h');
  }

  render() {
    return <Blocker color={this.props.color} {...this.props} className={'fade-in ' + (this.props.className || '')}>{this.props.children}</Blocker>;
  }
}

export default BodyBlocker;