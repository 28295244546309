import React from "react";
import styled from "styled-components";
import { CouponData } from "./interfaces";
import CouponAvailabilityIcon from "./CouponAvailabilityIcon";
import { breakpoints } from "../../../utils/styles";
import moment from "moment";

const Container = styled.div`
    margin: 0 0 0 -0.5em;
    overflow-x: auto;
    padding: 0 0.5em 1em;
    // width: 112%;
    
    @media screen and (min-width: ${breakpoints.desktop}px) {
        margin: 0 -0.5em;
        width: 100%;
    }
`;

const Item = styled.li`
    box-shadow: 0px 2px 4px 0px #00000040;
    cursor: pointer;
    flex: 0 0 40%;
    margin-right: 1rem;
    overflow: hidden;
    position: relative;

    & ${CouponAvailabilityIcon.Styled} {
        position: absolute;
        bottom: 0.25em;
        left: 0.25em;
        font-size: 1.5em;
    }

    @media screen and (min-width: ${breakpoints.desktop}px) {
        flex: none;
        margin-right: 0;
    }
`;

const List = styled.ul<{grid?: boolean}>`
    ${props => props.grid
        ? `
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1em;
        `
        : `display: flex;`}
    list-style-type: none;
    margin: 0 !important;
    padding: 0;
        
    ${Item} { ${props => props.grid && 'margin-right: 0;'} }

    @media screen and (min-width: ${breakpoints.desktop}px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1em;
    }
`;

const CouponThumb = styled.img`
    display: block;
    height: auto;
    width: 100%;
`;

const CouponAvailability = styled.div`
    background: #fff;
    color: #000;
    font-size: 0.65rem;
    padding: 0.75em 0.5em 0.5em 2.75em;
    text-transform: uppercase;
`;

interface Props {
    coupons: Array<CouponData>,
    grid?: boolean,
    onCouponClick: Function,
}

function getDateString(str: string): string {
    const d = moment(str);
    return 'al ' + d.format('DD/MMM/YY').replace('.', '');
}

function CouponsBlockCoupons({coupons, grid, onCouponClick}: Props) {
    return <Container>
        <List grid={grid}>
            {coupons.map((coupon:CouponData) =>
                <Item key={coupon.id} onClick={() => onCouponClick(coupon)}>
                    <CouponThumb src={coupon.thumb} alt={coupon.title} />
                    <CouponAvailability>
                        <CouponAvailabilityIcon color={coupon.status_color} />
                        {getDateString(coupon.code ? coupon.redeem_date_limit : coupon.activation_date_limit)}
                    </CouponAvailability>
                </Item>
            )}
        </List>
    </Container>;
}

export default CouponsBlockCoupons;