import { createSelector } from 'reselect';
import * as actions from '../actions/types';
import { combineReducers } from 'redux';
import uniqid from 'uniqid';
import areasBillboards from './areasBillboards';
import auth from './auth';
import checkoutData from './checkoutData';
import entities from './entities';
import home from './home';
import ie from './ie';
import notifications from './notifications';
import now from './now';
import posts from './posts';
import refundRequest from './refundRequest';
import showtimesData from './showtimesData';
import statesBillboards from './statesBillboards';
import ui from './ui';
import user from './user/index';
import wallet from './wallet';
import {filterVersionsForDate,filterVersionsByHours,filterAvailableVersions,getFilteredVersions} from '../utils/showtimes';
import {sortByProperty, unique} from '../utils/arrays';
import {getCashPaymentMethodsAllowed, parseOrderAmountByPaymentMethod} from '../utils/checkout';
import { compareIgnoringAccents } from '../utils/strings';
import landings from './landings';
import { isUserCouponsLoading, isUserCouponsProcessing } from './user/coupons';

const configData = window.appConfig;
const config = (state = configData, action) => state;

const defaultMovieTabState = {
  hidden: true,
};
const movieTab = (state = defaultMovieTabState, action) => {
  switch (action.type) {
    case actions.MOVIE_TAB_HIDE_TOGGLE:
      return Object.assign({}, state, {hidden: !state.hidden});
    case actions.MOVIE_TAB_OPEN:
    case actions.MOVIE_UPCOMING_SELECT:
    case actions.MOVIE_SELECT:
      return Object.assign({}, state, {hidden: false});
    case actions.MOVIE_TAB_CLOSE:
      return Object.assign({}, state, {hidden: true});
    default:
      return state;
  }
};

const dialogs = (state = {}, action) => {
  switch (action.type) {
    case actions.DIALOG_DISPLAY:
      return Object.assign({}, state, createDialog(action.payload));
    case actions.DIALOG_CLOSE:
      const result = Object.assign({}, state);
      delete result[action.id];
      return result;
    default:
      return state;
  }
};

const createDialog = dialog => {
  const id = uniqid();

  return {[id]: Object.assign({}, dialog, {id})};
};

const cinemasBillboards = (state = {}, action) => {
  switch (action.type) {
    case actions.CINEMA_MOVIES_FETCH_SUCCESS:
      return Object.assign({}, state, {[action.cinemaId]: action.movies});
    default:
      return state;
  }
};

const contactForm = (state = {isProcessing: false, isSubmitted: false}, action) => {
  switch (action.type) {
    case actions.CONTACT_FORM_SHOW:
      return Object.assign({}, state, {isProcessing: false, isSubmitted: false});
    case actions.CONTACT_FORM_SUBMIT:
      return Object.assign({}, state, {isProcessing: true, isSubmitted: false});
    case actions.CONTACT_FORM_SUCCESS:
      return Object.assign({}, state, {isProcessing: false, isSubmitted: true});
    case actions.CONTACT_FORM_ERROR:
      return Object.assign({}, state, {isProcessing: false, isSubmitted: false});
    default:
      return state;
  }
};

const jobsForm = (state = {isProcessing: false, isSubmitted: false}, action) => {
  switch (action.type) {
    case actions.JOBS_FORM_SHOW:
      return Object.assign({}, state, {isProcessing: false, isSubmitted: false});
    case actions.JOBS_FORM_SUBMIT:
      return Object.assign({}, state, {isProcessing: true, isSubmitted: false});
    case actions.JOBS_FORM_SUCCESS:
      return Object.assign({}, state, {isProcessing: false, isSubmitted: true});
    case actions.JOBS_FORM_ERROR:
      return Object.assign({}, state, {isProcessing: false, isSubmitted: false});
    default:
      return state;
  }
};

const arcoForm = (state = {isProcessing: false, isSubmitted: false}, action) => {
  switch (action.type) {
    case actions.ARCO_FORM_SHOW:
      return Object.assign({}, state, {isProcessing: false, isSubmitted: false, ref: null});
    case actions.ARCO_FORM_SUBMIT:
      return Object.assign({}, state, {isProcessing: true, isSubmitted: false, ref: null});
    case actions.ARCO_FORM_SUCCESS:
      return Object.assign({}, state, {isProcessing: false, isSubmitted: true, ref: action.data.ref});
    case actions.ARCO_FORM_ERROR:
      return Object.assign({}, state, {isProcessing: false, isSubmitted: false, ref: null});
    default:
      return state;
  }
};

const corpSalesForm = (state = {isProcessing: false, isSubmitted: false}, action) => {
  switch (action.type) {
    case actions.CORP_SALES_FORM_SHOW:
      return Object.assign({}, state, {isProcessing: false, isSubmitted: false});
    case actions.CORP_SALES_FORM_SUBMIT:
      return Object.assign({}, state, {isProcessing: true, isSubmitted: false});
    case actions.CORP_SALES_FORM_SUCCESS:
      return Object.assign({}, state, {isProcessing: false, isSubmitted: true});
    case actions.CORP_SALES_FORM_ERROR:
      return Object.assign({}, state, {isProcessing: false, isSubmitted: false});
    default:
      return state;
  }
};

const fingerprint = (state = null, action) => {
  switch (action.type) {
    case actions.FINGERPRINT_SET:
      return action.id;
    default:
      return state;
  }
}

const news = (state = {ids: [], lastLoadTime: null, isLoading: false}, action) => {
  switch (action.type) {
    case actions.NEWS_FETCH:
      return Object.assign({}, state, {isLoading: true});
    case actions.NEWS_FETCH_SUCCESS:
      return Object.assign({}, state, {ids: action.data.map(article => article.id), lastLoadTime: new Date().getTime(), isLoading: false});
    case actions.NEWS_FETCH_ERROR:
      return Object.assign({}, state, {isLoading: false});
    default:
      return state;
  }
};

const ieBenefits = (state = {categories: {}, lastLoadTime: null, isLoading: false}, action) => {
  switch (action.type) {
    case actions.IE_BENEFITS_FETCH:
      return Object.assign({}, state, {isLoading: true});
    case actions.IE_BENEFITS_FETCH_SUCCESS:
      return Object.assign({}, state, {categories: parseIeBenefitsData(action.data), lastLoadTime: new Date().getTime(), isLoading: false});
    case actions.IE_BENEFITS_FETCH_ERROR:
      return Object.assign({}, state, {isLoading: false});
    default:
      return state;
  }
};

const parseIeBenefitsData = data => {
  const result = {};
  Object.keys(data).forEach(category => {
    result[category] = data[category].map(benefit => benefit.id);
  });

  return result;
};

const promos = (state = {lastLoadTime: null, isLoading: false}, action) => {
  switch (action.type) {
    case actions.PROMOS_FETCH:
      return Object.assign({}, state, {isLoading: true});
    case actions.PROMOS_FETCH_SUCCESS:
      return Object.assign({}, state, {lastLoadTime: new Date().getTime(), isLoading: false});
    case actions.PROMOS_FETCH_ERROR:
      return Object.assign({}, state, {isLoading: false});
    default:
      return state;
  }
};

export default combineReducers({
  arcoForm,
  areasBillboards,
  auth,
  checkoutData,
  cinemasBillboards,
  config,
  contactForm,
  corpSalesForm,
  dialogs,
  entities,
  fingerprint,
  home,
  ie,
  ieBenefits,
  jobsForm,
  movieTab,
  news,
  notifications,
  landings,
  now,
  posts,
  promos,
  refundRequest,
  showtimesData,
  statesBillboards,
  ui,
  user,
  wallet
});

export const isUserLogged = state => state.user.token !== null;
export const getUserId = state => state.user.info && state.user.info.id;
export const getUserToken = state => state.user.token;
export const getUserInfo = state => state.user && state.user.info || null;
export const isUserIE = state => { const info = getUserInfo(state); return info && info.iecode; };
export const getUserIePoints = state => state.checkoutData.points;
export const getIeCodeResultEmailSent = state => state.checkoutData.ui.ieCodePanel.emailSent;
export const isIeCodePanelVisible = state => state.checkoutData.ui.ieCodePanel.visible;
export const getIeCodeValidateCardNumber = state => state.ui.iePanel.cardNumber;
export const getIeNewNip = state => state.ui.iePanel.newNip;
export const getUserPurchases = state => state.user.purchases.purchases;
export const getUserPurchasesFutureOnly = state => {
  const purchases = getUserPurchases(state);
  const now = (new Date()).getTime() / 1000;
  const timeWindow = 2 * 60 * 60;
  const limit = now - timeWindow;

  return purchases.filter(purchase => {
    return purchase.session.date > timeWindow;
  });
}
export const isUserPurchasesLoading = state => state.user.purchases.loading;
export const getUserSessions = state => state.user.sessions.sessions;
export const isUserSessionsLoading = state => state.user.sessions.loading;
export const isUserSessionBeingKilled = state => state.user.sessions.killing;
export const isUserStuffProcessing = state => {
  for (let i in state.user.isProcessing) {
    if (state.user.isProcessing[i]) {
      return true;
    }
  }
  return false;
};
export const getIeCardData = state => state.user.ieCardData;

export const getSelectedCinemaId = state => state.user.preferences.cinemaId;
export const getSelectedCinema = state => state.entities.cinemas[state.user.preferences.cinemaId];
export const getSelectedAreaId = state => state.user.preferences.areaId;
export const getSelectedArea = state => state.user.preferences.areaId && state.entities.areas[state.user.preferences.areaId];
export const getLandingSelectedArea = state => {
  if (!state.ui.landingSelectedArea) return null;
  if (state.ui.landingSelectedArea.indexOf('state-') === 0) {
    return {
      type: 'state',
      entity: state.entities.states[state.ui.landingSelectedArea.split('-').pop()],
    };
  } else {
    return {
      type: 'area',
      entity: state.entities.areas[state.ui.landingSelectedArea],
    }
  }
};

export const getOtherMainAreaMovies = state => {
  const {movies} = state.entities;
  const cinema = getSelectedCinema(state);

  if (!cinema) return undefined;

  const areaId = cinema.area.id;
  const ids = state.home.areaMovies[areaId];
  const inCinemaIds = state.home.billboardMovies[cinema.id] || [];
  const result = [];

  if (!movies || !ids || !ids.length) {
    return [];
  } else {
    ids.forEach(id => movies[id] && inCinemaIds.indexOf(id) === -1 && result.push(movies[id]));
  }

  return result;
};

export const isMainBillboardLoading = state => { // @todo Decide whether it is better to use this method or areBillboardMoviesLoading.
  const cinemaId = getSelectedCinemaId(state);
  return cinemaId && state.home.billboardMovies[cinemaId] === undefined;
};
export const getMainBillboardMoviesWithoutSearchFilter = state => {
  const {movies} = state.entities;
  const cinemaId = getSelectedCinemaId(state);
  const ids = cinemaId ? state.home.billboardMovies[cinemaId]
      : state.home.defaultBillboardMovies;
  const result = [];

  if (!movies || ids === undefined) {
    return [];
  } else {
    ids.forEach(id => movies[id] && result.push(movies[id]));
  }

  return result;
};

export const getMainBillboardMovies = state => {
  return filterBySearchTerm(state, getMainBillboardMoviesWithoutSearchFilter(state));
};

export const getExclusiveMovies = state => getMoviesFilteredByType(state, 'exclusive') || [];
export const getPopularMovies = state => {
  const {movies} = state.entities;
  const cinemaId = getSelectedCinemaId(state);
  const ids = cinemaId ? state.home.billboardPopularMovies[cinemaId] : [];
  let result = [];

  if (!movies || ids === undefined) {
    return [];
  } else {
    ids.forEach(id => movies[id] && result.push(movies[id]));
  }

  result = result.slice(0, 5);

  return filterBySearchTerm(state, result);
};

export const getPreSaleMovies = state => getMoviesFilteredByType(state, 'presale') || [];
export const getPremiereMovies = state => getMainBillboardMovies(state).filter(movie => movie.type.indexOf('premiere') > -1) || [];
export const getUpcomingMovies = state => filterBySearchTerm(state, Object.values(state.entities.moviesUpcoming)).sort(sortUpcomingMovies) || [];
const getMoviesFilteredByAttribute = (state, attribute) => getMainBillboardMovies(state).filter(movie => movie.attributes.indexOf(attribute) > -1);
const getMoviesFilteredByType = (state, type) => getMainBillboardMovies(state).filter(movie => movie.type.indexOf(type) > -1);
export const getFeaturedMovie = state => {
  const cinema = getSelectedCinema(state);
  let movies;
  
  if (cinema) {
    movies = getCinemaBillboardFull(state, cinema.id)
  } else {
    movies = getMainBillboardMoviesWithoutSearchFilter(state);
  }

  if (!movies) return null;

  movies = movies.filter(movie => movie.featured);
  
  if (movies.length > 0) {
    return movies[0];
  } else {
    return null;
  }
};

const filterBySearchTerm = (state, movies) => {
  const searchTerms = getHeaderSearchTerm(state);

  if (searchTerms) {
    return movies.filter(movie => (
        compareIgnoringAccents(movie.name, searchTerms)
        || compareIgnoringAccents(movie.info.original_title, searchTerms)
        || compareIgnoringAccents(movie.info.director, searchTerms)
        || compareIgnoringAccents(movie.info.cast, searchTerms)
    ));
  } else {
    return movies;
  }
};

const getMoviePopularity = (state, movieId, cinemaId) => state.home.billboardMoviesPopularity[cinemaId][movieId];

const sortUpcomingMovies = (a, b) => {
  const dateA = a.release_date;
  const dateB = b.release_date;

  if (dateA > dateB) {
    return 1;
  } else if (dateA < dateB) {
    return -1;
  } else {
    return 0;
  }
};

export const getAppConfig = state => state.config;
export const getViewText = (state, id) => {
  const config = getAppConfig(state);
  return (('view_texts' in config) && (id in config.view_texts)) ? config.view_texts[id] : undefined;
};

export const getMovie = (state, movieId) => state.entities.movies[movieId];
export const getMovieUpcoming = (state, movieId) => state.entities.moviesUpcoming[movieId];

export const getPromosPromos = state => Object.values(state.entities.promosPromos).sort(sortByProperty('order')) || [];
export const getPromosA = state => Object.values(state.entities.promosA) || [];
export const getPromosFeatured = state => Object.values(state.entities.promosFeatured) || [];
export const getPromosIe = state => Object.values(state.entities.promosIe) || [];
export const getPromosWide = state => Object.values(state.entities.promosWide);
export const getPromosWideFixed = state => Object.values(state.entities.promosWideFixed);
export const isPromosLoading = state => state.promos.isLoading;
export const getPromosLastLoadTime = state => state.promos.lastLoadTime;

export const getLanding = (state, slug) => (slug in state.entities.landings) ? state.entities.landings[slug] : null;

// export const isLandingLoading = state => state.landings.isLoading;
export const isLandingLoading = state => false;
export const getLandingLastLoadTime = state => state.landings.lastLoadTime;
export const getLandingShowtimesData = (state, pageId, area, movieId) => {
  const id = `${pageId}-${area.type}-${area.entity.id}-${movieId}`;
  const {showtimes} = state.landings;
  if (!showtimes || !(id in showtimes)) return null;
  return showtimes[id];
};

export const getCheckoutTimedOut = state => state.checkoutData.timedOut;
export const getCheckoutSession = state => state.checkoutData.data;
export const getCheckoutSeatsLayout = state => state.checkoutData.layout;
export const getCheckoutSelectedSeats = state => state.checkoutData.seats;
export const getCheckoutSeatsToSelect = state => state.checkoutData.seatsCount;
export const areCheckoutSeatsPreSelected = state => state.checkoutData.seatsPreSelected;
export const getCheckoutTransactionId = state => state.checkoutData.order.transactionId;
export const getCheckoutCinema = state => state.entities.cinemas[getCheckoutSession(state).cinema_id];
export const getCheckoutMovie = state => state.entities.movies[getCheckoutSession(state).movie_id];
export const getCheckoutOrderTickets = state => state.checkoutData.order.tickets;
export const getCheckoutOrderTicketsCount = state => {
  let total = 0;
  const tickets = getCheckoutOrderTickets(state);

  for (let ticketId in tickets) {
    total += tickets[ticketId].qty;
  }

  return total;
};
export const getCheckoutOrderSeatsLabels = createSelector([getCheckoutSession, getCheckoutSeatsLayout, getCheckoutSelectedSeats], (session, layout, seats) => {
  const seatsLabels = [];

  if (!session) return seatsLabels;
  if (!layout || !layout.length || !seats.length) return seatsLabels;

  layout.forEach(row => row.seats.forEach(seat => {
    if (seats.indexOf(seat.id) > -1) {
      seatsLabels.push(row.name + seat.label);
    }
  }));

  return seatsLabels;
});
export const getCheckoutTicketsWithQuantity = createSelector([getCheckoutSession, getCheckoutOrderTickets], (session, orderTickets) => {
  if (!session) return {};

  const tickets = session.tickets.map(ticket => {
    const result = Object.assign({}, ticket, {
      qty: orderTickets[ticket.id] ? orderTickets[ticket.id].qty : 0,
    });

    //ticketsSum += result.qty;

    return result;
  });

  Object.keys(tickets).forEach(id => {
    const ticket = tickets[id];
    ticket.enabled = getTicketsAffectingTicket(ticket, tickets).length === 0;
  });

  return tickets;
});

const getTicketsAffectingTicket = (ticket, catalog) => {
  const result = [];

  Object.keys(catalog).forEach(id => {
    const item = catalog[id];

    if (item.id === ticket.id) { return; }
    if (!item.qty) { return }

    if (!ticket.combinable || !item.combinable || item.cant_combine_with.indexOf(ticket.name) >= 0 || (ticket.extra.coupon && item.extra.coupon)) {
      result.push(item);
    }
  });

  return result;
};

export const getCheckoutOrderCandybar = state => state.checkoutData.order.candybar;
export const getCheckoutOrderTicketsParam = state => {
  const result = [];
  const tickets = getCheckoutOrderTickets(state);

  Object.keys(tickets).forEach(ticketId => {
    const ticket = state.entities.tickets[ticketId];
    result.push({
      type: ticketId,
      price: ticket.price,
      qty: tickets[ticketId].qty
    });
  });

  return result;
};

export const getCheckoutOrderTicketsParamForSuggestions = state => {
  const result = [];
  const tickets = getCheckoutOrderTickets(state);

  Object.keys(tickets).forEach(ticketId => {
    const ticket = state.entities.tickets[ticketId];
    
    result.push({
      type: ticketId,
      price: ticket.price,
      qty: tickets[ticketId].qty,
      payment:[{
        id: 'passes',
        multiplier: ticket.pcmultiplier
      }],
      extra: ticket.extra,
    });
  });

  return result;
}

export const getCheckoutOrderCandybarParam = state => {
  const result = [];
  const candybar = getCheckoutOrderCandybar(state);

  Object.keys(candybar).forEach(itemId => {
    const item = state.entities.candybarItems[itemId];
    const orderItem = candybar[itemId];

    Object.keys(orderItem).forEach(unitId => {
      const unit = orderItem[unitId];
      const configs = [];

      Object.keys(unit).forEach(configId => {
        configs.push({
          id: configId,
          value: unit[configId],
        });
      });

      result.push({
        id: itemId,
        type: item.type,
        price: item.price,
        configs,
        comments: unit.comments || '',
      });
    });
  });

  return result;
};
export const getCheckoutFlowDirection = state => state.checkoutData.flowDirection;
export const getCheckoutPaymentConstraints = state => state.checkoutData.paymentConstraints;
export const getCheckoutSelectedPaymentOption = state => {
  const {options, selected} = state.checkoutData.paymentSuggestions;
  return options[selected] || getCheckoutDefaultPaymentOption(state);
};
export const getCheckoutDefaultPaymentOption = state => {
  const result = {
    tickets: getCheckoutOrderTicketsParam(state),
    candybar: getCheckoutOrderCandybarParam(state),
  };
  const ticketsCount = getCheckoutOrderTicketsCount(state);
  const feePerTicket = ticketsCount ? (getCheckoutBookingFee(state) / ticketsCount) : 0;

  Object.keys(result).forEach(typeId => result[typeId].forEach(item => {
    let price;

    if (typeId === 'tickets') {
      price = (item.price + feePerTicket) * item.qty;
    } else if ('qty' in item) {
      price = item.price * item.qty;
    } else {
      price = item.price;
    }

    item.payment = {
      'id': 'cash',
      'value': price,
    };

    const ticket = state.entities.tickets[item.type];
    if (ticket) {
      if ('coupon_code' in ticket.extra) {
        item.payment.coupon = ticket.extra.coupon_code;
      } else if ('coupon' in ticket.extra) {
        item.payment.coupon = ticket.extra.coupon;
      }
    }
  }));

  return result;
};
export const getCheckoutMasterpassData = state => state.checkoutData.masterpass.data;
export const getCheckoutStep = state => state.checkoutData.step;
export const isCheckoutCCRequired = state => {
  const amountByMethod = parseOrderAmountByPaymentMethod(getCheckoutSelectedPaymentOption(state));
  return 'cash' in amountByMethod && amountByMethod.cash > 0;
};
export const isCheckoutIeNipRequired = state => {
  const amountByMethod = parseOrderAmountByPaymentMethod(getCheckoutSelectedPaymentOption(state));
  return ('ie' in amountByMethod && amountByMethod.ie > 0)
      || ('passes' in amountByMethod && amountByMethod.passes > 0);
};
export const isPaymentSuggestionNeeded = state => {
  if (state.checkoutData.paymentConstraints.length && ('card_brand' in state.checkoutData.paymentConstraints[0])) return false;
  
  const canUsePoints = state.checkoutData.points && state.checkoutData.points.length && getCashPaymentMethodsAllowed(state.entities.tickets, state.checkoutData.order.tickets).join(',') !== 'masterpass';
  let ticketsTotal = 0;
  let usingPasses = false;

  state.checkoutData.data.tickets.forEach(ticket => {
    const qty = state.checkoutData.order.tickets[ticket.id] ? state.checkoutData.order.tickets[ticket.id].qty : 0;

    ticketsTotal += qty * ticket.price;
    if (qty && ticket.extra.is_pass) { usingPasses = true; }
  });

  return (canUsePoints && ticketsTotal > 0) || usingPasses;
};
export const isPaymentWithPointsBlocked = state => {
  let result = false;
  state.checkoutData.points && state.checkoutData.points.forEach(p => {
    if (p.status !== 'available') result = true;
  });
  return result;
}
export const isOrderCostZero = state => {
  const amountByMethod = parseOrderAmountByPaymentMethod(getCheckoutSelectedPaymentOption(state));
  let total = 0;
  Object.keys(amountByMethod).forEach(method => total += amountByMethod[method]);
  return total === 0;
};
export const getCheckoutT1SessionId = state => state.checkoutData.t1.sessionId || null;
export const getCheckoutPaymentMethod = state => state.checkoutData.paymentMethod;
export const getCheckoutPaymentMethods = state => state.checkoutData.paymentMethods;
export const getCheckoutPaymentPostError = state => state.checkoutData.payment.postError;
export const getCheckoutNipCode = state => state.checkoutData.ieNip.nip;
export const getSmartIframeUrl = state => state.checkoutData.smart.url;
export const getStripeRedirectUrl = state => state.checkoutData.stripe.url;
export const getWalletUserId = state => state.wallet.uid;
export const getWalletCards = state => state.wallet.data;
export const getWalletStep = state => state.wallet.step;
export const getWalletAddCardInfo = state => state.wallet.addCardInfo;
export const getCheckoutWalletSelectedCard = state => state.wallet.selectedCard;
export const isWalletProcessing = state => state.wallet.isProcessing;
export const isCheckoutTermsChecked = state => state.checkoutData.termsCheck;
export const isPayPalSingleClickAvailable = state => state.checkoutData.payPalSingleClick;
export const getPayPalClientMetadataId = state => state.checkoutData.payPalClientMetadataId;
export const getCheckoutPaymentFormParams = state => state.checkoutData.payment.formData;

export const isUiMovieTabOpen = state => !state.movieTab.hidden;
export const isUiAreaSelectorOpen = state => state.ui.areaSelectorOpen;
export const isUiCinemaSelectorOpen = state => state.ui.cinemaSelectorOpen;
export const getUiAreaSelectorOptions = state => state.ui.areaSelectorOptions;
export const isUiPostRegisterPopupOpen = state => state.ui.postRegisterPopup && isUserLogged(state) && !isUserIE(state);
export const getPostPurchaseIePopupData = state => state.ui.postPurchaseIePopup;
export const isUiAuthPanelProcessing = state => state.ui.authPanel.login.isProcessing
    || state.ui.authPanel.recoverPass.isProcessing
    || state.ui.authPanel.resetPass.isProcessing
    || state.ui.authPanel.register.isProcessing
;
export const isUiAuthPanelRecoverPassSent = state => state.ui.authPanel.recoverPass.sent;
export const isUiAuthPanelResetPassSent = state => state.ui.authPanel.resetPass.sent;

export const getCinemasById = state => state.entities.cinemas;
export const getCinemas = state => Object.values(getCinemasById(state));
export const getCinema = (state, cinemaId) => getCinemasById(state)[cinemaId];
export const getCinemasByArea = (state, areaId) => Object.values(state.entities.cinemas).filter(cinema => cinema.area.id == areaId);
export const getCinemasByState = (state, stateId) => Object.values(state.entities.cinemas).filter(cinema => cinema.state.id == stateId);
export const getCinemaBillboardDates = (state, cinemaId) => {
  const bb = state.cinemasBillboards[cinemaId];
  if (!bb) {
    return null;
  }

  return bb.reduce((carry, movie) => carry.concat(movie.versions.reduce((carry, version) => carry.concat(version.sessions.map(session => session.datetime.substr(0, 10))), [])), []).filter(unique).sort();
};
export const getCinemaBillboardFull = (state, cinemaId) => state.cinemasBillboards[cinemaId];
export const getCinemaBillboardForDate = (state, cinemaId, date) => getCinemaBillboardWithFilters(state, cinemaId, {date});
export const getCinemaBillboardWithFilters = (state, cinemaId, filters) => {
  let movies = getCinemaBillboardFull(state, cinemaId);
  const result = [];

  if (!movies) { return movies; }

  if (filters.movie && filters.movie.length) {
    movies = movies.filter(movie => filters.movie.indexOf(movie.id) > -1);
  }

  movies.forEach(movie => {
    let versions = filterAvailableVersions(movie.versions);

    if (versions.length && filters.date) {
      versions = filterVersionsForDate(versions, filters.date);
    }

    if (versions.length && filters.format) {
      versions = getFilteredVersions(filters.format, versions);
    }

    if (versions.length && filters.lang) {
      versions = getFilteredVersions(filters.lang, versions);
    }

    if (versions.length && filters.hours) {
      versions = filterVersionsByHours(versions, filters.hours.min, filters.hours.max);
    }

    result.push(Object.assign({}, movie, {
      versions
    }));
  });

  return result.filter(movie => movie.versions.length);
};

export const getStateBillboardDates = (state, stateId) => state.statesBillboards.dates[stateId] || [];
export const getStateBillboardFull = (state, stateId, date) => state.statesBillboards.data[stateId + '_' + date];
export const getStateBillboardWithFilters = (state, stateId, filters) => getZoneBillboardWithFilters(state, getStateBillboardFull(state, stateId, filters.date), filters);
export const getAreaBillboardDates = (state, areaId) => state.areasBillboards.dates[areaId] || [];
export const getAreaBillboardFull = (state, areaId, date) => state.areasBillboards.data[areaId + '_' + date];
export const getAreaBillboardWithFilters = (state, areaId, filters) => getZoneBillboardWithFilters(state, getAreaBillboardFull(state, areaId, filters.date), filters);
export const getZoneBillboardWithFilters = (state, cinemas, filters) => {
  const result = [];

  if (!cinemas) { return cinemas; }

  if (filters.cinema && filters.cinema.length) {
    cinemas = cinemas.filter(cinema => filters.cinema.indexOf(cinema.id) > -1);
  }

  cinemas.forEach(cinema => {
    let movies = [];
    let cinemaMovies = cinema.movies;

    if (filters.movie && filters.movie.length) {
      cinemaMovies = cinemaMovies.filter(movie => filters.movie.indexOf(movie.id) > -1);
    }

    cinemaMovies.forEach(movie => {
      let versions = filterAvailableVersions(movie.versions);

      if (!versions.length) {
        return;
      }

      if (filters.date) {
        versions = filterVersionsForDate(versions, filters.date);
      }

      if (filters.format && filters.format.length) {
        versions = getFilteredVersions(filters.format, versions);
      }

      if (filters.lang && filters.lang.length) {
        versions = getFilteredVersions(filters.lang, versions);
      }

      if (filters.hours) {
        versions = filterVersionsByHours(versions, filters.hours.min, filters.hours.max);
      }

      versions.length && movies.push(Object.assign({}, movie, {
        versions
      }));
    });

    movies.length && result.push(Object.assign({}, cinema, {
      movies
    }));
  });

  return result;
};
export const isBillboardLoading = (state, areaType, areaId, date) => state[areaType + 'sBillboards'].isLoading[areaId + '_' + (date || 'initial')];
export const getBillboardLastLoadTime = (state, areaType, areaId, date) => state[areaType + 'sBillboards'].lastLoadTime[areaId + '_' + (date || 'initial')];

export const getState = (state, stateId) => state.entities.states[stateId];

export const getArea = (state, areaId) => state.entities.areas[areaId];
export const getAreas = (state) => Object.values(state.entities.areas);
export const getAreasById = (state) => state.entities.areas;
export const getStatesById = (state) => state.entities.states;
export const getStates = (state) => {
  const result = [];
  const states = state.entities.states;
  const existingIds = Object.keys(states);
  const fixedOrder = ["8","16","14"];

  fixedOrder.forEach(id => existingIds.indexOf(id) > -1 && result.push(states[id]));

  return result.concat(
      Object.values(states).filter(state => fixedOrder.indexOf(state.id.toString()) === -1)
          .sort(sortByProperty('name'))
  );
};
export const getStatesWithCinemas = state => {
  const states = getStates(state);
  const cinemas = getCinemas(state);
  const areasWithCinemas = cinemas.map(cinema => cinema.area.id);

  states.forEach(state => state.areas = state.areas.filter(area => areasWithCinemas.indexOf(area.id) > -1));
  
  return states.filter(state => state.areas.length > 0);
};

export const getBootstrapConfig = state => state.config;
export const getBootstrapFilters = state => {
  const config = getBootstrapConfig(state);
  const attributes = {};

  for (let key in config.attributes.movies) {
    const attribute = config.attributes.movies[key];
    if (attribute.filter) attributes[key] = attribute;
  }

  attributes['2d'] = {display_name: 'Tradicional'};

  return attributes;
};

export const getModals = state => state.entities.modals ? Object.values(state.entities.modals) : [];

export const getHeaderSearchTerm = state => state.home.searchTerm;

export const isContactFormProcessing = state => state.contactForm.isProcessing;
export const isContactFormSubmitted = state => state.contactForm.isSubmitted;

export const isJobsFormProcessing = state => state.jobsForm.isProcessing;
export const isJobsFormSubmitted = state => state.jobsForm.isSubmitted;

export const isArcoFormProcessing = state => state.arcoForm.isProcessing;
export const isArcoFormSubmitted = state => state.arcoForm.isSubmitted;
export const getArcoFormRef = state => state.arcoForm.ref;

export const isCorpSalesFormProcessing = state => state.corpSalesForm.isProcessing;
export const isCorpSalesFormSubmitted = state => state.corpSalesForm.isSubmitted;

export const isIeSignUpFormProcessing = state => state.ie.signUpFormProcessing;
export const isIeSignUpSubmitted = state => state.ie.signUpFormSubmitted;
export const isIeBenefitsLoading = state => state.ieBenefits.isLoading;
export const getIeBenefitsLastLoadTime = state => state.ieBenefits.lastLoadTime;
export const getIeBenefitsByCategory = state => {
  const result = {};
  const {categories} = state.ieBenefits;

  Object.keys(categories).forEach(category => {
    result[category] = categories[category].map(id => getIeBenefit(state, id));
  });

  return result;
};
export const getIeBenefit = (state, id) => state.entities.ieBenefits[id];

export const getNowSessions = (state, cinemaId) => state.now.sessions[cinemaId] || null;

export const isNewsLoading = state => state.news.isLoading;
export const getNewsLastLoadTime = state => state.news.lastLoadTime;
export const getNews = state => state.news.ids ? state.news.ids.map(id => state.entities.news[id]) : null;
export const getNewsSingle = (state, articleId) => state.entities.news[articleId] || null;

export const getPost = (state, slug) => state.entities.posts[slug];
export const isPostLoading = (state, slug) => state.posts.isLoading[slug] || false;
export const getPostLastLoadTime = (state, slug) => state.posts.lastLoadTime[slug] || false;

export const getCheckoutBookingFee = state => state.checkoutData.bookingFee;
export const getCheckoutSelectedPaymentOptionBookingFee = state => {
  const fallback = state.checkoutData.bookingFee;
  const {paymentSuggestions: {selected, options} = {selected: null, options: []}} = state.checkoutData;
  if (!selected || !(selected in options)) return fallback;
  
  return options[selected].booking_fee;
};
export const getCheckoutNonRedeemableTickets = state => state.checkoutData.paymentSuggestions.nonRedeemableTickets.map(item => state.entities.tickets[item]);

export const getFingerprint = state => state.fingerprint;
export const getLoginEmail = state => state.auth.email;
export const getLoginPassword = state => state.auth.password;

export const getSignUpManualStep = state => !state.auth.preFillInfo.token && !state.auth.preFillInfo['sign-up-options'] ? '1' : '2';
export const getSignUpManualPreFillInfo = state => state.auth.preFillInfo;

export const getUserProfileEdit = state => state.ui.userProfileEdit;

export const getNotificationsMovieUpcomingSubscriptions = state => state.notifications.upcomingMovies;
export const getNotificationRequestCallback = state => state.notifications.callback;
export const getNotificationsPermission = state => state.notifications.permission;

export const isRefundRequestProcessing = state => state.refundRequest.isProcessing;
export const getRefundRequestStep = state => state.refundRequest.step;
export const getRefundRequestCodes = state => state.refundRequest.codes;
export const getRefundRequestProcessedData = state => state.refundRequest.processedData;
export const getRefundRequestTransaction = state => state.refundRequest.transaction;

export const getUiCustomLoadingAnimation = state => state.ui.customLoadingAnimation;
export const isAuthPinDialogOpen = state => state.ui.authPanel.pin.visible;
export const getAuthPinDialogContext = state => state.ui.authPanel.pin.context;
export const getAuthPinDialogError = state => state.ui.authPanel.pin.error;
export const getAuthPinDialogMessage = state => state.ui.authPanel.pin.message;
export const getAuthPinDialogOriginalAction = state => state.ui.authPanel.pin.originalAction;


export const getCheckoutSeatTypes = createSelector([getBootstrapConfig, getCheckoutSession], (config, session) => {
  const types = config.seat_types;
  const overrides = session.seat_types_override;

  if (!overrides) return types;

  const typesToOverride = Object.keys(overrides);
  const result = {};

  Object.keys(types).forEach(k => {
    if (typesToOverride.indexOf(k) >= 0) {
      result[k] = overrides[k];
    } else {
      result[k] = types[k];
    }
  });

  return result;
});

export const getIeSignUpOptions = state => state.ie.signUpOptions;
export const getIeSignUpResult = state => state.ie.signUpResult;
export const getIePaymentUrl = state => state.ie.paymentUrl;
export const getIeSignUpUserInfo = state => state.ie.userInfo;

export const isSomethingLoading = state => state.ui.authPanel.login.isProcessing
    || state.checkoutData.isLoading
    || state.checkoutData.ticketsSelection.isProcessing
    || state.checkoutData.seatsSelection.isProcessing
    || state.checkoutData.masterpass.isProcessing
    || state.checkoutData.smart.isProcessing
    || state.checkoutData.stripe.isProcessing
    || state.checkoutData.ieNip.isValidating
    || state.checkoutData.paymentSuggestions.isLoading
    || state.checkoutData.candybar.isLoading
    || state.checkoutData.ui.ieCodePanel.processing
    || state.checkoutData.ui.promoCodesPanel.processing
    || state.wallet.isProcessing
    || isNewsLoading(state)
    || isPromosLoading(state)
    || isUserSessionBeingKilled(state)
    || isUserStuffProcessing(state)
    || isIeSignUpFormProcessing(state)
    || state.ie.signUpOptionsLoading
    || state.auth.isProcessing
    || isUiAuthPanelProcessing(state)
    || isUserCouponsLoading(state)
    || isUserCouponsProcessing(state);