import React from 'react';
import ErrorPage from './ErrorPage';
import Button from './Button';
import NoContentMessage from './common/NoContentMessage';

const Error404Page = () => <ErrorPage>
  <NoContentMessage bigTitle='Ups!' icon='fal fa-circle-exclamation'>
    <p>
    Lo que sea que estés buscando no está por aquí.
    <br/>
    Si fuera tú, regresaría al Home lo antes posible.
    </p>
    <Button blockSmall to='/'>Volver a la cartelera</Button>
  </NoContentMessage>
</ErrorPage>;

export default Error404Page;