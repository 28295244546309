import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {fixMovieUrl} from '../utils/urls';
import {Link} from 'react-router-dom';

const StyledMoviePoster = styled.div`
  & .c-card__link { min-height: 5em; }
  ${props => {
    if (props.alone === true || props.noCarousel === true) {
      return 'max-width: 100%; width: 100%;';
    } else {
      if (props.size === 'large') {
        return `
          @media screen and (min-width: 576px) { max-width: ${1 / 2.5 * 100}%; }
          @media screen and (min-width: 768px) { max-width: ${1 / 3.25 * 100}%; }
          @media screen and (min-width: 992px) { max-width: ${1 / 4.25 * 100}%; }
          @media screen and (min-width: 1200px) { max-width: ${1 / 5.25 * 100}%; }
          @media screen and (min-width: 1400px) { max-width: ${1 / 6.25 * 100}%; }
          @media screen and (max-width: 575px) { max-width: ${1 / 1.5 * 100}%; }

          margin-bottom: 1rem;
          `;
      } else {
        return `
          @media screen and (min-width: 576px) { max-width: ${1 / 3.25 * 100}%; }
          @media screen and (min-width: 768px) { max-width: ${1 / 4.25 * 100}%; }
          @media screen and (min-width: 992px) { max-width: ${1 / 6.25 * 100}%; }
          @media screen and (min-width: 1200px) { max-width: ${1 / 8.25 * 100}%; }
          @media screen and (max-width: 575px) { max-width: ${1 / 2.5 * 100}%; }

          margin-bottom: 0.75rem;
          `;
      }
    }
  }};

  .c-card__content {
    ${props => props.alone !== true ? `
        background-image: -webkit-gradient(linear, left top, left bottom, color-stop(45%, rgba(0,0,0,0)), color-stop(80%, #000));
        background-image: linear-gradient(to bottom, rgba(0,0,0,0) 45%, #000 80%);
    ` : `background: none;`
    }
  }

  .c-card__link {
    overflow: hidden;
    will-change: opacity;
    &:hover:before { opacity: 0.5; }
    &:before { content: none; }
  }
  .c-card__title { z-index: 2; }

  ${props => props.size !== 'large' && `
    .c-card__badge.ribbon { text-overflow: ellipsis; overflow: hidden; }
  `}
`;

const ReleaseDate = styled.div`
  background: var(--primary);
  color: white;
  line-height: 1em;
  font-size: 0.5em;

  @media screen and (min-width: 768px) {
    font-size: 10px;
    line-height: 15px;
  }
`;

const Video = styled.video`
  height: 100%;
  left: 0;
  object-fit: fill;
  overflow: hidden;
  position: absolute;
  top: 0px;
  width: 100%;
`;

class MoviePoster extends React.Component {
  constructor(props) {
    super(props);

    this.onVideoLoad = this.onVideoLoad.bind(this);
    this.onVideoError = this.onVideoError.bind(this);

    this.state = {
      videoReady: false
    };
  }

  onVideoLoad() {
    this.setState({videoReady: true});
  }

  onVideoError() {
    this.setState({videoReady: false});
  }

  getClass() {
    if (this.props.size === 'large') {
      return '';
    } else {
      return 'c-card--small';
    }
  }

  renderRating() {
    if (this.props.size === 'large') {
      return <span className="c-card__rating"><small>Puntaje</small> {Math.round(this.props.movie.score * 100 / 5)}%</span>;
    } else {
      return '';
    }
  }

  renderRibbon() {
    if (this.props.noRibbons === true) return null;

    let {ribbons} = this.props.movie;

    if (ribbons && this.props.ribbonPickerFn) {
      ribbons = this.props.ribbonPickerFn(ribbons);
    }

    if (!ribbons || !ribbons.length) {
      return '';
    }

    return (
        <div className="c-card__badge ribbon">
          {ribbons[0].label}
        </div>
    );
  }

  renderReleaseDate() {
    const date = moment(this.props.movie.release_date, 'YYYY-MM-DD');

    return (
        <ReleaseDate className="c-card__badge">
          {date.format('DD [de] MMMM')}
        </ReleaseDate>
    );
  }

  render() {
    const {movie} = this.props;
    const url = !('doNotFixUrl' in this.props) || this.props.doNotFixUrl !== true
        ? fixMovieUrl(movie.url)
        : movie.url;

    return (
        <StyledMoviePoster className="c-slider__item" data-movie-id={this.props.movie.id} size={this.props.size} alone={this.props.alone} noCarousel={this.props.noCarousel}>
          <div className={'c-card ' + this.getClass()}>
            <Link to={url || ""} className="c-card__link">
              <img className="c-card__image" src={movie.poster_small} alt={movie.name} />
              {/* <div className="c-card__content">
                {this.props.noTitle !== true && <h2 className="c-card__title">{movie.name}</h2>}
                {{this.renderRating()}}
              </div> */}
            </Link>
            {movie.release_date ? this.renderReleaseDate() : this.renderRibbon()}
          </div>
        </StyledMoviePoster>
    )
  }
}

MoviePoster.Styled = StyledMoviePoster;
export default MoviePoster;
