import React from "react";
import styled from "styled-components";
import LinkButton from "../LinkButton";
import IeSignUpOptions from "../user/IeSignUpOptions";
import { breakpoints } from "../../utils/styles";
import BackButton from "../common/BackButton";

const Container = styled.div`
    background: var(--page-bg);
    bottom: ${props => props.visible ? 0 : '-100%'};
    height: 98%;
    position: fixed;
    right: 0;
    width: 100%;
    overflow-y: auto;
    transition: bottom 0.5s ease-in-out, right 0.5s ease-in-out;

    @media screen and (min-width: ${breakpoints.tablet}px) {
        height: 100%;
        right: ${props => props.visible ? 0 : '-66.67vw'};
        top: 0;
        width: 66.66vw;
    }
`;

function SignUpManualIEOptions(props) {
    return <Container className="px-5 py-4" visible={props.visible}>
        <div className="mb-3">
            <BackButton onBackClick={props.close} label="Selecciona tu beneficio de bienvenida de Cinemex Loop"/>
        </div>
        <IeSignUpOptions levels={props.options} onLevelSelect={props.onIEOptionSelect} hidePriceZero altStyles />
    </Container>;
}

export default SignUpManualIEOptions;