import React from "react";
import { connect } from "react-redux";
import MDSpinner from "react-md-spinner";
import styled from "styled-components";
import { userCouponsActivate, userCouponsFetch } from "../../../actions/creators/user/coupons";
import { getUserCouponsData } from "../../../reducers/user/coupons";
import ActiveCouponsButton from "./ActiveCouponsButton";
import { CouponCategory, CouponData } from "./interfaces";
import CouponsCategorySelector from "./CouponsCategorySelector";
import CouponsBlocks from "./CouponsBlocks";
import MyCoupons from "./MyCoupons";
import CouponDetails from "./CouponDetails";
import { getUserId } from "../../../reducers";

interface BlockData {
    id:string,
    name:string,
    icon:string,
};

interface ResData {
    blocks:Array<BlockData>,
    categories:Array<CouponCategory>,
    coupons:{
        available:Array<CouponData>,
        active:Array<CouponData>
    }
};

const Container = styled.div`
    p { line-height: 1.3em; }
`;

interface Props {
    activate:Function,
    data:ResData,
    fetch:Function,
    userId?: number
};

interface State {
    category?:string,
    couponId?:Number,
    myCouponsOpen:Boolean
};

function getAvailabilityColor(coupons:Array<CouponData>):string {
    const availabilities = coupons.map(coupon => coupon.status);
    
    if (availabilities.indexOf('low') > -1) return 'var(--color-status-danger)';
    if (availabilities.indexOf('mid') > -1) return 'var(--color-status-mid)';
    return 'var(--color-status-safe)';
}

class UserCoupons extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            myCouponsOpen: false
        };
    }

    componentDidMount(): void {
        this.props.userId && this.props.fetch();
    }

    render() {
        if (!this.props.userId) return null;

        const { data } = this.props;
        const coupon = this.getSelectedCoupon();
        let { category } = this.state;

        if (data === null) return <div className="text-center my-5"><MDSpinner singleColor="var(--primary)"/></div>;

        if (!category && data.categories.length) category = data.categories[0].id;
        const categoryCoupons = category
            ? data.coupons.available.filter(coupon => coupon.categories.indexOf(category) > -1)
            : data.coupons.available;

        const myCouponsAvailabilityColor = getAvailabilityColor(data.coupons.active);
        
        return <Container>
            <ActiveCouponsButton onClick={() => this.setState({myCouponsOpen: true})} count={data.coupons.active.length} availabilityColor={myCouponsAvailabilityColor} />
            <p>Encuentra aquí tus cupones, verifica su vigencia, actívalos y canjéalos de forma fácil dando click.</p>
            {data && <React.Fragment>
                <CouponsCategorySelector category={category} categories={data.categories} setCategory={category => this.setState({category})}/>
                <CouponsBlocks blocks={data.blocks} coupons={categoryCoupons} setCoupon={coupon => this.setState({couponId: coupon.id})}/>
            </React.Fragment>}
            {this.state.myCouponsOpen && <MyCoupons coupons={data.coupons.active} onClose={() => this.setState({myCouponsOpen: false})} setCoupon={coupon => this.setState({couponId: coupon.id})} availabilityColor={myCouponsAvailabilityColor}/>}
            {coupon && <CouponDetails coupon={coupon} onActivate={this.props.activate} onClose={() => this.setState({couponId: undefined})}/>}
        </Container>;
    }

    getSelectedCoupon() {
        const { couponId } = this.state;
        if (!couponId) return null;

        let coupon = this.props.data.coupons.available.filter(c => c.id === couponId);
        if (!coupon.length) coupon = this.props.data.coupons.active.filter(c => c.id === couponId);

        return coupon.length ? coupon[0] : null;
    }
}

const mapStateToProps = state => ({
    data: getUserCouponsData(state),
    userId: getUserId(state),
});

const mapDispatchToProps = dispatch => ({
    activate: coupon => dispatch(userCouponsActivate(coupon.id)),
    fetch: () => dispatch(userCouponsFetch()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserCoupons);