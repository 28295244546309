import React from 'react';
import styled from 'styled-components';

const Blocker = styled.div`
    background: var(--page-bg);
    bottom: 0;
    cursor: wait;
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
`;

export default props => <Blocker/>;