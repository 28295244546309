import React from 'react';
import {Link} from 'react-router-dom';
import styled, { css } from 'styled-components';
import { breakpoints } from '../utils/styles';

interface StylesProps {
  block?: boolean,
  blockSmall?: boolean,
  defaultPadding?: boolean,
  disabled?: boolean,
  facebook?: boolean,
  large?: boolean,
  outline?: boolean,
  primary?: boolean,
  secondary?: boolean,
  small?: boolean,
  smallPadding?: boolean,
  transparent?: boolean,
};

const BaseStyles = css<StylesProps>`
  ${props => {
    if (props.disabled) return `
      --local-text-color: #000000;
      --local-border-color: #F0F0F0;
      --local-background-color: #F0F0F0;

      body.theme-market & {
        --local-text-color: #999999;
        --local-border-color: #4D4D4D;
        --local-background-color: #4D4D4D;
      }
        
      body.theme-platinum & {
        --local-text-color: var(--text-primary);
        --local-border-color: var(--text-primary);
        --local-background-color: transparent;
        opacity: 0.5;
      }
        
      body.theme-platinum .dyn-modal-dialog & {
        --local-text-color: #666;
        --local-border-color: #666;
      }
    `;

    if (props.facebook) return `
      --local-text-color: #FFFFFF;
      --local-border-color: #4761AE;
      --local-background-color: #4761AE;
    `;
    
    if (props.transparent) return `
      --local-text-color: #FFFFFF;
      --local-border-color: #FFFFFF;
      --local-background-color: transparent;

      body.theme-platinum & {
        --local-text-color: var(--text-primary);
        --local-border-color: var(--text-primary);
      }
    `;
    
    if (props.primary) return `
      --local-text-color: #FFFFFF;
      --local-border-color: var(--primary);
      --local-background-color: var(--primary);
      
      body.theme-platinum & {
        --local-text-color: var(--text-primary);
      }
    `;

    return `
      --local-text-color: var(--text-primary);
      --local-border-color: var(--text-primary);
      --local-background-color: transparent;

      body.theme-market .dyn-modal-dialog & {
        --local-text-color: #000000;
        --local-border-color: #000000;
      }

      body.theme-platinum .dyn-modal-dialog & {
        --local-text-color: var(--primary-c);
        --local-border-color: var(--primary-c);
      }
    `;
  }}

  background: var(--local-background-color);
  border: 1px solid var(--local-border-color);
  // border-radius: 2rem;
  color: var(--local-text-color);
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  outline: none;
  padding: 0.75em 2.5em;
  position: relative;
  text-align: center;
  transition: background-color .1s ease-in-out;
  white-space: nowrap;

  &:hover {
    color: var(--local-text-color);
    outline: none;

    ${props => {
      if (props.disabled) return `
        --local-text-color: #FFFFFF;
        --local-border-color: #E6E6E6;
        --local-background-color: #E6E6E6;
      `;

      if (props.primary) return `
        --local-border-color: var(--accent);
        --local-background-color: var(--accent);

        body.theme-platinum & {
          --local-border-color: var(--primary-c);
          --local-background-color: var(--primary-c);
        }
      `;

      return '';
    }}
  }

  ${props => props.block && `
    display: block;
    width: 100%;
  `};

  ${props => props.blockSmall && `
    @media screen and (max-width: ${breakpoints.tablet}px) {
      display: block;
      width: 100%;
    }
  `};

  ${props => props.small && `
    font-size: 0.825rem;
    padding: 0.75em 1.25em;

    body.theme-platinum &,
    body.theme-market & { padding: 0.8em 1.25em 0.6em; }
  `}

  ${props => props.smallPadding && 'padding-right: 0.5em; padding-left: 0.5em;'}

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid transparent;
  }

  &:hover:before { border-color: var(--local-border-color); }
  
  body.theme-market &,
  body.theme-platinum & {
    text-transform: uppercase;
  }
`;

const StyledButton = styled.button<StylesProps>`
  ${BaseStyles}
`;

const BaseLinkStyles = css<StylesProps>`
  ${BaseStyles}

  &:hover {
    color: var(--local-text-color);
  }
  &, &:hover { text-decoration: none; }
`;

const StyledLink = styled(Link)<StylesProps>`
  ${BaseLinkStyles}
`;

const StyledAnchor = styled.a<StylesProps>`
  ${BaseLinkStyles}
`;

interface Props extends StylesProps{
  children?: React.ReactNode,
  className?: string,
  focus?: boolean,
  href?: string,
  type?: string,
  onClick?: any,
  rel?: string,
  submit?: boolean,
  target?: string,
  title?: string,
  to?: string,
};

class Button extends React.PureComponent<Props> {
  private btn = React.createRef<HTMLElement>();

  componentDidMount() {
    if (this.props.focus && this.btn.current) {
      this.btn.current.focus();
    }
  }

  render() {
    const props = this.props;
    let Element;
    
    if (props.to) {
      Element = StyledLink;
    } else if (props.href) {
      Element = StyledAnchor;
    } else {
      Element = StyledButton;
    }

    return <Element
        innerRef={btn => this.btn = btn}
        className={props.className}
        onClick={props.onClick}
        href={props.href}
        target={props.target}
        to={props.to}
        rel={props.rel}
        type={props.to ? undefined : (props.submit ? 'submit' : 'button')}
        block={props.block}
        blockSmall={props.blockSmall}
        disabled={props.disabled}
        facebook={props.facebook}
        smallPadding={props.smallPadding}
        defaultPadding={props.defaultPadding}
        primary={props.primary}
        small={props.small}
        title={props.title}
        transparent={props.transparent}
    >
      {props.children}
    </Element>;
  }
}

export default Button;