import React from "react";
import { connect } from 'react-redux';
import Button from "../Button";
import { authShowLogin, authShowRegister } from '../../actions/creators';
import NoContentMessage from "../common/NoContentMessage";

interface Props {
    children: React.ReactNode,
    icon?: string,
    openLogin: Function,
    openRegister: Function
}

class RequireAuthMessage extends React.Component<Props> {
    constructor(props) {
        super(props);
    
        this.onLoginClick = this.onLoginClick.bind(this);
        this.onRegisterClick = this.onRegisterClick.bind(this);
    }
    
    onLoginClick(e) {
        e.preventDefault();
        this.props.openLogin();
    }

    onRegisterClick(e) {
        e.preventDefault();
        this.props.openRegister();
    }

    render() {
        return <NoContentMessage icon={`fa-light ${this.props.icon || 'fa-ticket'}`}>
            <div className="mt-5">{this.props.children}</div>
            <Button block onClick={this.onLoginClick} to="/login" className='mb-3'>Iniciar sesión</Button>
            <Button block primary onClick={this.onRegisterClick} to="/registro">Registrarme</Button>
        </NoContentMessage>;
    }
}

const mapDispatchToProps = dispatch => ({
    openLogin: () => dispatch(authShowLogin()),
    openRegister: () => dispatch(authShowRegister()),
});

export default connect(
    null,
    mapDispatchToProps
)(RequireAuthMessage);