import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js'
import React, { useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js';
import Button from '../../Button';
import { validateEmail } from '../../../utils/validation';
import { StripeCardNumberElementChangeEvent } from '@stripe/stripe-js';
import { PaymentConstraint } from './interfaces';

interface Props {
    constraints: Array<PaymentConstraint>,
    onPaymentSubmit: Function,
    user: any, // @todo Define user info model
}

export default function StripeForm(props: Props) {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState<string | null>(null);
    const [processing, setProcessing] = useState(false);
    const [email, setEmail] = useState(props.user ? props.user.email : '');
    const [name, setName] = useState(props.user ? [props.user.first_name, props.user.last_name, props.user.mothers_last_name].join(' ') : '');
    const [cardValid, setCardValid] = useState<boolean>();
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async ev => {
        ev.preventDefault();

        if (processing) return;
        if (!stripe || !elements) return;

        setSubmitted(true);

        if (!email || !validateEmail(email)) {
            setMessage('Por favor, ingresa tu email.');
            return;
        }

        if (!name) {
            setMessage('Por favor, ingresa tu nombre.');
            return;
        }

        const cardNumber = elements.getElement(CardNumberElement);
        if (!cardNumber) {
            console.log('no card');
            return;
        }

        setProcessing(true);
        setMessage('');

        const payload = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumber,
            billing_details: {
                name,
                email
            }
        });

        if (payload.error) {
            setMessage(payload.error.message || 'Error.');
            setProcessing(false);
        } else {
            setMessage(null);
            setProcessing(false);

            const params = {
                name,
                email,
                stripe: { payment_method:payload.paymentMethod.id }
            };
            props.onPaymentSubmit(params);
        }
    };

    function onCardChange(e: StripeCardNumberElementChangeEvent) {
        if (!e.complete) {
            setCardValid(undefined);
            return;
        }

        props.constraints.forEach(constraint => {
            if ('card_brand' in constraint) {
                if (e.brand === constraint.card_brand) {
                    setCardValid(true);
                    setMessage('');
                } else {
                    setCardValid(false);
                    setMessage(constraint.error_message || 'Los datos ingresados no cumplen los requisitos necesarios para esta compra.');
                }
            }
        });
    }

    const bodyStyle = getComputedStyle(document.body);
    const options = {
        classes: {
            base: 'form-control',
            focus: 'form-control form-control--focus',
            invalid: 'form-control ' + (submitted ? 'form-control-invalid' : ''),
            complete: 'form-control ' + (submitted ? 'form-control-valid' : ''),
        },
        style: {
            base: {
                color: bodyStyle.getPropertyValue('--text-primary'),
                fontFamily:'MaisonNeue, sans-serif'
            },
            complete: {
                color: bodyStyle.getPropertyValue(submitted ? '--color-status-safe' : '--text-primary'),
            },
            invalid: {
                color: bodyStyle.getPropertyValue(submitted ? '--color-status-danger': '--text-primary'),
            }
        }
    };
    
    const cardOptions = Object.assign({}, options);
    if (cardValid === false) {
        cardOptions.classes.complete = 'form-control form-control-invalid';
        cardOptions.style.complete.color = bodyStyle.getPropertyValue('--color-status-danger');
    }

    return <div className="container pad-y">
        <form id="payment-form" className={submitted ? 'was-validated' : ''} onSubmit={handleSubmit}>
            <div className='form-group form-group--label-helper not-empty'>
                <label>Email</label>
                <input type='email' className={`form-control form-control-${email.length > 0 && validateEmail(email) ? 'valid' : 'invalid'}`} placeholder='Email' onChange={e => setEmail(e.target.value)} value={email}/>
            </div>
            <div className='form-group form-group--label-helper not-empty'>
                <label>Nombre y apellido</label>
                <input type='text' className={`form-control form-control-${name.length > 0 ? 'valid' : 'invalid'}`} placeholder='Nombre y apellido' onChange={e => setName(e.target.value)} value={name}/>
            </div>
            <div className={cardValid === false ? 'was-validated' : ''}>
                <div className='form-group form-group--label-helper not-empty'>
                    <label>Número de tarjeta</label>
                    <CardNumberElement
                        onChange={onCardChange}
                        options={cardOptions}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <div className='form-group form-group--label-helper not-empty'>
                        <label>Vencimiento</label>
                        <CardExpiryElement options={options}/>
                    </div>
                </div>
                <div className="col-6">
                    <div className='form-group form-group--label-helper not-empty'>
                        <label>Código de seguridad</label>
                        <CardCvcElement options={options}/>
                    </div>
                </div>
            </div>
            <div className='pt-3'>
                <Button primary block submit>Finalizar</Button>
            </div>
            {message && <div id="payment-message" className="pt-3">{message}</div>}
        </form>
    </div>;
}