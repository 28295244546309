import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import NavBarLayout from '../NavBarLayout';
import UserProfile from './UserProfile';
import UserPurchases from './UserPurchases';
import ErrorPage from '../ErrorPage';
import {getBootstrapConfig, getUserInfo, isUserLogged} from '../../reducers';
import {authShowLogin} from '../../actions/creators'
import {facebookLogin, logout} from '../../actions/creators/auth';
import UserWallet from './UserWallet';
import UserDeleteAccount from './UserDeleteAccount';
import { deleteAccount } from '../../actions/creators/user';
import { openPopup } from '../../actions/creators/auth/apple';
import UserSessionsList from './UserSessionsList';
import IERegistrationPage from '../IERegistrationPage';
import IEMigrationPage from '../IEMigrationPage';
import { hasIeAction } from '../../utils/misc';
import UserCoupons from './coupons/UserCoupons';
import RequireAuthMessage from './RequireAuthMessage';

class UserPage extends React.Component {
  constructor(props) {
    super(props);

    this.deleteAccount = this.deleteAccount.bind(this);
  }

  render() {
    if (!this.props.isUserLogged) {
      return <ErrorPage>{this.renderNoUserLogged()}</ErrorPage>;
    }

    const routes = [
      {
        title: 'Mis datos',
        component: UserProfile,
        path: ['/usuario/', '/usuario/cambiar-nip/'],
      },
      {
        title: 'Historial de compras',
        component: UserPurchases,
        path: '/usuario/mis-compras',
      },
      {
        title: 'Boletos activos',
        render: () => <UserPurchases future={true}/>,
        path: '/usuario/mis-compras/tickets-activos',
        showSectionTitle: false,
      },
      {
        title: 'Cupones',
        component: UserCoupons,
        path: '/usuario/cupones',
        showSectionTitle: false,
      },
    ];
  
    if (this.props.wallet === 't1') {
      routes.push({
        title: 'Mis tarjetas',
        showTitle: false,
        component: UserWallet,
        path: '/usuario/mis-tarjetas',
      });
    }

    if (this.props.user) {
      routes.push({
        title: 'Inscripción Cinemex Loop',
        component: IERegistrationPage,
        path: '/usuario/loop/inscripcion',
        nav: !this.props.user.iecode && hasIeAction(this.props.user, 'sign-up')
      });

      if (this.props.user.iecode) {
        routes.push({
          title: 'Cambia tu tarjeta Cinemex Loop',
          render: () => <IEMigrationPage action='ie-migration'/>,
          path: '/usuario/loop/migracion',
          nav: this.props.user.migration_available
        });

        routes.push({
          title: 'Cambio de Nivel Cinemex Loop',
          render: () => <IEMigrationPage action='ie-renewal'/>,
          path: '/usuario/loop/renovacion',
          nav: false
        });

        routes.push({
          title: 'Cambio de Nivel Cinemex Loop',
          render: () => <IEMigrationPage action='ie-renewal'/>,
          path: '/usuario/loop/cambio-de-nivel',
          nav: this.props.user.renewal_available
        });
      }
    }
    
    routes.push({
      title: 'Dispositivos vinculados',
      showTitle: false,
      render: () => <UserSessionsList />,
      path: '/usuario/sesiones-activas',
      nav: false
    });
  
    routes.push({
      title: 'Eliminar cuenta',
      showTitle: false,
      render: () => <UserDeleteAccount onSubmit={this.deleteAccount} appleLogin={this.props.appleLogin} facebookLogin={this.props.facebookLogin}/>,
      path: '/usuario/eliminar-cuenta',
    });
  
    routes.push({
      title: 'Cerrar sesión',
      onClick: this.props.logout,
    });

    return <NavBarLayout fixedTitle="Perfil" routes={routes}/>;
  }

  renderNoUserLogged() {
    if (this.props.location.pathname.indexOf('/tickets-activos') >= 0) {
      return <RequireAuthMessage>
        <p>Para guardar y ver tus boletos activos, primero debes iniciar sesión.</p>
      </RequireAuthMessage>;
    }
      
    if (this.props.location.pathname.indexOf('/cupones') >= 0) {
      return <RequireAuthMessage>
          <p>Para activar y canjear cupones, primero debes inciar sesión.</p>
        </RequireAuthMessage>;
    }

    return <RequireAuthMessage icon='fa-circle-exclamation'>
      <p>Debes iniciar sesión para acceder a esta sección.</p>
    </RequireAuthMessage>;
  }

  deleteAccount(params) {
    this.props.deleteAccount(this.props.history, params);
  }

}

const mapStateToProps = state => ({
  isUserLogged: isUserLogged(state),
  wallet: getBootstrapConfig(state).wallet,
  user: getUserInfo(state)
});

const mapDispatchToProps = dispatch => ({
  authShowLogin: () => dispatch(authShowLogin()),
  logout: () => dispatch(logout()),
  deleteAccount: (history, params) => dispatch(deleteAccount(history, params)),
  facebookLogin: (callback, options) => dispatch(facebookLogin(callback, options)),
  appleLogin: callback => dispatch(openPopup(callback)),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserPage));