import React from 'react';
import styled from 'styled-components';
import DatesScroller from './common/DatesScroller';

const PaddedContainer = styled.div`
  background: var(--bg-secondary);
  padding: 1rem 0 1rem var(--pad-x);

  body.theme-market & {
    background: var(--page-bg);
    padding-top: 0;
    padding-bottom: 0;
  }

  body.theme-platinum & {
    background: var(--primary-c);
  }
`;

interface Props {
  dates: Array<string>,
  date: string,
  onDateSelect: Function,
}

function ShowtimesDateFilter({ dates, date, onDateSelect }: Props) {
  if (!dates.length) {
    return <PaddedContainer>Aún no hay horarios para esta película en el cine seleccionado.</PaddedContainer>;
  }

  return <PaddedContainer>
    <DatesScroller dates={dates} selectedDate={date} onDateSelect={onDateSelect} />
  </PaddedContainer>;
}

export default ShowtimesDateFilter;