import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

const Btn = styled.button`
  border: 1px solid #666666;
  position: absolute;
  top: 1.25rem;
  right: 0;
  margin-top: -0.5em;
  width: 2.5em;
  padding: 0;
  height: 2.5em;

  &.btn-primary { border-color: var(--primary); }
`;

const Label = styled.strong`
  font-size: ${11/12.6}em;
  font-weight: 700;
  line-height: 1.2;
  margin: 0;
  text-transform: uppercase;
  padding-top: 1.55rem;
`;

const Value = styled.span`
  border-bottom: 1px solid #ddd;
  padding: 1.25rem 0;
`;

const InfoRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 2rem;

  &:last-child ${Value} { border-bottom: 0; }
`;

const InfoItem = props => (
    <InfoRow key={props.label}>
      <Label>{props.label}</Label>
      <Value>{props.content}</Value>
    </InfoRow>
);

const renderReleaseDate = ({movie, onNotificationToggle, notificationStatus})  => {
  if (!movie.release_date) { return null; }

  const date = moment(movie.release_date, 'YYYY-MM-DD');

  return <div style={{position:'relative'}}>
      <InfoItem label="Fecha de estreno" content={date.format('DD [de] MMMM [de] YYYY')} />
      {('Notification' in window) && onNotificationToggle && <Btn className={`btn btn-${notificationStatus ? 'primary' : 'white'}`} onClick={onNotificationToggle} title={`${notificationStatus ? 'Desactivar' : 'Activar'} notificación`}>
        <i class="fal fa-bell"></i>
      </Btn>}
    </div>;
};

const MovieDetailsBlock = props => {
  const {movie} = props;
  const bullets = [
    {
      label: 'Título original',
      content: movie.info.original_title,
    },
    {
      label: 'Clasificación',
      content: movie.info.rating,
    },
    {
      label: 'Género',
      content: movie.info.genre.join(', '),
    },
    {
      label: 'Director',
      content: movie.info.director,
    },
    {
      label: 'Actores',
      content: movie.info.cast,
    },
    {
      label: 'País',
      content: movie.info.country,
    },
    {
      label: 'Duración',
      content: movie.info.duration,
    },
    {
      label: 'Distribuidor',
      content: movie.info.distributor,
    },
  ];

  return (
      <React.Fragment>
        {movie.info.sinopsis && <p className='mt-5'>{movie.info.sinopsis}</p>}

        {renderReleaseDate(props)}
        {bullets.map(bullet => bullet.content && <InfoItem key={bullet.label} {...bullet}/>)}
      </React.Fragment>
  );
};

export default MovieDetailsBlock;