import React from 'react';
import styled from 'styled-components';

const SliderContainer = styled.div`
  margin: 0;
  margin-left: ${props => props.margin !== false ? 'var(--pad-x)' : '0'};
  position: relative;
  
  .tns-outer > button { display: none; }
  
  .tns-nav {
    bottom: 0.5em;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 1;
    
    & > button {
      background: white;
      border: 0;
      width: 0.65em;
      height: 0.65em;
      border-radius: 1em;
      margin: 0 0.25em;
      display: inline-block;
      opacity: 0.25;
      outline: none;
      padding: 0;
      
      &.tns-nav-active {
        opacity: 0.75;      
      }
    }
  }
`;

export default props => (
    <SliderContainer {...props}>{props.children}</SliderContainer>
);