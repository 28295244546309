import React from "react";
import styled from "styled-components";

const Container = styled.div`
    padding: 4rem 1rem;
    text-align: center;

    & > p { margin: 3rem 0; }
`;

const Icon = styled.i`
    font-size: 3.5rem;
`;

const BigTitle = styled.p`
    color: var(--primary);
    font-family: var(--ff-subtitle);
    font-size: ${(40/12.6).toFixed(2)}rem;
    font-weight: 700;
    line-height: 1.2;

    body.theme-market & {
        font-weight: 900;
        margin: 1em 0;
        text-transform: uppercase;
    }
`;

const Title = styled.p`
    font-size: 1.5em;
    font-weight: 900;
    line-height: 1.2;

    body.theme-market & {
        font-family: var(--ff-title);
    }
`;

const Content = styled.div`
    body.theme-market & { font-size: 1em; }
`;

interface Props {
    bigTitle?: string,
    children?: React.ReactNode,
    icon?: string,
    title?: string,
}

function NoContentMessage(props: Props) {
    return <Container>
        {props.icon && <Icon className={props.icon}></Icon>}
        {props.bigTitle && <BigTitle>{props.bigTitle}</BigTitle>}
        {props.title && <Title>{props.title}</Title>}
        <Content>
            {props.children}
        </Content>
    </Container>;
}

export default NoContentMessage;