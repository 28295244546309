import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import SidebarPanel from "./SidebarPanel";
import CloseCross from "./CloseCross";
import {authClose} from '../actions/creators';
import LoginPanel from "./auth/LoginPanel";
import RecoverPassPanel from "./RecoverPassPanel";
import RegisterPanel from "./auth/RegisterPanel";
import ModalPanel from './ModalPanel';
import CompleteSignUpPanel from './CompleteSignUpPanel';
import styled from 'styled-components';
import { breakpoints } from '../utils/styles';
import icon from '../assets/svg/loop-icon.svg';

const Container = styled.div`
  @media (min-width: ${breakpoints.desktop}px) {
    & > .l-wrapper__content {
      background: var(--primary);
      color: var(--page-bg);
      min-height:100vh;
      overflow: hidden;

      body.theme-market & { color: var(--text-primary); }
      body.theme-platinum & { 
        background:var(--primary-c);
        color: var(--text-primary);
      }

      &:before {
        content: '';
        mask-image: url(${icon});
        mask-size: 100%;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        mask-position: center;
        width: 50rem;
        height: 50rem;
        position: absolute;
        right: -5rem;
        bottom: -10rem;
        background: var(--accent);

        body.theme-market & { background: var(--page-bg); }
        body.theme-platinum & { background: var(--primary); }
      }
    }
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
`;

const AboutTitle = styled.h1`
  color: var(--page-bg);
   font-size: ${(70/12.6).toFixed(2)}rem !important;

  body.theme-market & { color: var(--page-bg); font-size: ${(56/12.6).toFixed(2)}rem !important; font-weight: 900; }
  body.theme-platinum & { color: var(--primary); font-size: ${(56/12.6).toFixed(2)}rem !important; }
`;

const Copy = styled.p`
  font-size: 1.35em;
  font-weight: 300;
  margin-bottom: 0.5em;

  & > strong { font-weight: 700; }
`;

const Benefits = styled.ul`
  font-size: 1.15em;
  font-weight: 300;
  margin-top: 1.5em;
`;

class AuthPanel extends React.Component {
  constructor(props) {
    super(props);

    this.getPanel = this.getPanel.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  getPanel() {
    switch (this.props.panel) {
      case 'complete-sign-up':
        return <CompleteSignUpPanel code={this.props.match.params.token} history={this.props.history} title="Completa tu registro" action="complete-sign-up"/>;
      case 'recover-pass':
        return <RecoverPassPanel/>;
      case 'reset-pass':
        return <CompleteSignUpPanel code={this.props.match.params.token || this.props.location.search.match(/\bcode=([0-9a-zA-z]+)\b/)[1]} history={this.props.history} title="Recupera tu contraseña" action="reset-pass"/>;
      case 'register':
        return <RegisterPanel/>;
      case 'login':
      default:
        return <LoginPanel/>;
    }
  }

  onClose() {
    if (this.props.cleanRoute) {
      this.props.history.push('/');
    }

    this.props.authClose();
  }

  render() {
    if (!this.props.open) {
      return '';
    }

    return (
        <ModalPanel className="fade-in">
          {/*<BodyBlocker color="208,3,61"/>*/}
          <Container className="l-wrapper l-wrapper--register">
            <div className="l-wrapper__content">
              {(this.props.panel === 'register' || this.props.panel === 'login') && (
                  <Content className="p-5 fade-in">
                    <AboutTitle className="mb-5 title-1">¿Sabes qué es Cinemex Loop?</AboutTitle>
                    <Copy><strong>El mejor programa de recompensas</strong> que premia tu diversión.</Copy>
                    <Copy>Regístrate aquí y empieza a disfrutar la experiencia de ser miembro Cinemex Loop.</Copy>
                    <div className="row">
                      <div className="col-12 col-xl-8">
                        <Benefits className="fa-ul">
                          <li className="mb-3">
                            <span className="fa-li"><i className="fal fa-hundred-points mr-3"></i></span>
                            Acumula puntos en cada una de tus compras.
                          </li>
                          <li className="mb-3">
                            <span className="fa-li"><i className="fal fa-ticket mr-3"></i></span>
                            Canjea tus puntos por boletos en la taquilla y productos en dulcería.
                          </li>
                          <li className="mb-3">
                            <span className="fa-li"><i className="fal fa-user-group mr-3"></i></span>
                            Todos los martes 2x1.
                          </li>
                          <li className="mb-3">
                            <span className="fa-li"><i className="fal fa-popcorn mr-3"></i></span>
                            Combo Lunes y Jueves a precio preferencial.
                          </li>
                          <li className="mb-3">
                            <span className="fa-li"><i className="fal fa-party-horn mr-3"></i></span>
                            Recibe regalos en el mes de tu cumpleaños.
                          </li>
                          <li className="mb-3">
                            <span className="fa-li"><i className="fal fa-cup-straw-swoosh mr-3"></i></span>
                            Refill de palomitas y/o refresco a precio especial.
                          </li>
                          <li className="mb-3">
                            <span className="fa-li"><i className="fal fa-badge-percent mr-3"></i></span>
                            Promociones todos los días.
                          </li>
                        </Benefits>
                      </div>
                    </div>
                  </Content>
              )}
            </div>
            <SidebarPanel>
              <CloseCross onClick={this.onClose} theme="dark"/>
              {this.getPanel()}
            </SidebarPanel>
          </Container>
        </ModalPanel>
    );
  }
}

const mapStateToProps = (state, props) => ({
  open: props.open || state.auth.open,
  panel: props.panel || state.auth.panel,
});

const mapDispatchToProps = dispatch => ({
  authClose: () => dispatch(authClose()),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthPanel));