import React from 'react';
import {connect} from 'react-redux';
import CinemaPageContent from './CinemaPageContent';
import CinemaSidebar from './CinemaSidebar';
import TwoColumnLayout from '../TwoColumnLayout';
import {extractUrlParams} from '../../utils/billboard';
import {getBillboardShowtimesDates} from '../../utils/showtimes';
import {getCinema,getCinemaBillboardFull,getCinemaBillboardForDate} from '../../reducers';
import Header from '../Header';
import { css } from 'styled-components';

const HeaderStyled = css`
  @media screen and (max-width: 767px) {
    body.theme-platinum & { background: var(--primary-b); }
  }
`;

class CinemaPage extends React.Component {
  render() {
    return <TwoColumnLayout
        MainContent={CinemaPageContent}
        SidebarContent={CinemaSidebar}
        Header={<Header baseStyle={HeaderStyled} logoSmNegative/>}
        fixedSidebar
        sidebarMobileToggle
        noSidePadding
        noTopMargin
        {...this.props}
        baseUrl={`/cine/${this.props.match.params.id}/${this.props.match.params.slug}/`}
    />;
  }
}

const mapStateToProps = (state, props) => {
  const urlParams = extractUrlParams(props.location.pathname);
  let movies = getCinemaBillboardFull(state, props.match.params.id);
  const dates = movies ? getBillboardShowtimesDates(movies) : [];
  const date = urlParams.date || (dates.length && dates[0]);

  movies = date ? getCinemaBillboardForDate(state, props.match.params.id, date) : [];
  let versions = [];

  movies && movies.forEach(movie => versions = versions.concat(movie.versions));

  return {
    cinemaId: props.match.params.id,
    cinema: getCinema(state, props.match.params.id),
    date,
    dates,
    movies,
    urlParams
  };
};

export default connect(
  mapStateToProps
)(CinemaPage);