import React from 'react';
import Header from './Header';
import Button from './Button';

class MessagePage extends React.Component {
    render() {
        return <React.Fragment>
        <div className={'l-wrapper l-wrapper--toggle'} id="the-body">
          <div className="l-wrapper__content">
            <Header withUserCinemaNav={false} />
  
            <div className="text-center" style={{maxWidth:'700px',margin:'0 auto'}}>
              <h4 className="my-5 px-5" style={{margin:'5rem auto',lineHeight:'1.5em'}}>
                {this.renderContent()}
              </h4>
              <Button to="/" primary>Volver a cinemex.com</Button>
            </div>
          </div>
        </div>
      </React.Fragment>;
    }

    renderContent() {
      switch (this.props.match.params.page) {
        case 'vincular-ie': return this.renderLinkIeContent();
        case 'desvincular-ie': return this.renderUnlinkIeContent();
        case 'confirmacion-email': return this.renderEmailConfirmationContent();
        case 'cambio-email': return this.props.match.params.result !== 'error' ? 'Tu correo electrónico ha sido actualizado con éxito.' : 'Ocurrió un error. Por favor inténtalo nuevamente.';
        case 'cuenta-eliminada': return 'Tu cuenta ha sido eliminada.';
      }
    }

    renderLinkIeContent() {
      if (this.props.match.params.result !== 'error') {
        return 'Tu cuenta Cinemex Loop fue vinculada con éxito.'
      } else {
        return 'No hemos podido vincular tu cuenta Cinemex Loop. Por favor intenta nuevamente más tarde. Si el problema persiste, por favor comunícate con nosotros.'
      }
    }

    renderUnlinkIeContent() {
      if (this.props.match.params.result !== 'error') {
        return 'Tu cuenta Cinemex Loop fue desvinculada con éxito.'
      } else {
        return 'No hemos podido desvincular tu cuenta Cinemex Loop. Por favor intenta nuevamente más tarde. Si el problema persiste, por favor comunícate con nosotros.'
      }
    }

    renderEmailConfirmationContent() {
      if (this.props.match.params.result !== 'error') {
        return '¡Tu cuenta ha sido creada con éxito!'
      } else {
        return 'El enlace no es válido.'
      }
    }
}

export default MessagePage;