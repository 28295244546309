import React from 'react';
import {connect} from 'react-redux';
import TicketsSelection from "./TicketsSelection/TicketsSelection";
import SeatsSelection from "./SeatsSelection/SeatsSelection";
import CandybarSelection from "./CandybarSelection";
import PaymentMethodSelection from "./PaymentMethodSelection";
import PaymentSelection from "./PaymentSelection";
import PaymentSidebar from "./PaymentSidebar";
import Confirmation from "./Confirmation";
import {parseOrderAmountByPaymentMethod} from '../../utils/checkout';
import IeNipPanel from './IeNipPanel';
import { nipRequestCancel, nipRequestSubmit } from '../../actions/creators/checkout/paymentSuggestions';
import { getAppConfig, getCheckoutNipCode, getPayPalClientMetadataId, getCheckoutSelectedPaymentOption } from '../../reducers';
import { AnalyticsCheckout } from '../../utils/analytics';

class CheckoutContent extends React.Component {
  constructor(props) {
    super(props);

    this.fraudNetOptions = null;
    this.fraudNetScript = null;
  }

  componentDidMount() {
    if (this.props.clientMetadataId) {
      this.renderFraudnet();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.step !== this.props.step) {
      window.scrollTo(0, 0);
      AnalyticsCheckout.trackStep(this.props.step, {
        session: this.props.data,
        movie: this.props.movie,
        cinema: this.props.cinema,
        transaction: this.props.step === 'confirmation' ? this.props.confirmationData : null,
        order: {
          tickets: this.props.tickets
        }
      });
    }

    if (this.props.clientMetadataId && prevProps.clientMetadataId !== this.props.clientMetadataId) {
      this.renderFraudnet();
    }
  }

  componentWillUnmount() {
    console.log(this.fraudNetOptions, this.fraudNetScript);
    if (this.fraudNetOptions) document.body.removeChild(this.fraudNetOptions);
    if (this.fraudNetScript) document.body.removeChild(this.fraudNetScript);

    this.fraudNetOptions = null;
    this.fraudNetScript = null;
  }

  getStep() {
    switch(this.props.step) {
      case 'tickets':
        return <TicketsSelection tickets={this.props.tickets} ticketsSum={this.props.ticketsSum} session={this.props.data}/>;
      case 'seats':
        return <SeatsSelection/>;
      case 'candybar':
        return <CandybarSelection/>;
      case 'payment-suggestions':
        return <PaymentMethodSelection/>;
      case 'payment':
        return <PaymentSelection/>;
      default:
        return '';
    }
  }

  getPaymentSidebar() {
    const usingDefaultGateway =  this.props.paymentGateway === 'default';

    if (this.props.step !== 'payment') {
      return '';
    }
    
    if (this.props.ccRequired && this.props.nipRequired && !this.props.nip) {
      return <IeNipPanel onCancel={this.props.nipRequestCancel} onSubmit={this.props.nipRequestSubmit} />;
    }
    
    if (
      (!this.props.ccRequired && this.props.nipRequired)
      ||
      (this.props.paymentMethod && (usingDefaultGateway || this.props.paymentGateway === 'stripe-elements' || this.props.paymentMethod !== 'credit-card'))
      ||
      (!this.props.paymentMethod && !this.props.cash && !this.props.nipRequired)
    ) {
      return <PaymentSidebar paymentMethod={this.props.paymentMethod} />;
    } else {
      return '';
    }
  }

  renderFraudnet() {
    let script = document.createElement('script');
    script.setAttribute('type', 'application/json');
    script.setAttribute('fncls', 'fnparams-dede7cc5-15fd-4c75-a9f4-36c430ee3a99');
    script.innerHTML = JSON.stringify({
        "f": this.props.clientMetadataId,
        "s": this.props.payPalMerchantId
    });
    document.body.appendChild(script);
    this.fraudNetOptions = script;

    script = document.createElement('script');
    script.src = "https://c.paypal.com/da/r/fb.js";
    document.body.appendChild(script);
    this.fraudNetScript = script;
  }

  render() {
    return (
        <React.Fragment>
          {this.props.step === 'confirmation' && <Confirmation data={this.props.confirmationData}/>}
          {this.getStep()}
          {this.getPaymentSidebar()}

          <p className="mt-3 checkout-disclaimer">Los boletos son válidos únicamente para el día y horario seleccionado. No se realizarán reembolsos ni cambios de horarios una vez realizada la compra.</p>
        </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  // const {options, selected} = state.checkoutData.paymentSuggestions;
  // const amountByMethod = options && selected ? parseOrderAmountByPaymentMethod(options[selected]) : {};
  const amountByMethod = parseOrderAmountByPaymentMethod(getCheckoutSelectedPaymentOption(state));
  const cash = ('cash' in amountByMethod) ? amountByMethod.cash : 0;
  const ccRequired = cash > 0;
  const nipRequired = ('ie' in amountByMethod && amountByMethod.ie > 0) // @todo check isCheckoutIeNipRequired function as it does exactly the same thing.
      || ('passes' in amountByMethod && amountByMethod.passes > 0); 

  return {
    cash,
    ccRequired,
    clientMetadataId: getPayPalClientMetadataId(state),
    confirmationData: state.checkoutData.confirmation,
    data: state.checkoutData.data,
    nip: getCheckoutNipCode(state),
    nipRequired,
    paymentMethod: state.checkoutData.paymentMethod,
    paymentGateway: state.checkoutData.paymentGateway,
    payPalMerchantId: getAppConfig(state).paypal.merchant_id,
    step: state.checkoutData.step,
    tickets: props.tickets,
    ticketsSum: props.ticketsSum,
  }
};

const mapDispatchToProps = dispatch => ({
  nipRequestCancel: () => dispatch(nipRequestCancel()),
  nipRequestSubmit: nip => dispatch(nipRequestSubmit(nip)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckoutContent);