import React from 'react';
import BillboardMovieBlock from './BillboardMovieBlock';
import NoContentMessage from "../common/NoContentMessage";
import { AnalyticsBillboards } from '../../utils/analytics';
import styled from 'styled-components';

const CinemaName = styled.h1`
  background: var(--bg-secondary);
  border-top: 1px solid var(--text-primary);
  font-size: ${(30/12.6).toFixed(2)}rem;
  margin: 0;
  padding-bottom: 1em;
  padding-top: 1em;

  body.theme-market & { background: none; }
  body.theme-platinum & { background: none; border-color: var(--primary-b-hr); }
`;

class BillboardCinemaBlock extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
    this.onIntersect = this.onIntersect.bind(this);
    this.observer = new IntersectionObserver(this.onIntersect, {threshold: 1});
    this.tracked = [];
  }

  componentDidMount() {
    this.addListeners();
  }

  componentDidUpdate() {
    this.addListeners();
  }

  componentWillUnmount() {
    this.observer = null;
  }

  addListeners() {
    this.ref.current.querySelectorAll('.billboard-movie-block').forEach(elem => this.observer.observe(elem));
  }

  onIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const movieId = parseInt(entry.target.getAttribute('data-movie-id'), 10);

        if (this.tracked.indexOf(movieId) > -1) return;

        const movie = this.getMovie(movieId);
        if (movie) {
          AnalyticsBillboards.trackMovie(movie, this.props.cinema);
          this.tracked.push(movieId);
        }
      }
    });
  }

  getMovie(movieId) {
    const {movies} = this.props;
    for (let i = 0; i < movies.length; i++) {
      if (movies[i].id === movieId) {
        return movies[i];
      }
    }
  }

  render() {
    const {cinema, movies} = this.props;

    return <div className="mb-5" ref={this.ref}>
      <div className={(this.props.header === 'desktop-only' ? 'd-none d-md-block ' : '')}>
        <CinemaName className='container title-2'>{cinema.name}</CinemaName>
      </div>
      {movies.length ? (
          movies.map(movie => <BillboardMovieBlock key={`${cinema.id}-${movie.id}`} date={this.props.date} movie={movie}/>)
      ) : (
        <div className="list-group-item px-5 py-2">
          <NoContentMessage
            icon='fal fa-ticket'
            title="No hay películas disponibles con los filtros seleccionados."
          >
            <p>Puedes probar con otras opciones de filtrado.</p>
          </NoContentMessage>
        </div>
      )}
    </div>;
  }
}

export default BillboardCinemaBlock;