import React from 'react';
import { connect } from 'react-redux';
import uniqid from 'uniqid';
import Blocker from "./Blocker";
import Button from './Button';
import FieldRow from './FieldRow';
import * as validators from '../utils/validation';
import {formatDateDmy} from '../utils/dates';
import TwoColumnLayout from './TwoColumnLayout';
import {getArcoFormRef, getCinemas, getStates, isArcoFormProcessing, isArcoFormSubmitted} from '../reducers';
import {sortByName} from '../utils/arrays';
import { arcoFormShow, arcoFormSubmit } from '../actions/creators';
import { dialogAlert } from '../actions/creators/dialogs';

const getStatesChoices = props => props.states;
const getCinemasChoices = props => props.cinemas;

const fields = {
    first_name: {
        label: 'Primer nombre',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa tu nombre.'
    },
    middle_name: {
        label: 'Segundo nombre',
        id: uniqid(),
    },
    last_name: {
        label: 'Apellido paterno',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa tu apellido paterno.'
    },
    mothers_last_name: {
        label: 'Apellido materno',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa tu materno.'
    },
    birth_date: {
        label: 'Fecha de nacimiento',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateBirthDate],
        validationMessage: 'Ingresa tu fecha de nacimiento.',
        placeholder: 'dd/mm/yyyy',
        formatterFn: formatDateDmy
    },
    address: {
        label: 'Domicilio',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa tu dirección.'
    },
    zip_code: {
        label: 'Código postal',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty, validators.validateNumber5Digits],
        validationMessage: 'Ingresa tu código postal.'
    },
    state: {
        label: 'Estado',
        required: true,
        id: uniqid(),
        getChoicesMethod: getStatesChoices,
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Selecciona tu estado.'
    },
    id_type: {
        label: 'Identificación',
        required: true,
        id: uniqid(),
        choices: [
            {id: '1', name: 'Pasaporte'},
            {id: '2', name: 'Credencial del Instituto Federal Electoral. (IFE)'},
            {id: '3', name: 'Cartilla del servicio Militar Nacional'},
            {id: '4', name: 'Cedula profesional'},
            {id: '5', name: 'Cartilla de identidad postal (expedida por SEPOMEX)'},
            {id: '6', name: 'Certificado o constancia de estudios'},
            {id: '7', name: 'Credencial de afiliacion del IMSS'},
            {id: '8', name: 'Credencial de afiliacion al ISSSTE'},
            {id: '9', name: 'Documento migratorio'},
        ],
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Selecciona el tipo de identificación.'
    },
    email: {
        label: 'Email',
        type: 'email',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateEmail],
        validationMessage: 'Ingresa tu email.'
    },
    phone: {
        label: 'Teléfono',
        id: uniqid(),
    },
    contact_hours: {
        label: 'Horario de contacto',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa el horario de contacto.'
    },
    id_attach: {
        label: 'Anexo que acredite la identidad del titular',
        type: 'file',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Adjunta la documentación.',
        half: false,
    },
    rep_first_name: {
        label: 'Primer nombre',
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa tu nombre.'
    },
    rep_middle_name: {
        label: 'Segundo nombre',
        id: uniqid(),
    },
    rep_last_name: {
        label: 'Apellido paterno',
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa tu apellido paterno.'
    },
    rep_mothers_last_name: {
        label: 'Apellido materno',
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa tu materno.'
    },
    rep_email: {
        label: 'Email',
        type: 'email',
        id: uniqid(),
        validationFns: [validators.validateEmail],
        validationMessage: 'Ingresa tu email.'
    },
    rep_id: {
        label: 'Identificación oficial del representante legal (pasaporte, credencial de elector, cartilla)',
        type: 'file',
        id: uniqid(),
        half: false,
    },
    rep_power: {
        label: 'Poder donde se acredite la representación del titular',
        type: 'file',
        id: uniqid(),
        half: false,
    },
    rel_cmx: {
        label: 'Indique por favor el tipo de relación que tiene usted con Cinemex',
        id: uniqid(),
        required: true,
        choices: [
            {id: 'employee', name: 'Empleado'},
            {id: 'ie', name: 'Miembro de Cinemex Loop'},
            {id: 'provider', name: 'Proveedor'},
            {id: 'applicant', name: 'Solicitante o candidato a trabajar en'},
            {id: 'billing', name: 'Facturación'},
            {id: 'other', name: 'Otro'},
        ],
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Selecciona el tipo de relación.',
        half: false,
    },
    rel_employee_num: {
        label: 'Indique su número de empleado',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa el número de empleado.',
        half: false,
    },
    rel_ie_num: {
        label: 'Indique su número de miembro Cinemex Loop',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa el número de miembro Cinemex Loop.',
        half: false,
    },
    rel_company: {
        label: 'Compañía',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa el nombre de la compañía.',
        half: false,
    },
    rel_position_type: {
        label: 'Tipo de puesto',
        required: true,
        id: uniqid(),
        choices: [
            {id: 'corporate', name: 'Corporativo'},
            {id: 'cinema', name: 'Complejo'},
        ],
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Selecciona el tipo de puesto.'
    },
    rel_cinema: {
        label: 'Seleccione el cine',
        required: true,
        id: uniqid(),
        getChoicesMethod: getCinemasChoices,
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Selecciona el cine.',
    },
    rel_application_sent: {
        label: 'Marque este campo si ya envió su solicitud de empleo',
        type: 'checkbox',
        id: uniqid(),
        half: false,
    },
    rel_rfc: {
        label: 'RFC',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa el RFC.',
        half: false,
    },
    rel_other: {
        label: 'Especifique cuál',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Especifica el tipo de relación.',
        half: false,
    },
    request: {
        label: 'En el ejercicio del derecho de acceso sobre mis datos de carácter personal, solicito a Producciones Expreso Astral S.A. de C.V. en adelante Cinemex lo siguiente (seleccionar una opción)',
        required: true,
        id: uniqid(),
        choices: [
            {id: 'access', name: 'Acceso a la información que poseen de mis Datos Personales, Patrimoniales y/o Sensibles.'},
            {id: 'modify', name: 'Rectificación o modificación de los siguientes Datos Personales en el sentido que se indican a continuación:'},
            {id: 'cancel', name: 'Cancelación de alguna finalidad o tratamiento de mis Datos Personales, Patrimoniales y/o Sensibles, que estén estipulados en el Aviso de Privacidad.'},
            {id: 'refusal', name: 'Oposición a la transferencia o al tratamiento de mis Datos Personales, Patrimoniales y/o Sensibles.'},
        ],
        expanded: true,
        half: false,
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Selecciona una opción.',
    },
    req_comments: {
        label: 'Comentarios',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa tus comentarios.',
        half: false,
    },
    req_field: {
        label: 'Dato que quiere modificar',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa el dato a modificar.',
        half: false,
    },
    req_current: {
        label: 'Dato actual',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa el dato actual.',
    },
    req_update: {
        label: 'Actualizar por',
        required: true,
        id: uniqid(),
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Ingresa el dato actualizado.',
    },
    req_doc: {
        label: 'Documentación que acredite la modificación del dato',
        required: true,
        id: uniqid(),
        type: 'file',
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Adjunta la documentación.',
        half: false,
    },
    req_doc_extra: {
        label: 'Documentación adicional (opcional)',
        id: uniqid(),
        type: 'file',
        half: false,
    },
};

const fieldSets = [
    {
        label: 'Información general',
        fields: ['first_name', 'middle_name', 'last_name', 'mothers_last_name', 'birth_date', 'address', 'zip_code', 'state', 'id_type', 'email', 'phone', 'contact_hours', 'id_attach'],
    },
    {
        label: 'Representante del titular',
        fields: ['rep_first_name', 'rep_middle_name', 'rep_last_name', 'rep_mothers_last_name', 'rep_email'],
    },
    {
        text: 'Para acreditar el poder de representación deberá adjuntar la siguiente documentación (Art. 29, Art. 35 de la LFPDPPP)',
        fields: ['rep_id', 'rep_power']
    },
    {
        fields: ['rel_cmx', 'rel_employee_num', 'rel_ie_num', 'rel_company', 'rel_position_type', 'rel_cinema', 'rel_application_sent', 'rel_rfc', 'rel_other']
    },
    {
        fields: ['request', 'req_comments', 'req_field', 'req_current', 'req_update', 'req_doc', 'req_doc_extra']
    }
];

class ArcoForm extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.validate = this.validate.bind(this);
        this.validateField = this.validateField.bind(this);

        const state = {};

        Object.keys(fields).forEach(key => {
            state[key + '_value'] = fields[key].type === 'checkbox' ? false : '';
            state[key + '_valid'] = null;
        });

        state['rel_employee_num_visible'] = false;
        state['rel_ie_num_visible'] = false;
        state['rel_company_visible'] = false;
        state['rel_position_type_visible'] = false;
        state['rel_cinema_visible'] = false;
        state['rel_application_sent_visible'] = false;
        state['rel_rfc_visible'] = false;
        state['rel_other_visible'] = false;
        state['req_comments_visible'] = false;
        state['req_field_visible'] = false;
        state['req_current_visible'] = false;
        state['req_update_visible'] = false;
        state['req_doc_visible'] = false;
        state['req_doc_extra_visible'] = false;

        this.state = state;
    }

    componentDidMount() {
        this.props.show();
    }

    componentDidUpdate(prevProps, prevState) {
        const relChanged = prevState.rel_cmx_value !== this.state.rel_cmx_value;
        const reqChanged = prevState.request_value !== this.state.request_value;
        const positionTypeChanged = prevState.rel_position_type_value !== this.state.rel_position_type_value;

        if (relChanged || positionTypeChanged) {
            let rel_employee_num = false;
            let rel_ie_num = false;
            let rel_company = false;
            let rel_position_type = false;
            let rel_cinema = false;
            let rel_application_sent = false;
            let rel_rfc = false;
            let rel_other = false;

            switch (this.state.rel_cmx_value) {
                case 'employee': rel_employee_num = true; break;
                case 'ie': rel_ie_num = true; break;
                case 'provider': rel_company = true; break;
                case 'applicant': rel_position_type = true; rel_application_sent = true; break;
                case 'billing': rel_rfc = true; break;
                case 'other': rel_other = true; break;
            }

            rel_cinema = rel_position_type && this.state.rel_position_type_value === 'cinema';
            
            this.setState(state => ({
                rel_employee_num_visible: rel_employee_num,
                rel_ie_num_visible: rel_ie_num,
                rel_company_visible: rel_company,
                rel_position_type_visible: rel_position_type,
                rel_cinema_visible: rel_cinema,
                rel_application_sent_visible: rel_application_sent,
                rel_rfc_visible: rel_rfc,
                rel_other_visible: rel_other,
                rel_employee_num_value: relChanged ? '' : this.state.rel_employee_num_value,
                rel_ie_num_value: relChanged ? '' : this.state.rel_ie_num_value,
                rel_company_value: relChanged ? '' : this.state.rel_company_value,
                rel_position_type_value: relChanged ? '' : this.state.rel_position_type_value,
                rel_cinema_value: relChanged || positionTypeChanged ? '' : this.state.rel_cinema_value,
                rel_application_sent_value: relChanged ? '' : this.state.rel_application_sent_value,
                rel_rfc_value: relChanged ? '' : this.state.rel_rfc_value,
                rel_other_value: relChanged ? '' : this.state.rel_other_value,
            }));
        }

        if (reqChanged) {
            let req_comments = false;
            let req_field = false;
            let req_current = false;
            let req_update = false;
            let req_doc = false;
            let req_doc_extra = false;

            switch (this.state.request_value) {
                case 'modify': req_field = true; req_current = true; req_update = true; req_doc = true; req_doc_extra = true; break;
                case 'cancel':
                case 'refusal':
                case 'access': req_comments = true; break;
            }

            this.setState(state => ({
                req_comments_visible: req_comments,
                req_field_visible: req_field,
                req_current_visible: req_current,
                req_update_visible: req_update,
                req_doc_visible: req_doc,
                req_doc_extra_visible: req_doc_extra,
                req_comments_value: reqChanged ? '' : this.state.req_comments_value,
                req_field_value: reqChanged ? '' : this.state.req_field_value,
                req_current_value: reqChanged ? '' : this.state.req_current_value,
                req_update_value: reqChanged ? '' : this.state.req_update_value,
                req_doc_value: reqChanged ? '' : this.state.req_doc_value,
                req_doc_extra_value: reqChanged ? '' : this.state.req_doc_extra_value,
                req_doc_file: reqChanged ? null : this.state.req_doc_file,
                req_doc_extra_file: reqChanged ? null : this.state.req_doc_extra_file,
            }));
        }
    }

    render() {
        return this.props.isSubmitted ? this.renderSubmitMessage() : this.renderForm();
    }

    renderSubmitMessage() {
        return (
            <div className="row fade-in">
                <div className="col-12 col-lg-7">
                    <p><i className="fal fa-envelope-open fa-4x text-primary"></i></p>
                    <h1 className="h2">¡La solicitud se ha enviado con éxito!</h1>
                    <p className="text-secondary mb-5">
                        Su requerimiento con folio <strong>{this.props.refId}</strong> ha sido enviado. Gracias.
                    </p>
                    <Button to="/" outline primary large>Volver al inicio</Button>
                </div>
            </div>
        );
    }

    renderForm() {
        return (
            <React.Fragment>
                {this.props.isProcessing && <Blocker />}
                <p className="mt-3 mb-5">Formulario para el ejercicio de los Derechos de Acceso, Rectificación, Cancelación u Oposición según la Ley Federal de Protección de Datos Personales en Posesión de Particulares.</p>
                <form noValidate onSubmit={this.onSubmit} className={this.state.validated ? 'was-validated' : ''}>
                    {fieldSets.map(fieldSet => (
                        <fieldset key={fieldSet.label} className="mb-4">
                            {fieldSet.label && <legend className="subtitle-2">{fieldSet.label}</legend>}
                            {fieldSet.text && <p style={{lineHeight:'1.55em'}}>{fieldSet.text}</p>}
                            <div className="form-row">
                                {fieldSet.fields.map(fieldName => {
                                    const field = fields[fieldName];
                                    const fieldValue = this.state[fieldName + '_value'];
                                    const valid = this.state[fieldName + '_valid'];
                                    const validationError = this.state[fieldName + '_validationError'];
                                    const visible = this.state[fieldName + '_visible'] !== false;

                                    if (!visible) {
                                        return null;
                                    }

                                    return (
                                        <div key={fieldName} className={field.half !== false ? 'col-md-6' : 'col-md-12'}>
                                            <FieldRow
                                                label={field.label}
                                                placeholder={field.placeholder}
                                                type={field.type}
                                                onChange={(e) => this.onChange(fieldName, e.target.value, e.target)}
                                                onBlur={() => this.validate(fieldName)}
                                                value={fieldValue}
                                                valid={valid}
                                                validationError={validationError}
                                                inputProps={field.inputProps || {}}
                                                choices={field.choices || (field.getChoicesMethod && field.getChoicesMethod(this.props, this.state, fieldName, fieldValue))}
                                                expanded={field.expanded}
                                                id={field.id}
                                                required={field.required}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </fieldset>
                    ))}

                    <div className="text-right">
                        <Button submit>Enviar</Button>
                    </div>
                </form>
            </React.Fragment>
        );
    }

    onChange(prop, value, element) {
        const field = fields[prop];
        const { formatterFn } = field;
        if (formatterFn) {
            value = formatterFn(value);
        }

        if (value === '0' && (field.choices || field.getChoicesMethod)) {
            value = '';
        }

        if (field.type === 'checkbox') {
            value = element.checked;
        }
        
        const newState = { [prop + '_value']: value };

        if (field.type === 'file') {
            newState[prop + '_file'] = element.files.length ? element.files[0] : null;
        }

        this.setState(() => (newState), () => this.validate(prop));
    }

    onSubmit(e) {
        e.preventDefault();

        const isValid = this.validateForm();

        if (!isValid) {
            this.setState(() => ({ validated: true, valid: false }));
            this.props.dialog('Por favor, verifica los datos ingresados.');
            return;
        }

        const params = {};
        const files = {};

        Object.keys(fields).forEach(field => {
            if (fields[field].type === 'file') {
                files[field] = this.state[field + '_file'];
            } else {
                params[field] = this.state[field + '_value']
            }
        });

        this.props.submit(params, files);
    }

    validateForm() {
        let validForm = true;

        Object.keys(fields).forEach(prop => {
            const { valid, validationError } = this.validateField(prop);
            this.setState((state) => ({
                [prop + '_valid']: valid,
                [prop + '_validationError']: validationError
            }));
            validForm &= valid !== false;
        });

        return validForm;
    }

    validate(prop) {
        const { valid, validationError } = this.validateField(prop);
        this.setState((state) => ({
            [prop + '_valid']: valid,
            [prop + '_validationError']: validationError
        }));
    }

    validateField(prop) {
        const value = this.state[prop + '_value'];
        const visible = this.state[prop + '_visible'] !== false;
        const { required, validationFns, validationMessage } = fields[prop];
        let valid = true;

        if (!visible || (!validationFns || !validationFns.length) || (!value && !required)) {
            return { valid: null, validationError: '' };
        }

        validationFns && validationFns.forEach(fn => {
            valid &= fn(value);
        });

        valid = !!valid;
        const validationError = valid ? '' : validationMessage;

        return { valid, validationError };
    }
}

const mapStateToProps = state => ({
    cinemas: getCinemas(state).sort(sortByName),
    states: getStates(state).sort(sortByName),
    isProcessing: isArcoFormProcessing(state),
    isSubmitted: isArcoFormSubmitted(state),
    refId: getArcoFormRef(state),
});

const mapDispatchToProps = dispatch => ({
    show: () => dispatch(arcoFormShow()),
    submit: (data, files) => dispatch(arcoFormSubmit(data, files)),
    dialog: msg => dispatch(dialogAlert(msg)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ArcoForm);