import React from "react";
import styled from 'styled-components';
import {getMethodLabelPart} from '../../utils/checkout';

const PointsPill = styled.div`
  border: 1px solid var(--text-primary);
  flex: 1;
  flex-grow: 1;
  margin-right: 1rem;
  padding: 1em 1.5em 0.65em;

  &:last-child { margin-right: 0; }
`;

const Icon = styled.i`
  vertical-align: middle;
`;
const Count = styled.span`
  display: inline-block;
  vertical-align: middle;

  body.theme-platinum & {
    color: var(--text-primary) !important;
  }
`;

const PointsMessages = styled.div`
  color: var(--primary);
  font-size: 0.9em;
  line-height: 1.2;
  padding: 0.5em 1em;
  text-align: center;
`;

class IePointsBlock extends React.Component {
    render() {
        let messages = [];
        
        return <React.Fragment>
            <div className="d-flex">
            {this.props.points.map(type => {
              const value = Math.min(type.value, type.available);
              type.messages && type.messages.length && (messages = messages.concat(type.messages));

              return (
                  <PointsPill key={type.id}>
                    <span className="d-block">
                      <Icon className="fal fa-star mr-1"></Icon>
                      <Count className="mb-0 title-2">{value}</Count>
                    </span>
                    <small>{getMethodLabelPart(type.id, value)}</small>
                  </PointsPill>
              );
            })}
            {/*<div className="border rounded text-secondary p-2 mr-2 mb-2">*/}
            {/*<span className="d-block">*/}
            {/*<i className="fal fa-eye mr-1"></i>*/}
            {/*<span className="h5 mb-0 text-primary">45</span>*/}
            {/*</span>*/}
            {/*<small>visitas acumuladas</small>*/}
            {/*</div>*/}
          </div>
          {messages.length ? <PointsMessages>{messages.map((m, i) => <p key={i}>{m}</p>)}</PointsMessages> : null}
        </React.Fragment>;
    }
}

export default IePointsBlock;