import React from "react";
import styled from "styled-components";

interface Props {
    choices: Array<{
        id: string|number,
        name: string,
    }>,
    id: string,
    label: string,
    onChange: Function,
    valid: boolean,
};

interface State {
    value: string|number,
};

const RadioOption = styled.div`
    line-height: 1.5em;
    margin-bottom: 1em;

    &:last-child { margin-bottom: 0; }
`;

const Label = styled.label`
    font-size: 1rem !important;
    font-weight: 400 !important;
    left: 0 !important;
    margin-left: 0.5em !important;
    opacity: 1 !important;
    position: relative !important;
    top: -0.05em !important;

    body.theme-platinum & { text-transform: none !important; }
`;

class RadiosGroup extends React.Component<Props, State> {
    constructor(props) {
      super(props);
  
      this.state = {value: props.value};
  
      this.onChangeValue = this.onChangeValue.bind(this);
    }
  
    onChangeValue(e) {
      const {value} = e.target;
      this.setState({value}, () => {
        this.props.onChange({target: {value}});
      });
    }
  
    render() {
      const {id} = this.props;
      
      return <div onChange={this.onChangeValue} className={this.props.valid ? 'form-radio-group valid' : ''}>
        <p style={{lineHeight:'1.5em',marginBottom:'0.75em'}}>{this.props.label}</p>
        {this.props.choices.map((option, i) => <RadioOption>
            <input id={id + '-' + i} name={id} type="radio" value={option.id} checked={option.id === this.state.value}/>
            <Label htmlFor={id + '-' + i}>{option.name}</Label>
        </RadioOption>)}
      </div>;
    }
  }

  export default RadiosGroup;