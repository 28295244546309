import React from "react";
import TicketRow from "./TicketRow";
import { TicketsBlockProps } from "./types";
import styled from "styled-components";

const Title = styled.h3`
    body.theme-market &,
    body.theme-platinum & {
        font-weight: 400;
        text-transform: uppercase;
    }
`;

function TicketsBlock(props: TicketsBlockProps) {
    const { tickets } = props;

    if (!tickets.length) return null;

    return <div className="mb-5">
        <Title className="subtitle-1">{props.title ? props.title : ''}</Title>
        {tickets.map(ticket => <TicketRow
            ticket={ticket}
            limitReached={props.limitReached}
            onIncrease={props.onIncrease}
            onDecrease={props.onDecrease}
        />)}
    </div>;
}

export default TicketsBlock;