import React, { MouseEventHandler } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../utils/styles';

const Link = styled.button<{square?: boolean}>`
  aspect-ratio: 1/1;
  background-color:#3d3d3d;
  border: 0;
  display:block;
  margin: 0;
  overflow:hidden;
  padding: 0;
  position:relative;
  width: 100%;

  &, &:focus { outline: none; }
  
  &:before {
    background: rgba(0,0,0,0.75);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  
  &:after {
    border: 2px solid #fff;
    border-radius:50%;
    color: #fff;
    content: '\f04b';
    display: block;
    font-family: 'Font Awesome\ 5 Pro';
    font-size: 2rem;
    font-weight: 600;
    height: 2em;
    left: 50%;
    line-height: 2em;
    position: absolute;
    text-align: center;
    text-indent: 2px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2em;
    z-index: 2;

    body.theme-platinum & { color: var(--primary); border-color: var(--primary); }
  }
    
  @media screen and (min-width: ${breakpoints.tablet}px){
    &:before {
      font-size:16px;
    }
  }
`;

const Image = styled.img`
  display: block;
  height: auto;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all .2s ease;
  width: 100%;
`;

interface Props {
  movie: { // @todo Define model.
    name: string,
    poster_small: string,
  },
  onTrailerToggle: MouseEventHandler,
  square?: boolean,
}

class BillboardMoviePoster extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return this.props.movie.poster_small !== nextProps.movie.poster_small;
  }

  render() {
    return (
        <Link onClick={this.props.onTrailerToggle} square={this.props.square}>
          <Image src={this.props.movie.poster_small} alt={this.props.movie.name}/>
        </Link>
    );
  }
}

export default BillboardMoviePoster;