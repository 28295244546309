import React from 'react';
import uniqBy from "lodash/uniqBy";
import has from "lodash/has";
import orderBy from "lodash/orderBy";
import { getCinemaPermalink } from "../../utils/urls";
import MapPosition from "../MapPosition";
import Button from '../Button';
import styled from 'styled-components';

const Buttons = styled.div`
  display: flex;
  gap: 1em;
`;

class CinemasContent extends React.Component {
  static defaultProps = {
    content: {},
    selectedState: {}
  };

  state = {
    selectedState: {},
    selectedArea: {},
    searchedTerm: "",
    selectedCinema: {},
    filteredCinemas: []
  };

  getCinemasStates() {
    const states = this.props.content.cinemas.map(cinema => cinema.state);

    return orderBy(uniqBy(states, "id"), ["name"], ["asc"]);
  }

  getCinemasAreas() {
    const { selectedState } = this.state;
    const { cinemas } = this.props.content;

    const filteredCinemas = cinemas.filter(cinema => {
      if (!has(selectedState, "id")) {
        return true;
      }

      return cinema.state.id === parseInt(selectedState.id, 10);
    });

    const areas = filteredCinemas.map(cinema => cinema.area);

    return orderBy(uniqBy(areas, "id"), ["name"], ["asc"]);
  }

  getCinemas() {
    const { selectedState, selectedArea, searchedTerm } = this.state;
    const { cinemas } = this.props.content;

    let filteredCinemas = cinemas;

    if (has(selectedState, "id")) {
      filteredCinemas = cinemas.filter(
          cinema => cinema.state.id === parseInt(selectedState.id, 10)
      );
    }

    if (has(selectedArea, "id")) {
      filteredCinemas = filteredCinemas.filter(
          cinema => cinema.area.id === parseInt(selectedArea.id, 10)
      );
    }

    if (!!searchedTerm.length) {
      filteredCinemas = filteredCinemas.filter(cinema =>
          cinema.name.toLowerCase().includes(searchedTerm)
      );
    }

    return orderBy(uniqBy(filteredCinemas, "id"), ["name"], ["asc"]);
  }

  updateSelectedState = event => {
    const { target } = event;
    const { value } = [...target.selectedOptions].shift();

    const selectedState = this.getCinemasStates()
        .filter(state => parseInt(state.id, 10) === parseInt(value, 10))
        .shift();

    this.setState({
      selectedState
    });
  };

  updateSelectedArea(selectedArea) {
    this.setState({ selectedArea, selectedCinema: {} });
  }

  // updateSearchedName = event => {
  //   const { target } = event;
  //
  //   this.setState({
  //     searchedTerm: target.value
  //   });
  // };

  showCinemaInfo = event => {
    const { content } = this.props;
    const { target } = event;
    const selectedId = parseInt(target.value, 10);
    const selectedCinema = content.cinemas
        .filter(cinema => cinema.id === selectedId)
        .shift() || {};

    this.setState({ selectedCinema });
  };

  render() {
    return (
        <React.Fragment>
          {this.props.content.html && <div dangerouslySetInnerHTML={{__html: this.props.content.html}} />}
          <div className="list-group mb-4">
            {this.renderStateSelector()}
            {this.renderAreaSelector()}
            {this.renderCinemaSelector()}
            {this.renderCinemaMap()}
          </div>
        </React.Fragment>
    );
  }

  renderStateSelector() {
    return (
        <div className='mb-5'>
          <h2 className="subtitle-1">
            <label htmlFor="cinemas-widget-states">Estado</label>
          </h2>

          <select
              id="cinemas-widget-states"
              className="form-control"
              onChange={this.updateSelectedState}
          >
            <option>Todos los estados</option>

            {this.getCinemasStates().map(state => (
                <option
                    key={`cinemas-widget-states-${state.id}`}
                    value={state.id}
                >
                  {state.name}
                </option>
            ))}
          </select>
        </div>
    );
  }

  renderAreaSelector() {
    if (!has(this.state.selectedState, 'id')) {
      return null;
    }

    return (
      <div className='mb-5'>
          <h2 className="subtitle-1">Área</h2>
          <Buttons>
            <Button
                onClick={() => this.updateSelectedArea(null)}
                primary={!has(this.state.selectedArea, "id")}
                small
            >
              Todos
            </Button>

            {this.getCinemasAreas().map(item => {
              return (
                  <Button
                      key={`cinemas-widget-areas-${item.id}`}
                      primary={this.state.selectedArea && this.state.selectedArea.id === item.id}
                      onClick={() => this.updateSelectedArea(item)}
                      small
                  >
                    {item.name}
                  </Button>
              );
            })}
          </Buttons>
        </div>
    );
  }

  renderCinemaSelector() {
    if (!has(this.state.selectedState, 'id')) {
      return null;
    }

    return (
      <div className='mb-5'>
          <h2 className="subtitle-1">
            <label htmlFor="cinemas-widget-name">Nombre</label>
          </h2>

          {/*<div className="mb-4">*/}
            {/*<input*/}
                {/*id="cinemas-widget-name"*/}
                {/*className="form-control"*/}
                {/*type="text"*/}
                {/*placeholder="Busca tu cine favorito"*/}
                {/*onChange={this.updateSearchedName}*/}
            {/*/>*/}
          {/*</div>*/}

          <div>
            <select
                id="cinemas-widget-cinemas"
                className="form-control"
                onChange={this.showCinemaInfo}
                value={this.state.selectedCinema.id || ''}
            >
              <option value="">Selecciona un cine</option>
              {this.getCinemas().map(cinema => (
                  <option
                      key={`cinemas-widget-lits-item-${cinema.id}`}
                      value={cinema.id}
                  >
                    {cinema.name}
                  </option>
              ))}
            </select>
          </div>
        </div>
    );
  }

  renderCinemaMap() {
    const {selectedCinema} = this.state;

    if (!has(selectedCinema, 'id')) {
      return null;
    }

    return (
        <div ref={selectedCinema.id} className="list-group-item p-0">
          <MapPosition
              mapImage={selectedCinema.info.map_image}
              address={selectedCinema.info.address}
              name={selectedCinema.name}
              actionLinkUrl={getCinemaPermalink(selectedCinema)}
              mapLinkUrl={selectedCinema.info.map_link}
              mapMarker={this.props.mapMarker}
          />
        </div>
    );
  }
}

export default CinemasContent;