import React from 'react';
import {connect} from 'react-redux';
import MDSpinner from "react-md-spinner";
import {withRouter} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import BillboardCinemaBlock from '../billboard/BillboardCinemaBlock';
import BillboardCinemaHeading from '../billboard/BillboardCinemaHeading';
import BillboardSidebarDates from '../billboard/BillboardSidebarDates';
import BillboardStickyHeader from '../billboard/BillboardStickyHeader';
import {getCinemaBillboardWithFilters,getSelectedCinema} from '../../reducers';
import {cinemaMoviesFetch} from '../../actions/creators';
import {getAbsoluteUrl, getCinemaPermalink} from '../../utils/urls';
import {getCinemaJsonLdObject} from '../../utils/jsonLd';

class CinemaPageContent extends React.Component {
  constructor(props) {
    super(props);

    this.onDateSelect = this.onDateSelect.bind(this);
    this.buildUrlWithDate = this.buildUrlWithDate.bind(this);
  }
  
  // @todo This functions are duplicated in BillboardSidebarBase.jsx. Find a better way to implement it.
  onDateSelect(date) {
    this.props.history.push(this.buildUrlWithDate(date));
  }

  buildUrlWithDate(dateYmd) {
    return this.buildUrlWithParamValue('date', 'fecha-' + dateYmd);
  }

  buildUrlWithParamValue(param, value) {
    const params = Object.assign({}, this.props.match.params);
    const paramsList = ['date', 'format', 'lang', 'hours', 'cinema', 'movie'];
    const parts = [];

    params[param] = value;

    paramsList.forEach(paramName => {
      if (paramName in params) {
        parts.push(params[paramName]);
      }
    });

    return this.props.baseUrl + parts.filter(part => !!part).join('/');
  }

  componentDidMount() {
    this.loadCinema();
  }

  componentDidUpdate(prevProps) {
    if (this.props.cinemaId !== prevProps.cinemaId) {
      this.loadCinema();
      return;
    }

    if (this.props.selectedCinema
        && (
            !prevProps.selectedCinema
            ||
            (this.props.selectedCinema.id !== prevProps.selectedCinema.id)
        )
    ) {
      this.props.history.push(getCinemaPermalink(this.props.selectedCinema));
    }
  }

  loadCinema() {
    window.scroll(0,0);
    this.props.moviesFetch(this.props.cinemaId);
  }

  render() {
    const {cinema, movies} = this.props;

    return <React.Fragment>
      <Helmet>
        <title>Cinemex {cinema.name} - Cartelera y horarios en {cinema.area.name}</title>
        <meta name="description" content={'Checa la cartelera y los horarios de los estrenos en ' + cinema.name + ', ubicado en ' + cinema.area.name + ', ' + cinema.state.name + '. ¡No te quedes sin tus boletos!'} />
        <link rel="canonical" href={getAbsoluteUrl(getCinemaPermalink(cinema))} />
        <script type="application/ld+json">
          {JSON.stringify(getCinemaJsonLdObject(cinema))}
        </script>
      </Helmet>
      {(!cinema || !movies) ? (
          <div className="text-center mb-5"><MDSpinner singleColor="var(--primary)"/></div>
      ) : (
          <React.Fragment>
            <BillboardStickyHeader className="d-block d-md-none">
              <div className='container'>
                <BillboardCinemaHeading cinema={cinema}/>
                <BillboardSidebarDates dates={this.props.dates} selectedDate={this.props.date} baseUrl={this.props.baseUrl} onDateSelect={this.onDateSelect} paddingX={false}/>
              </div>
            </BillboardStickyHeader>
            <BillboardCinemaBlock cinema={cinema} movies={movies} header="desktop-only"/>
          </React.Fragment>
      )}
    </React.Fragment>
  }
}

const mapStateToProps = (state, props) => {
  const {cinemaId, dates, urlParams} = props;

  if (!urlParams.date) {
    urlParams.date = dates && dates.length && dates[0];
  }

  return {
    movies: getCinemaBillboardWithFilters(state, cinemaId, urlParams),
    selectedCinema: getSelectedCinema(state),
  };
};

const mapDispatchToProps = dispatch => ({
  moviesFetch: cinemaId => dispatch(cinemaMoviesFetch(cinemaId)),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CinemaPageContent));