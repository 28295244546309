import React from 'react';
import {Link} from 'react-router-dom';
import MDSpinner from 'react-md-spinner';
import NowMovieBlock from "../NowMovieBlock";
import NoMoviesMessage from '../NoMoviesMessage';
import BackButton from '../common/BackButton';
import NoContentMessage from '../common/NoContentMessage';

class NowPageContent extends React.Component {
  render() {
    return (
        <React.Fragment>
          <div className='container mb-5'>
            <BackButton label='A punto de comenzar' backTo='/'/>
          </div>
          {this.renderContent()}
        </React.Fragment>
    );
  }

  renderContent() {
    if (this.props.sessions === null) {
      return <div className="text-center mb-5"><MDSpinner singleColor="var(--primary)"/></div>;
    } else {
      return <div className="list-group mb-4">{this.renderResults()}</div>;
    }
  }

  renderResults() {
    if (this.props.sessions.length) {
      return this.props.sessions.map(session => <NowMovieBlock session={session} key={session.id}/>)
    } else {
      return <div className='container'>
        <NoContentMessage
          bigTitle='Ups!'
          title="No hay funciones próximas a comenzar cerca del cine seleccionado.">
          <p>Puedes probar otro cine u otro horario.</p>
        </NoContentMessage>
      </div>;
    }
  }
}

export default NowPageContent;