import React, { MouseEventHandler } from "react";
import BackButton from "./BackButton";
import styled from "styled-components";

const Container = styled.div`
    margin: 0 0 2.5rem;
`;

const Title = styled.h1<{fullWidth?: boolean}>`
    margin: 1rem 0 0 !important;
    ${props => !props.fullWidth && 'max-width: 60%;'}

    body.theme-platinum & {
        font-size: ${(35/12.6).toFixed(2)}rem;
        font-weight: 700;
    }
`;

interface Props {
    backTo?: string,
    fullWidth?: boolean
    onBackClick?: MouseEventHandler<HTMLElement>,
    title: string,
}

function PageTitle(props: Props) {
    return <Container>
        <BackButton backTo={props.backTo} onBackClick={props.onBackClick}/>
        <Title className="title-1" fullWidth={props.fullWidth}>{props.title}</Title>
    </Container>;
}

export default PageTitle;