import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../utils/styles";
import ShowtimeButton from "../ShowtimeButton";

const Grid = styled.div`
    display: grid;
    gap: 0.5em;
    grid-template-columns: 1fr 1fr 1fr;

    @media screen and (min-width: ${breakpoints.desktop}px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media screen and (min-width: ${breakpoints.desktopLarge}px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
`;

interface Props {
    movie: object, // @todo Define model
    version: object, // @todo Define model
};

function ShowtimesGrid({movie, version}) {
    return <Grid>
        {version.sessions.map(session => <ShowtimeButton key={session.id} session={session}
                      askConfirmation={true} version={version.label} movieName={movie.name} />)}
    </Grid>
}

export default ShowtimesGrid;