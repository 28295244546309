import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import LinkButton from "../LinkButton";
import {authOpen} from '../../actions/creators';
import {ieCodePanelShow} from '../../actions/creators/ie';
import {isUserLogged,getUserInfo,getUserIePoints} from '../../reducers';
import IePointsBlock from './IePointsBlock';
import { asset } from '../../utils/misc';

const Menu = styled.div`
  display: ${props => props.visible ? 'block' : 'none'} !important;
`;

const IeCardsIcon = styled.img`
  height: 25px;
`;

class IeSidebarBlock extends React.Component {
  constructor(props) {
    super(props);

    this.beginClick = this.beginClick.bind(this);

    this.state = {expanded: false};
  }

  render() {
    if (!this.props.userIsLogged) {
      return this.renderUserNotLogged();
    } else {
      return this.renderUserLoggedIn();
    }
  }

  renderUserNotLogged() {
    return (
        <div className="container dropdown py-2">
          <div className='border-bottom'>
            <LinkButton onClick={() => this.setState(() => ({expanded: !this.state.expanded}))} width="100%" className="d-flex py-2" aria-haspopup="true" aria-expanded="false">
              <IeCardsIcon src={asset('/dist/images/ie-cards-icon.png')} alt="" className="mr-2"/> ¿Eres miembro Cinemex Loop?
              <i className="fal fa-angle-down mt-1 ml-auto mr-3"></i>
            </LinkButton>
            <Menu className="dropdown-menu dropdown-menu-right" visible={this.state.expanded}>
              <Link className="dropdown-item" to="/loop/programa">¿Qué es Cinemex Loop?</Link>
              <Link className="dropdown-item" to="/loop/niveles-beneficios">Conoce los
                Beneficios</Link>
              <span className="dropdown-item" onClick={this.beginClick}>Ingresar</span>
            </Menu>
          </div>
        </div>
    );
  }

  beginClick() {
    if (this.props.userIsLogged) {
      this.props.openIePanel();
    } else {
      this.props.openAuthPanel();
    }
  }

  renderUserLoggedIn() {
    if (!this.props.userInfo.iecode) {
      return this.renderUserLoggedWithoutIe();
    } else {
      return this.renderUserLoggedWithIe()
    }
  }

  renderUserLoggedWithoutIe() {
    return this.renderUserNotLogged();
  }

  renderUserLoggedWithIe() {
    if (this.props.points.length) {
      return this.renderUserWithPoints();
    } else {
      return this.renderUserWithoutPoints();
    }
  }

  renderUserWithPoints() {
    return (
        <div className="container pad-y">
          <p className="mb-3">
            Hola {this.props.userInfo.first_name},
            tienes para canjear:
          </p>

          <IePointsBlock points={this.props.points}/>
        </div>
    );
  }

  renderUserWithoutPoints() {
    return (
        <div className="container pad-y">
          <p>
            Hola {this.props.userInfo.first_name}
            ¡con esta compra sumas puntos de Cinemex Loop!
          </p>
        </div>
    );
  }
}

const mapStateToProps = state => ({
  userIsLogged: isUserLogged(state),
  userInfo: getUserInfo(state),
  points: getUserIePoints(state),
});

const mapDispatchToProps = dispatch => ({
  openIePanel: () => dispatch(ieCodePanelShow()),
  openAuthPanel: () => dispatch(authOpen()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IeSidebarBlock);