import {combineReducers} from 'redux';
import * as actions from '../../actions/types';

const loginDefaultState = {
  isProcessing: false
};

const login = (state = loginDefaultState, action) => {
  switch (action.type) {
    case actions.AUTH_LOGIN_POST:
    case actions.AUTH_LOGIN_PIN_POST:
    case actions.AUTH_FB_LOGIN:
    case actions.AUTH_RENEW_TOKEN:
      return Object.assign({}, state, {isProcessing: true});
    case actions.AUTH_LOGIN_POST_SUCCESS:
    case actions.AUTH_LOGIN_POST_ERROR:
    case actions.AUTH_FB_LOGIN_ERROR:
      return Object.assign({}, state, {isProcessing: false});
    default:
      return state;
  }
};

const recoverPassDefaultState = {
  isProcessing: false,
  sent: false,
};

const recoverPass = (state = recoverPassDefaultState, action) => {
  switch (action.type) {
    case actions.AUTH_RECOVER_PASS_POST:
      return Object.assign({}, state, {isProcessing: true});
    case actions.AUTH_RECOVER_PASS_ERROR:
      return Object.assign({}, state, {isProcessing: false});
    case actions.AUTH_RECOVER_PASS_SUCCESS:
      return Object.assign({}, state, {isProcessing: false, sent: true});
    case actions.AUTH_SHOW_RECOVER_PASS:
      return Object.assign({}, state, {sent: false});
    default:
      return state;
  }
};

const resetPassDefaultState = {
  isProcessing: false,
  sent: false,
};

const resetPass = (state = resetPassDefaultState, action) => {
  switch (action.type) {
    case actions.AUTH_RESET_PASS_POST:
      return Object.assign({}, state, {isProcessing: true});
    case actions.AUTH_RESET_PASS_ERROR:
      return Object.assign({}, state, {isProcessing: false});
    case actions.AUTH_RESET_PASS_SUCCESS:
      return Object.assign({}, state, {isProcessing: false, sent: true});
    case actions.AUTH_SHOW_RESET_PASS:
      return Object.assign({}, state, {sent: false});
    default:
      return state;
  }
};

const registerDefaultState = {
  isProcessing: false,
  sent: false,
};

const register = (state = registerDefaultState, action) => {
  switch (action.type) {
    case actions.AUTH_REGISTER_POST:
      return Object.assign({}, state, {isProcessing: true});
    case actions.AUTH_REGISTER_POST_ERROR:
      return Object.assign({}, state, {isProcessing: false});
    case actions.AUTH_REGISTER_POST_SUCCESS:
      return Object.assign({}, state, {isProcessing: false, sent: true});
    case actions.AUTH_SHOW_REGISTER:
      return Object.assign({}, state, {sent: false});
    case actions.AUTH_SET_REF:
      return Object.assign({}, state, {ref: action.ref});
    default:
      return state;
  }
};

const pin = (state = {visible: false, message: null, context: null}, action) => {
  switch (action.type) {
    case actions.AUTH_LOGIN_REQUEST_PIN_SHOW:
      return Object.assign({}, state, {visible: true, error: action.error.response, message: action.error.message, context: action.context, payload: action.payload, originalAction: action.originalAction});
    case actions.AUTH_LOGIN_REQUEST_PIN_CLOSE:
    case actions.AUTH_LOGIN_POST_SUCCESS:
    case actions.AUTH_SIGN_UP_CHECK_EMAIL_SUCCESS:
    case actions.CHECKOUT_PAYMENT_SUCCESS:
    case actions.IE_CODE_VALIDATE_SUCCESS:
    case actions.IE_CODE_SUBMIT_SUCCESS:
    case actions.IE_NIP_UPDATE_SUCCESS:
    case actions.USER_UPDATE_INFO_SUCCESS:
      return Object.assign({}, state, {visible: false});
    default:
      return state;
  }
};

export default combineReducers({
  login,
  pin,
  recoverPass,
  resetPass,
  register,
});