import React from 'react';
import {Link} from 'react-router-dom';
import BillboardMovieDetailsBlock from "./billboard/BillboardMovieDetailsBlock";
import BillboardMoviePoster from "./billboard/BillboardMoviePoster";
import {getCinemaPermalink} from '../utils/urls';
import {parseSessionIsoDateForDisplay} from '../utils/dates';
import ShowtimeButton from "./ShowtimeButton";
import styled from 'styled-components';

const Block = styled.div`
  border-top: 1px solid var(--text-primary);
  margin-bottom: 2rem;
  padding-top: 2rem;

  body.theme-platinum & { border-color: var(--primary-c); }
`;

const StartsIn = styled.span`
  body.theme-platinum & {
    background: var(--primary-c) !important;
    color: var(--text-primary) !important;
  }
`

const getStartRelativeTime = (date) => {
  const n = (new Date()).getTime() / 1000;
  const diffMinutes = Math.round((date.unix() - n) / 60);

  if (diffMinutes < 0) {
    return 'Ya comenzó';
  } else if (diffMinutes < 5) {
    return 'Comienza ya';
  } else if (diffMinutes < 60) {
    return 'Comienza en ' + diffMinutes + '\'';
  } else {
    const h = Math.floor(diffMinutes / 60);
    const m = diffMinutes % 60;
    return 'Comienza en ' + h + ' hora' + (h === 1 ? '' : 's') + (m > 0 ? (' y ' + Math.round(m) + '\'') : '');
  }
};

class NowMovieBlock extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onExpandedToggle = this.onExpandedToggle.bind(this);
    this.onTrailerToggle = this.onTrailerToggle.bind(this);
    this.onTrailerClose = this.onTrailerClose.bind(this);

    this.state = {
      expanded: false,
      trailer: false,
    };
  }

  onExpandedToggle() {
    this.setState(prevState => ({expanded: !prevState.expanded}));
  }

  onTrailerToggle() {
    this.setState(prevState => ({trailer: !prevState.trailer}));
  }

  onTrailerClose() {
    this.setState(prevState => ({trailer: false}));
  }

  render() {
    const {session} = this.props;
    const {movie,cinema} = session;
    const date = parseSessionIsoDateForDisplay(session.datetime);
    
    return <Block className='container'>
      <div className="row">
        <div className="col col-3 col-lg-2 d-none d-md-block">
          <BillboardMoviePoster movie={movie} onTrailerToggle={this.onTrailerToggle}/>
        </div>
        <div className="col col-md-9 col-lg-10">
          <BillboardMovieDetailsBlock movie={movie} trailerOpen={this.state.trailer} onTrailerClose={this.onTrailerClose}/>
          
          <div className="row mb-3">
            <div className="col col-4">
              <p className="h6 text-uppercase"><small>{movie.label}</small></p>
            </div>
            <div className="col col-8 text-right">
              <StartsIn className="badge badge-pill badge-secondary">{getStartRelativeTime(date)}</StartsIn>
            </div>
          </div>

          <div className="row">
            <div className="col col-4">
              <h3 className="h6 mb-0">Cine</h3>
              <Link to={getCinemaPermalink(cinema)}>{cinema.name}</Link>
            </div>
            <div className="col col-8 text-right">
              <ShowtimeButton session={session} askConfirmation={false} />
            </div>
          </div>
        </div>
      </div>
    </Block>;
  }
}

export default NowMovieBlock;