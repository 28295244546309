import React from "react";
import styled from "styled-components";
import { centsToCurrencyString } from '../../../utils/numbers';
import Button from '../../Button';
import { TicketProps, TicketRowProps } from "./types";

interface RowProps {
    disabled?: boolean,
};

const Name = styled.span`
    display: block;

    body.theme-market & {
        font-size: ${(18/12.6).toFixed(2)}rem !important;
        margin: 0.25em 0;
        text-transform: uppercase !important;
    }

    body.theme-platinum & {
        color: var(--text-primary) !important;
        font-size: 1.25rem !important;
        font-weight: 400 !important;
    }
`;

const Row = styled.div<RowProps>`
    border-bottom: 1px solid var(--text-primary);
    padding: 2rem 0;

    ${props => props.disabled
        ? 'opacity: 0.5; cursor: not-allowed;'
        : 'opacity: 1'};
`;

const TicketDescription = styled.span`
    font-size: ${(11/12.6).toFixed(2)}rem;
    line-height: 1.2;
`;

const RoundButton = styled(Button)`
    font-size: 2rem;
    padding: 0;
    line-height: 1.4;
    height: 1.4em;
    width: 1.4em;
    border-radius: 2em;

    &:before { border-radius: 2em; }
`;

const Quantity = styled.span`
    font-family: var(--ff-subtitle);
    display: block;
    font-size: ${40/12.6}rem;
    line-height: 1;

    body.theme-platinum & {
        font-weight: 700;
        font-size: ${38/12.6}rem;
    }
`;

const Price = styled.span`
    font-size: ${(12/12.6).toFixed(2)}rem;
`;

function getTicketPriceString(ticket) {
    if (ticket.extra.is_pass) {
        return ticket.pcmultiplier + ' pase' + (ticket.pcmultiplier.toString() === '1' ? '' : 's') + ' c/u';
    }

    if (ticket.price === 0) {
        return 'Gratis';
    }

    return `${centsToCurrencyString(ticket.price)} c/u`;
}

function canIncreaseTicket(ticket: TicketProps, limitReached: boolean) {
    return ticket.enabled && ticket.qty < ticket.max && !limitReached;
}

function canDecreaseTicket(ticket: TicketProps) {
    return ticket.enabled && ticket.qty > 0
}

function TicketRow(props:TicketRowProps) {
    const { ticket } = props;

    return <Row disabled={!ticket.enabled}>
        <div className="d-flex justify-content-between align-items-center">
            <RoundButton defaultPadding disabled={!canDecreaseTicket(ticket)} onClick={() => props.onDecrease(ticket)}>
                <i className="fal fa-minus"></i>
            </RoundButton>

            <div className="text-center">
                <Quantity>{ticket.qty}</Quantity>
                <Name className="title-2">
                    {ticket.name}
                </Name>
                {ticket.description ? <TicketDescription className="text-secondary d-block">{ticket.description}</TicketDescription> : ''}
                <Price>{getTicketPriceString(ticket)}</Price>
            </div>

            <RoundButton defaultPadding disabled={!canIncreaseTicket(ticket, props.limitReached)} onClick={() => props.onIncrease(ticket)}>
                <i className="fal fa-plus"></i>
            </RoundButton>
        </div>
    </Row>;
}

export default TicketRow;