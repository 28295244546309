import React from 'react';
import {connect} from 'react-redux';
import Billboard from './Billboard';
import BillboardSidebar from './BillboardSidebar';
import TwoColumnLayout from '../TwoColumnLayout';
import {extractUrlParams} from '../../utils/billboard';
import {getArea,getState,getAreaBillboardWithFilters,getAreaBillboardFull,getAreaBillboardDates,getStateBillboardWithFilters,getStateBillboardFull,getStateBillboardDates,isBillboardLoading} from '../../reducers';

class BillboardPage extends React.Component {
  constructor(props) {
    super(props);

    this.onDateSelect = this.onDateSelect.bind(this);
    this.buildUrlWithDate = this.buildUrlWithDate.bind(this);
  }

  // @todo This functions are duplicated in BillboardSidebarBase.jsx. Find a better way to implement it.
  onDateSelect(date) {
    this.props.history.push(this.buildUrlWithDate(date));
  }

  buildUrlWithDate(dateYmd) {
    return this.buildUrlWithParamValue('date', 'fecha-' + dateYmd);
  }

  buildUrlWithParamValue(param, value) {
    const params = Object.assign({}, this.props.match.params);
    const paramsList = ['date', 'format', 'lang', 'hours', 'cinema', 'movie'];
    const parts = [];

    params[param] = value;

    paramsList.forEach(paramName => {
      if (paramName in params) {
        parts.push(params[paramName]);
      }
    });

    return this.props.baseUrl + parts.filter(part => !!part).join('/');
  }

  render() {
    return <TwoColumnLayout
        MainContent={Billboard}
        SidebarContent={BillboardSidebar}
        fixedSidebar
        sidebarMobileToggle
        noSidePadding={true}
        {...this.props}
        onDateSelect={this.onDateSelect}
    />;
  }
}

const mapStateToProps = (state, props) => {
  const urlParams = extractUrlParams(props.location.pathname, props.match.params);
  const {areaId} = urlParams;
  const isState = 'estado' === urlParams.areaType;
  const areaType = isState ? 'state' : 'area';
  const area = isState ? getState(state, areaId) : getArea(state, areaId);

  const dates = isState
      ? getStateBillboardDates(state, areaId)
      : getAreaBillboardDates(state, areaId);

  if (!urlParams.date) {
    urlParams.date = dates && dates.length && dates[0];
  }

  const date = urlParams.date;

  const isLoading = isBillboardLoading(state, isState ? 'state' : 'area', areaId, date);

  let dataFull, dataFiltered;

  if (!isLoading && areaId) {
    if (isState) {
      dataFull = getStateBillboardFull(state, areaId, urlParams.date);
      dataFiltered = getStateBillboardWithFilters(state, areaId, urlParams);
    } else {
      dataFull = getAreaBillboardFull(state, areaId, urlParams.date);
      dataFiltered = getAreaBillboardWithFilters(state, areaId, urlParams);
    }
  }

  return {
    area,
    areaId,
    areaType,
    baseUrl: `/cartelera/${props.match.params.areaType}-${props.match.params.area}/${props.match.params.slug}/`,
    dataFiltered,
    dataFull,
    date,
    dates,
    isLoading,
    isState,
    urlParams,
  };
};

export default connect(
    mapStateToProps
)(BillboardPage);