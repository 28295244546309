import React from "react";
import styled from "styled-components";
import CouponsBlockCoupons from "./CouponsBlockCoupons";

const CouponsBlock = styled.div`
    margin-bottom: 2em;
`;

const BlockTitle = styled.h2`
    color: var(--text-primary) !important;
    font-size: ${(25/12.6).toFixed(2)}rem !important;
    margin: 0 0 1em 0;

    body.theme-platinum & {
        font-size: ${(18/12.6).toFixed(2)}rem !important;
        font-weight: 700;
    }
`;

function CouponsBlocks({blocks, coupons, setCoupon}) {
    if (coupons.length === 0) return <p>No hay cupones disponibles en la categoría seleccionada.</p>;

    return blocks.map(block => {
        let blockCoupons = coupons.filter(coupon => coupon.blocks.indexOf(block.id) > -1);
        if (!blockCoupons.length) return '';

        return <CouponsBlock key={block.id}>
            <BlockTitle className="title-2">{block.name}</BlockTitle>
            <CouponsBlockCoupons coupons={blockCoupons} onCouponClick={setCoupon}/>
        </CouponsBlock>;
    });
}

export default CouponsBlocks;