import React from 'react';
import {connect} from 'react-redux';
import LinkButton from '../LinkButton';
import StepHeader from './StepHeader';
import PaymentOptionButton from './PaymentOptionButton';
import {prevStep} from '../../actions/creators/checkout';
import {paymentSuggestionsFetch, paymentSuggestionSelect} from '../../actions/creators/checkout/paymentSuggestions';
import {getOptionIcons,getOptionLabel} from '../../utils/checkout';
import {getCheckoutBookingFee,getCheckoutFlowDirection,getCheckoutOrderCandybarParam,getUserIePoints, getCheckoutOrderTicketsParamForSuggestions, isPaymentWithPointsBlocked, getCheckoutNonRedeemableTickets} from '../../reducers';

class PaymentMethodSelection extends React.Component {
  constructor(props) {
    super(props);

    this.fetch = this.fetch.bind(this);
  }

  componentWillMount() {
    if (this.props.flowDirection === 'prev') {
      return;
    }

    this.fetch();
  }

  fetch() {
    this.props.fetch(this.props.params);
  }

  render() {
    if (this.props.isLoading) return null;

    return (
        <React.Fragment>
          <StepHeader onPrevClick={this.props.prevStep} label="Forma de pago"/>
          {this.props.loadError ? this.renderError() : this.renderOptions()}
          {this.props.pointsBlocked ? <p className="checkout-disclaimer mt-3">Tu tarjeta está bloqueada momentáneamente para redimir puntos de Cinemex Loop.</p> : ''}
          {this.props.messages.map(message => <p className="checkout-disclaimer mt-3">{message}</p>)}
        </React.Fragment>
    );
  }

  renderError() {
    return <div>Ocurrió un error. <LinkButton onClick={this.fetch}>Haz click aquí para intentarlo nuevamente.</LinkButton></div>;
  }

  renderOptions() {
    return <ul className="c-option-list">
      {Object.keys(this.props.options).map(optionId => (
          <PaymentOptionButton key={optionId} selected={optionId === this.props.selected}
                               onClick={() => this.props.onOptionSelect(optionId)}
          >
            <span>{getOptionLabel(this.props.options[optionId])}</span>
          </PaymentOptionButton>
      ))}
    </ul>;
  }
}

const mapStateToProps = state => {
  const nonRedeemableItems = getCheckoutNonRedeemableTickets(state);
  const {options, selected, isLoading, loadError, messages} = state.checkoutData.paymentSuggestions;
  const allowedPaymentMethods = state.checkoutData.data.payment_methods;

  return {
    nonRedeemableItems,
    messages,
    options,
    params: {
      tickets: getCheckoutOrderTicketsParamForSuggestions(state),
      candybar: getCheckoutOrderCandybarParam(state),
      points: filterIePointsByAllowedPaymentMethods(getUserIePoints(state), allowedPaymentMethods),
      booking_fee: getCheckoutBookingFee(state),
    },
    pointsBlocked: false, //isPaymentWithPointsBlocked(state),
    selected,
    isLoading,
    loadError,
    flowDirection: getCheckoutFlowDirection(state),
  };
};

const mapDispatchToProps = dispatch => ({
  prevStep: () => dispatch(prevStep()),
  fetch: params => dispatch(paymentSuggestionsFetch(params)),
  onOptionSelect: optionId => dispatch(paymentSuggestionSelect(optionId)),
});

const filterIePointsByAllowedPaymentMethods = (points, allowedMethods) => {
  const availablePoints = [];

  for (var i = 0 ; i < points.length ; i++) {
    if (allowedMethods.indexOf(points[i].id) > -1) {
      availablePoints.push(points[i]);
    }
  }

  return availablePoints;
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentMethodSelection);