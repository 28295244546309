import { isElementInViewport } from "./misc";

let trackedPromos = {};

const isAlreadyTracked = (type, id) => {
    return (type in trackedPromos) && trackedPromos[type].indexOf(id) > -1;
};

const mapPromoTypeToNewNames = (type) => {
    const newNames = {
        'promo_a': 'destacada',
        'promo_featured': 'secundaria',
        'promo_wide': 'ancha'
    };

    return (type in newNames) ? newNames[type] : type;
};

export const AnalyticsPromos = {
    trackView(type, id, description, element) {
        if (isAlreadyTracked(type, id)) return;
        if (!isElementInViewport(element)) return;

        if (!(type in trackedPromos)) trackedPromos[type] = [];

        trackedPromos[type].push(id);
        dataLayerPush({
            event: 'promoView',
            type: mapPromoTypeToNewNames(type),
            id,
            description,
        });
    },

    trackClick(type, id, description, callback) {
        dataLayerPush({
            event: 'promoClick',
            type: mapPromoTypeToNewNames(type),
            id,
            description,
            eventCallback: callback,
            eventTimeout: 2000
        });
    },

    init() {
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) return;
                
                const {type, id, description, element} = this.getObserved(entry.target);
                this.trackView(type, id, description, element);
            });
        }, {threshold: 1});
        this.observed = [];
    },

    observe(type, id, description, element) {
        if (!element) return;
        if (!this.observer) this.init();

        this.observer.observe(element);
        this.observed.push({
            type,
            id,
            description,
            element
        });
    },

    getObserved(element) {
        for (let i = 0; i < this.observed.length; i++) {
            if (this.observed[i].element === element) {
                return this.observed[i];
            }
        }
    },

    reset(type) {
        trackedPromos[type] = [];
    },

    resetAll() {
        trackedPromos = {};
    }
};

export const AnalyticsModals = {
    trackView(id, description, placement) {
        dataLayerPush({
            event: 'modalView',
            id,
            description,
            placement
        });
    },

    trackClose(id, description, placement) {
        dataLayerPush({
            event: 'modalClose',
            id,
            description,
            placement
        });
    },
    
    trackClick(id, description, placement, callback) {
        dataLayerPush({
            event: 'modalClick',
            id,
            description,
            placement,
            eventCallback: callback,
            eventTimeout: 2000
        });
    }
};

export const AnalyticsCheckout = {
    trackStep(step, data) {
        // @todo Replace step names strings with constants/variables.
        const newIdToOldIdMap = {
            'tickets': 'select-tickets',
            'seats': 'select-seats',
            'candybar': 'candybar',
            'payment-suggestions': 'select-payment',
            'payment': 'payment',
            'confirmation': 'confirmation'
        };
        dataLayerPush({
            event: 'checkoutChangeStep',
            step: (step in newIdToOldIdMap) ? newIdToOldIdMap[step] : step,
            data: {
                session: {
                    id: data.session.id,
                    dateTime: data.session.datetime,
                    date: data.session.datetime.match(/^([0-9]{4}-[0-9]{2}-[0-9]{2})T/)[1],
                    time: data.session.datetime.match(/T([0-9]{2}:[0-9]{2}:[0-9]{2})/)[1],
                },
                cinema: {
                    id: data.cinema.id,
                    name: data.cinema.name,
                },
                movie: {
                    id: data.movie.parent_id,
                    versionId: data.movie.id,
                    title: data.movie.name,
                    genre: data.movie.info.genre,
                    ageRating: data.movie.info.rating,
                    director: data.movie.info.director,
                    cast: data.movie.info.cast,
                    country: data.movie.info.country,
                    year: data.movie.info.year,
                    format: data.movie.label,
                    url: 'https:' + data.movie.url,
                    poster_url: 'https:' + data.movie.poster_big,
                },
                order: parseOrderData(data.order),
                transaction: data.transaction,
            }
        });

        fbTrackCheckoutStep(step, data);
    },

    trackTransaction(transaction) {
        const tickets = transaction.tickets.map(ticket => {
            return {
                name: 'Boleto',
                sku: ticket.type,
                category: ticket.name,
                price: ticket.price / 100,
                quantity: ticket.qty
            };
        });

        dataLayerPush({
            event: 'checkoutComplete',
            transactionId: transaction.id,
            transactionAffiliation: transaction.cinema.name,
            transactionTotal: ('cash' in transaction.payment.methods) ? (transaction.payment.methods.cash / 100) : 0,
            transactionShipping: 0,
            transactionTax: 0,
            transactionProducts: tickets
        });
    }
}

function parseOrderData(order) {
    if (!order || !order.tickets) return {
        tickets: []
    };
    const result = {
        tickets: order.tickets.filter(ticket => ticket.qty >= 1).map(ticket => ({
            type: ticket.id,
            name: ticket.name,
            qty: ticket.qty,
            price: ticket.price,
        }))
    };

    return result;
}

export const AnalyticsBillboards = {
    trackCinema(cinema) {
        dataLayerPush({
            event: 'billboardCinemaView',
            action: 'Cinema',
            label: cinema.name,
            data: {
                cinema: {
                    id: cinema.id,
                    name: cinema.name
                }
            }
        });
    },

    trackMovie(movie, cinema) {
        dataLayerPush({
            event: 'billboardMovieView',
            action: 'Movie',
            label: `${cinema.name} - ${movie.name}`,
            data: {
                cinema: {
                    id: cinema.id,
                    name: cinema.name
                },
                movie: {
                    id: movie.id,
                    name: movie.name,
                    url: 'https:' + movie.url,
                    poster_url: 'https:' + movie.poster_big,
                }
            }
        });
    },

    trackMovieView(movie) {
        dataLayerPush({
            event: 'movieView',
            action: 'Open',
            label: `${movie.name}`,
            data: {
                movie: {
                    id: movie.parent_id,
                    versionId: movie.id,
                    title: movie.name,
                    genre: movie.info.genre,
                    ageRating: movie.info.rating,
                    director: movie.info.director,
                    cast: movie.info.cast,
                    country: movie.info.country,
                    year: movie.info.year,
                    format: movie.label,
                    url: 'https:' + movie.url,
                    poster_url: 'https:' + movie.poster_big,
                },
            }
        });
        movie.extra.fb_pixels.length && fbqTrack(movie.extra.fb_pixels, 'ViewContent', {
            content_ids: movie.id,
            content_name: movie.name,
            content_type: 'product',
            movie_genre: movie.info.genre,
            movie_age_rating: movie.info.rating,
            movie_directors: movie.info.director,
            movie_starring: movie.info.cast,
            movie_country: movie.info.country,
            movie_year: movie.info.year,
            movieref: 'fb_movies'
        });
    },

    trackSession(session, movie, cinema) {
        dataLayerPush({
            event: 'billboardSessionView',
            action: 'Session',
            label: `${cinema.name} - ${movie.name} - ${session.datetime}`,
        });
    }
};

export const AnalyticsUsers = {
    trackSignUp() {
        dataLayerPush({
            event: 'userSignUp',
            action: 'Success'
        });
    },

    trackSignIn() {
        dataLayerPush({
            event: 'userSignIn',
            action: 'sign-in'
        });
    },

    trackSignOut() {
        dataLayerPush({
            event: 'userSignOut',
            action: 'sign-out'
        });
    },

    trackIeNipChange() {
        dataLayerPush({
            event: 'ieNipChange',
            action: 'nip-change'
        });
    },

    trackIeLink(origin) {
        dataLayerPush({
            event: 'ieLink',
            action: 'link',
            level: '',
        });
    },

    trackIeSignUp(level) {
        dataLayerPush({
            event: 'ieSignUp',
            action: 'sign-up',
            level
        });
    },

    trackIeMigration(action, level) {
        dataLayerPush({
            event: 'ieMigration',
            action,
            level
        });
    },

    trackIeRenewal(action, level) {
        dataLayerPush({
            event: 'ieRenewal',
            action,
            level
        });
    }
}

const dataLayerPush = data => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
}

const fbqTrack = (pixelIds, event, data) => {
    if (!('fbq' in window)) {
        fbqInject();
    }

    pixelIds.forEach(pixelId => {
        fbqInit(pixelId);

        window.fbq('trackSingle', pixelId, event, data);
        console.log('trackSingle', pixelId, event, data);
    });
};

const fbqInject = () => {
    (function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)})(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
};

const fbqInit = pixelId => {
    const loadedIds = getFbLoadedIds();
    const pixelLoaded = loadedIds.indexOf(pixelId) > -1;

    if (!pixelLoaded) {
        window.fbq('init', pixelId);
    }
};

const getFbLoadedIds = () => {
    let result = [];

    if (window.fbq && window.fbq.__fbeventsModules && window.fbq.__fbeventsModules.fbevents) {
        const events = window.fbq.__fbeventsModules.fbevents();
        if (events.getState) {
            result = events.getState().pixels.map(pixel => pixel.id);
        }
    }
    
    return result;
};

const fbTrackCheckoutStep = (step, data) => {
    const fbEvent = fbGetCheckoutStepEventName(step);
    
    if (!data.movie.extra.fb_pixels.length) return;
    if (!fbEvent) return;

    fbqTrack(data.movie.extra.fb_pixels, fbEvent, {
        content_ids: data.movie.id,
        content_name: data.movie.name,
        content_type: 'product',
        movie_genre: data.movie.info.genre.join(','),
        movie_age_rating: data.movie.info.rating,
        movie_version: data.movie.label,
        session_date: data.session.datetime.match(/^([0-9]{4}-[0-9]{2}-[0-9]{2})T/)[1],
        session_time: data.session.datetime.match(/T([0-9]{2}:[0-9]{2}:[0-9]{2})/)[1],
        session_cinema: data.cinema.name,
        session_id: data.session.id,
        movie_directors: data.movie.info.director,
        movie_starring: data.movie.info.cast,
        movie_country: data.movie.info.country,
        movie_year: data.movie.info.year,
        num_items: 0, // @todo
        value: 0, // @todo
        currency: 'MXN',
        movieref: 'fb_movies'
    });
};

const fbGetCheckoutStepEventName = step => {
    switch (step) {
        case 'seats': return 'AddToCart';
        case 'payment': return 'InitiateCheckout';
        case 'confirmation': return 'Purchase';
        default: return null;
    }
}

export const trackAppStoreRedirect = (store, callback) => {
    dataLayerPush({
        event: 'appStoreRedirect',
        store,
        eventCallback: callback,
        eventTimeout: 2000
    });
};