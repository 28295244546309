import { combineReducers } from 'redux';
import * as actions from '../../actions/types';

const data = (state = null, action) => {
    switch (action.type) {
        case actions.USER_COUPONS_FETCH_SUCCESS:
            return action.data;
        default:
            return state;
    }
};

const isLoading = (state = false, action) => {
    switch (action.type) {
        case actions.USER_COUPONS_FETCH:
            return true;
        case actions.USER_COUPONS_FETCH_SUCCESS:
        case actions.USER_COUPONS_FETCH_ERROR:
            return false;
        default:
            return state;
    }
};

const isProcessing = (state = false, action) => {
    switch (action.type) {
        case actions.USER_COUPONS_ACTIVATE:
            return true;
        case actions.USER_COUPONS_ACTIVATE_SUCCESS:
        case actions.USER_COUPONS_ACTIVATE_ERROR:
            return false;
        default:
            return state;
    }
};

export const getUserCouponsData = state => state.user.coupons.data;
export const isUserCouponsLoading = state => state.user.coupons.isLoading;
export const isUserCouponsProcessing = state => state.user.coupons.isProcessing;

export default combineReducers({
    data,
    isLoading,
    isProcessing
});