import React from 'react';
import {connect} from 'react-redux';
import MDSpinner from "react-md-spinner";
import Button from './Button';
import ShowtimesDateFilter from "./ShowtimesDateFilter";
import ShowtimesVersionsBlocks from "./ShowtimesVersionsBlocks";
import SidebarPanel from "./SidebarPanel";
import SidebarMovieInfo from "./SidebarMovieInfo";
import {cinemaMovieFetch, showtimesFilterDateChange, movieTabHideToggle, movieTabOpen, showtimesFilterFormatChange, showtimesFilterFormatClear} from '../actions/creators';
import {getAvailableFilters,filterAvailableVersions,getFilteredVersions,filterVersionsForDate,getMovieShowtimesDates} from '../utils/showtimes';
import {getArea,getCinema,getBootstrapFilters,isUiMovieTabOpen} from '../reducers';
import {getAreaBillboardPermalink} from '../utils/urls';
import ChoicesFilter from "./ChoicesFilter";
import { cinemaSelectorOpen } from '../actions/creators/ui';
import { AnalyticsBillboards } from '../utils/analytics';
import BackButton from './common/BackButton';
import styled from 'styled-components';

let lastTracked;
let lastMovieTracked;

const FiltersCotainer = styled.div`
  background: var(--bg-secondary);

  body.theme-market & { background: var(--page-bg); border-bottom: 1px solid var(--text-primary); }
  body.theme-platinum & { background: var(--primary-c); }
`;

class ShowtimeSelector extends React.Component {
  constructor(props) {
    super(props);

    this.onFilterToggle = this.onFilterToggle.bind(this);
    this.onFormatToggle = this.onFormatToggle.bind(this);
    this.onLanguageToggle = this.onLanguageToggle.bind(this);

    this.ref = React.createRef();
  }

  componentDidMount() {
    this.props.open();
    lastTracked = null;
    lastMovieTracked = null;

    const { cinemaId, movieId } = this.props;
    if (!cinemaId || !movieId) return;
    
    this.props.cinemaMovieFetch(cinemaId, movieId);
    this.trackView();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.movieId !== prevProps.movieId || this.props.cinemaId !== prevProps.cinemaId) {
      this.props.open();
      this.props.cinemaMovieFetch(this.props.cinemaId, this.props.movieId);
      this.ref && this.ref.current && this.ref.current.scrollTop && this.ref.current.scrollTop();
    }
    
    this.trackView();
  }

  trackView() {
    const movieId = this.props.movie ? this.props.movie.id : null;
    const cinemaId = this.props.cinema ? this.props.cinema.id : null;

    if (!movieId) return;

    const id = movieId + '-' + cinemaId;

    if (id === lastTracked) return;

    if (movieId !== lastMovieTracked) {
      lastMovieTracked = movieId;
      AnalyticsBillboards.trackMovieView(this.props.movie);
    }

    if (cinemaId) {
      lastTracked = id;
      AnalyticsBillboards.trackMovie(this.props.movie, this.props.cinema);
    }
  }

  render() {
    if (!this.props.movieId) {
      return '';
    }

    return (
        <SidebarPanel ref={this.ref} isHidden={this.props.hidden}>
          <div className='container pad-y'>
            <BackButton label={this.props.cinema ? this.props.cinema.name : 'Selecciona un cine'}
              onBackClick={this.props.hideToggle}
              onLabelClick={this.props.openCinemaSelector}
            />
          </div>
          {this.props.movie && <SidebarMovieInfo movie={this.props.movie} open={this.props.movieTabOpen} />}
          {this.renderShowtimes()}
          {this.renderNearCinemasButton()}
        </SidebarPanel>
    );
  }

  renderShowtimes() {
    if (this.props.cinemaId === 0) {
      return (
          <div className="container py-5">
            <Button primary block>Selecciona un cine para ver los horarios</Button>
          </div>
      );
    }

    if (this.props.isLoading) {
      return <div className="container py-5 text-center"><MDSpinner singleColor="var(--primary)"/></div>;
    }

    if (this.props.loadError) {
      return <div className="container py-5">Ocurrió un error</div>;
    }

    if (!this.props.dates.length) {
      return <div className="container py-5">{this.props.cinema
        ? 'No hay funciones para esta pelicula en el cine seleccionado'
        : <a href="#" onClick={(e) => { e.preventDefault(); this.props.openCinemaSelector(); }}>Selecciona un cine para ver los horarios</a>}</div>;
    }

    return (
        <React.Fragment>
          <ShowtimesDateFilter dates={this.props.dates} date={this.props.date} onDateSelect={this.props.showtimesFilterDateChange} />
          {this.renderFiltersAndVersions()}
        </React.Fragment>
    );
  }

  renderFiltersAndVersions() {
    if (this.props.dates.indexOf(this.props.date) === -1) {
      return <div className="container py-5">No hay funciones para la fecha seleccionada</div>;
    }

    return (
        <div key={this.props.date} className="fade-in">
          <FiltersCotainer>
            <ChoicesFilter collapsible={true} label="Formato" partialSet={true} filters={this.props.filters.format} selection={this.props.filtersSelection.format} attributes={this.props.attributes} onChange={this.onFormatToggle} />
            <ChoicesFilter collapsible={true} label="Idioma" partialSet={true} filters={this.props.filters.lang} selection={this.props.filtersSelection.lang} attributes={this.props.attributes} onChange={this.onLanguageToggle} />
          </FiltersCotainer>

          {this.renderVersions()}
        </div>
    );
  }

  renderVersions() {
    if (!this.props.filteredVersions.length) {
      return (
          <div className="container py-5">
            <p>No hay horarios con los filtros seleccionados.</p>
            <Button large primary outline block onClick={this.props.clearFilters}>Ver todos los horarios disponibles</Button>
          </div>
      );
    }

    return <ShowtimesVersionsBlocks versionsForDate={this.props.filteredVersions} movie={this.props.movie} />;
  }

  onFilterToggle(key, filter) {
    const {filtersSelection} = this.props;
    const selection = filtersSelection[key];
    let result;

    if ('*' === filter) {
      result = [];
    } else {
      const i = selection.indexOf(filter);
      if (i === -1) {
        result = selection.concat([filter]);
      } else {
        result = selection.slice(0, i).concat(selection.slice(i+1));
      }
    }

    Object.keys(filtersSelection).forEach(k => {
      if (k !== key) {
        result = result.concat(filtersSelection[k]);
      }
    });

    this.props.filtersChange(result);
  }

  onFormatToggle(format) {
    this.onFilterToggle('format', format);
  }

  onLanguageToggle(lang) {
    this.onFilterToggle('lang', lang);
  }

  renderNearCinemasButton() {
    if (!this.props.cinema || !this.props.cinema.area) {
      return '';
    }

    let url = getAreaBillboardPermalink(this.props.cinema.area);
    
    if (this.props.date) {
      url += '/fecha-' + this.props.date.split('-').join('');
    }

    url += '/pelicula-' + this.props.movieId;

    return (
        <div className="container pb-5 pt-3">
          <Button to={url} block>Ver horarios en toda la zona</Button>
        </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  // @todo use reselect
  // @todo better conditions
  const cinema = getCinema(state, parseInt(props.cinemaId, 10));
  const area = cinema && getArea(state, cinema.area.id);
  const {data} = state.showtimesData;
  const versions = data && data.versions ? filterAvailableVersions(data.versions) : [];
  const dates = versions.length && getMovieShowtimesDates(versions);
  const date = state.showtimesData.date || (dates && dates[0]);
  const versionsForDate = (versions.length && dates) ? filterVersionsForDate(versions, date) : [];

  let {filtersSelection} = state.showtimesData;
  filtersSelection = {
    format: filtersSelection.filter(filter => !filter.match(/^lang_/)),
    lang: filtersSelection.filter(filter => filter.match(/^lang_/)),
  };

  let filteredVersions = [];
  if (versionsForDate.length) {
    filteredVersions = getFilteredVersions(filtersSelection.format, versionsForDate);
    filteredVersions = getFilteredVersions(filtersSelection.lang, filteredVersions);
  }

  const filters = getAvailableFilters(versionsForDate);

  return {
    area,
    attributes: getBootstrapFilters(state),
    cinema,
    date,
    dates,
    filteredVersions,
    filters: {
      format: filters.filter(filter => !filter.match(/^lang_/)),
      lang: filters.filter(filter => filter.match(/^lang_/)),
    },
    filtersSelection,
    hidden: state.movieTab.hidden,
    isLoading: state.showtimesData.isLoading,
    loadError: state.showtimesData.loadError,
    movie: state.entities.movies[props.movieId],
    movieTabOpen: isUiMovieTabOpen(state),
  };
};

const mapDispatchToProps = dispatch => ({
  cinemaMovieFetch: (cinemaId, movieId) => dispatch(cinemaMovieFetch(cinemaId, movieId)),
  showtimesFilterDateChange: (date) => dispatch(showtimesFilterDateChange(date)),
  hideToggle: () => dispatch(movieTabHideToggle()),
  open: () => dispatch(movieTabOpen()),
  filtersChange: (formats) => dispatch(showtimesFilterFormatChange(formats)),
  clearFilters: () => dispatch(showtimesFilterFormatClear()),
  openCinemaSelector: () => dispatch(cinemaSelectorOpen()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShowtimeSelector);