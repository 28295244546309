import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {withRouter} from 'react-router-dom';
import {preCheckoutConfirm} from '../actions/creators/checkout';
//import {Link} from 'react-router-dom';
import {parseSessionIsoDateForDisplay} from '../utils/dates';
import { getCinema } from '../reducers';
import Button from './Button';

const StyledButton = styled(Button)`  
  --local-border-color: ${props => {
    switch(props.availability) {
      case 'low': return `var(--color-status-danger)`;
      case 'mid': return `var(--color-status-mid)`;
      default: return `var(--color-status-safe)`;
    }
  }};
`;

class ShowtimeButton extends React.Component {
  constructor(props) {
    super(props);

    this.onButtonClick = this.onButtonClick.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.session.id !== nextProps.session.id;
  }

  onButtonClick(e) {
    e.preventDefault();
    
    const {path} = this.props;
    const {history} = this.props;

    if (this.props.askConfirmation === true) {
      this.props.preCheckoutConfirm({
        movie: this.props.movieName,
        version: this.props.version,
        date: this.props.date.format('dddd D [de] MMMM'),
        time: this.props.date.format('hh:mm A'),
        cinema: this.props.cinema,
      }).then(() => { history.push(path); }).catch();
    } else {
      history.push(path);
    }
  }

  render() {
    const {session} = this.props;
    const {date} = this.props;

    return (
        <StyledButton href={this.props.path} availability={session.availability} outline small
                      title={date.format('llll')} onClick={this.onButtonClick}>
          {date.format('hh:mm A')}
        </StyledButton>
    );
  }
}

const mapStateToProps = (state, props) => ({
  cinema: getCinema(state, ('cinema' in props.session) ? props.session.cinema.id : props.session.cinema_id).name,
  date: parseSessionIsoDateForDisplay(props.session.datetime),
  path: '/checkout/' + props.session.id,
});

const mapDispatchToProps = dispatch => ({
  preCheckoutConfirm: payload => dispatch(preCheckoutConfirm(payload))
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowtimeButton));