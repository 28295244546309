import React from 'react';
import PropTypes from 'prop-types';
import {NavLink,Switch,Route} from 'react-router-dom';
import Sticky from 'react-stickynode';
import {Helmet} from 'react-helmet';
import DynamicPost from './DynamicPost';
import FullColumnLayout from './FullColumnLayout';
import LinkButton from "./LinkButton";
import PageTitle from './common/PageTitle';
import styled from 'styled-components';

const Nav = styled.nav`
  background: var(--page-bg);

  & a { color: var(--text-color); }
`;

class PageContentContent extends React.Component {
  constructor(props) {
    super(props);

    this.menuToggle = this.menuToggle.bind(this);

    this.state = {
      menuOpen: false,
    };
  }

  menuToggle(e) {
    e.preventDefault();

    this.setState(state => ({menuOpen: !state.menuOpen}));
  }

  render() {
    const {props} = this;

    return (
        <div className="container">
          <Helmet><title>{props.title + (props.fixedTitle ? (' - ' + props.fixedTitle) : '')} - Cinemex</title></Helmet>
          <div className="row mt-4">

            {props.showSectionTitle !== false && (
              <div className="col-12">
                <div className="d-flex align-items-end">
                  <PageTitle backTo='/' title={props.fixedTitle || props.title} fullWidth/>

                  <a className="d-md-none mt-2 mb-5 ml-auto text-nowrap" role="button" data-toggle="collapse"
                    href="#navbarSubpages" aria-controls="navbarSubpages" aria-expanded="false" onClick={this.menuToggle}>
                    Menú <i className={'fal ' + (this.state.menuOpen ? 'fa-angle-up' : 'fa-angle-down')}></i>
                  </a>
                </div>
              </div>
            )}

            <div className="col-12 col-md-3">

              <Sticky top="#header" bottomBoundary="#the-body" innerZ="1">
                <Nav className="navbar navbar-expand-md p-0">
                  <div className={(this.state.menuOpen ? 'fade-in' : 'collapse') + ' navbar-collapse'} id="navbarSubpages">
                    <ul className="navbar-nav nav flex-column w-100 mb-5">
                      {props.routes.map((route, i) => (
                          route.nav !== false && (
                              <li key={i} className="nav-item border-bottom">
                                {route.onClick ? (
                                    <LinkButton paddingDefault onClick={route.onClick} className="nav-link">
                                      {route.anchor || route.title}
                                    </LinkButton>
                                ) : (
                                    <NavLink to={typeof route.path === 'string' ? route.path : route.path[0]} exact={('exact' in route) ? route.exact : true} className="nav-link" activeClassName="active text-primary">
                                      {route.anchor || route.title}
                                    </NavLink>
                                )}
                              </li>
                          )
                      ))}
                    </ul>
                  </div>
                </Nav>
              </Sticky>

            </div>

            <div className="col-12 col-md-9 col-xl-7 pl-md-5">

              <article className="c-entry">
                {props.header && <img className="img-fluid rounded mb-5" src={props.header} alt={props.headerAlt}/>}
                {(props.showTitle !== false) && <h1 className="c-entry__title">{props.title}</h1>}
                <div className={props.entryClass !== false ? 'c-entry__body' : ''}>
                  {renderEntryBody(props)}
                </div>
              </article>

            </div>

          </div>
        </div>
    );
  }
}

const renderEntryBody = props => {
  window.scroll && window.scroll(0, 0);

  if (props.slug) {
    return <DynamicPost key={props.slug} slug={props.slug}/>;
  }

  if (props.component) {
      return <props.component/>;
  }

  if (props.render) {
    return props.render();
  }
};

const PageContent = props => (
    <Switch>
      {props.routes.map((route, i) => <Route key={i} exact={('exact' in route) ? route.exact : true} path={route.path} render={() => <PageContentContent {...route} fixedTitle={props.fixedTitle} routes={props.routes} header={props.header} headerAlt={props.headerAlt} entryClass={props.entryClass}/>}/>)}
    </Switch>
);

class NavBarLayout extends React.Component {
  render() {
    return <FullColumnLayout Content={PageContent} {...this.props} bgWhite/>
  }
}

NavBarLayout.propTypes = {
  fixedTitle: PropTypes.string,
};

export default NavBarLayout;