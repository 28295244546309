import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import LinkButton from "./LinkButton";
import {authOpen} from '../actions/creators';
import {cinemaSelectorOpen,areaSelectorOpen} from '../actions/creators/ui';
import {getUserInfo,getSelectedCinema} from '../reducers';
import {onAvatarLoadError} from '../utils/misc';

const Avatar = styled.img`
  display: block;
  margin-left: 2em;
  vertical-align: middle;
  max-width: 1.75rem;
`;

const NoAvatarIcon = styled.i`
  font-size: 1.75rem;
`;

class UserCinemaNavLinks extends React.PureComponent {
  constructor(props) {
    super(props);

    this.renderUserIcon = this.renderUserIcon.bind(this);
  }

  render() {
    return (
        <React.Fragment>
          <li className="nav-item">
            {this.props.cinemaSelector !== false && (
                <LinkButton onClick={this.props.openCinemaSelector} icon='fa-angle-down'>
                  {this.props.cinema ? this.props.cinema.name : 'Selecciona tu cine'}
                </LinkButton>
            )}
            {this.props.areaSelector === true && (
                <LinkButton onClick={this.props.openAreaSelector} icon='fa-angle-down'>
                  {this.props.area ? this.props.area.name : 'Selecciona una zona'}
                </LinkButton>
            )}
          </li>
          {this.props.userIcon !== false && (
            <li className='d-none d-md-inline-block'>
              {this.renderUserIcon()}
            </li>
          )}
        </React.Fragment>
    );
  }

  renderUserIcon() {
    if (!this.props.userInfo) {
      return <LinkButton className="nav-link ml-5" onClick={this.props.authOpen}>
        <NoAvatarIcon className="fal fa-user-circle"></NoAvatarIcon>
      </LinkButton>;
    } else {
      return <Link to="/usuario/">
        <Avatar src={this.props.userInfo.avatar} className="rounded-circle" onError={onAvatarLoadError}/>
      </Link>;
    }
  }
}

const mapStateToProps = (state, props) => ({
  userInfo: getUserInfo(state),
  cinema: props.cinema || getSelectedCinema(state),
});

const mapDispatchToProps = dispatch => ({
  authOpen: () => dispatch(authOpen()),
  openCinemaSelector: () => dispatch(cinemaSelectorOpen()),
  openAreaSelector: () => dispatch(areaSelectorOpen()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserCinemaNavLinks);