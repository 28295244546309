import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import { asset } from '../../utils/misc';
import { centsToCurrencyString } from '../../utils/numbers';
import { breakpoints } from '../../utils/styles';
import Button from '../Button';

const LevelsContainer = styled.div`
  margin-bottom: 2em;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    ${props => props.extraWidth ? 'width: 120%;' : ''}
  }
`;
const LevelIcon = styled.img`
  opacity: 0.5;
  position: absolute;
  right: -2rem;
  top: -2rem;
  width: 195px;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    left: 50%;
    opacity: 1;
    top: 1em;
    transform: translateX(-50%);
    width: auto;
  }
`;
const LevelEmoji = styled.p`
  position: absolute;
  right: 2rem;
  top: 0.75rem;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    position: relative;
    right: auto;
    top: auto;
    font-size: 2.5em;
    margin: 0.25em 0 !important;
  }
`;
const LevelTitle = styled.h2`
  color: var(--text-primary) !important;
  font-size: 1.75em;
  font-weight: 700;
  margin: 0.5em 0 1em;

  body.theme-market & {
    text-transform: uppercase !important;
  }
    
  body.theme-platinum & {
    color: var(--text-primary) !important;
    font-size: ${(16/12.6).toFixed(2)}rem !important;
    font-weight: 400 !important;
    line-height: 1.5;
  }
    
  @media screen and (min-width: ${breakpoints.desktop}px) {
    font-size: 1.75em !important;
  }

`;
const LevelBenefits = styled.ul`
  margin-bottom: 1em !important;
  padding: 0 0 0 1.5em;
  text-align: left;

  & > li { margin-bottom: 0.5em; }
  & > li > strong {
    color: var(--accent);
    body.theme-platinum & { color: var(--primary); }
  }
`;
const LevelPrice = styled.p`
  font-size: 1.75em;
  font-weight: 700;
  margin: 0.75em 0 0.5em 0 !important;
`;

const LevelBox = styled.div`
  background: var(--page-bg);
  border: 1px solid var(--text-primary);
  cursor: pointer;
  margin-bottom: 2em;
  overflow: hidden;
  padding-bottom: 3.5rem;
  position: relative;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 0;
    text-align: center;
  }

  & ${LevelTitle} { ${props => props.altStyles ? 'font-size: 1.2em;' : ''} }

  ${props => props.selected && `
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border: 2px solid var(--text-primary);
    }
  `}

  ${props => props.selected && `
    body.theme-platinum & {
      &, &:before { border-color: var(--primary); }
    }
  `}

  body.theme-platinum & ${LevelTitle} {
    ${props => props.selected && `
      color: var(--primary) !important;
    `}
  }
`;

const LevelContent = styled.div`
  line-height: 1.5;
  padding: 1rem 2rem;
  transition: border-color 0.25s ease-out;

  & p {
    margin: 1em 0;
  }
  
  @media screen and (min-width: ${breakpoints.desktop}px) {
    border-bottom-color: transparent !important;
    font-size: 0.7em;
    height: 101%;

    body.theme-market & { font-size: 1em; }
  }
`;

const LevelButton = styled(Button)`
  bottom: 1.5em;
  position: absolute;
  right: 1.5em;
  
  @media screen and (min-width: ${breakpoints.desktop}px) {
    bottom: 1rem;
    margin-top: -2px;
    right: 1rem;
    left: 1rem;
  }

  ${LevelBox}:hover & {
    background: var(--primary);
    --local-border-color: var(--primary);
    color: var(--text-tertiary);
  }
`;

const TermsLinks = styled.p`
  font-size: 0.8em;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    font-size: 1em;
  }
`;

function getIconImage(optionId) {
  if (optionId.substr(0, 4) === 'one-') optionId = 'one';
  if (optionId.substr(0, 9) === 'buen-fin-') optionId = 'basic';
  return 'logo-cinemex-icon-' + optionId + '.svg'
}

function getOptionTitle(option) {
  let title = '';
  
  if (('is_current' in option) && option.is_current === false) {
    title = 'Subir a ';
  }

  title += option.name;

  return title;
}

function formatText(text) {
  return text.replace(/\*(.+)\*/, '<strong>$1</strong>');
}

function IeSignUpOptions(props) {
    return <LevelsContainer>
        {props.levels.map(option => {
          const selected = props.selected && props.selected.id === option.id;

          return <LevelBox key={option.id} onClick={e => { e.stopPropagation(); props.onLevelSelect(option); }} selected={selected} className="fade-in" altStyles={props.altStyles}>
              <LevelContent selected={selected}>
                {option.icon ? <LevelEmoji>{option.icon}</LevelEmoji> : ''}
                <LevelTitle className='title-2'>{getOptionTitle(option)}</LevelTitle>
                {option.benefits.length > 1
                  ? <LevelBenefits>
                        {option.benefits.map(benefit => <li dangerouslySetInnerHTML={{__html: formatText(benefit)}}/>)}
                    </LevelBenefits>
                  : <p>{option.benefits[0]}</p>}
                <TermsLinks>
                  {option.terms_url ? <React.Fragment><a href={option.terms_url}>Consulta términos y condiciones.</a><br/></React.Fragment> : ''}
                  <Link to="/loop/niveles-beneficios">Ver todos los beneficios.</Link>
                </TermsLinks>
                {!props.hidePriceZero ? <LevelPrice>{centsToCurrencyString(option.price)}<small>/año</small></LevelPrice> : ''}
              </LevelContent>
              <LevelButton primary={selected}>Seleccionar</LevelButton>
            </LevelBox>
    })}
    </LevelsContainer>;
}

export default IeSignUpOptions;