import React from 'react';
import ShowtimesGrid from '../common/ShowtimesGrid';
import styled from 'styled-components';

const Label = styled.h3`
  body.theme-platinum & {
    font-size: 0.8rem;
    font-weight: 400 !important;
    text-transform: uppercase;
  }
`;

const shallowSessionsSerialize = sessions => sessions.map(session => session.id).join(',');

const getVersionLabel = version => {
  const types = version.type.filter(type => !type.match(/^lang_/));
  let label = version.label;

  if (!types.length || types.indexOf('2d') > -1) {
    label = 'Tradicional ' + label;
  }

  return label;
};

class BillboardMovieVersion extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.version.id !== nextProps.version.id
        || shallowSessionsSerialize(this.props.version.sessions) !== shallowSessionsSerialize(nextProps.version.sessions);
  }

  render() {
    const {version} = this.props;
    const label = getVersionLabel(version);

    return (
        <div className="row pt-4" key={version.id}>
          <div className="col col-12 col-lg-3">
            <Label className="subtitle-2 mb-3 mt-md-2">{label}</Label>
          </div>
          <div className="col col-12 col-lg-9">
            <ShowtimesGrid movie={this.props.movie} version={version}/>
          </div>
        </div>
    );
  }
}

export default BillboardMovieVersion;