import React from 'react';
import uniqid from 'uniqid';
import styled, { css } from 'styled-components';
import Hint from './forms/Hint';
import RadiosGroup from './forms/RadioGroup';

const StyledInput = styled.input`
  padding-left: 0;
  padding-right: 0;
  
  &.card-visa, &.card-amex, &.card-master, &.card-moviecard, &.card-carnet {
      background-image: url('/dist/images/card-icons-sprite.png');
      background-repeat: no-repeat;
  }
  &.card-visa { background-position: right -33px; }
  &.card-amex { background-position: right -111px; }
  &.card-master { background-position: right -72px; }
  &.card-moviecard { background-position: right -156px; }
  &.card-carnet { background-position: right -189px; }
  
  ${props => (props.type === 'checkbox' || props.type === 'radio') && `
    display: inline-block !important;
    width: auto !important;
    margin-right: 0.5em;
    vertical-align: middle;
  `}
`;

const StyledTextArea = styled.textarea`
  padding-left: 0;
  padding-right: 0;
`;

const StyledSelect = styled.select`
  padding-left: 0;
  padding-right: 0;
`;

const HideLabel = styled.label`
  left: 0 !important;
  text-indent: 2px !important;
  ${props => props.visible && css`opacity: 1 !important; top: 10px !important;`}
  
  ${props => (props.type === 'checkbox' || props.type === 'radio') && `
    opacity: 1 !important;
    position: relative !important;
    top: auto !important;
    display: inline !important;
    font-size: 1rem !important;
    font-weight: 400 !important;

    body.theme-platinum & { text-transform: none !important; }
  `}

  ${props => ('color' in props) && `color: ${props.color} !important;`}
`;

const FieldIcon = styled.em`
  position: absolute;
  top: 40px;
  right: 5px;
  width: 2em;
  text-align: center;
`;

const getValidClassName = valid => {
  if (valid === null) {
    return '';
  }

  return 'form-control-' + (valid === true ? 'valid' : 'invalid');
};

const onFocus = (e, fn) => {
  const input = e.target;
  const v = input.value;

  if (!v) {
    const placeholder = input.getAttribute('data-placeholder');
    placeholder && input.setAttribute('placeholder', placeholder);
  }

  fn && fn(e);
};

const onBlur = (e, fn) => {
  const input = e.target;
  const v = input.value;

  if (!v) {
    const placeholder = input.getAttribute('data-label');
    input.setAttribute('placeholder', placeholder);
  }

  fn && fn(e);
};

const RenderElement = props => {
  let type = props.type || 'text';

  if (type === 'password' && props.passVisibility) type = 'text';

  return React.createElement(
    props.Input,
    Object.assign(
        {
          className: 'form-control ' + getValidClassName(props.valid) + ' ' + (props.className || ''),
          id: props.id,
          checked: props.checked,
          disabled: props.disabled,
          onBlur: props.onBlur,
          onChange: props.onChange,
          onClick: props.onClick,
          onFocus: props.onFocus,
          placeholder: props.label,
          readOnly: !props.onChange,
          type: type,
          value: props.value,
          'data-placeholder': props.placeholder,
          'data-label': props.label,
          autoComplete: props.autoComplete
        },
        props.inputProps
    ),
    props.choices && [React.createElement('option', {value: '', key: 'placeholder'}, props.placeholder || props.label)].concat(
        props.choices.map(choice => React.createElement('option', {value: choice.id, key: choice.id},choice.name))
    )
  );
}

class FieldRow extends React.Component {
  constructor(props) {
    super(props);

    this.onPassVisibilityToggle = this.onPassVisibilityToggle.bind(this);

    this.state = {
      passVisibility: false
    };
  }

  onPassVisibilityToggle() {
    this.setState({passVisibility: !this.state.passVisibility});
  }

  render() {
    const {props} = this;
    const id = props.id || uniqid();
    let Input;

    if (props.type === 'textarea') {
      Input = StyledTextArea;
    } else if (props.choices) {
      Input = StyledSelect;
    } else {
      Input = StyledInput;
    }

    const onBlurLocal = props.label !== props.placeholder
        ? e => onBlur(e, props.onBlur)
        : props.onBlur;

    const onFocusLocal = props.label !== props.placeholder
        ? e => onFocus(e, props.onFocus)
        : props.onFocus;

    return (
        <div className={'form-group form-group--label-helper ' + (props.rowClassName || '')}>
          {props.choices && props.expanded
              ? <RadiosGroup id={id} {...props}/>
              : <React.Fragment>
                <RenderElement Input={Input} {...props} id={id} onBlur={onBlurLocal} onFocus={onFocusLocal} passVisibility={this.state.passVisibility}/>
                <HideLabel htmlFor={id} visible={props.value !== '' || props.type === 'file' || props.labelAlwaysVisible} type={props.type} color={props.labelColor || ''}>{props.label} {props.required && <span className="text-primary">*</span>}</HideLabel>
                {props.hint ? <Hint>{props.hint}</Hint> : ''}
                {props.type === 'password' && props.passwordVisibilityToggle !== false ? <FieldIcon className={`fal fa-eye${this.state.passVisibility ? '' : '-slash'}`} onClick={this.onPassVisibilityToggle}></FieldIcon> : ''}
                {props.icon ? <FieldIcon className={props.icon}/> : ''}
              </React.Fragment>
          }
          {props.validationError && (
              <div className="form-message-invalid fade-in">
                {props.validationError}
              </div>
          )}
        </div>
    );
  }
};

export default FieldRow;