import React from "react";
import FieldRow from "../../FieldRow";

interface Props {
    onChange: Function,
    validate: Function,
    email: string,
    emailValid: boolean,
    emailError: string,
    name: string,
    nameValid: boolean,
    nameError: string,
}

function CommonFields(props: Props) {
    return (
        <React.Fragment>
            <FieldRow type="email" label="Email"
                onChange={(e) => props.onChange('email', e.target.value)}
                onBlur={() => props.validate('email')}
                value={props.email}
                valid={props.emailValid}
                validationError={props.emailError}
            />
            <FieldRow label="Nombre y apellido"
                onChange={(e) => props.onChange('name', e.target.value)}
                onBlur={() => props.validate('name')}
                value={props.name}
                valid={props.nameValid}
                validationError={props.nameError}
            />
        </React.Fragment>
    );
}

export default CommonFields;