import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import Button from "../../Button";
import CouponWindow from "./CouponWindow";
import CouponAvailabilityIcon from "./CouponAvailabilityIcon";
import FloatingPost from "../../FloatingPost";
import Barcode from "react-barcode";
import { breakpoints } from "../../../utils/styles";

const Header = styled.div`
    position: relative;

    & ${CouponAvailabilityIcon.Styled} {
        bottom: 0;
        font-size: 2.25rem;
        left: 50%;
        margin: 0 auto;
        position: absolute;
        transform: translate(-50%,50%);
    }
`;

const CouponImage = styled.img`
    display: block;
    height: auto;
    width: 100%;
`;

const CouponContent = styled.div`
`;

const AvailabilityMessage = styled.p<{color: string}>`
    background: ${props => props.color};
    color: #FFFFFF;
    font-weight: 700;
    margin: 0 !important;
    padding: 0.5em 1.5em 0.35em;
    text-align: center;
`;

const CouponTitle = styled.h2`
    color: var(--text-primary) !important;
    font-family: var(--ff-title);
    font-size: ${(30/12.6).toFixed(2)}rem !important;
    font-weight: 400;
    margin-bottom: 0.75em !important;
    text-align: center;
    text-transform: uppercase;

    body.theme-market & {
        font-size: ${(25/12.6).toFixed(2)}rem !important;
        margin-bottom: 1em !important;
    }

    body.theme-platinum & {
        font-weight: 700;
    }
`;

const CouponDescription = styled.div`
    margin-bottom: 3em;

    & > p {
        line-height: 1.2;
        margin-bottom: 1.5em;
    }
`;

const CouponTerms = styled.p`
    font-size: 0.8em;
`;

const CouponExpiryBlock = styled.p`
    display: flex;
    font-size: 0.8em;
    line-height: 1.5;

    & strong {
        font-weight: 700;
        margin-bottom: 0.5em;
        text-transform: uppercase;
    }

    & i {
        margin-right: 0.5em;
    }

    & > p {
        flex-grow: 1;
        margin: 0 0 1em;
        text-align: center;
        &:first-child { border-right: 1px solid #999; }
    }
`;

const CodeBlock = styled.div`
    & > svg {
        display: block;
        margin: 0 auto;
        max-width: 90%;
    }
`;

const CouponCode = styled.p<{copied:boolean}>`
    font-family: 'Roboto Mono', monospace;
    font-size: 1.25rem;
    letter-spacing: 0.25em;
    margin-bottom: 0 !important;
    padding: 0;
    text-align: center;
    transition: opacity 0.5s ease-in-out;

    ${props => props.copied && 'opacity: 0; transition: none;'}

    @media screen and (min-width: ${breakpoints.desktop}px) {
        font-size: 1rem;
    }

    body.theme-market &,
    body.theme-platinum & { margin-top: 1rem; }
`;

const CopyButton = styled.i`
    cursor: pointer;
    margin-left: 1em;
`;

function CouponDetails({coupon, onActivate, onClose}) {
    const [termsOpen, setTermsOpen] = useState<boolean>(false);

    const activationDateLimit = moment(coupon.activation_date_limit, 'YYYY-MM-DD');
    const redeemDateLimit = moment(coupon.redeem_date_limit, 'YYYY-MM-DD');
    
    // useEffect(() => {
    //     if (!ref.current) return;
    //     setTimeout(function() {
    //         ref.current && ref.current.classList.add('active');
    //     }, 100);
    // }, []);
    

    return <React.Fragment>
        <CouponWindow title="Cupón" onClose={onClose} desktopModal>
            <Header>
                {coupon.image && <CouponImage src={coupon.image} alt={coupon.title} />}
                <AvailabilityMessage color={coupon.status_color}>{coupon.status_text}</AvailabilityMessage>
            </Header>
            <CouponContent>
                <div className="pad-block">
                    <CouponTitle>{coupon.title}</CouponTitle>
                    <CouponDescription>
                        {coupon.description.split('\n').filter(line => line.trim().length).map(line => <p>{line}</p>)}
                        <CouponTerms>
                            <a href={coupon.terms} onClick={e => { e.preventDefault(); setTermsOpen(true); }}>Consulta los términos y condiciones aquí</a>
                        </CouponTerms>
                    </CouponDescription>
                    <CouponExpiryBlock className="d-flex">
                        <p>
                            <strong><i className="fa-light fa-calendar"></i> Vigencia de activación</strong>
                            <br/>
                            Hasta el {activationDateLimit.format('DD [de] MMMM [de] YYYY')}
                        </p>
                        <p>
                            <strong><i className="fa-light fa-clock"></i> Vigencia de redención</strong>
                            <br/>
                            Hasta el {redeemDateLimit.format('DD [de] MMMM [de] YYYY')}
                        </p>
                    </CouponExpiryBlock>
                    {coupon.code
                        ? <CodeBlock>
                            <Barcode value={coupon.code} width={2} height={50} displayValue={false}/>
                            <Code code={coupon.code}/>
                        </CodeBlock>
                        : <Button block primary large onClick={() => onActivate(coupon)}>Activar cupón</Button>
                    }
                </div>
            </CouponContent>
        </CouponWindow>
        {termsOpen && <FloatingPost slug={coupon.terms} onClose={() => setTermsOpen(false)}/>}
    </React.Fragment>;
}

function Code({code}) {
    const [copied, setCopied] = useState<boolean>(false);
    useEffect(() => {}, [copied]);

    function copy() {
        navigator.clipboard.writeText(code);
        setCopied(true);
        setTimeout(function() {
            setCopied(false);
        }, 10);
    }

    return <CouponCode copied={copied}>
        {code}
        <CopyButton className="fa-regular fa-copy" onClick={copy}></CopyButton>
    </CouponCode>;
}

export default CouponDetails;