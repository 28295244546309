import * as actions from '../actions/types';
import { combineReducers } from 'redux';

const mapEntitiesById = entities => {
  const result = {};

  entities.forEach(entity => result[entity.id] = entity);

  return result;
};

const sessions = (state = {}, action) => {
  switch (action.type) {
    case actions.CHECKOUT_SESSION_FETCH_SUCCESS:
      return Object.assign({}, state, {[action.data.id]: action.data});
    default:
      return state;
  }
};

const tickets = (state = {}, action) => {
  switch (action.type) {
    case actions.CHECKOUT_SESSION_FETCH_SUCCESS:
      return Object.assign({}, state, mapEntitiesById(action.data.tickets));
    case actions.CHECKOUT_PROMO_CODE_SUBMIT_SUCCESS:
      return Object.assign({}, state, mapEntitiesById(action.tickets));
    case actions.USER_TICKETS_FETCH_SUCCESS:
      return Object.assign({}, state, mapEntitiesById(action.tickets));
    default:
      return state;
  }
};

const movies = (state = {}, action) => {
  switch (action.type) {
    case actions.MOVIES_FETCH_SUCCESS:
      return Object.assign({}, state, mapEntitiesById(action.movies));
    case actions.CHECKOUT_MOVIE_FETCH_SUCCESS:
    case actions.CINEMA_MOVIE_FETCH_SUCCESS:
      return mergeIfNotPresent(state, reduceMovieEntity(action.data));
    default:
      return state;
  }
};

const upcomingData = mapEntitiesById(window.upcoming.movies);
const moviesUpcoming = (state = upcomingData, action) => {
  switch (action.type) {
    case actions.MOVIES_UPCOMING_FETCH_SUCCESS:
      return Object.assign({}, state, mapEntitiesById(action.movies));
    case actions.MOVIE_UPCOMING_FETCH_SUCCESS:
      return Object.assign({}, state, mapEntitiesById([action.movie]));
    default:
      return state;
  }
};

const reduceMovieEntity = movie => {
  const result = {[movie.id]: movie};

  movie.versions.forEach(version => {
    result[version.id] = Object.assign({}, movie, version);
    if (!result[version.id].parent_id) result[version.id].parent_id = movie.id;
  });

  return result;
};

function mergeIfNotPresent(entities, newEntities) {
  const filtered = {};
  const existingIds = Object.keys(entities);
  const newIds = Object.keys(newEntities);
  const addIds = newIds.filter(id => existingIds.indexOf(id) === -1);

  if (!addIds.length) return entities;

  addIds.forEach(id => filtered[id] = newEntities[id]);

  return Object.assign({}, entities, filtered);
}

const modalsData = window.modals;
const defaultModalsState = {};
modalsData.forEach(modal => defaultModalsState[modal.id] = modal);

const modals = (state = defaultModalsState, action) => state;

const areasData = window.states;
const defaultStatesState = {};
areasData.forEach(state => defaultStatesState[state.id] = state);

const states = (state = defaultStatesState, action) => state;

const defaultAreasState = {};
areasData.forEach(state => state.areas.forEach(area => defaultAreasState[area.id] = area));

const areas = (state = defaultAreasState, action) => state;

const defaultCinemasState = {};
window.cinemas.forEach(cinema => defaultCinemasState[cinema.id] = cinema);

const cinemas = (state = defaultCinemasState, action) => {
  switch (action.type) {
    case actions.CHECKOUT_CINEMA_FETCH_SUCCESS:
      return Object.assign({}, state, {[action.data.id]: action.data});
    default:
      return state;
  }
};

const candybarItems = (state = {}, action) => {
  switch (action.type) {
    case actions.CHECKOUT_CANDYBAR_FETCH_SUCCESS:
      return Object.assign({}, state, reduceCandybarItemsEntities(action.data.catalog));
    default:
      return state;
  }
};

const reduceCandybarItemsEntities = catalog => {
  const result = {};

  catalog.forEach(category => category.items.forEach(item => result[item.id] = item));

  return result;
};

const candybarOptions = (state = {}, action) => {
  switch (action.type) {
    case actions.CHECKOUT_CANDYBAR_FETCH_SUCCESS:
      return Object.assign({}, state, reduceCandybarOptionsEntities(action.data.catalog));
    default:
      return state;
  }
};

const reduceCandybarOptionsEntities = catalog => {
  const result = {};

  catalog
      .forEach(category => category.items
          .forEach(item => item.configs
              .forEach(config => config.options
                  .forEach(option => result[option.id] = option))));

  return result;
};

const promosData = window.promos;
promosData.promo = mapEntitiesById(promosData.promo);
export const promosPromos = (state = promosData.promo, action) => {
  switch (action.type) {
    case actions.PROMOS_FETCH_SUCCESS:
      return action.data.promo ? Object.assign({}, state, mapEntitiesById(action.data.promo)) : state;
    default:
      return state;
  }
};

export const promosA = (state = promosData.promoA, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const promosFeatured = (state = promosData.promoImportant, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

promosData.promoIE = mapEntitiesById(promosData.promoIE);
export const promosIe = (state = promosData.promoIE, action) => {
  switch (action.type) {
    case actions.PROMOS_FETCH_SUCCESS:
      return action.data.promoIE ? Object.assign({}, state, mapEntitiesById(action.data.promoIE)) : state;
    default:
      return state;
  }
};

export const promosWide = (state = promosData.promoWide, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const promosWideFixed = (state = promosData.promoWideFixed, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const news = (state = {}, action) => {
  switch (action.type) {
    case actions.NEWS_FETCH_SUCCESS:
      return Object.assign({}, state, mapEntitiesById(action.data));
    default:
      return state;
  }
};

const posts = (state = {}, action) => {
  switch (action.type) {
    case actions.POST_FETCH_SUCCESS:
      return Object.assign({}, state, {[action.slug]: action.data});
    default:
      return state;
  }
};

const landings = (state = {}, action) => {
  switch (action.type) {
    case actions.LANDING_FETCH_SUCCESS:
      return Object.assign({}, state, {[action.slug]: action.landing});
    default:
      return state;
  }
};

const ieBenefits = (state = {}, action) => {
  switch (action.type) {
    case actions.IE_BENEFITS_FETCH_SUCCESS:
      return Object.assign({}, state, parseIeBenefits(action.data));
    default:
      return state;
  }
};

const parseIeBenefits = data => {
  const result = {};

  Object.values(data).forEach(benefits => benefits.forEach(benefit => result[benefit.id] = benefit));

  return result;
};

export default combineReducers({
  areas,
  candybarItems,
  candybarOptions,
  cinemas,
  ieBenefits,
  landings,
  modals,
  movies,
  moviesUpcoming,
  news,
  posts,
  promosA,
  promosFeatured,
  promosIe,
  promosPromos,
  promosWide,
  promosWideFixed,
  sessions,
  states,
  tickets,
});