import styled from "styled-components";

const MovieRating = styled.span`
  background: var(--text-primary);
  color: var(--page-bg);
  display: block;
  font-size: 1.167em;
  font-weight: 700;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  width: 2rem;

  body.theme-market & {
    background: var(--primary);
    color: #FFFFFF;
    font-weight: 700;
  }

  body.theme-platinum & {
    background: var(--primary);
    color: var(--text-primary);
    font-weight: 700;
  }
`;

export default MovieRating;