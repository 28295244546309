import React from 'react';
import styled from 'styled-components';

const Btn = styled.button`
    background: none;
    border: 0;
    color: var(--text-tertiary);
    text-shadow: 0 0 1px black;

    &, &:focus { outline: none; }
`;

export default props => (
    <Btn className={'d-md-none ' + (props.className || '')} type="button" data-toggle="collapse"
                                aria-controls={props.target}
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                onClick={props.onClick}
    ><i className="fal fa-bars"></i></Btn>
);