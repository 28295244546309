import React from 'react';
import {Link} from 'react-router-dom';
import {cinemexAbsoluteToRelative} from '../utils/urls';

class NewsPageContent extends React.Component {
  render() {
    if (this.props.isLoading) return '';

    return (
        <div className="container">
          <div className="row mt-4">
            <div className={this.getMainColumnClass()}>

              <h1 className="h2 mb-5">
                <Link to="/" className="pr-3"><i className="fal fa-arrow-left fa-xs"></i></Link>
                Noticias
              </h1>

              <div className="row">
                {this.renderFirstArticle()}
                {this.renderArticles()}
              </div>

            </div>
          </div>
        </div>
    );
  }

  getMainColumnClass() {
    return this.props.open
        ? 'col-12 col-md-8'
        : 'col-12';
  }
  renderFirstArticle() {
    let firstArticle = this.props.articles.slice(0, 1);
    if (!firstArticle.length) {
      return '';
    }

    firstArticle = firstArticle[0];

    return (
        <div className={this.getFirstArticleClass()}>
          <article className="c-card-new c-card-new--overlay">
            <Link to={cinemexAbsoluteToRelative(firstArticle.url)} className="c-card-new__link">
              <div className="c-card-new__image" style={{backgroundImage: 'url(' + firstArticle.thumb + ')' }}></div>
              <div className="c-card-new__content">
                <h1 className="c-card-new__title">{firstArticle.title}</h1>
              </div>
            </Link>
          </article>
        </div>
    );
  }

  getFirstArticleClass() {
    return this.props.open
        ? 'col-12 col-md-8 col-xl-6 mb-gutter'
        : 'col-12 col-md-6 col-xl-4 mb-gutter';
  }

  renderArticles() {
    const articles = this.props.articles.slice(1);

    return articles.map(article => (
        <div key={article.id} className={this.getArticlesClass()}>
          <article className="c-card-new">
            <Link to={cinemexAbsoluteToRelative(article.url)} className="c-card-new__link">
              <div className="c-card-new__image">
                <img src={article.thumb} alt=""/>
              </div>
              <div className="c-card-new__content">
                <h1 className="c-card-new__title">{article.title}</h1>
              </div>
            </Link>
          </article>
        </div>
    ));
  }

  getArticlesClass() {
    return this.props.open
        ? 'col-12 col-sm-6 col-md-4 col-xl-3 mb-gutter'
        : 'col-12 col-sm-6 col-md-3 col-xl-2 mb-gutter';
  }
}

export default NewsPageContent;