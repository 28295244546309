import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import StepHeader from '../StepHeader';
import {seatSelect} from '../../../actions/creators/checkout/seats';
import {prevStep} from '../../../actions/creators/checkout';
import {areCheckoutSeatsPreSelected, getCheckoutSeatsLayout, getCheckoutSeatsToSelect, getCheckoutSeatTypes, getCheckoutSelectedSeats} from '../../../reducers';
import {breakpoints} from '../../../utils/styles';
import SeatsSelectionCount from './SeatsSelectionCount';
import './SeatsSelection.css';

const Auditorium = styled.div`
    border-top: none;
    margin-top: 1em;
    max-width: 100%;
    padding: 4rem 2rem 1rem;
    position:relative;
    
    ${props => props.screenImage
      ? `
        background: none;
        padding-top: 8rem;
        `
      : `
        background-image:-webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(0,0,0,0.25)), color-stop(15%, rgba(255,255,255,0)));
        background-image:linear-gradient(to bottom, rgba(0,0,0,0.25) 0, rgba(255,255,255,0) 15%);
        border-radius: 30% 30% 0 0 / 10% 10% 0 0
        &:before {
            border-radius: 30% 30% 0 0 / 10% 10% 0 0
            border-top: 5px solid rgba(208,3,61,0.5);
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
        }
        `}
`;

const ScreenLabel = styled.div`
    color: var(--primary);
    font-size: 0.9em;
    left: 50%;
    letter-spacing: 1em;
    line-height: 1em;
    padding: 0 1em 0 2em;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: -1.5em;
    transform: translateX(-50%);
    width: auto;
`;

const ScreenImageWrapper = styled.div`
    height: 100%;
    left: 50%;
    overflow: hidden;
    position: absolute;
    transform: translateX(-50%);
    top: 0;
    width: 100%;
`;

const ScreenImage = styled.img`
    height: auto;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    top: 0;
    width: 1000px;
`;

const SeatDimensions = `
    width: 1.5rem;
    height: auto;
    margin: 0.2rem;

    @media screen and (min-width: ${breakpoints.desktop}px) {
      margin: 0.15rem;
    }
`;

const RowName = styled.span`
    font-size: 0.75em;
    line-height: 2.75em;
    width: 3.5em;
    display: inline-block;
    text-align: center;
    ${props => props.left ? 'padding-right: 2em;' : 'padding-left: 2em;'}
`;

const SeatImg = styled.img`
    ${SeatDimensions}
`;

const SeatSpacer = styled.span`
    display: inline-block;
    ${SeatDimensions}
`;

const Arrow = styled.button`
    background: white;
    border: 0;
    border-radius: 100%;
    cursor: pointer;
    display: none;
    line-height: 2.5em;
    outline: none;
    padding: 0;
    position: absolute;
    transform: translate3d(0, -50%, 0);
    top: 50%;
    width: 2.5em;

    &:focus { outline: none; }

    ${props => props.right ? 'left: -1em;' : 'right: -1em'}
`;

const Scrollable = styled.div`
    overflow-x: auto;
    padding-bottom: 1em;
    position: relative;
    scroll-behavior: smooth;
    width: 100%;

    &.can-scroll-left { mask-image: linear-gradient(to left, transparent 0%, black 10%); }
    &.can-scroll-right { mask-image: linear-gradient(to left, black 90%, transparent); }
    &.can-scroll-left.can-scroll-right { mask-image: linear-gradient(to left, transparent 0%, black 10%, black 90%, transparent); }

    &.can-scroll-left + .arrows > ${Arrow}:last-child { display: block; }
    &.can-scroll-right + .arrows > ${Arrow}:first-child { display: block; }

    @media screen and (min-width: ${breakpoints.desktop}px) {
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar { display: none; }
    }
`;

const getSeatClasses = (seat, selected) => ['c-seats__seat'].concat(selected ?
    'is-selected'
    :
    getSeatClassesByStatus(seat.status)
).join(' ');

const getSeatClassesByStatus = status => {
  switch (status) {
    case 'E':
      return 'is-hide';
    case '1':
      return 'is-occupied';
    default:
      return '';
  }
};

const renderSeatTypesReferences = (configSeatTypes, sessionSeatTypes) => {
  const ids = Object.keys(configSeatTypes);

  return ids.map(key => {
    const props = configSeatTypes[key];
    key = key.split('_');
    const id = key[0];
    const status = key[1];

    return shouldShowSeatTypeReference(id, props, sessionSeatTypes) && renderSeatTypeReference(id, status, props)
  });
};

const shouldShowSeatTypeReference = (id, seatType, sessionSeatTypes) => {
  switch (seatType.show_reference) {
    case 'never': return false;
    case 'always': return true;
    case 'if-present': return sessionSeatTypes ? (sessionSeatTypes.indexOf(id) > -1) : false;
    default: return true;
  }
};

const renderSeatTypeReference = (id, status, props) => <span className="c-seats__seat"><SeatImg src={props.image}/> <small className="ml-2 text-secondary">{props.label}</small></span>;

class SeatsSelection extends React.Component {
  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
    this.renderSeat = this.renderSeat.bind(this);
    this.renderSeatImage = this.renderSeatImage.bind(this);
    this.onResize = this.onResize.bind(this);
    this.scrollRight = this.scrollRight.bind(this);
    this.scrollLeft = this.scrollLeft.bind(this);

    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.ref) {
      window.addEventListener('resize', this.onResize);
      this.ref.current.addEventListener('scroll', this.onResize);
      this.onResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    const s = this.ref.current;
    
    if (!s) return;

    const canScrollLeft = Math.ceil(s.clientWidth + s.scrollLeft) < s.scrollWidth;
    const canScrollRight = s.scrollLeft > 0;

    if (canScrollLeft) {
      s.classList.add('can-scroll-left');
    } else {
      s.classList.remove('can-scroll-left');
    }

    if (canScrollRight) {
      s.classList.add('can-scroll-right');
    } else {
      s.classList.remove('can-scroll-right');
    }
  }

  scrollRight() {
    this.ref.current.scrollLeft -= this.getScrollAmount();
  }

  scrollLeft() {
    this.ref.current.scrollLeft += this.getScrollAmount();
  }

  getScrollAmount() {
    return this.ref.current.clientWidth * 0.2;
  }

  render() {
    return (
        <React.Fragment>
          <StepHeader onPrevClick={this.props.prevStep} label="Selecciona tus asientos"/>

          <div className="c-seats c-seats--indicators mb-5">
            {renderSeatTypesReferences(this.props.seatTypesConfig, this.props.seatTypes)}
          </div>

          <div className="c-seats">
            <Auditorium className="c-seats__container" screenImage={this.props.screenImage !== null}>
              {this.props.screenImage
                ? <ScreenImageWrapper><ScreenImage src={this.props.screenImage.image_light}/></ScreenImageWrapper>
                : <ScreenLabel>Pantalla</ScreenLabel>
              }
              <div style={{zIndex:2}}>
                <Scrollable innerRef={this.ref}>
                  {this.props.layout && this.props.layout.map(this.renderRow)}
                </Scrollable>
                <div className="arrows">
                  <Arrow right onClick={this.scrollRight}><i className="fal fa-arrow-left"></i></Arrow>
                  <Arrow left onClick={this.scrollLeft}><i className="fal fa-arrow-right"></i></Arrow>
                </div>
              </div>
            </Auditorium>
          </div>

          {!this.props.seatsPreSelected && <SeatsSelectionCount total={this.props.seatsToSelect} selected={this.props.seats.length} />}
        </React.Fragment>
    );
  }

  renderRow(row) {
    return (
      <div className="c-seats__row mb-1" key={row.name}>
        <RowName left>{row.name}</RowName>
        {Object.values(row.seats).reverse().map(this.renderSeat)}
        <RowName right>{row.name}</RowName>
      </div>
    );
  }

  renderSeat(seat) {
    return (
      <span className={getSeatClasses(seat, this.props.seats.indexOf(seat.id) > -1)}
            key={seat.id} onClick={() => this.props.seatSelect(seat)}>
        {this.renderSeatImage(seat)}
      </span>
    );
  }

  renderSeatImage(seat) {
    const seatConfig = this.props.seatTypesConfig[this.getSeatTypeString(seat)];

    if (seatConfig && seatConfig.image) {
      return <SeatImg src={seatConfig.image} />;
    } else {
      return <SeatSpacer/>;
    }
  }

  getSeatTypeString(seat) {
    const parts = [seat.type];
    const isSelected = this.props.seats.indexOf(seat.id) > -1;

    if (isSelected) {
      parts.push('selected');
    } else {
      parts.push(seat.status);
    }

    return parts.join('_');
  }
}

const mapStateToProps = state => ({
  seatTypesConfig: getCheckoutSeatTypes(state),
  customPointer: state.checkoutData.data.custom_pointer,
  layout: getCheckoutSeatsLayout(state),
  seats: getCheckoutSelectedSeats(state),
  seatTypes: state.checkoutData.seatTypes,
  seatsPreSelected: areCheckoutSeatsPreSelected(state),
  seatsToSelect: getCheckoutSeatsToSelect(state),
  screenImage: state.checkoutData.data.screen_override || null,
});

const mapDispatchToProps = dispatch => ({
  seatSelect: seat => dispatch(seatSelect(seat)),
  prevStep: () => dispatch(prevStep()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SeatsSelection);