import React from 'react';
import {connect} from 'react-redux';
import MDSpinner from "react-md-spinner";
import {Helmet} from 'react-helmet';
import {promosFetch} from '../actions/creators/promos';
import {isPromosLoading, getPromosIe} from '../reducers';

let scrollTop;

class IEPromosPage extends React.Component {
  constructor(props) {
    super(props);

    this.onPromoSelected = this.onPromoSelected.bind(this);
    this.onPromoClose = this.onPromoClose.bind(this);

    this.state = {
      selectedPromo: null,
    };
  }

  onPromoSelected(e) {
    scrollTop = window.scrollY;
    window.scroll(0, 250);

    e.preventDefault();
    const selectedPromo = parseInt(e.target.getAttribute('data-id'), 10);

    this.setState(() => ({selectedPromo}));
  }

  onPromoClose(e) {
    e.preventDefault();
    this.setState(() => ({selectedPromo: null}));
    window.scroll(0, scrollTop);
  }

  componentWillMount() {
    this.props.fetch();
  }

  render() {
    return (
        <React.Fragment>
          <Helmet><title>Promociones - Cinemex Loop - Cinemex</title></Helmet>

          <p>
            Forma parte del mejor programa de recompensas que Premia tu Diversión, adquiere tu tarjeta y empieza a
            disfrutar de las exclusivas promociones.
          </p>

          <hr/>

          <div className="c-inline-modal">
            <div className="row">
              {this.renderPromos()}
            </div>
          </div>
        </React.Fragment>
    );
  }
  
  renderPromos() {
    if (this.props.isLoading) {
      return <div className="p-5 text-center"><MDSpinner singleColor="white"/></div>;
    }

    return this.props.promos.map(promo => (
        <div key={promo.id} className="col-12 col-md-6 col-lg-4 mb-gutter c-inline-modal__item">

          {promo.id === this.state.selectedPromo && (
              <div className="c-inline-modal__modal fade-in">

                <a onClick={this.onPromoClose} href="#" className="c-inline-modal__modal-close"><i className="fal fa-fw fa-times fa-lg"></i></a>

                <div className="row c-inline-modal__modal-content">
                  <div className="col-md-6 d-none d-md-block">
                    <img src={promo.image_v2} className="img-fluid rounded" alt=""/>
                  </div>
                  <div class="col-12 col-md-6" dangerouslySetInnerHTML={{__html: promo.content}}/>
                </div>
              </div>
          )}

          <div className="c-card">
            <a onClick={this.onPromoSelected} data-id={promo.id} href="#" className="c-card__link">
              <img className="c-card__image" src={promo.thumb_v2} alt={promo.title} />
            </a>
          </div>

        </div>
    ));
  }
}

const mapStateToProps = state => ({
  isLoading: isPromosLoading(state),
  promos: getPromosIe(state),
});

const mapDispatchToProps = dispatch => ({
  fetch: () => dispatch(promosFetch()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IEPromosPage);