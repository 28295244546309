import * as actions from '../actions/types';
import { getSelectedCinema } from '../reducers';

export const commonMiddleware = ({ getState, dispatch }) => next => async action => {
    // pre(action, getState, dispatch);
    next(action);
    post(action, getState, dispatch);
};

function post(action, getState, dispatch) {
    switch (action.type) {
        case actions.APP_LOADED:
        case actions.CHANGE_PREFERRED_CINEMA: return onAppLoaded(action, getState, dispatch);
    }
}

function onAppLoaded(action, getState, dispatch) {
    const state = getState();
    const cinema = getSelectedCinema(state);

    const isMarket = cinema && cinema.market === true;
    const isPlatinum = cinema && cinema.platinum === true;

    if (isMarket) {
        document.body.classList.add('theme-market');
        document.body.classList.remove('theme-platinum');
    } else if (isPlatinum) {
        document.body.classList.remove('theme-market');
        document.body.classList.add('theme-platinum');
    } else {
        document.body.classList.remove('theme-market');
        document.body.classList.remove('theme-platinum');
    }
}

export default commonMiddleware;