import React from 'react';
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet';
import MDSpinner from "react-md-spinner";
import BillboardCinemaBlock from './BillboardCinemaBlock';
import {billboardFetch} from '../../actions/creators/billboard';
import BillboardSidebarDates from './BillboardSidebarDates';
import BillboardStickyHeader from './BillboardStickyHeader';
import { getAbsoluteUrl, getAreaBillboardPermalink, getStateBillboardPermalink } from '../../utils/urls';
import BackButton from '../common/BackButton';
import NoContentMessage from '../common/NoContentMessage';
import PageTitle from '../common/PageTitle';

class Billboard extends React.Component {
  componentDidMount() {
    this.loadArea();
  }

  componentDidUpdate(prevProps) {
    if (this.props.areaId !== prevProps.areaId || this.props.match.params.date !== prevProps.match.params.date) {
      this.loadArea();
    }
  }

  loadArea() {
    window.scroll(0,0);

    let date = this.props.match.params.date;

    if (date) {
      date = date.split('-')[1];
      date = date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2);
    }

    this.props.fetch(this.props.areaType, this.props.areaId, date);
  }

  render() {
    return (
        <React.Fragment>
          <Helmet>
            <title>Cartelera de {this.props.area.name} - Cinemex'</title>
            <link rel="canonical" href={getAbsoluteUrl(this.props.areaType === 'state' ? getStateBillboardPermalink(this.props.area) : getAreaBillboardPermalink(this.props.area))} />
          </Helmet>
          <div className='container'>
            <div className="d-block d-md-none">
              <BillboardStickyHeader>
                <div className='container pb-3'>
                  <BackButton backTo="/" label={`Cartelera completa de ${this.props.area.name}`}/>
                </div>
                <BillboardSidebarDates dates={this.props.dates} selectedDate={this.props.date} baseUrl={this.getBaseUrl()} onDateSelect={this.props.onDateSelect}/>
              </BillboardStickyHeader>
            </div>
            <div className="d-none d-md-block mb-5">
              <PageTitle backTo='/' title={`Cartelera completa de ${this.props.area.name}`}/>
            </div>
          </div>
          {this.renderCinemasBlocks()}
        </React.Fragment>
    );
  }

  getBaseUrl() {
    return '';
  }

  renderCinemasBlocks() {
    const data = this.props.dataFiltered;

    if (this.props.isLoading) {
      return <div className="text-center my-5"><MDSpinner singleColor="var(--primary)"/></div>;
    }

    if (!data || !data.length) {
      return <NoContentMessage
        icon='fal fa-ticket'
        title="No hay películas disponibles con los filtros seleccionados."
        >
          <p>Puedes probar con otras opciones de filtrado.</p>
      </NoContentMessage>;
    }

    return data.map(cinema => <BillboardCinemaBlock key={cinema.id} cinema={cinema} movies={cinema.movies}/>);
  }
}

const mapDispatchToProps = dispatch => ({
  fetch: (areaType, areaId, date) => dispatch(billboardFetch(areaType, areaId, date)),
});

export default connect(
    null,
    mapDispatchToProps
)(Billboard);