import React from 'react';
import {Link} from 'react-router-dom';
import UserCinemaNav from './UserCinemaNav';
import SocialLinksButtons from './common/SocialLinksButtons';

class ContactPageSidebar extends React.Component {
  render() {
    return (
        <div className='container' id="contact-page-sidebar">
          <div className='d-none d-md-block'>
            <UserCinemaNav cinemaSelector={false}/>
          </div>
          <div className="py-5 border-bottom">
            <h3 className="h5">Tu opinión nos interesa mucho!</h3>
            <p>Tu opinión es muy importante para nosotros, envíanos tus opiniones y
              comentarios acerca de nuestro servicio para poder mejorar cada día.</p>
          </div>

          <div className="py-5 border-bottom">
            <h3 className="h6 mb-4">Síguenos en nuestras redes sociales:</h3>
            <SocialLinksButtons theme='secondary' />
          </div>

          <div className="py-5">
            <p><small>Cinemex Desarrollos, S.A. de C.V. (en adelante "Cinemex"), con domicilio en Avenida Javier Barros Sierra No. 540, Torre 1, PH1, Colonia Santa Fe, Delegación Álvaro Obregón, C.P. 01210, México, Ciudad de México, te comunica lo siguiente:</small></p>
            <p><small>Los Datos Personales que le son solicitados, serán tratados con las finalidades primarias de atender y darle seguimiento a sus dudas, quejas, comentarios o sugerencias.</small></p>
            <p><small><Link to="/privacidadvisitantes">Aviso de privacidad</Link><br /> Fecha de última actualización: 5/Enero/2015</small></p>
          </div>
        </div>
    );
  }
}

export default ContactPageSidebar;