import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {getPaymentString} from '../../utils/checkout';
import {parseSessionIsoDateForDisplay} from '../../utils/dates';
import {centsToCurrencyString} from '../../utils/numbers';
import {ucFirst} from '../../utils/strings';
import { AnalyticsCheckout } from '../../utils/analytics';
import { breakpoints } from '../../utils/styles';
import Button from '../Button';
import logo from '../../assets/svg/logo-cinemex.svg';
import "./Confirmation.css";

const Logo = styled.img`
  display: none;
  height: auto;
  margin: 0 auto 1rem;
  width: 65%;
  
  @media screen and (min-width: ${breakpoints.desktop}px) {
    display: block;
  }
`;

const MovieTitle = styled.span`
  color: var(--primary);

  body.theme-market & {
    display: block;
    font-size: ${(36/12.6).toFixed(2)}rem;
    font-weight: 900;
  }

  body.theme-platinum & {
    color: var(--primary);
    display: block;
    font-size: ${(36/12.6).toFixed(2)}rem;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 0.1em;
    
    @media screen and (min-width: ${breakpoints.desktop}px) {
      color: var(--primary-b);
    }
  }
`;

const Label = styled.small`
  display: block;
  font-weight: 700;
  text-transform: uppercase;
  
  body.theme-platinum & {
    color: var(--primary);
  }
`;

const BookingCode = styled.span`
  display: block;
  font-family: var(--ff-subtitle);
  font-size: 2rem;
  line-height: 1;
  margin-top: 0.5em;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    font-size: 1.5rem;
  }
`;

const QrImg = styled.img`
  body.theme-platinum & { mix-blend-mode: multiply; }
`;

const PaymentMethodRow = styled.div`
  font-size: 0.8em;
  line-height: 1.75em;
`;

const PaymentMethodLogo = styled.img`
  vertical-align: middle;
  margin: -2px 0.5em 0;
`;

const PayPalLogo = styled(PaymentMethodLogo)`
  max-width: 70px;
`;

const PromoContanier = styled.div`
  display: none;
  padding-right: 3rem;
  position: absolute;
  right: -16rem;
  top: 0;
  
  & > img {
    height: 25rem;
    border-radius: 1.5rem;
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    display: block;
  }
`;

const TicketLastColumn = styled.div`
  &:before {
    ${props => (props.dynamicStyles && ('bg_color' in props.dynamicStyles)) ? `border-color: ${props.dynamicStyles.bg_color[0]};` : ''}}
  }

  body.theme-platinum & {
    background-color: var(--text-primary);
    color: #000000;
  }
`;

const Messages = styled.div`
  color: var(--primary);
  font-size: 0.9em;
  line-height: 1.35;
`;

const PosterContainer = styled.div`
  background-position: center;

  & > img { display: none !important; }
`

const ExtraButtons = styled.div`
  margin-bottom: 2rem;
  text-align: center;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    text-align: left;
  }
`;

const BottomText = styled.p`
  color: #fff;
  font-size: 0.75em;
  padding: 0;

  & > a {
    color: #fff;
    text-decoration: underline;
  }

  body.theme-platinum &,
  body.theme-platinum & > a {
    color: var(--text-primary);
  }
`

const BackLink = styled.span`
  color: #fff;

  body.theme-platinum & { color: var(--text-primary); }
`;

class Confirmation extends React.PureComponent {
  componentDidMount() {
    AnalyticsCheckout.trackTransaction(this.props.data);
  }

  render() {
    const {data} = this.props;

    if (!data) {
      return '';
    }

    const date = parseSessionIsoDateForDisplay(data.session.timestamp);
    const paymentMethodsString = getPaymentString(data.payment.methods);
    const seatsString = data.seats ? data.seats.join('-') : '';
    const bookingPdfUrl = data.qr.replace('bookingQr', 'bookingPdf').replace(/\.(jpg|png)$/, '.pdf');

    return (
        <div className="c-ticket-wrap py-5 d-flex align-items-md-center slide-in-from-left" style={this.getContainerStyles()}>
          <div className="container">
            <div className="row">

              <div className="col-12 col-xl-10">

                <h1 className="h2 mb-5">
                  <Link to="/">
                    <BackLink>
                      <i className="fal fa-arrow-left fa-xs pr-3"></i>
                      Volver a la Cartelera
                    </BackLink>
                  </Link>
                </h1>

                <div style={{position:'relative'}}>
                  <div className="c-ticket">
                    <PosterContainer className="c-ticket__image" style={{backgroundImage:'url(' + data.movie.cover + ')'}}>
                      <img src={data.movie.cover} alt=""/>
                    </PosterContainer>
                    <div className="c-ticket__primary">
                      <div className="row">
                        <div className="col-12">
                          <p className="lead">
                            <Label style={{fontWeight:400}}>{data.cinema.name}</Label>
                            <MovieTitle className='title-1'>{data.movie.name}</MovieTitle>
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <p>
                            <Label>Fecha</Label>
                            {ucFirst(date.format('dddd DD [de] MMMM'))}
                          </p>
                        </div>
                        <div className="col-6">
                          <p>
                            <Label>Hora</Label>
                            {date.format('hh:mm A')}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        {seatsString && (
                          <div className="col-6">
                            <p>
                              <Label>Asientos</Label>
                              {seatsString}
                            </p>
                          </div>
                        )}
                        <div className="col-6">
                          <p>
                            <Label>Sala</Label>
                            {data.auditorium_number}
                          </p>
                        </div>
                      </div>
                      {data.candybar && (
                        <div className="row">
                          <div className="col-12">
                            <p className="text-truncate">
                              <Label>Dulcería</Label>
                              1 Super burguer + Bacón, 2 Burguer Extreme lorem ipsum dolor sit amet
                            </p>
                          </div>
                        </div>
                      )}
                      <div className="row">
                        <div className="col-6">
                          <p>
                            <Label>Tus boletos</Label>
                            {centsToCurrencyString(data.payment.details.tickets)}
                          </p>
                        </div>
                        <div className="col-6">
                          <p>
                            <Label>Costo por servicio</Label>
                            {centsToCurrencyString(data.payment.details.fees.booking)}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <p>
                            <Label>Total pagado</Label>
                            {paymentMethodsString}
                          </p>
                        </div>
                      </div>
                      {('extra' in data.payment) && data.payment.extra.paypal && (
                          <PaymentMethodRow className="row">
                            <div className="col-12">
                              <p>
                                <small className="d-block">
                                  Pagado con
                                  <PayPalLogo src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png" alt="PayPal Logo"/>
                                </small>
                                <small className="d-block">
                                  Transacción {data.payment.extra.paypal}
                                </small>
                              </p>
                            </div>
                          </PaymentMethodRow>
                      )}
                      {('extra' in data.payment) && data.payment.extra.masterpass && (
                          <PaymentMethodRow className="row">
                            <div className="col-12">
                              <p>
                                <small className="d-block">
                                  Pagado con
                                  <PaymentMethodLogo src="https://static.masterpass.com/dyn/img/acc/global/mp_mark_hor_blk.svg" alt="Masterpass Logo"/>
                                </small>
                              </p>
                            </div>
                          </PaymentMethodRow>
                      )}
                      {data.messages && data.messages.length ? (
                        <div className="row">
                          <Messages className="col-12">
                            {data.messages.map(m => <React.Fragment>{m}<br/></React.Fragment>)}
                          </Messages>
                        </div>
                      ) : ''}
                    </div>
                    <TicketLastColumn className="c-ticket__secondary" dynamicStyles={this.props.data.styles}>
                      <Logo src={logo}/>
                      <p className="mb-4">
                        <span className="d-block">Código de compra</span>
                        <BookingCode>{data.confirmation_code}</BookingCode>
                      </p>
                      <QrImg className="img-fluid" src={data.qr} alt="" style={{width:'150px'}}/>
                      <Button href={bookingPdfUrl} primary block className="mt-4" target="_blank" rel="noopener noreferrer">Descargar</Button>
                    </TicketLastColumn>
                  </div>

                  {this.renderPromo()}
                </div>

                {data.extra_buttons && data.extra_buttons.length ? (
                <ExtraButtons>
                  {data.extra_buttons.map(b => <Button href={b.url} transparent small className="mr-3" target='_blank'>{b.label}</Button>)}
                </ExtraButtons>
                ) : ''}

                <BottomText>
                  Hemos enviado la confirmación de compra y tus boletos a la dirección de correo {data.confirmation_email}.
                  Si no la recibiste, te recomendamos descargarla haciendo <a href={bookingPdfUrl} target="_blank" rel="noopener noreferrer">clic aquí</a> o
                  en el botón "Descargar" para que puedas ingresar el cine sin hacer filas.
                </BottomText>

              </div>

            </div>
          </div>

        </div>
    );
  }

  getContainerStyles() {
    if (!this.props.data.styles || !this.props.data.styles.bg_color) return {};

    return {
      background: `linear-gradient(to right, ${this.props.data.styles.bg_color[0]}, ${this.props.data.styles.bg_color[1]})`
    };
  }

  renderPromo() {
    if (!this.props.data.side_banner) return null;

    return <PromoContanier>
      <img src={this.props.data.side_banner} alt='' />
    </PromoContanier>;
  }
}

export default Confirmation;