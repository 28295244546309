import { dialogAlert } from "../actions/creators/dialogs";
import * as actions from '../actions/types';

export function onApiError(dispatch, action) {
    dispatch(getRequestPinActionIfNeeded(action) || dialogAlert(action.error.message || 'Ocurrió un error. Por favor, inténtalo nuevamente.'));
}

function getRequestPinActionIfNeeded({error, context, payload, originalAction}) {
    if (!error || !error.response || error.response.errorcode !== 'auth-pin-required') return null;

    return {
        type: actions.AUTH_LOGIN_REQUEST_PIN_SHOW,
        error,
        context,
        payload,
        originalAction
    };
}