export const APP_LOADED = 'APP_LOADED';

export const MOVIES_FETCH = 'MOVIES_FETCH';
export const MOVIES_FETCH_SUCCESS = 'MOVIES_FETCH_SUCCESS';
export const MOVIES_FETCH_ERROR = 'MOVIES_FETCH_ERROR';
export const MOVIES_UPCOMING_FETCH = 'MOVIES_UPCOMING_FETCH';
export const MOVIES_UPCOMING_FETCH_SUCCESS = 'MOVIES_UPCOMING_FETCH_SUCCESS';
export const MOVIES_UPCOMING_FETCH_ERROR = 'MOVIES_UPCOMING_FETCH_ERROR';
export const MOVIE_UPCOMING_FETCH = 'MOVIE_UPCOMING_FETCH';
export const MOVIE_UPCOMING_FETCH_SUCCESS = 'MOVIE_UPCOMING_FETCH_SUCCESS';
export const MOVIE_UPCOMING_FETCH_ERROR = 'MOVIE_UPCOMING_FETCH_ERROR';

export const CHANGE_PREFERRED_CINEMA = 'CHANGE_PREFERRED_CINEMA';
export const MOVIE_SELECT = 'MOVIE_SELECT';
export const MOVIE_UPCOMING_SELECT = 'MOVIE_UPCOMING_SELECT';
export const MOVIE_TAB_HIDE_TOGGLE = 'MOVIE_TAB_HIDE_TOGGLE';
export const MOVIE_TAB_OPEN = 'MOVIE_TAB_OPEN';
export const MOVIE_TAB_CLOSE = 'MOVIE_TAB_CLOSE';

export const CINEMAS_FETCH = 'CINEMAS_FETCH';
export const CINEMAS_FETCH_SUCCESS = 'CINEMAS_FETCH_SUCCESS';
export const CINEMAS_FETCH_ERROR = 'CINEMAS_FETCH_ERROR';
export const CINEMA_MOVIE_FETCH_SUCCESS = 'CINEMA_MOVIE_FETCH_SUCCESS';
export const CINEMA_MOVIE_FETCH_ERROR = 'CINEMA_MOVIE_FETCH_ERROR';
export const CINEMA_MOVIE_IS_LOADING = 'CINEMA_MOVIE_IS_LOADING';
export const CINEMA_MOVIES_FETCH = 'CINEMA_MOVIES_FETCH';
export const CINEMA_MOVIES_FETCH_SUCCESS = 'CINEMA_MOVIES_FETCH_SUCCESS';
export const CINEMA_MOVIES_FETCH_ERROR = 'CINEMA_MOVIES_FETCH_ERROR';
export const AREA_MOVIES_FETCH = 'AREA_MOVIES_FETCH';
export const AREA_MOVIES_FETCH_SUCCESS = 'AREA_MOVIES_FETCH_SUCCESS';
export const AREA_MOVIES_FETCH_ERROR = 'AREA_MOVIES_FETCH_ERROR';

export const SHOWTIMES_FILTER_DATE_CHANGE = 'SHOWTIMES_FILTER_DATE_CHANGE';
export const SHOWTIMES_FILTER_FORMAT_CHANGE = 'SHOWTIMES_FILTER_FORMAT_CHANGE';
export const SHOWTIMES_FILTER_FORMAT_CLEAR = 'SHOWTIMES_FILTER_FORMAT_CLEAR';

export const CHECKOUT_TIMER_START = 'CHECKOUT_TIMER_START';
export const CHECKOUT_TIMER_STOP = 'CHECKOUT_TIMER_STOP';
export const CHECKOUT_TIMER_TICK = 'CHECKOUT_TIMER_TICK';
export const CHECKOUT_TIMER_TIMEOUT = 'CHECKOUT_TIMER_TIMEOUT';

export const CHECKOUT_INIT = 'CHECKOUT_INIT';
export const CHECKOUT_OPEN_PAY_INIT = 'CHECKOUT_OPEN_PAY_INIT';
export const CHECKOUT_SESSION_FETCH_SUCCESS = 'CHECKOUT_SESSION_FETCH_SUCCESS';
export const CHECKOUT_SESSION_FETCH_ERROR = 'CHECKOUT_SESSION_FETCH_ERROR';
export const CHECKOUT_SESSION_IS_LOADING = 'CHECKOUT_SESSION_IS_LOADING';
export const CHECKOUT_MOVIE_FETCH_SUCCESS = 'CHECKOUT_MOVIE_FETCH_SUCCESS';
export const CHECKOUT_CINEMA_FETCH_SUCCESS = 'CHECKOUT_CINEMA_FETCH_SUCCESS';
export const CHECKOUT_TICKET_QTY_INCREASE = 'CHECKOUT_TICKET_QTY_INCREASE';
export const CHECKOUT_TICKET_QTY_DECREASE = 'CHECKOUT_TICKET_QTY_DECREASE';
export const CHECKOUT_TICKET_SELECTION_SUBMIT = 'CHECKOUT_TICKET_SELECTION_SUBMIT';
export const CHECKOUT_TICKET_SELECTION_PROCESSING = 'CHECKOUT_TICKET_SELECTION_PROCESSING';
export const CHECKOUT_TICKET_SELECTION_SUCCESS = 'CHECKOUT_TICKET_SELECTION_SUCCESS';
export const CHECKOUT_TICKET_SELECTION_FAILURE = 'CHECKOUT_TICKET_SELECTION_FAILURE';
export const CHECKOUT_PROMO_CODE_SHOW = 'CHECKOUT_PROMO_CODE_SHOW';
export const CHECKOUT_PROMO_CODE_CANCEL = 'CHECKOUT_PROMO_CODE_CANCEL';
export const CHECKOUT_PROMO_CODE_CHANGE = 'CHECKOUT_PROMO_CODE_CHANGE';
export const CHECKOUT_PROMO_CODE_SUBMIT = 'CHECKOUT_PROMO_CODE_SUBMIT';
export const CHECKOUT_PROMO_CODE_SUBMIT_SUCCESS = 'CHECKOUT_PROMO_CODE_SUBMIT_SUCCESS';
export const CHECKOUT_PROMO_CODE_SUBMIT_FAILURE = 'CHECKOUT_PROMO_CODE_SUBMIT_FAILURE';
export const CHECKOUT_NEXT_STEP = 'CHECKOUT_NEXT_STEP';
export const CHECKOUT_PREV_STEP = 'CHECKOUT_PREV_STEP';
export const CHECKOUT_LEAVE_STEP = 'CHECKOUT_LEAVE_STEP';
export const CHECKOUT_SEAT_SELECT = 'CHECKOUT_SEAT_SELECT';
export const CHECKOUT_SEATS_SELECTION_PROCESSING = 'CHECKOUT_SEATS_SELECTION_PROCESSING';
export const CHECKOUT_SEATS_SELECTION_SUCCESS = 'CHECKOUT_SEATS_SELECTION_SUCCESS';
export const CHECKOUT_SEATS_SELECTION_FAILURE = 'CHECKOUT_SEATS_SELECTION_FAILURE';
export const CHECKOUT_CANDYBAR_IS_LOADING = 'CHECKOUT_CANDYBAR_IS_LOADING';
export const CHECKOUT_CANDYBAR_FETCH_ERROR = 'CHECKOUT_CANDYBAR_FETCH_ERROR';
export const CHECKOUT_CANDYBAR_FETCH_SUCCESS = 'CHECKOUT_CANDYBAR_FETCH_SUCCESS';
export const CHECKOUT_CANDYBAR_CATEGORY_SELECT = 'CHECKOUT_CANDYBAR_CATEGORY_SELECT';
export const CHECKOUT_CANDYBAR_ITEM_BACKUP = 'CHECKOUT_CANDYBAR_ITEM_BACKUP';
export const CHECKOUT_CANDYBAR_ITEM_ADD = 'CHECKOUT_CANDYBAR_ITEM_ADD';
export const CHECKOUT_CANDYBAR_ITEM_REMOVE = 'CHECKOUT_CANDYBAR_ITEM_REMOVE';
export const CHECKOUT_CANDYBAR_ITEM_SELECT = 'CHECKOUT_CANDYBAR_ITEM_SELECT';
export const CHECKOUT_CANDYBAR_ITEM_EDIT_CANCEL = 'CHECKOUT_CANDYBAR_ITEM_EDIT_CANCEL';
export const CHECKOUT_CANDYBAR_UNIT_SELECT = 'CHECKOUT_CANDYBAR_UNIT_SELECT';
export const CHECKOUT_CANDYBAR_OPTION_SELECT = 'CHECKOUT_CANDYBAR_OPTION_SELECT';
export const CHECKOUT_CANDYBAR_UNIT_COMMENT_UPDATE = 'CHECKOUT_CANDYBAR_UNIT_COMMENT_UPDATE';
export const CHECKOUT_PAYMENT_SUGGESTIONS_IS_LOADING = 'CHECKOUT_PAYMENT_SUGGESTIONS_IS_LOADING';
export const CHECKOUT_PAYMENT_SUGGESTIONS_FETCH_ERROR = 'CHECKOUT_PAYMENT_SUGGESTIONS_FETCH_ERROR';
export const CHECKOUT_PAYMENT_SUGGESTIONS_FETCH_SUCCESS = 'CHECKOUT_PAYMENT_SUGGESTIONS_FETCH_SUCCESS';
export const CHECKOUT_PAYMENT_SUGGESTIONS_SELECT = 'CHECKOUT_PAYMENT_SUGGESTIONS_SELECT';
export const CHECKOUT_PAYMENT_METHOD_SELECT_BEFORE = 'CHECKOUT_PAYMENT_METHOD_SELECT_BEFORE';
export const CHECKOUT_PAYMENT_METHOD_SELECT = 'CHECKOUT_PAYMENT_METHOD_SELECT';
export const CHECKOUT_PAYMENT_METHOD_CANCEL = 'CHECKOUT_PAYMENT_METHOD_CANCEL';
export const CHECKOUT_PAYMENT_FORM_SUBMIT = 'CHECKOUT_PAYMENT_FORM_SUBMIT';
export const CHECKOUT_PAYMENT_IS_PROCESSING = 'CHECKOUT_PAYMENT_IS_PROCESSING';
export const CHECKOUT_PAYMENT_ERROR = 'CHECKOUT_PAYMENT_ERROR';
export const CHECKOUT_SMART_PAYMENT_ERROR = 'CHECKOUT_SMART_PAYMENT_ERROR';
export const CHECKOUT_PAYMENT_SUCCESS = 'CHECKOUT_PAYMENT_SUCCESS';
export const CHECKOUT_ABANDON = 'CHECKOUT_ABANDON';
export const CHECKOUT_NIP_REQUEST_CANCEL = 'CHECKOUT_NIP_REQUEST_CANCEL';
export const CHECKOUT_NIP_REQUEST_SUBMIT = 'CHECKOUT_NIP_REQUEST_SUBMIT';
export const CHECKOUT_NIP_REQUEST_SUCCESS = 'CHECKOUT_NIP_REQUEST_SUCCESS';
export const CHECKOUT_NIP_REQUEST_ERROR = 'CHECKOUT_NIP_REQUEST_ERROR';
export const CHECKOUT_SET_TERMS_CHECK = 'CHECKOUT_SET_TERMS_CHECK';

export const GET_SMART_URL = 'GET_SMART_URL';
export const GET_SMART_URL_SUCCESS = 'GET_SMART_URL_SUCCESS';
export const GET_SMART_URL_ERROR = 'GET_SMART_URL_ERROR';

export const GET_STRIPE_URL = 'GET_STRIPE_URL';
export const GET_STRIPE_URL_SUCCESS = 'GET_STRIPE_URL_SUCCESS';
export const GET_STRIPE_URL_ERROR = 'GET_STRIPE_URL_ERROR';

export const CHECKOUT_STRIPE_OPEN = 'CHECKOUT_STRIPE_OPEN';
export const CHECKOUT_STRIPE_ERROR = 'CHECKOUT_STRIPE_ERROR';
export const CHECKOUT_STRIPE_SUCCESS = 'CHECKOUT_STRIPE_SUCCESS';

export const CHECKOUT_MASTERPASS_OPEN = 'CHECKOUT_MASTERPASS_OPEN';
export const CHECKOUT_MASTERPASS_ERROR = 'CHECKOUT_MASTERPASS_ERROR';
export const CHECKOUT_MASTERPASS_SUCCESS = 'CHECKOUT_MASTERPASS_SUCCESS';

export const CHECKOUT_SMART_OPEN = 'CHECKOUT_SMART_OPEN';
export const CHECKOUT_SMART_ERROR = 'CHECKOUT_SMART_ERROR';
export const CHECKOUT_SMART_SUCCESS = 'CHECKOUT_SMART_SUCCESS';

export const IE_CODE_PANEL_SHOW = 'IE_CODE_PANEL_SHOW';
export const IE_CODE_BEGIN = 'IE_CODE_BEGIN';
export const IE_CODE_CANCEL = 'IE_CODE_CANCEL';
export const IE_CODE_CHANGE = 'IE_CODE_CHANGE';
export const IE_BARCODE_CHANGE = 'IE_BARCODE_CHANGE';
export const IE_BIRTH_DATE_CHANGE = 'IE_BIRTH_DATE_CHANGE';
export const IE_CODE_VALIDATE_SET = 'IE_CODE_VALIDATE_SET';
export const IE_CODE_VALIDATE_SUBMIT = 'IE_CODE_VALIDATE_SUBMIT';
export const IE_CODE_VALIDATE_SUCCESS = 'IE_CODE_VALIDATE_SUCCESS';
export const IE_CODE_VALIDATE_FAILURE = 'IE_CODE_VALIDATE_FAILURE';
export const IE_CODE_SUBMIT= 'IE_CODE_SUBMIT';
export const IE_CODE_SUBMIT_SUCCESS = 'IE_CODE_SUBMIT_SUCCESS';
export const IE_CODE_SUBMIT_FAILURE = 'IE_CODE_SUBMIT_FAILURE';
export const IE_CODE_PANEL_PREV_STEP = 'IE_CODE_PANEL_PREV_STEP';
export const IE_CODE_DELETE = 'IE_CODE_DELETE';
export const IE_CODE_DELETE_SUCCESS = 'IE_CODE_DELETE_SUCCESS';
export const IE_CODE_DELETE_FAILURE = 'IE_CODE_DELETE_FAILURE';
export const IE_NIP_UPDATE_SUBMIT = 'IE_NIP_UPDATE_SUBMIT';
export const IE_NIP_UPDATE_SUCCESS = 'IE_NIP_UPDATE_SUCCESS';
export const IE_NIP_UPDATE_FAILURE = 'IE_NIP_UPDATE_FAILURE';

export const AUTH_OPEN = 'AUTH_OPEN';
export const AUTH_CLOSE = 'AUTH_CLOSE';
export const AUTH_SHOW_LOGIN = 'AUTH_SHOW_LOGIN';
export const AUTH_SHOW_RECOVER_PASS = 'AUTH_SHOW_RECOVER_PASS';
export const AUTH_SHOW_RESET_PASS = 'AUTH_SHOW_RESET_PASS';
export const AUTH_SHOW_REGISTER = 'AUTH_SHOW_REGISTER';
export const AUTH_SHOW_REGISTER_MANUAL = 'AUTH_SHOW_REGISTER_MANUAL';
export const AUTH_SHOW_REGISTER_APPLE = 'AUTH_SHOW_REGISTER_APPLE';
export const AUTH_SIGN_UP_CHECK_EMAIL_SUBMIT = 'AUTH_SIGN_UP_CHECK_EMAIL_SUBMIT';
export const AUTH_SIGN_UP_CHECK_EMAIL_SUCCESS = 'AUTH_SIGN_UP_CHECK_EMAIL_SUCCESS';
export const AUTH_SIGN_UP_CHECK_EMAIL_ERROR = 'AUTH_SIGN_UP_CHECK_EMAIL_ERROR';
export const AUTH_LOGIN_POST = 'AUTH_LOGIN_POST';
export const AUTH_LOGIN_POST_SUCCESS = 'AUTH_LOGIN_POST_SUCCESS';
export const AUTH_LOGIN_POST_ERROR = 'AUTH_LOGIN_POST_ERROR';
export const AUTH_LOGIN_PIN_POST = 'AUTH_LOGIN_PIN_POST';
export const AUTH_PIN_SUBMIT = 'AUTH_PIN_SUBMIT';
export const AUTH_LOGIN_REQUEST_PIN_SHOW = 'AUTH_LOGIN_REQUEST_PIN_SHOW';
export const AUTH_LOGIN_REQUEST_PIN_CLOSE = 'AUTH_LOGIN_REQUEST_PIN_CLOSE';
export const AUTH_RECOVER_PASS_POST = 'AUTH_RECOVER_PASS_POST';
export const AUTH_RECOVER_PASS_SUCCESS = 'AUTH_RECOVER_PASS_SUCCESS';
export const AUTH_RECOVER_PASS_ERROR = 'AUTH_RECOVER_PASS_ERROR';
export const AUTH_RESET_PASS_POST = 'AUTH_RESET_PASS_POST';
export const AUTH_RESET_PASS_SUCCESS = 'AUTH_RESET_PASS_SUCCESS';
export const AUTH_RESET_PASS_ERROR = 'AUTH_RESET_PASS_ERROR';
export const AUTH_FB_LOGIN = 'AUTH_FB_LOGIN';
export const AUTH_FB_LOGIN_SUCCESS = 'AUTH_FB_LOGIN_SUCCESS';
export const AUTH_FB_LOGIN_ERROR = 'AUTH_FB_LOGIN_ERROR';
export const AUTH_REGISTER_POST = 'AUTH_REGISTER_POST';
export const AUTH_REGISTER_POST_SUCCESS = 'AUTH_REGISTER_POST_SUCCESS';
export const AUTH_REGISTER_POST_ERROR = 'AUTH_REGISTER_POST_ERROR';
export const AUTH_SESSION_EXPIRED = 'AUTH_SESSION_EXPIRED';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_SET_REF = 'AUTH_SET_REF';
export const POST_PURCHASE_IE_POPUP_SHOW = 'POST_PURCHASE_IE_POPUP_SHOW';
export const POST_REGISTER_POPUP_CLOSE = 'POST_REGISTER_POPUP_CLOSE';
export const AUTH_RENEW_TOKEN = 'AUTH_RENEW_TOKEN';

export const DIALOG_DISPLAY = 'DIALOG_DISPLAY';
export const DIALOG_CLOSE = 'DIALOG_CLOSE';

export const UI_CINEMA_SELECTOR_OPEN = 'UI_CINEMA_SELECTOR_OPEN';
export const UI_CINEMA_SELECTOR_CLOSE = 'UI_CINEMA_SELECTOR_CLOSE';
export const UI_AREA_SELECTOR_OPEN = 'UI_AREA_SELECTOR_OPEN';
export const UI_AREA_SELECTOR_CLOSE = 'UI_AREA_SELECTOR_CLOSE';
export const UI_AREA_SELECTOR_SELECT_AREA = 'UI_AREA_SELECTOR_SELECT_AREA';
export const UI_CUSTOM_LOADING_FETCH_SUCCESS = 'UI_CUSTOM_LOADING_FETCH_SUCCESS';
export const UI_LANDING_SELECT_AREA = 'UI_LANDING_SELECT_AREA';

export const USER_WALLET_FETCH_SUCCESS = 'USER_WALLET_FETCH_SUCCESS';
export const USER_WALLET_FETCH_ERROR = 'USER_WALLET_FETCH_ERROR';
export const CHECKOUT_WALLET_SELECT_CARD = 'CHECKOUT_WALLET_SELECT_CARD';
export const WALLET_CARD_ADD = 'WALLET_CARD_ADD';
export const WALLET_CARD_ADD_SUCCESS = 'WALLET_CARD_ADD_SUCCESS';
export const WALLET_CARD_ADD_ERROR = 'WALLET_CARD_ADD_ERROR';
export const WALLET_CARD_ADD_CHANGE = 'WALLET_CARD_ADD_CHANGE';
export const WALLET_CARD_DELETE = 'WALLET_CARD_DELETE';
export const WALLET_CARD_DELETE_SUCCESS = 'WALLET_CARD_DELETE_SUCCESS';
export const WALLET_CARD_DELETE_ERROR = 'WALLET_CARD_DELETE_ERROR';
export const WALLET_SET_STEP = 'WALLET_SET_STEP';
export const WALLET_SHOW = 'WALLET_SHOW';

export const USER_PRE_CHECKOUT_FETCH = 'USER_PRE_CHECKOUT_FETCH';
export const USER_PRE_CHECKOUT_FETCH_SUCCESS = 'USER_PRE_CHECKOUT_FETCH_SUCCESS';

export const USER_POINTS_FETCH_SUCCESS = 'USER_POINTS_FETCH_SUCCESS';
export const USER_POINTS_FETCH_ERROR = 'USER_POINTS_FETCH_ERROR';
export const USER_TICKETS_FETCH_SUCCESS = 'USER_TICKETS_FETCH_SUCCESS';
export const USER_PURCHASE_HISTORY_FETCH = 'USER_PURCHASE_HISTORY_FETCH';
export const USER_PURCHASE_HISTORY_FETCH_SUCCESS = 'USER_PURCHASE_HISTORY_FETCH_SUCCESS';
export const USER_PURCHASE_HISTORY_FETCH_ERROR = 'USER_PURCHASE_HISTORY_FETCH_ERROR';
export const USER_SESSIONS_FETCH = 'USER_SESSIONS_FETCH';
export const USER_SESSIONS_FETCH_SUCCESS = 'USER_SESSIONS_FETCH_SUCCESS';
export const USER_SESSIONS_FETCH_ERROR = 'USER_SESSIONS_FETCH_ERROR';
export const USER_SESSION_KILL = 'USER_SESSION_KILL';
export const USER_SESSION_KILL_SUCCESS = 'USER_SESSION_KILL_SUCCESS';
export const USER_SESSION_KILL_ERROR = 'USER_SESSION_KILL_ERROR';
export const USER_INFO_FETCH = 'USER_INFO_FETCH';
export const USER_INFO_FETCH_SUCCESS = 'USER_INFO_FETCH_SUCCESS';
export const USER_INFO_FETCH_ERROR = 'USER_INFO_FETCH_ERROR';
export const USER_UPDATE_INFO_PUT = 'USER_UPDATE_INFO_PUT';
export const USER_UPDATE_INFO_SUCCESS = 'USER_UPDATE_INFO_SUCCESS';
export const USER_UPDATE_INFO_ERROR = 'USER_UPDATE_INFO_ERROR';
export const USER_AVATAR_UPLOAD = 'USER_AVATAR_UPLOAD';
export const USER_AVATAR_UPLOAD_SUCCESS = 'USER_AVATAR_UPLOAD_SUCCESS';
export const USER_AVATAR_UPLOAD_ERROR = 'USER_AVATAR_UPLOAD_ERROR';
export const USER_DELETE_ACCOUNT = 'USER_DELETE_ACCOUNT';
export const USER_DELETE_ACCOUNT_SUCCESS = 'USER_DELETE_ACCOUNT_SUCCESS';
export const USER_DELETE_ACCOUNT_ERROR = 'USER_DELETE_ACCOUNT_ERROR';
export const UI_USER_PROFILE_EDIT = 'UI_USER_PROFILE_EDIT';
export const USER_IE_CARD_FETCH = 'USER_IE_CARD_FETCH';
export const USER_IE_CARD_FETCH_SUCCESS = 'USER_IE_CARD_FETCH_SUCCESS';
export const USER_IE_CARD_FETCH_ERROR = 'USER_IE_CARD_FETCH_ERROR';

export const USER_COUPONS_FETCH = 'USER_COUPONS_FETCH';
export const USER_COUPONS_FETCH_SUCCESS = 'USER_COUPONS_FETCH_SUCCESS';
export const USER_COUPONS_FETCH_ERROR = 'USER_COUPONS_FETCH_ERROR';
export const USER_COUPONS_ACTIVATE = 'USER_COUPONS_ACTIVATE';
export const USER_COUPONS_ACTIVATE_SUCCESS = 'USER_COUPONS_ACTIVATE_SUCCESS';
export const USER_COUPONS_ACTIVATE_ERROR = 'USER_COUPONS_ACTIVATE_ERROR';

export const PROMOS_FETCH = 'PROMOS_FETCH';
export const PROMOS_FETCH_SUCCESS = 'PROMOS_FETCH_SUCCESS';
export const PROMOS_FETCH_ERROR = 'PROMOS_FETCH_ERROR';

export const BILLBOARD_AREA_FETCH = 'BILLBOARD_AREA_FETCH';
export const BILLBOARD_AREA_FETCH_SUCCESS = 'BILLBOARD_AREA_FETCH_SUCCESS';
export const BILLBOARD_AREA_FETCH_ERROR = 'BILLBOARD_AREA_FETCH_ERROR';

export const BILLBOARD_STATE_FETCH = 'BILLBOARD_STATE_FETCH';
export const BILLBOARD_STATE_FETCH_SUCCESS = 'BILLBOARD_STATE_FETCH_SUCCESS';
export const BILLBOARD_STATE_FETCH_ERROR = 'BILLBOARD_STATE_FETCH_ERROR';

export const HEADER_SEARCH_TERM_CHANGE = 'HEADER_SEARCH_TERM_CHANGE';

export const CONTACT_FORM_SHOW = 'CONTACT_FORM_SHOW';
export const CONTACT_FORM_SUBMIT = 'CONTACT_FORM_SUBMIT';
export const CONTACT_FORM_SUCCESS = 'CONTACT_FORM_SUCCESS';
export const CONTACT_FORM_ERROR = 'CONTACT_FORM_ERROR';

export const JOBS_FORM_SHOW = 'JOBS_FORM_SHOW';
export const JOBS_FORM_SUBMIT = 'JOBS_FORM_SUBMIT';
export const JOBS_FORM_SUCCESS = 'JOBS_FORM_SUCCESS';
export const JOBS_FORM_ERROR = 'JOBS_FORM_ERROR';

export const ARCO_FORM_SHOW = 'ARCO_FORM_SHOW';
export const ARCO_FORM_SUBMIT = 'ARCO_FORM_SUBMIT';
export const ARCO_FORM_SUCCESS = 'ARCO_FORM_SUCCESS';
export const ARCO_FORM_ERROR = 'ARCO_FORM_ERROR';

export const CORP_SALES_FORM_SHOW = 'CORP_SALES_FORM_SHOW';
export const CORP_SALES_FORM_SUBMIT = 'CORP_SALES_FORM_SUBMIT';
export const CORP_SALES_FORM_SUCCESS = 'CORP_SALES_FORM_SUCCESS';
export const CORP_SALES_FORM_ERROR = 'CORP_SALES_FORM_ERROR';

export const IE_SIGN_UP_FORM_SHOW = 'IE_SIGN_UP_FORM_SHOW';
export const IE_SIGN_UP_FORM_SUBMIT = 'IE_SIGN_UP_FORM_SUBMIT';
export const IE_SIGN_UP_SUCCESS = 'IE_SIGN_UP_SUCCESS';
export const IE_SIGN_UP_ERROR = 'IE_SIGN_UP_ERROR';
export const IE_BENEFITS_FETCH = 'IE_BENEFITS_FETCH';
export const IE_BENEFITS_FETCH_SUCCESS = 'IE_BENEFITS_FETCH_SUCCESS';
export const IE_BENEFITS_FETCH_ERROR = 'IE_BENEFITS_FETCH_ERROR';
export const IE_FETCH_SIGN_UP_OPTIONS_SUCCESS = 'IE_FETCH_SIGN_UP_OPTIONS_SUCCESS';
export const IE_FETCH_SIGN_UP_OPTIONS_ERROR = 'IE_FETCH_SIGN_UP_OPTIONS_ERROR';
export const IE_GET_PAYMENT_URL_SUCCESS = 'IE_GET_PAYMENT_URL_SUCCESS';

export const FINGERPRINT_SET = 'FINGERPRINT_SET';

export const NOW_FETCH = 'NOW_FETCH';
export const NOW_FETCH_SUCCESS = 'NOW_FETCH_SUCCESS';
export const NOW_FETCH_ERROR = 'NOW_FETCH_ERROR';

export const NEWS_FETCH = 'NEWS_FETCH';
export const NEWS_FETCH_SUCCESS = 'NEWS_FETCH_SUCCESS';
export const NEWS_FETCH_ERROR = 'NEWS_FETCH_ERROR';

export const POST_FETCH = 'POST_FETCH';
export const POST_FETCH_SUCCESS = 'POST_FETCH_SUCCESS';
export const POST_FETCH_ERROR = 'POST_FETCH_ERROR';

export const LANDING_FETCH = 'LANDING_FETCH';
export const LANDING_FETCH_SUCCESS = 'LANDING_FETCH_SUCCESS';
export const LANDING_FETCH_ERROR = 'LANDING_FETCH_ERROR';
export const LANDING_SHOWTIMES_FETCH = 'LANDING_SHOWTIMES_FETCH';
export const LANDING_SHOWTIMES_FETCH_SUCCESS = 'LANDING_SHOWTIMES_FETCH_SUCCESS';
export const LANDING_SHOWTIMES_FETCH_ERROR = 'LANDING_SHOWTIMES_FETCH_ERROR';

export const NOTIFICATIONS_MOVIE_UPCOMING_SUBSCRIBE = 'NOTIFICATIONS_MOVIE_UPCOMING_SUBSCRIBE';
export const NOTIFICATIONS_MOVIE_UPCOMING_SUBSCRIBE_SUCCESS = 'NOTIFICATIONS_MOVIE_UPCOMING_SUBSCRIBE_SUCCESS';
export const NOTIFICATIONS_MOVIE_UPCOMING_SUBSCRIBE_ERROR = 'NOTIFICATIONS_MOVIE_UPCOMING_SUBSCRIBE_ERROR';
export const NOTIFICATIONS_MOVIE_UPCOMING_UNSUBSCRIBE = 'NOTIFICATIONS_MOVIE_UPCOMING_UNSUBSCRIBE';
export const NOTIFICATIONS_MOVIE_UPCOMING_UNSUBSCRIBE_SUCCESS = 'NOTIFICATIONS_MOVIE_UPCOMING_UNSUBSCRIBE_SUCCESS';
export const NOTIFICATIONS_MOVIE_UPCOMING_UNSUBSCRIBE_ERROR = 'NOTIFICATIONS_MOVIE_UPCOMING_UNSUBSCRIBE_ERROR';
export const NOTIFICATIONS_TOKEN_REQUEST = 'NOTIFICATIONS_TOKEN_REQUEST';
export const NOTIFICATIONS_TOKEN_REQUEST_DENIED = 'NOTIFICATIONS_TOKEN_REQUEST_DENIED';
export const NOTIFICATIONS_TOKEN_REQUEST_GRANTED = 'NOTIFICATIONS_TOKEN_REQUEST_GRANTED';
export const NOTIFICATIONS_TOKEN_REQUEST_ABORT = 'NOTIFICATIONS_TOKEN_REQUEST_ABORT';

export const REFUND_REQUEST_BEGIN = 'REFUND_REQUEST_BEGIN';
export const REFUND_REQUEST_VALIDATE_SUBMIT = 'REFUND_REQUEST_VALIDATE_SUBMIT';
export const REFUND_REQUEST_VALIDATE_SUBMIT_ERROR = 'REFUND_REQUEST_VALIDATE_SUBMIT_ERROR';
export const REFUND_REQUEST_VALIDATE_SUBMIT_SUCCESS = 'REFUND_REQUEST_VALIDATE_SUBMIT_SUCCESS';
export const REFUND_REQUEST_VALIDATE_ALREADY_PROCESSED = 'REFUND_REQUEST_VALIDATE_ALREADY_PROCESSED';
export const REFUND_REQUEST_CHOOSE_SUBMIT = 'REFUND_REQUEST_CHOOSE_SUBMIT';
export const REFUND_REQUEST_CHOOSE_SUBMIT_SUCCESS = 'REFUND_REQUEST_CHOOSE_SUBMIT_SUCCESS';
export const REFUND_REQUEST_CHOOSE_SUBMIT_ERROR = 'REFUND_REQUEST_CHOOSE_SUBMIT_ERROR';
export const REFUND_REQUEST_COMPLETE_SUBMIT = 'REFUND_REQUEST_COMPLETE_SUBMIT';
export const REFUND_REQUEST_COMPLETE_SUBMIT_SUCCESS = 'REFUND_REQUEST_COMPLETE_SUBMIT_SUCCESS';
export const REFUND_REQUEST_COMPLETE_SUBMIT_ERROR = 'REFUND_REQUEST_COMPLETE_SUBMIT_ERROR';
export const REFUND_REQUEST_QUESTIONS_COMPLETE = 'REFUND_REQUEST_QUESTIONS_COMPLETE';
export const REFUND_REQUEST_SELECT_TRANSACTION = 'REFUND_REQUEST_SELECT_TRANSACTION';