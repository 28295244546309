import * as actions from '../actions/types';
import { combineReducers } from 'redux';
import order from './checkout/order';
import paymentSuggestions from './checkout/paymentSuggestions';
import payment from './checkout/payment';
import ui from './checkout/ui';
import {getNextStep,getPrevStep} from '../utils/checkout';
import { uuid } from 'uuidv4';

const bookingFee = (state = null, action) => {
  switch (action.type) {
    case actions.CHECKOUT_TICKET_SELECTION_SUCCESS:
    case actions.CHECKOUT_SEATS_SELECTION_SUCCESS:
      return action.data.fees ? action.data.fees.booking : state;
    case actions.CHECKOUT_ABANDON:
    case actions.CHECKOUT_INIT:
      return null;
    default:
      return state;
  }
};

const ieNip = (state = {isValidating: false, nip: null}, action) => {
  switch (action.type) {
    case actions.CHECKOUT_NIP_REQUEST_SUBMIT:
      return {isValidating: true, nip: null};
    case actions.CHECKOUT_NIP_REQUEST_SUCCESS:
      return {isValidating: false, nip: action.nip};
    case actions.CHECKOUT_NIP_REQUEST_ERROR:
    case actions.CHECKOUT_NIP_REQUEST_CANCEL:
    case actions.CHECKOUT_ABANDON:
    case actions.CHECKOUT_INIT:
    case actions.AUTH_LOGOUT_SUCCESS:
    case actions.AUTH_SESSION_EXPIRED: // @todo think other possible cases where this needs to be wiped
      return {isValidating: false, nip: null};
    default:
      return state;
  }
}

const isLoading = (state = false, action) => {
  switch (action.type) {
    case actions.CHECKOUT_SESSION_IS_LOADING:
      return action.isLoading;
    case actions.CHECKOUT_SESSION_FETCH_ERROR:
      return false;
    default:
      return state;
  }
};

const loadError = (state = false, action) => {
  switch (action.type) {
    case actions.CHECKOUT_SESSION_IS_LOADING:
      return false;
    case actions.CHECKOUT_SESSION_FETCH_SUCCESS:
      return false;
    case actions.CHECKOUT_SESSION_FETCH_ERROR:
      return action.error.status || true;
    default:
      return state;
  }
};

const data = (state = null, action) => {
  switch (action.type) {
    case actions.CHECKOUT_SESSION_FETCH_SUCCESS:
      return action.data;
    case actions.CHECKOUT_PROMO_CODE_SUBMIT_SUCCESS:
      return getUpdatedCheckoutDataWithPromoTickets(state, action);
    case actions.USER_TICKETS_FETCH_SUCCESS:
      return getUpdatedCheckoutDataWithPromoTickets(state, action);
    case actions.CHECKOUT_ABANDON:
    // case actions.CHECKOUT_INIT:
      return null;
    default:
      return state;
  }
};

const getUpdatedCheckoutDataWithPromoTickets = (state, action) => {
  const result = Object.assign({}, state);
  const tickets = Object.assign([], state.tickets || []);

  result.tickets = action.tickets.concat(tickets);

  return result;
};

const payPalClientMetadataId = (state = false, action) => {
  switch (action.type) {
    case actions.USER_PRE_CHECKOUT_FETCH_SUCCESS:
      return action.data.client_metadata_id || false;
    default:
      return state;
  }
};

const payPalSingleClick = (state = false, action) => {
  switch (action.type) {
    case actions.USER_PRE_CHECKOUT_FETCH_SUCCESS:
      return action.data.paypal_single_click || false;
    case actions.CHECKOUT_PAYMENT_ERROR:
      return action.errorcode === 'paypal-agreement-invalid' ? false : state;
    case actions.AUTH_LOGOUT_SUCCESS:
    case actions.CHECKOUT_INIT:
      return false;
    default:
      return state;
  }
};

const step = (state = 'tickets', action) => {
  switch (action.type) {
    case actions.CHECKOUT_NEXT_STEP:
      return getNextStep(state, action);
    case actions.CHECKOUT_PREV_STEP:
      return getPrevStep(state, action);
    case actions.CHECKOUT_PAYMENT_SUCCESS:
      return 'confirmation';
    case actions.CHECKOUT_ABANDON:
    case actions.CHECKOUT_INIT:
      return 'tickets';
    case actions.CHECKOUT_NIP_REQUEST_CANCEL:
      return 'payment-suggestions';
    default:
      return state;
  }
};

const layout = (state = null, action) => {
  switch (action.type) {
    case actions.CHECKOUT_TICKET_SELECTION_SUCCESS:
      return Object.assign([], action.data.layout);
    case actions.CHECKOUT_SEATS_SELECTION_FAILURE:
      return action.response.layout ? Object.assign([], action.response.layout) : state;
    default:
      return state;
  }
};

const seatTypes = (state = null, action) => {
  switch (action.type) {
    case actions.CHECKOUT_TICKET_SELECTION_SUCCESS:
      return Object.assign([], action.data.seat_types);
    case actions.CHECKOUT_SEATS_SELECTION_FAILURE:
      return action.response.seat_types ? Object.assign([], action.response.seat_types) : state;
    default:
      return state;
  }
};

const seats = (state = [], action) => {
  switch (action.type) {
    case actions.CHECKOUT_TICKET_SELECTION_SUCCESS:
      return Object.assign([], action.data.seats);
    case actions.CHECKOUT_SEAT_SELECT:
      return action.seats;
    case actions.CHECKOUT_SEATS_SELECTION_FAILURE:
      return action.response.seats ? Object.assign([], action.response.seats) : state;
    case actions.CHECKOUT_ABANDON:
    case actions.CHECKOUT_INIT:
      return [];
    default:
      return state;
  }
};

const seatsCount = (state = null, action) => {
  switch (action.type) {
    case actions.CHECKOUT_TICKET_SELECTION_SUCCESS:
    case actions.CHECKOUT_SEATS_SELECTION_SUCCESS:
      return action.data.seats_count || action.data.seats.length;
    default:
      return state;
  }
};

const seatsPreSelected = (state = null, action) => {
  if (action.type === actions.CHECKOUT_TICKET_SELECTION_SUCCESS) {
    return action.data.seats_count ? action.data.seats.length === action.data.seats_count : true;
  } else {
    return state;
  }
}

const candybar = (state = {isLoading: false, loadError: false, filterByCategory: null}, action) => {
  switch (action.type) {
    case actions.CHECKOUT_CANDYBAR_IS_LOADING:
      return Object.assign({}, state, {isLoading: action.isLoading});
    case actions.CHECKOUT_CANDYBAR_FETCH_ERROR:
      return Object.assign({}, state, {loadError: true});
    case actions.CHECKOUT_CANDYBAR_FETCH_SUCCESS:
      return Object.assign({}, state, action.data, {loadError: false, filterByCategory: null});
    case actions.CHECKOUT_CANDYBAR_CATEGORY_SELECT:
      return Object.assign({}, state, action.data, {filterByCategory: action.categoryId});
    case actions.CHECKOUT_CANDYBAR_ITEM_SELECT:
      return Object.assign({}, state, action.data, {selectedItem: action.itemId, selectedUnit: action.unitId});
    case actions.CHECKOUT_CANDYBAR_UNIT_SELECT:
      return Object.assign({}, state, action.data, {selectedUnit: action.unitId});
    case actions.CHECKOUT_CANDYBAR_ITEM_EDIT_CANCEL:
      return Object.assign({}, state, action.data, {selectedItem: null});
    default:
      return state;
  }
};

const timer = (state = {time: 0, running: false}, action) => {
  switch (action.type) {
    case actions.CHECKOUT_TIMER_START:
      return {timeoutUnix: action.timeoutUnix, time: calculateTimerRemain(action.timeoutUnix), running: true};
    case actions.CHECKOUT_TIMER_TICK:
      return Object.assign({}, state, {time: calculateTimerRemain(state.timeoutUnix)});
    case actions.CHECKOUT_TIMER_STOP:
      return Object.assign({}, state, {running: false});
    default:
      return state;
  }
};

const calculateTimerRemain = timeoutUnix => {
  const now = parseInt(new Date().getTime() / 1000, 10);
  return timeoutUnix - now;
};

const defaultPaymentGateway = 'default';
const paymentGateway = (state = defaultPaymentGateway, action) => {
  switch (action.type) {
    case actions.CHECKOUT_TICKET_SELECTION_SUCCESS:
      return action.data.payment_gateway;
    default:
      return state;
  }
}

const paymentConstraints = (state = [], action) => {
  switch (action.type) {
    case actions.CHECKOUT_TICKET_SELECTION_SUCCESS:
      return action.data.payment_constraints;
    default:
      return state;
  }
}

const paymentMethod = (state = null, action) => {
  switch (action.type) {
    case actions.CHECKOUT_PAYMENT_METHOD_SELECT:
      return action.method;
    case actions.CHECKOUT_PAYMENT_METHOD_CANCEL:
    case actions.CHECKOUT_TICKET_SELECTION_SUCCESS:
    case actions.GET_SMART_URL_ERROR:
    case actions.GET_STRIPE_URL_ERROR:
    case actions.CHECKOUT_SMART_PAYMENT_ERROR:
    case actions.CHECKOUT_ABANDON:
    case actions.CHECKOUT_INIT:
      return null;
    default:
      return state;
  }
};

const paymentMethods = (state = [], action) => {
  switch (action.type) {
    case actions.CHECKOUT_TICKET_SELECTION_SUCCESS:
      return action.data.payment_methods || [];
    default:
      return state;
  }
}

const confirmation = (state = null, action) => {
  switch (action.type) {
    case actions.CHECKOUT_PAYMENT_SUCCESS:
      return Object.assign({}, action.data);
    default:
      return state;
  }
};

const points = (state = [], action) => {
  switch (action.type) {
    case actions.USER_POINTS_FETCH_SUCCESS:
      return action.points;
    case actions.AUTH_LOGOUT_SUCCESS:
    case actions.AUTH_LOGIN_POST_SUCCESS:
    case actions.AUTH_FB_LOGIN_SUCCESS:
    case actions.CHECKOUT_ABANDON:
    case actions.USER_PRE_CHECKOUT_FETCH:
      return [];
    default:
      return state;
  }
};

const needsToSubmitSeats = (state = false, action) => {
  switch (action.type) {
    case actions.CHECKOUT_SEAT_SELECT:
      return true;
    case actions.CHECKOUT_TICKET_SELECTION_SUCCESS:
    case actions.CHECKOUT_SEATS_SELECTION_SUCCESS:
      return false;
    default:
      return state;
  }
};

const ticketsSelection = (state = {isProcessing: false}, action) => {
  switch (action.type) {
    case actions.CHECKOUT_TICKET_SELECTION_PROCESSING:
      return Object.assign({}, state, {isProcessing: action.processing});
    default:
      return state;
  }
};

const seatsSelection = (state = {isProcessing: false}, action) => {
  switch (action.type) {
    case actions.CHECKOUT_SEATS_SELECTION_PROCESSING:
      return Object.assign({}, state, {isProcessing: action.processing});
    default:
      return state;
  }
};

const flowDirection = (state = null, action) => {
  switch (action.type) {
    case actions.CHECKOUT_NEXT_STEP:
      return 'next';
    case actions.CHECKOUT_PREV_STEP:
      return 'prev';
    default:
      return state;
  }
};

const promoCodeCode = (state = '', action) => {
  switch (action.type) {
    case actions.CHECKOUT_INIT:
      return '';
    case actions.CHECKOUT_PROMO_CODE_SUBMIT_SUCCESS:
      return action.code;
    default:
      return state;
  }
};

const promoCodeTemp = (state = '', action) => {
  switch (action.type) {
    case actions.CHECKOUT_PROMO_CODE_SHOW:
      return '';
    case actions.CHECKOUT_PROMO_CODE_CHANGE:
      return action.code;
    case actions.CHECKOUT_PROMO_CODE_CANCEL:
      return action.code;
    case actions.CHECKOUT_PROMO_CODE_SUBMIT_SUCCESS:
      return action.code;
    default:
      return state;
  }
};

const promoCode = combineReducers({
  code: promoCodeCode,
  temp: promoCodeTemp,
});

const masterpass = (state = {isProcessing: false, data: null}, action) => {
  switch (action.type) {
    case actions.CHECKOUT_MASTERPASS_OPEN:
      return Object.assign({}, state, {isProcessing: true, data: null});
    case actions.CHECKOUT_MASTERPASS_ERROR:
      return Object.assign({}, state, {isProcessing: false, data: null});
    case actions.CHECKOUT_MASTERPASS_SUCCESS:
      return Object.assign({}, state, {isProcessing: false, data: action.data});
    case actions.CHECKOUT_PAYMENT_METHOD_SELECT:
      return Object.assign({}, state, {data: null});
    default:
      return state;
  }
};

const timedOut = (state = false, action) => {
  switch (action.type) {
    case actions.CHECKOUT_INIT:
      return false;
    case actions.CHECKOUT_TIMER_TIMEOUT:
      return true;
    default:
      return state;
  }
};

const t1 = (state = {}, action) => {
  switch (action.type) {
    case actions.CHECKOUT_INIT:
      return {sessionId: uuid()};
    default:
      return state;
  }
};

const smart = (state = {url: null, isProcessing: false}, action) => {
  switch (action.type) {
    case actions.GET_SMART_URL:
      return {url: null, isProcessing: true};
    case actions.GET_SMART_URL_SUCCESS:
      return {url: action.data.url, isProcessing: false};
    case actions.GET_SMART_URL_ERROR:
      return {url: null, isProcessing: false};
    default:
      return state;
  }
};

const stripe = (state = {url: null, isProcessing: false}, action) => {
  switch (action.type) {
    case actions.GET_STRIPE_URL:
      return {url: null, isProcessing: true};
    case actions.GET_STRIPE_URL_SUCCESS:
      return {url: action.data.url, isProcessing: false};
    case actions.GET_STRIPE_URL_ERROR:
      return {url: null, isProcessing: false};
    default:
      return state;
  }
};

const termsCheck = (state = false, action) => {
  switch (action.type) {
    case actions.CHECKOUT_INIT:
    case actions.CHECKOUT_ABANDON:
      return false;
    case actions.CHECKOUT_SET_TERMS_CHECK:
      return action.checked;
    default:
      return state;
  }
}

export default combineReducers({
  bookingFee,
  candybar,
  confirmation,
  data,
  flowDirection,
  ieNip,
  isLoading,
  layout,
  loadError,
  masterpass,
  needsToSubmitSeats,
  order,
  payment,
  paymentConstraints,
  paymentGateway,
  paymentMethod,
  paymentMethods,
  paymentSuggestions,
  payPalClientMetadataId,
  payPalSingleClick,
  points,
  promoCode,
  seatTypes,
  seats,
  seatsCount,
  seatsSelection,
  seatsPreSelected,
  smart,
  step,
  stripe,
  termsCheck,
  t1,
  ticketsSelection,
  timedOut,
  timer,
  ui,
});