import React, { MouseEventHandler } from "react";
import moment from 'moment';
import styled, { ThemedCssFunction } from "styled-components";
import Button from "../Button";
import { getDateFilterDisplayString } from '../../utils/showtimes';
import { ucFirst } from '../../utils/strings';

const Scroller = styled.div<{customStyle?: ThemedCssFunction<string>}>`
  overflow-x: auto;
  padding: 0.5rem 0 0.25rem 0;
  white-space: nowrap;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track { background: #fcfcfc; /* Color of the track */ }
  &::-webkit-scrollbar-thumb { background: #eee; /* Color of the thumb */ }
  &::-webkit-scrollbar-thumb:hover { background: #ddd; /* Darker color on hover */ }

  body.theme-market & { 
    &::-webkit-scrollbar-track { background: #333; /* Color of the track */ }
    &::-webkit-scrollbar-thumb { background: #666; /* Color of the thumb */ }
    &::-webkit-scrollbar-thumb:hover { background: #777; /* Darker color on hover */ }
  }

  body.theme-platinum & { 
    &::-webkit-scrollbar-track { background: var(--primary-b); /* Color of the track */ }
    &::-webkit-scrollbar-thumb { background: var(--primary); /* Color of the thumb */ }
    &::-webkit-scrollbar-thumb:hover { background: var(--primary); /* Darker color on hover */ }
  }

  ${props => `${props.customStyle}` || ''}
`;

const StyledButton = styled(Button)<{customStyle?: ThemedCssFunction<string>, primary: boolean}>`
  margin-right: 0.5em;
  min-width: 90px;
  text-transform: uppercase;

  &:last-child { margin-right: var(--pad-x); }
  
  ${props => !props.primary && `${props.customStyle}` || ''}
`;

function getDateTooltipString(date: string): string { return ucFirst(moment(date).format('dddd DD')) };

interface Props {
    dates: Array<string>,
    selectedDate: string,
    onDateSelect: Function,
    customStyle?: ThemedCssFunction<string>,
    buttonCustomStyle?: ThemedCssFunction<string>,
}

function DatesScroller({dates, selectedDate, onDateSelect, customStyle, buttonCustomStyle}: Props) {
    return <Scroller customStyle={customStyle}>
        {dates.map(date => (
            <StyledButton
                key={date}
                primary={date === selectedDate}
                small
                onClick={() => onDateSelect(date)}
                title={getDateTooltipString(date)}
                customStyle={buttonCustomStyle}
            >
                {getDateFilterDisplayString(date)}
            </StyledButton>
        ))}
    </Scroller>;
}

DatesScroller.StyledButton = StyledButton;
export default DatesScroller;