import React from 'react';
import {connect} from 'react-redux';
import BillboardSidebarBase from '../billboard/BillboardSidebarBase';
import UserCinemaNav from "../UserCinemaNav";
import {getAvailableFilters} from '../../utils/showtimes';
import {getCinemaBillboardFull,getCinemaBillboardForDate,getBootstrapFilters} from '../../reducers';

class CinemaSidebar extends React.Component {
  render() {
    return <BillboardSidebarBase
        heading={<UserCinemaNav cinema={this.props.cinema}/>}
        baseUrl={this.props.baseUrl}
        {...this.props}
    />;
  }
}

const mapStateToProps = (state, props) => {
  let movies = getCinemaBillboardFull(state, props.match.params.id);
  const {date} = props;
  movies = date ? getCinemaBillboardForDate(state, props.match.params.id, date) : [];
  let versions = [];

  movies && movies.forEach(movie => versions = versions.concat(movie.versions));

  const availableFilters = getAvailableFilters(versions);
  const attributes = getBootstrapFilters(state);
  const filters = availableFilters.filter(filter => (filter in attributes) && !filter.match(/^lang_/));
  const languages = availableFilters.filter(filter => (filter in attributes) && filter.match(/^lang_/));

  return {
    attributes,
    filters,
    languages,
    movies,
  };
};

export default connect(
    mapStateToProps
)(CinemaSidebar);