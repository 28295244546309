import React from 'react';
import {connect} from 'react-redux';
import {
  ieCodePanelCancel,
  ieCodeChange,
  ieCodePanelPrevStep,
  ieCodeValidateSubmit,
  ieCodeSubmit,
  ieCodePanelBegin,
  ieCodeValidateSet
} from '../../actions/creators/ie';
import IeCodePanelStep1 from '../checkout/IeCodePanelStep1';
import IeCodePanelStep2 from '../checkout/IeCodePanelStep2';
import Button from '../Button';
import {getIeCodeResultEmailSent} from '../../reducers';
import styled from 'styled-components';
import { breakpoints } from '../../utils/styles';
import BackButton from '../common/BackButton';

const PanelContainer = styled.div`
  @media screen and (min-width: ${breakpoints.desktop}px) {
    max-width: 70%;
  }
`;

const Title = styled.p`
  color: var(--text-primary) !important;
`;

const formatIeCode = v => {
  const bin = '308';
  const maxLength = 16;

  return (bin + v.replace(/[^\d]/g, '').substr(bin.length)).substr(0, maxLength);
};

const formatBarCode = v => {
  const bin = '2';
  const maxLength = 13;

  return (bin + v.replace(/[^\d]/g, '').substr(bin.length)).substr(0, maxLength);
};

const formatBirthDate = v => {
  v = v.replace(/[^\d]/g, '').substr(0, 8).match(/^([0-9]{0,2})([0-9]{0,2})([0-9]{0,4})$/).slice(1, 4).filter(p => p).join('/');

  return v;
};

class UserIeCode extends React.Component {
  constructor(props) {
    super(props);

    this.onPrevClick = this.onPrevClick.bind(this);
    this.onIeCodeChange = this.onIeCodeChange.bind(this);
    this.onBarCodeChange = this.onBarCodeChange.bind(this);
    this.onBirthDateChange = this.onBirthDateChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);

    this.state = {
      code: formatIeCode(''),
      barCode: formatBarCode(''),
      birthDate: '',
    };
  }

  componentWillUnmount() {
    this.props.ieCodePanelBegin();
  }

  onIeCodeChange(code) {
    this.setState(() => ({code: formatIeCode(code)}));
    this.props.setCardNumber(code);
  }

  onBarCodeChange(barCode) {
    this.setState(() => ({barCode: formatBarCode(barCode)}));
  }

  onBirthDateChange(birthDate) {
    this.setState(() => ({birthDate: formatBirthDate(birthDate)}));
  }

  onPrevClick() {
    this.props.step === 1
        ? this.props.onCancel()
        : this.props.prevStep();
  }

  onSubmit(e) {
    e.preventDefault();

    const params = Object.assign({}, this.state, {memberId: this.props.memberId});
    params.birthDate = params.birthDate.split('/').reverse().join('-');
    this.props.onSubmit(params);
  }

  onCancel() {
    this.props.onCancel();
    this.props.ieCodePanelCancel();
  }

  renderPanel() {
    return this.props.step === 1 ? (
        <IeCodePanelStep1 processing={this.props.processing} code={this.state.code}
                          onChange={this.onIeCodeChange} onSubmit={this.props.onValidateSubmit}/>
    ) : (
        <IeCodePanelStep2
            barCode={this.state.barCode} onBarCodeChange={this.onBarCodeChange}
            birthDate={this.state.birthDate} onBirthDateChange={this.onBirthDateChange}
            onSubmit={this.onSubmit}
        />
    );
  }

  renderConfirmation() {
    return (
        <React.Fragment>
          <div className="rounded bg-primary text-white text-center p-5 fade-in">
            <div className="row justify-content-center my-5">
              <div className="col-md-10">

                <i className="fal fa-crown fa-2x mb-5"></i>

                <Title className="title-1">Felicitaciones, tu cuenta Cinemex Loop ya se encuentra vinculada.</Title>

                <p style={{lineHeight:'1.4em', fontWeight: 200}}>{this.props.emailSent ? (
                    <React.Fragment>
                      Te llegará un correo electrónico con tu <strong>NIP</strong> de transacciones.
                      <br/>
                      No lo pierdas, ya que deberás ingresarlo cada vez que desees hacer uso de tus puntos y pases
                      corporativos.
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      Puedes usar el mismo NIP que generaste la última vez.
                      <br/>
                      Si olvidaste tu NIP de transacciones puedes modificarlo desde tu perfil.
                    </React.Fragment>
                )}</p>
                <Button className="mt-3" transparent onClick={this.props.onCancel}>Continuar</Button>
              </div>
            </div>
          </div>
        </React.Fragment>
    );
  }

  render() {
    return (
        <div>
          {this.props.step === 3 ?
              this.renderConfirmation()
              :
              (
                  <PanelContainer>
                    <BackButton onBackClick={this.onCancel} label='Ingresa tu número de miembro Cinemex Loop'/>
                    
                    <div className='mt-3' style={{minHeight:'300px'}}>{this.renderPanel()}</div>
                  </PanelContainer>
              )
          }
        </div>
    );
  }
}

const mapStateToProps = state => ({
  processing: state.checkoutData.ui.ieCodePanel.processing,
  step: state.checkoutData.ui.ieCodePanel.step,
  memberId: state.checkoutData.ui.ieCodePanel.tempMemberId,
  emailSent: getIeCodeResultEmailSent(state),
});

const mapDispatchToProps = dispatch => ({
  ieCodePanelBegin: () => dispatch(ieCodePanelBegin()),
  ieCodePanelCancel: () => dispatch(ieCodePanelCancel(false)),
  onChange: code => dispatch(ieCodeChange(code)),
  prevStep: () => dispatch(ieCodePanelPrevStep()),
  onValidateSubmit: code => dispatch(ieCodeValidateSubmit(code)),
  onSubmit: payload => dispatch(ieCodeSubmit(payload)),
  setCardNumber: cardNumber => dispatch(ieCodeValidateSet(cardNumber)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserIeCode);