import React from 'react';
import BillboardMovieDetailsBlock from "./BillboardMovieDetailsBlock";
import BillboardMovieVersion from "./BillboardMovieVersion";
import BillboardMoviePoster from "./BillboardMoviePoster";
import styled from 'styled-components';

const Movie = styled.div`
  border-top: 1px solid var(--text-primary);
  padding-top: 2rem;
  margin-bottom: 2rem;

  body.theme-platinum & { border-color: var(--primary-b-hr); }
`

class BillboardMovieBlock extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onExpandedToggle = this.onExpandedToggle.bind(this);
    this.onTrailerToggle = this.onTrailerToggle.bind(this);
    this.onTrailerClose = this.onTrailerClose.bind(this);

    this.state = {
      expanded: false,
      trailer: false,
    };
  }

  onExpandedToggle() {
    this.setState(prevState => ({expanded: !prevState.expanded}));
  }

  onTrailerToggle() {
    this.setState(prevState => ({trailer: !prevState.trailer}));
  }

  onTrailerClose() {
    this.setState(prevState => ({trailer: false}));
  }

  render() {
    const {movie} = this.props;
    const {versions} = movie;

    if (!versions.length) {
      return '';
    }

    return (
        <Movie data-movie-id={movie.id} className='container'>
          <div className="row">
            <div className="col col-3 col-lg-2 d-none d-md-block">
              <BillboardMoviePoster movie={movie} onTrailerToggle={this.onTrailerToggle}/>
            </div>
            <div className="col col-md-9 col-lg-10">
              <BillboardMovieDetailsBlock movie={movie} trailerOpen={this.state.trailer} onTrailerClose={this.onTrailerClose} onTrailerToggle={this.onTrailerToggle}/>
              {versions.map(version => <BillboardMovieVersion key={movie.id + '-' + version.id} version={version} movie={movie}/>)}
            </div>
          </div>
        </Movie>
    );
  }
}

export default BillboardMovieBlock;