import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Button = css`
  background: none;
  border: 0;
  color: var(--text-primary);
  
  ${props => props.disabled
    ? 'cursor: not-allowed; opacity: 0.5;'
    : 'cursor: pointer; opacity: 1;'}
  ${props => props.paddingDefault ? '' : 'padding: 0;'}
  text-align: inherit;
  width: ${props => props.width || 'auto'}
  
  &:focus { outline: none; }
`;

const StyledAnchor = styled(Link)`
    ${Button}
`;

const StyledSpan = styled.span`
    ${Button}
`;

const Icon = styled.i`
    border: 1px solid var(--text-primary);
    border-radius: 1rem;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5;
    text-align: center;
    margin-left: 0.5em;
    vertical-align: middle;
    margin-top: -0.1em;
`;

function LinkButton(props) {
  const Element = props.to ? StyledAnchor : StyledSpan;

  return <Element {...props}>
    {props.children}
    {props.icon && <Icon className={'fal ' + props.icon}></Icon>}
  </Element>
}

export default LinkButton;