import React from "react";
import styled from "styled-components";

interface Props {
    color: string,
};

const Icon = styled.div<Props>`
    --status-color: ${props => props.color};
    background: var(--status-color);
    height: 1.25em;
    border-radius: 1em;
    color: #fff;
    display: inline-block;
    line-height: 1.25;
    margin: 0 auto;
    width: 1.25em;
    text-align: center;
    position: relative;

    & > i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
`;

function CouponAvailabilityIcon({color}: Props) {
    return <Icon color={color}><i className="fa-light fa-clock"></i></Icon>;
}

CouponAvailabilityIcon.Styled = Icon;
export default CouponAvailabilityIcon;