import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import Sticky from 'react-stickynode';
import DrawerButton from "./DrawerButton";
import HeaderSearch from "./HeaderSearch";
import UserCinemaNav from './UserCinemaNav';
import {cleanForSlug} from '../utils/urls';
import {getSelectedArea,getSelectedCinema, getUserInfo, isUserLogged} from '../reducers';
import { asset } from '../utils/misc';
import {breakpoints} from '../utils/styles';
import DrawerNav from './DrawerNav';
import { logout } from '../actions/creators/auth';
import { authOpen } from '../actions/creators';
import IELogoH from '../assets/svg/IELogoH';
import { cinemaSelectorOpen } from '../actions/creators/ui';

const Logo = styled(Link)`
  display: block;
  flex: auto;
  width: 140px;
  height: 30px;
  text-indent: -9999em;
  text-align: left;
  background-image: url("${props => asset('/dist/images/logo-cinemex' + (props.negative ? '-negative' : '') + '.svg?20241212')}");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -0.3em;
  margin-right: 1rem;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    flex: none;
    margin-top: -0.75em;
  }
  
  ${props => props.smNegative && `
    @media screen and (max-width: ${breakpoints.desktop - 1}px) {
      background-image: url("${asset('/dist/images/logo-cinemex-negative.svg?20241212')}");
    }
  `}
  
  body.theme-platinum & {
    background-image: url("${props => asset('/dist/images/logo-cinemex-platinum' + (props.negative ? '' : '') + '.svg?20241212')}");
    width: 204px;
  }
  
  body.theme-market & {
    background-image: url("${props => asset('/dist/images/logo-cinemex-market' + (props.negative ? '-negative' : '') + '.svg?20241212')}");
    width: 204px;
  }
`;

const HeaderNav = styled.nav`
  background: var(--page-bg);
  padding: 1rem var(--pad-x);
  width: 100%;
  z-index: 1030;
  transition: background-color ease-in-out 0.5s;
  
  & > .container {
    padding: 0 !important;
    display: flex;
    align-items: center;
  }
  .nav-link { color: #fff !important; }
  @media screen and (min-width: ${breakpoints.desktop}px) { .nav-link { color: var(--primary) !important; } }

  ${props => props.fixed && 'box-shadow: 0 0 30px rgba(0,0,0,0.15);'}
  ${props => props.transparent && 'position: fixed; width: 100%;'}
  ${props => props.transparent && `
    background: transparent !important;
    
    .nav-link { color: #fff !important; }
    .navbar-toggler {
        color: rgba(255,255,255,0.5);
        border-color: rgba(255,255,255,0.1);
    }
  `}

  .c-nav__searchform {
    background: ${props => (props.transparent) ? 'black' : 'var(--tertiary)'};
  }

  ${props => props.baseStyle || ''}
`;

const Menu = styled.ul`
  display: none;
  font-weight: 400;
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
  position: relative;

  a {
    padding: .5rem 0;
  }
  &, a, span { color: var(--text-primary); }
  li { margin-bottom: 0; }
  a, span {
    display: inline-block; padding: .5rem; cursor: pointer; white-space: nowrap;
    &:hover { color: var(--primary); }
  }

  ${props => props.transparent && '& > li > a, & > li > span { color: #fff; &:hover { color: var(--primary); }  }'}

  @media screen and (min-width: ${breakpoints.desktop}px) {
    display: flex;
  }
`;

const DropDownContent = styled.ul`
  list-style-type: none;
  padding: 0;
  border: 1px solid #bdc4c9;
  background: white;
  display: none;
  position: absolute;
  padding: 0.75rem 1.25rem 0.75rem 0.75rem ;
  box-shadow: 0 0 10px rgb(0 0 0 / 15%);
  border-radius: 0.5em;
  right: 1.5rem;
  text-align: center;
  top: 2.25em;
  transform: translateX(50%);

  &:before {
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    border-left: 6px solid transparent;
    content: '';
    display: inline-block;
    position: absolute;
    right: 50%;
    top: -6px;
    transform: translateX(50%);
  }

  a { white-space: nowrap; padding: 0.25em; }
  a:hover { color: var(--primary) !important; }
  a i { display: inline-block; }

  body.theme-market & {
    a { color: #000; }
  }

  body.theme-platinum & {
    background: var(--text-primary);
    &:before { border-bottom-color: var(--text-primary); }
    a { color: #000; }
  }
`;

const DropDown = styled.li`
  position: relative;
  
  &:focus,
  &:hover {
    outline: none;
    & > .dropdown-menu {
      display: block; left: 100%; margin: 0; position: absolute; transform: translateX(-100%);
    }
  }
  
  .dropdown-item { padding: .25rem 1.5rem .25rem .75rem; }

  &:hover ${DropDownContent} { display: block; }
`;

const DropDownTrigger = styled.span`
  cursor: pointer;
  display: inline;

  &:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
  }
`;

const IELogo = styled.span`
  width: 4em;
  margin-top: -0.4em;
  margin-bottom: -1em;
  ${props => props.negative
    ? `
      fill: #fff
      &:hover { fill: var(--primary); }
    `
    : `
      fill: var(--text-primary);
      &:hover { fill: var(--primary); }
    `
  };
  
  body.theme-market &,
  body.theme-platinum & { margin-top: -0.5em; }
`;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.onMenuToggle = this.onMenuToggle.bind(this);

    this.state = {
      menuOpen: false,
    };
  }

  componentWillUnmount() {
    if (this.state.menuOpen) {
      document.body.classList.remove('overflow-h');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.menuOpen && !prevState.menuOpen) {
      document.body.classList.add('overflow-h');
    } else if (!this.state.menuOpen && prevState.menuOpen) {
      document.body.classList.remove('overflow-h');
    }
  }

  onMenuToggle() {
    this.setState(state => ({menuOpen: !state.menuOpen}));
  }

  render() {
    return <React.Fragment>
      <Sticky bottomBoundary="#the-body" innerZ="1031">{
        status => this.renderHeader({sticky: status.status})
      }</Sticky>

      <DrawerNav
        cinema={this.props.cinema}
        user={this.props.user}
        isOpen={this.state.menuOpen}
        onCloseClick={this.onMenuToggle}
        onLogInClick={this.props.authOpen}
        onLogOutClick={this.props.logout}
        onCinemaClick={this.props.openCinemaSelector}
      />
    </React.Fragment>;
  }

  renderHeader({sticky}) {
    const transparent = this.props.transparent || (this.props.feat && sticky !== Sticky.STATUS_FIXED);

    return <React.Fragment>
        <HeaderNav id="header" style={this.props.style} baseStyle={this.props.baseStyle}
          transparent={transparent} fixed={sticky === Sticky.STATUS_FIXED}>
          <div className="container no-width">
            <Logo negative={transparent} smNegative={this.props.logoSmNegative} to="/">Cinemex</Logo>
            <DrawerButton onClick={this.onMenuToggle}/>
            <div>
              <Menu transparent={transparent}>
                <li><Link to="/">Inicio</Link></li>
                <li><Link to="/promociones">Promociones</Link></li>
                <li><Link to="/loop/programa"><IELogo negative={transparent} area-label="Cinemex Loop" dangerouslySetInnerHTML={{__html:IELogoH}}/></Link></li>
                <li><Link to="/landing/preventas/">Preventas</Link></li>
                <li><Link to="/landing/imax/peliculas">IMAX</Link></li>
                <li><Link to="/usuario/cupones">Cupones</Link></li>
                <DropDown>
                  <DropDownTrigger>Más</DropDownTrigger>
                  <DropDownContent>
                    {this.props.cinema && <li><Link to={'/por-comenzar/cine-' + this.props.cinema.id + '/' + cleanForSlug(this.props.cinema.name)}>A punto de comenzar</Link></li>}
                    <li><Link to="/contacto">Contacto</Link></li>
                  </DropDownContent>
                </DropDown>
                {this.props.includeSearch && <HeaderSearch className="d-block d-sm-none"/>}
              </Menu>
            </div>

            {this.props.withUserCinemaNav && <div style={{marginLeft:'auto'}}><UserCinemaNav negative={transparent} showInMobile={false} padding={false}/></div>}
          </div>
        </HeaderNav>
    </React.Fragment>;
  }
}

const mapStateToProps = (state, props) => ({
  area: getSelectedArea(state),
  cinema: getSelectedCinema(state),
  feat: props.feat,// && !getHeaderSearchTerm(state),
  user: getUserInfo(state)
});

const mapDispatchToProps = dispatch => ({
  authOpen: () => dispatch(authOpen()),
  logout: () => dispatch(logout()),
  openCinemaSelector: () => dispatch(cinemaSelectorOpen()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);