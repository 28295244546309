import React from 'react';
import TinySlider from 'tiny-slider-react';
import uniqid from 'uniqid';
import {centsToCurrencyString} from '../../utils/numbers';
import SliderArrows from "../SliderArrows";
import SliderContainer from '../SliderContainer';
import Button from '../Button';

let navId = '';

const getAddButtonLabel = qty => qty > 0
    ? <React.Fragment><span className="badge badge-pill badge-white mr-1">{qty}</span> Agregar otro</React.Fragment>
    : 'Agregar';

class CandybarItems extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);

    navId = uniqid();
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(this.props.items).join(',') !== Object.keys(prevProps.items).join(',')) {
      navId = uniqid();
    }
  }

  onClick(slide, info, event) {
    let target = event.target;
    let action = 'add';

    while (!target.classList.contains('c-slider__item')) {
      target = target.parentNode;

      if (target.classList.contains('_edit')) {
        action = 'edit';
      }
    }

    const itemId = parseInt(target.getAttribute('data-item-id'), 10);

    if ('edit' === action) {
      this.props.onEdit(itemId);
    } else {
      this.props.onAdd(itemId);
    }
  }

  render() {
    const settings = {
      controlsContainer: '#' + navId,
      items: 1.25,
      gutter: 15,
      mouseDrag: true,
      nav: false,
      responsive: {
        '576': {items: 2.125},
      }
    };
    const {items} = this.props;

    return (
        <SliderContainer>
          <div className="mt-4 mb-3">
            <TinySlider settings={settings} onClick={this.onClick} className="c-slider c-slider--preview">
              {Object.keys(items).map(itemId => {
                const item = items[itemId];
                const qty = itemId in this.props.order
                    ? Object.keys(this.props.order[itemId]).length
                    : 0;

                return (
                    <div className="c-slider__item" key={item.id} data-item-id={item.id}>
                      <div className="c-card-shop shadow">
                        <div className="c-card-shop__image">
                          {item.image && <img src={item.image} alt={'Foto de ' + item.name}/>}
                          {qty > 0 && <Button defaultPadding className="btn-white c-card-shop__edit _edit"><i className="fal fa-pencil-alt"></i></Button>}
                        </div>
                        <h2 className="h5">{item.name}</h2>
                        {item.description && <p>{item.description}</p>}
                        <p className="lead text-primary">{centsToCurrencyString(item.price)}</p>
                        <Button primary defaultPadding>{getAddButtonLabel(qty)}</Button>
                      </div>
                    </div>
                );
              })}
            </TinySlider>
            <SliderArrows navId={navId}/>
          </div>
        </SliderContainer>
    );
  }
}

export default CandybarItems;