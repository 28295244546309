import React from 'react';
import {withRouter} from 'react-router-dom';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import {hoursRange} from '../../utils/billboard';
import '../cinema/CinemaSidebar.css';
import BillboardSidebarDates from "./BillboardSidebarDates";
import ChoicesFilter from "../ChoicesFilter";
import Button from '../Button';
import FilterLabel from '../common/FilterLabel';
import styled from 'styled-components';

const getMovieSelectionLabel = (result, allSelected) => allSelected ? 'Todas' : getNSelectionLabel(result, 'seleccionadas');
const getCinemaSelectionLabel = (result, allSelected) => allSelected ? 'Todos' : getNSelectionLabel(result, 'seleccionados');

const getNSelectionLabel = (result, word) => {
  const l = result.length;

  if (l === 1) {
    return result[0];
  } else {
    return l + ' ' + word;
  }
};

const formatIntToHour = (n, type) => {
  if (type !== 'value') { return ''; }

  let amPm;

  if (n > 24) {
    amPm = 'am';
    n -= 24;
  } else if (n > 12) {
    amPm = 'pm';
    n -= 12;
    if (n === 12) {
      n = 0;
      amPm = 'am';
    }
  } else if (n === 12) {
    amPm = 'pm';
  } else {
    amPm = 'am';
  }

  return (n < 10 ? '0' : '') + n.toString() + ':00 ' + amPm;
};

const BorderTopContainer = styled.div`
  border-top: 1px solid var(--text-primary);
  body.theme-platinum & { border-color: var(--primary-b-hr); }
`;

class BillboardSidebarBase extends React.Component {
  constructor(props) {
    super(props);

    this.onFormatFilterToggle = this.onFormatFilterToggle.bind(this);
    this.onLanguageFilterToggle = this.onLanguageFilterToggle.bind(this);
    this.onHoursFilterChange = this.onHoursFilterChange.bind(this);
    this.onCinemaSelect = this.onCinemaSelect.bind(this);
    this.onMovieSelect = this.onMovieSelect.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.getBaseUrl = this.getBaseUrl.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
    this.buildUrlWithDate = this.buildUrlWithDate.bind(this);
    this.buildUrlWithFormats = this.buildUrlWithFormats.bind(this);
    this.buildUrlWithParamValue = this.buildUrlWithParamValue.bind(this);

    this.state = {
      hours: {min: props.urlParams.hours.min || hoursRange.min, max: props.urlParams.hours.max || hoursRange.max}
    };
  }

  render() {
    return (
        <React.Fragment>
          {this.props.heading}

          {this.renderCinemaSelector()}
          {this.renderMovieSelector()}
          <div class="d-none d-md-block">
            <BillboardSidebarDates dates={this.props.dates} selectedDate={this.props.date} baseUrl={this.getBaseUrl()} onDateSelect={this.onDateSelect}/>
          </div>
          <ChoicesFilter label="Formato" filters={this.props.filters} selection={this.props.urlParams.format} onChange={this.onFormatFilterToggle} attributes={this.props.attributes}/>
          <ChoicesFilter label="Idioma" filters={this.props.languages} selection={this.props.urlParams.lang} onChange={this.onLanguageFilterToggle} attributes={this.props.attributes}/>
          {this.renderHoursFilter()}

          <BorderTopContainer className="container py-4">
            <Button block onClick={this.resetFilters}>Limpiar filtros</Button>
          </BorderTopContainer>
        </React.Fragment>
    );
  }

  renderCinemaSelector() {
    if (!this.props.cinemas || !this.props.cinemas.length) {
      return '';
    }

    const cinemas = {};
    const filters = [];

    this.props.cinemas.forEach(cinema => {
      cinemas[cinema.id] = {display_name: cinema.name};
      filters.push(cinema.id);
    });

    return <ChoicesFilter allLabelFn={getCinemaSelectionLabel} attributes={cinemas} collapsible filters={filters} label="Cine" onChange={this.onCinemaSelect} selection={this.props.urlParams.cinema}/>;
  }

  onCinemaSelect(cinemaId) {
    const selection = this.props.urlParams.cinema;
    let result;

    if ('*' === cinemaId) {
      result = [];
    } else {
      cinemaId = parseInt(cinemaId, 10);
      const i = selection.indexOf(cinemaId);
      if (i === -1) {
        result = selection.concat([cinemaId]);
      } else {
        result = selection.slice(0, i).concat(selection.slice(i+1));
      }
    }

    this.props.history.push(this.buildUrlWithCinema(result.join(',')));
  }

  renderMovieSelector() {
    if (!this.props.movies || !this.props.movies.length) {
      return '';
    }

    const movies = {};
    const filters = [];

    this.props.movies.forEach(movie => {
      movies[movie.id] = {display_name: movie.name};
      filters.push(movie.id);
    });

    return <ChoicesFilter allLabelFn={getMovieSelectionLabel} attributes={movies} collapsible filters={filters} label="Películas" onChange={this.onMovieSelect} selection={this.props.urlParams.movie}/>;
  }

  onMovieSelect(movieId) {
    const selection = this.props.urlParams.movie;
    let result;

    if ('*' === movieId) {
      result = [];
    } else {
      movieId = parseInt(movieId, 10);
      const i = selection.indexOf(movieId);
      if (i === -1) {
        result = selection.concat([movieId]);
      } else {
        result = selection.slice(0, i).concat(selection.slice(i+1));
      }
    }

    this.props.history.push(this.buildUrlWithMovie(result.join(',')));
  }

  onDateSelect(date) {
    this.props.history.push(this.buildUrlWithDate(date));
  }

  onFormatFilterToggle(filter) {
    const selection = this.props.urlParams.format;
    let result;

    if ('*' === filter) {
      result = [];
    } else {
      const i = selection.indexOf(filter);
      if (i === -1) {
        result = selection.concat([filter]);
      } else {
        result = selection.slice(0, i).concat(selection.slice(i+1));
      }
    }

    this.props.history.push(this.buildUrlWithFormats(result));
  }

  onLanguageFilterToggle(filter) {
    const selection = this.props.urlParams.lang;
    let result;

    if ('*' === filter) {
      result = [];
    } else {
      const i = selection.indexOf(filter);
      if (i === -1) {
        result = selection.concat([filter]);
      } else {
        result = selection.slice(0, i).concat(selection.slice(i+1));
      }
    }

    this.props.history.push(this.buildUrlWithLanguages(result));
  }

  renderHoursFilter() {
    if (!this.props.dates || !this.props.dates.length) {
      return '';
    }

    return (
        <BorderTopContainer className="container py-3">
          <FilterLabel>Horario</FilterLabel>
          <div className="my-4 mx-2">
            <InputRange
                maxValue={hoursRange.max}
                minValue={hoursRange.min}
                value={this.state.hours}
                onChange={value => this.setState(() => ({hours: value}))}
                onChangeComplete={this.onHoursFilterChange}
                formatLabel={formatIntToHour}
            />
          </div>
        </BorderTopContainer>
    );
  }

  onHoursFilterChange(range) {
    this.props.history.push(this.buildUrlWithHours(range));
  }

  resetFilters(e) {
    e.preventDefault();
    this.setState(() => ({hours: {min: hoursRange.min, max: hoursRange.max}}));
    this.props.history.push(this.getBaseUrl());
  }

  buildUrlWithDate(dateYmd) {
    return this.buildUrlWithParamValue('date', 'fecha-' + dateYmd);
  }

  buildUrlWithFormats(formats) {
    const value = !formats.length
        ? ''
        : 'formato-' + formats.join(',');

    return this.buildUrlWithParamValue('format', value);
  }

  buildUrlWithLanguages(languages) {
    const value = !languages.length
        ? ''
        : 'idioma-' + languages.map(l => l.substr(5)).join(',');

    return this.buildUrlWithParamValue('lang', value);
  }

  buildUrlWithHours(hours) {
    const value = (hours.min === hoursRange.min && hours.max === hoursRange.max)
        ? ''
        : 'horas-' + hours.min + 'a' + hours.max;

    return this.buildUrlWithParamValue('hours', value);
  }

  buildUrlWithCinema(cinemaId) {
    const value = (cinemaId === "0" || !cinemaId.length) ? '' : 'cine-' + cinemaId;

    return this.buildUrlWithParamValue('cinema', value);
  }

  buildUrlWithMovie(movieId) {
    const value = (movieId === "0" || !movieId.length) ? '' : 'pelicula-' + movieId;

    return this.buildUrlWithParamValue('movie', value);
  }

  buildUrlWithParamValue(param, value) {
    const params = Object.assign({}, this.props.match.params);
    const paramsList = ['date', 'format', 'lang', 'hours', 'cinema', 'movie'];
    const parts = [];

    params[param] = value;

    paramsList.forEach(paramName => {
      if (paramName in params) {
        parts.push(params[paramName]);
      }
    });

    return this.getBaseUrl() + parts.filter(part => !!part).join('/');
  }

  getBaseUrl() {
    return this.props.baseUrl;
  }

}

export default withRouter(BillboardSidebarBase);