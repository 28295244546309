import React from 'react';
import LinkButton from '../LinkButton';
import MovieDetailsBlock from '../MovieDetailsBlock';
import {getYouTubeEmbedUrl} from '../../utils/misc';
import MovieRating from '../common/MovieRating';
import styled from 'styled-components';
import BillboardMoviePoster from './BillboardMoviePoster';

const Heading = styled.div`
  display: grid;
  grid-template-columns: 3fr 5fr;
  gap: 1rem;
`;

const HeadingRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.h3`
  font-family: var(--ff-title);
  font-size: ${(35/12.6).toFixed(2)}em;
  text-transform: uppercase;

  body.theme-market & {
    font-family: var(--ff-title);
    font-size: ${(20/12.6).toFixed(2)}rem;
    text-transform: none;
    font-weight: 900;
  }

  body.theme-platinum & {
    color: var(--primary);
    font-size: ${(26/12.6).toFixed(2)}em;
    font-weight: 900;
  }
`;

class BillboardMovieDetailsBlock extends React.Component {
  constructor(props) {
    super(props);

    this.onExpandedToggle = this.onExpandedToggle.bind(this);

    this.state = {
      infoExpanded: false,
    };
  }

  onExpandedToggle() {
    this.setState(prevState => ({infoExpanded: !prevState.infoExpanded}));
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.movie.id !== nextProps.movie.id
        || this.props.trailerOpen !== nextProps.trailerOpen
        || this.state.infoExpanded !== nextState.infoExpanded;
  }

  render() {
    const {movie} = this.props;
    const youtubeUrl = getYouTubeEmbedUrl(movie);

    return (
        <React.Fragment>
          <div className="mb-4">
            <Heading>
              <div className='d-block d-md-none'>
                <BillboardMoviePoster movie={movie} onTrailerToggle={this.props.onTrailerToggle}/>
              </div>
              <HeadingRight>
                <Title>{movie.name}</Title>
                <div className='d-flex justify-content-between'>
                  <LinkButton className="mt-1" onClick={this.onExpandedToggle} icon={this.state.infoExpanded ? 'fa-angle-up' : 'fa-angle-down'}>
                    Info película
                  </LinkButton>
                  <MovieRating>{movie.info.rating}</MovieRating>
                </div>
              </HeadingRight>
            </Heading>
          </div>

          {this.state.infoExpanded && (
              <div className="pb-4 fade-in">
                <MovieDetailsBlock movie={movie}/>
              </div>
          )}

          {youtubeUrl && this.props.trailerOpen && (
              <div className="py-4 border-top fade-in">
                <div className="d-flex mb-3">
                  <p className="h6 text-secondary mb-0">Trailer</p>
                  <LinkButton onClick={this.props.onTrailerClose} className="ml-auto"><i className="fal fa-times"></i></LinkButton>
                </div>

                <div className="embed-responsive embed-responsive-16by9">
                  <iframe className="embed-responsive-item"
                          src={youtubeUrl}
                          allowFullScreen
                          title={'Trailer de ' + movie.name}
                  ></iframe>
                </div>
              </div>
          )}
        </React.Fragment>
    );
  }
}

export default BillboardMovieDetailsBlock;