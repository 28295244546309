import React from 'react';
import Footer from "./Footer";
import Header from './Header';
import styled from 'styled-components';

const Container = styled.div`
  ${props => props.bg && 'background: var(--page-bg);'}
`;

export default props => (
    <React.Fragment>
      <Container className='l-wrapper l-wrapper--toggle' id="the-body" bg={props.bgWhite}>
        <div className="l-wrapper__content">
          <Header withUserCinemaNav={true} feat={props.feat} bgWhite={props.bgWhite} includeSearch={props.includeSearch} transparent={props.headerTransparent} />

          <props.Content {...props}/>
        </div>
      </Container>
      <Footer includeContent={props.footerContent !== false}/>
    </React.Fragment>
);