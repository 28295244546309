import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { cleanForSlug, getAreaBillboardPermalink } from '../utils/urls';
import UserCinemaNavLinks from './UserCinemaNavLinks';
import { breakpoints } from '../utils/styles';
import LinkButton from "./LinkButton";

const Drawer = styled.div`
  background: var(--primary);
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  height: 100%;
  padding: 3rem 3rem 0.5rem;
  position: fixed;
  right: -90vw;
  top: 0;
  width: 90vw;
  transition: 0.5s ease-out right;
  z-index: 1032;

  .nav-link { color: #fff; }

  ${props => props.open && `
    right: 0;
    box-shadow: 0 0 20px rgba(0,0,0,0.5);`}

  @media screen and (min-width: ${breakpoints.desktop}px) { display: none; }
  
  body.theme-platinum & {
    background: var(--primary-c);
  }
`;

const CloseBtn = styled.button`
  background: none;
  border: 0;
  color: white;
  cursor: pointer;
  padding: 0;
  outline: none;

  &:focus { border: 0; outline: none; }
  @media screen and (min-width: ${breakpoints.desktop}px) {
    display: none;
  }

  body.theme-platinum & { color: var(--text-primary); }
`;

const Top = styled.div`
    --text-primary: var(--text-tertiary);
    color: var(--text-primary);
    display: flex;
    justify-content: space-between;

    body.theme-platinum & { color: var(--text-primary); }
`

const Bottom = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
`;

const Menu = styled.ul`
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 1.5em;
    padding: 0;
    text-align: right;

    & > li {
        margin-bottom: 1em;
        & > a {
            color: var(--text-primary);
            font-family: var(--ff-title);
            font-size: ${(30/12.6).toFixed(2)}rem;
            line-height: 1;
            text-transform: uppercase;

            &:hover { color: var(--text-tertiary); }
        }
    }

    body.theme-market & {
        & > li {
            margin-bottom: 1.5em;
            & > a {
                font-family: var(--ff-text);
                font-size: ${(18/12.6).toFixed(2)}rem;
            }
        }
    }

    body.theme-platinum & {
        & > li {
            margin-bottom: 1.5em;
            & > a {
                color: var(--text-primary);
                font-size: ${(18/12.6).toFixed(2)}rem;
                text-transform: uppercase;

                &:hover { color: var(--page-bg); }
            }
        }
    }
`;

const UserBox = styled.div`
    border-top: 1px solid var(--text-primary);
    color: var(--text-primary);
    margin-top: 2rem;
    padding: 2rem 0;
    position: relative;
    text-align: right;

    body.theme-platinum & { border-top-color: var(--text-primary); }
`;

const LogOutBtn = styled.button`
    background: none;
    border: 0;

    &, &:focus { outline: none; }

    & > i {
        font-size: 2em;
        margin-left: 0.5em;
        vertical-align: middle;
    }

    body.theme-market & {
        font-size: 1rem;
    }

    body.theme-platinum & {
        color: var(--text-primary);
    }
`;

class DrawerNav extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
        this.onLogInClick = this.onLogInClick.bind(this);
    }

    onClick(e) {
        if (['a', 'button'].indexOf(e.target.nodeName.toLowerCase()) > -1) {
            this.props.onCloseClick();
        }
    }

    onLogInClick(e) {
        e.preventDefault();
        this.props.onLogInClick();
    }

    render() {
        const { cinema } = this.props;

        return <Drawer open={this.props.isOpen}>
            <Top>
                <CloseBtn onClick={this.props.onCloseClick}><i className="fal fa-times"></i></CloseBtn>
                <LinkButton icon='fa-angle-down' onClick={this.props.onCinemaClick}>
                    {this.props.cinema ? this.props.cinema.name : 'Selecciona un cine'}
                </LinkButton>
            </Top>
            <Bottom>
                <Menu onClick={this.onClick}>
                    {cinema ? <li><Link to={'/por-comenzar/cine-' + cinema.id + '/' + cleanForSlug(cinema.name)}>A punto de comenzar</Link></li> : null}
                    {cinema ? <li><Link to={getAreaBillboardPermalink(cinema.area)}>Cartelera por zonas</Link></li> : null}
                    <li><Link to="/promociones">Promociones</Link></li>
                    <li><Link to="/landing/preventas/">Preventas</Link></li>
                    <li><Link to="/landing/imax/peliculas">IMAX</Link></li>
                    <li><Link to="/contacto">Contacto</Link></li>
                    <li><a href="tel:5552576969">Línea Cinemex</a></li>
                </Menu>
                <UserBox>
                    {this.props.user
                        ? <LogOutBtn onClick={this.props.onLogOutClick}>
                            Cerrar sesión
                            <i class="fal fa-arrow-right-from-bracket"></i>
                        </LogOutBtn>
                        : <LogOutBtn onClick={this.onLogInClick}>
                            Ingresar
                            <i class="fal fa-arrow-right-to-bracket"></i>
                        </LogOutBtn>}
                </UserBox>
            </Bottom>
        </Drawer>;
    }
}

export default DrawerNav;