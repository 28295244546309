import React from 'react';
import styled from 'styled-components';
import Lottie from "lottie-react";
import BodyBlocker from './BodyBlocker';
import { connect } from 'react-redux';
import { getUiCustomLoadingAnimation } from '../reducers';
import animation from '../assets/json/loader.json';
import { breakpoints } from '../utils/styles';

const Container = styled.div`
  position: fixed;
  z-index: 1060;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  ${props => !props.custom && `
    max-width: 25%;
    @media screen and (min-width: ${breakpoints.desktop}px) {
      max-width: 150px;
    }
  `}
`;

class LogoLoading extends React.Component {
    render() {
        return <div>
            <BodyBlocker overSidebars solidBg/>
            <Container custom={!!this.props.customAnimation}>
                <Lottie animationData={this.props.customAnimation || animation}/>
            </Container>
        </div>;
    }
}

const mapStateToProps = state => ({
  customAnimation: getUiCustomLoadingAnimation(state)
});

export default connect(mapStateToProps)(LogoLoading);