import React from 'react';
import {connect} from 'react-redux';
import uniqid from 'uniqid';
import Blocker from "./Blocker";
import Button from './Button';
import FieldRow from './FieldRow';
import * as validators from '../utils/validation';
import {formatDateDmy,formatTimeHm} from '../utils/dates';
import {getCinemas,isCorpSalesFormProcessing,isCorpSalesFormSubmitted} from '../reducers';
import {corpSalesFormShow,corpSalesFormSubmit} from '../actions/creators';
import {sortByName} from '../utils/arrays';

const getCinemasChoices = (props, state) => props.cinemas;

const fields = {
  name: {
    label: 'Nombre completo',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu nombre.'
  },
  email: {
    label: 'Email',
    type: 'email',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateEmail],
    validationMessage: 'Ingresa tu email.'
  },
  company: {
    label: 'Compañía',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu dirección.'
  },
  phone: {
    label: 'Teléfono',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa tu teléfono.'
  },
  event_type: {
    label: 'Tipo de evento',
    required: true,
    id: uniqid(),
    choices: [
      {id: 'Función de grupo', name: 'Función de grupo'},
      {id: 'Función privada', name: 'Función privada'},
      {id: 'Renta de sala', name: 'Renta de sala'},
      {id: 'Renta de sala y función privada', name: 'Renta de sala y función privada'},
      {id: 'Boletos y Convenios', name: 'Boletos y Convenios'},
      {id: 'Boletos Electrónicos', name: 'Boletos Electrónicos'},
    ],
    // placeholder: 'Selecciona un topo de evento',
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Selecciona el tipo de evento.'
  },
  cinema: {
    label: 'Cine',
    required: true,
    id: uniqid(),
    getChoicesMethod: getCinemasChoices,
    // placeholder: 'Selecciona un cine',
    validationMessage: 'Selecciona un cine.',
    validationFns: [validators.validateNotEmpty],
  },
  event_date: {
    label: 'Fecha del evento',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateFutureDate],
    validationMessage: 'Ingresa la fecha del evento.',
    placeholder: 'dd/mm/yyyy',
    formatterFn: formatDateDmy
  },
  event_time: {
    label: 'Horario del evento',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateHour],
    validationMessage: 'Ingresa el horario del evento.',
    placeholder: 'hh:mm',
    formatterFn: formatTimeHm
  },
  capacity: {
    label: 'Capacidad de la sala requerida',
    required: true,
    id: uniqid(),
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Ingresa la capacidad requerida.'
  },
  need_candy: {
    label: '¿Requiere de productos de dulcería?',
    required: true,
    id: uniqid(),
    choices: [
      {id: 'si', name: 'Sí'},
      {id: 'no', name: 'No'},
    ],
    // placeholder: 'Selecciona una opción',
    validationFns: [validators.validateNotEmpty],
    validationMessage: 'Selecciona una opción.'
  },
  comments: {
    label: 'Comentarios',
    id: uniqid(),
    type: 'textarea',
  },
};

const fieldSets = [
  {
    label: 'Información de contacto',
    fields: ['name', 'email', 'company', 'phone',],
  },
  {
    label: 'Información del evento',
    fields: ['event_type', 'cinema', 'event_date', 'event_time', 'capacity', 'need_candy', 'comments'],
  },
];

class JobsForm extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);

    const state = {};

    Object.keys(fields).forEach(key => {
      state[key + '_value'] = '';
      state[key + '_valid'] = null;
    });

    this.state = state;
  }

  componentDidMount() {
    this.props.show();
  }

  render() {
    return this.props.isSubmitted ? this.renderSubmitMessage() : this.renderForm();
  }

  renderSubmitMessage() {
    return (
        <div className="row fade-in">
          <div className="col-12 col-lg-7">
            <p><i className="fal fa-envelope-open fa-4x text-primary"></i></p>
            <h1 className="h2">¡Tu consulta se ha enviado con éxito!</h1>
            <p className="text-secondary mb-5">
              Te responderemos a la brevedad.
            </p>
            <Button to="/" outline primary large>Volver al inicio</Button>
          </div>
        </div>
    );
  }

  renderForm() {
    return (
        <React.Fragment>
          {this.props.isProcessing && <Blocker/>}
          <form noValidate onSubmit={this.onSubmit} className={this.state.validated ? 'was-validated' : ''}>
            {fieldSets.map(fieldSet => (
                <fieldset key={fieldSet.label} className="mb-4">
                  <legend className="subtitle-2">{fieldSet.label}</legend>
                  <div className="form-row">
                    {fieldSet.fields.map(fieldName => {
                      const field = fields[fieldName];
                      const fieldValue = this.state[fieldName + '_value'];
                      const valid = this.state[fieldName + '_valid'];
                      const validationError = this.state[fieldName + '_validationError'];
                      const visible = this.state[fieldName + '_visible'] !== false;

                      if (!visible) {
                        return null;
                      }

                      return (
                          <div key={fieldName} className={field.half !== false ? 'col-md-6' : 'col-md-12'}>
                            <FieldRow label={field.label} placeholder={field.placeholder} type={field.type}
                                      onChange={(e) => this.onChange(fieldName, e.target.value)}
                                      onBlur={() => this.validate(fieldName)}
                                      value={fieldValue}
                                      valid={valid}
                                      validationError={validationError}
                                      inputProps={field.inputProps || {}}
                                      choices={field.choices || (field.getChoicesMethod && field.getChoicesMethod(this.props, this.state, fieldName, fieldValue))}
                                      id={field.id}
                                      required={field.required}
                            />
                          </div>
                      );
                    })}
                  </div>
                </fieldset>
            ))}

            <div className="text-right">
              <Button submit>Enviar</Button>
            </div>
          </form>
        </React.Fragment>
    );
  }

  onChange(prop, value) {
    const field = fields[prop];
    const {formatterFn} = field;
    if (formatterFn) {
      value = formatterFn(value);
    }

    if (value === '0' && (field.choices || field.getChoicesMethod)) {
      value = '';
    }

    this.setState((state) => ({[prop + '_value']: value}), () => this.validate(prop));
  }

  onSubmit(e) {
    e.preventDefault();

    const isValid = this.validateForm();

    if (!isValid) {
      this.setState(() => ({validated: true, valid: false}));
      return;
    }

    const params = {};

    Object.keys(fields).forEach(field => params[field] = this.state[field + '_value']);

    this.props.submit(params);
  }

  validateForm() {
    let validForm = true;

    Object.keys(fields).forEach(prop => {
      const {valid, validationError} = this.validateField(prop);
      this.setState((state) => ({
        [prop + '_valid']: valid,
        [prop + '_validationError']: validationError
      }));
      validForm &= valid !== false;
    });

    return validForm;
  }

  validate(prop) {
    const {valid, validationError} = this.validateField(prop);
    this.setState((state) => ({
      [prop + '_valid']: valid,
      [prop + '_validationError']: validationError
    }));
  }

  validateField(prop) {
    const value = this.state[prop + '_value'];
    const visible = this.state[prop + '_visible'] !== false;
    const {required, validationFns, validationMessage} = fields[prop];
    let valid = true;

    if (!visible || (!validationFns || !validationFns.length) || (!value && !required)) {
      return {valid: null, validationError: ''};
    }

    validationFns && validationFns.forEach(fn => {
      valid &= fn(value);
    });

    valid = !!valid;
    const validationError = valid ? '' : validationMessage;

    return {valid, validationError};
  }
}

const mapStateToProps = state => ({
  cinemas: getCinemas(state).sort(sortByName),
  isProcessing: isCorpSalesFormProcessing(state),
  isSubmitted: isCorpSalesFormSubmitted(state),
});

const mapDispatchToProps = dispatch => ({
  show: () => dispatch(corpSalesFormShow()),
  submit: data => dispatch(corpSalesFormSubmit(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobsForm);