import React from 'react';
import UserCinemaNav from '../UserCinemaNav';
import Button from '../Button';
import styled from 'styled-components';
import BackButton from '../common/BackButton';

const StyledButton = styled(Button)``;

const Buttons = styled.div`
  display: flex;
  & > ${StyledButton} {
    margin-bottom: 0.5em;
    margin-right: 0.5em;
    
    &:last-child { margin-right: 0; }
  }
`;

function NowPageSidebar(props) {
  return <React.Fragment>
{/*     <BackButton/> */}
    <div>
      <p className="h5 pl-5 pt-5 mb-0">Películas que comienzan en:</p>
      <Buttons className="px-5 py-3">
        {renderTimeButtons(props)}
      </Buttons>
    </div>
  </React.Fragment>;
}

function renderTimeButtons(props) {
  const result:any[] = [];

  for (let i = 30; i <= 180; i = i + 30) {
    result.push(<StyledButton
      to={props.baseUrl + '/minutos-' + i}
      primary={i === props.timeLimit}
      small
    >
        {i}'
    </StyledButton>);
  }

  return result;
}

export default NowPageSidebar;