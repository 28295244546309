import React from 'react';
import LinkButton from './LinkButton';
import BackButton from './common/BackButton';
import styled from 'styled-components';

const Image = styled.img`
  display: block;
  width: 100%;
`

class PromosPageContent extends React.Component {
  render() {
    if (this.props.isLoading) return '';

    return (
        <React.Fragment>
          <div className='mb-3'>
            <BackButton backTo="/" label='Promociones'/>
          </div>
          <div className="row">
            {this.renderPromos()}
          </div>
        </React.Fragment>
    );
  }

  renderPromos() {
    const {promos} = this.props;

    return promos.map(promo => (
        <div key={promo.id} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-gutter">
          <div>
            <LinkButton onClick={() => this.props.onSelect(promo)} href="#" className={'jsToggle ' + (this.props.selectedPromo && this.props.selectedPromo.id === promo.id ? 'is-active' : '')}>
              <Image src={promo.thumb_v2} alt={promo.title}/>
            </LinkButton>
          </div>
        </div>
    ));
  }
}

export default PromosPageContent;