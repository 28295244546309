import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const Block = styled.div`
  color: var(--primary);
  font-size: ${16/12.6}em;
  position: absolute;
  top: 0;
  right: 0;
`;

const formatTime = seconds => {
  const minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;

  return (minutes < 10 ? '0' : '') + minutes.toString()
    + ':'
    + (seconds < 10 ? '0' : '') + seconds.toString();
};

class Timer extends React.PureComponent {
  render() {
    return <Block>
        {formatTime(this.props.time)} <i className="fal fa-stopwatch ml-1"></i>
    </Block>;
  }
}

const mapStateToProps = state => ({
  time: state.checkoutData.timer.time,
});

export default connect(
    mapStateToProps
)(Timer);