import * as actions from '../../types';

export const userCouponsFetch = () => ({
    type: actions.USER_COUPONS_FETCH
});

export const userCouponsFetchSuccess = (data) => ({
    type: actions.USER_COUPONS_FETCH_SUCCESS,
    data
});

export const userCouponsFetchError = (error) => ({
    type: actions.USER_COUPONS_FETCH_ERROR,
    error
});

export const userCouponsActivate = (id:number) => ({
    type: actions.USER_COUPONS_ACTIVATE,
    id
});

export const userCouponsActivateSuccess = (data) => ({
    type: actions.USER_COUPONS_ACTIVATE_SUCCESS,
    data
});

export const userCouponsActivateError = (error) => ({
    type: actions.USER_COUPONS_ACTIVATE_ERROR,
    error
});
