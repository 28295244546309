import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AuthPinDialogClose, loginPinPost } from '../actions/creators/auth';
import { getAuthPinDialogContext, getAuthPinDialogMessage, getAuthPinDialogOriginalAction, getAuthPinDialogError } from '../reducers';
import BodyBlocker from './BodyBlocker';
import Button from './Button';
import CloseCross from './CloseCross';
import { paymentPinPost } from '../actions/creators/checkout/payment';
import { ieNipUpdatePost, iePinPost } from '../actions/creators/ie';

const Popup = styled.div`    
    position: fixed;
    background: var(--page-bg);
    width: 90%;
    z-index: 10000;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    border-radius: 10px;
    padding: 3em;
    text-align: center;
    max-width: 600px;
    z-index: 1071;

    body.theme-platinum & {
        background: var(--text-primary);
        color: #000;
    }
`;

const Input = styled.input`
    background: #eee;
    border: none;
    border-radius: 10px;
    font-family: var(--ff-title);
    font-size: 2rem;
    letter-spacing: 0.5em;
    margin: 0.5em 0 1.5em;
    max-width: 10em;
    outline: none;
    padding: 0.25em;
    text-align: center;
    text-indent: 0.5em;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    -moz-appearance: textfield;
`;

class AuthPinDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pin: ''
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(pin) {
        this.setState({pin: pin.substr(0,6)});
    }

    onSubmit(e) {
        e.preventDefault();

        switch (this.props.context) {
            case 'login':
                return this.props.loginPost(this.state.pin);
            case 'payment':
                return this.props.paymentPost(this.state.pin);
            case 'ie_link':
                return this.props.iePost(this.state.pin);
            case 'ie_nip_update':
                return this.props.ieNipUpdatePost(this.state.pin);
            default:
                return this.props.dispatch(Object.assign({auth_pin: this.state.pin}, this.props.originalAction));
        }
    }

    render() {
        const { error } = this.props;

        return <div style={{position:'fixed',zIndex:1070}}>
            <BodyBlocker overflow/>
            <Popup className='dyn-modal-dialog'>
                <CloseCross theme="dark" onClick={this.props.close}/>
                <p>{this.props.message}</p>
                <form onSubmit={this.onSubmit}>
                    <Input type="number" autoFocus maxLength="6" onChange={(e) => this.onChange(e.target.value)} value={this.state.pin}/>
                    {error && ('terms_link' in error) && <p className='small'>Al continuar aceptas los <a href={error.terms_link} target="_blank">Términos y Condiciones</a></p>}
                    <div><Button submit primary large block disabled={this.state.pin.length !== 6}>Continuar</Button></div>
                </form>
            </Popup>
        </div>;
    }
}

const mapStateToProps = state => ({
    context: getAuthPinDialogContext(state),
    error: getAuthPinDialogError(state),
    message: getAuthPinDialogMessage(state),
    originalAction: getAuthPinDialogOriginalAction(state),
});

const mapDispatchToProps = dispatch => ({
    close: () => dispatch(AuthPinDialogClose()),
    loginPost: (pin) => dispatch(loginPinPost(pin)),
    paymentPost: (pin) => dispatch(paymentPinPost(pin)),
    iePost: (pin) => dispatch(iePinPost(pin)),
    ieNipUpdatePost: (pin) => dispatch(ieNipUpdatePost(pin)),
    dispatch: action => dispatch(action),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthPinDialog);