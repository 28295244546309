import React from 'react';
import {connect} from 'react-redux';
import StepHeader from '../StepHeader';
import {ticketQuantityIncrease,ticketQuantityDecrease,promoCodePanelShow} from '../../../actions/creators/checkout/tickets';
import LinkButton from "../../LinkButton";
import PromoCodePanel from "../PromoCodePanel";
import TicketsBlock from './TicketsBlock';

class TicketsSelection extends React.Component {
  render() {
    if (this.props.promoCodesPanel.visible) {
      return <PromoCodePanel processing={this.props.promoCodesPanel.processing}/>;
    }

    const limitReached = this.props.ticketsSum >= this.props.session.tickets_limit;
    const benefits = this.props.tickets.filter(ticket => ticket.extra.recognition);
    const regular = this.props.tickets.filter(ticket => !ticket.extra.recognition);

    return (
        <React.Fragment>
          <StepHeader label="Selecciona tus boletos" timer={false} to="/"/>
          
          <TicketsBlock
            tickets={benefits}
            title="Tus beneficios"
            onIncrease={this.props.ticketIncrease}
            onDecrease={this.props.ticketDecrease}
            limitReached={limitReached}
          />
          
          <TicketsBlock
            tickets={regular}
            title={benefits.length > 0 ? 'Boletos generales' : ''}
            onIncrease={this.props.ticketIncrease}
            onDecrease={this.props.ticketDecrease}
            limitReached={limitReached}
          />

          <p className="mt-5 lead text-center">
            <LinkButton onClick={this.props.promoCode ? null : this.props.showPromoCodePanel} disabled={!!this.props.promoCode}>
              ¿Tienes un código promocional?
            </LinkButton>
          </p>

          {this.props.promoCode
            ? <p className='checkout-disclaimer text-center mb-5'>Solo es posible usar un código promocional/tarjeta Cinefan por transacción.<br/>Si deseas usar uno diferente al que ingresaste, puedes refrescar la página o <a href="javascript:location.reload()">hacer click aquí</a>.</p>
            : ''}
        </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  promoCodesPanel: state.checkoutData.ui.promoCodesPanel,
  promoCode: state.checkoutData.promoCode.code
});

const mapDispatchToProps = dispatch => ({
  ticketIncrease: ticket => dispatch(ticketQuantityIncrease(ticket)),
  ticketDecrease: ticket => dispatch(ticketQuantityDecrease(ticket)),
  showPromoCodePanel: () => dispatch(promoCodePanelShow(true)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TicketsSelection);