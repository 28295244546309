import React from "react";
import {connect} from 'react-redux';
import { getBootstrapConfig } from "../../reducers";

interface Props {
    links: Array<string>,
    theme: string
}

class SocialLinksButtons extends React.Component<Props> {
    render() {
        return <div className="d-flex justify-content-between">
            {[['facebook', 'facebook-f'], ['twitter', 'x-twitter'], ['instagram', 'instagram'], ['youtube', 'youtube']].map(
            (item) => (item[0] in this.props.links) && <a key={item[0]} className={`btn btn-outline-${this.props.theme} btn-icon-circle`} href={this.props.links[item[0]]} target="_blank" rel="noopener noreferrer"><i className={'fab fa-fw fa-' + item[1]}></i></a>
            )}
        </div>;
    }
}

const mapStateToProps = state => ({
    links: getBootstrapConfig(state).links
});

export default connect(
    mapStateToProps
)(SocialLinksButtons);