import React from "react";
import { connect } from 'react-redux';
import { getCheckoutSession, getCheckoutStep } from "../../reducers";
import styled from "styled-components";

const Container = styled.div`
    text-align: center;
`;

const Steps = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const Step = styled.li<{current: boolean}>`
    border: 0.5px solid var(--text-primary);
    border-radius: 100%;
    display: inline-block;
    height: 0.5rem;
    margin-right: 0.5em;
    width: 0.5rem;

    &:last-child { margin-right: 0; }

    ${props => props.current && 'background: var(--primary); border-color: var(--primary);'}
`;

interface Props {
    sidebar?: boolean,
    step: string,
    steps: Array<string>,
};

class StepIndicator extends React.Component<Props> {
    isStepCompleted(step: string): boolean {
        const steps = ['tickets', 'seats', 'candybar', 'payment-suggestions', 'payment'];

        return steps.indexOf(step) <= steps.indexOf(this.props.step);
    }

    render() {
        return <Container className={this.props.sidebar ? 'd-flex justify-content-between align-items-center border-bottom py-2' : ''}>
            <Steps>
                {this.props.steps.map(step => {
                    return (
                        <Step key={step} current={step === this.props.step}/>
                    );
                })}
            </Steps>
            <span>{this.props.steps.indexOf(this.props.step) + 1} - {this.props.steps.length}</span>
        </Container>;
    }
}

const mapStateToProps = state => {
    const session = getCheckoutSession(state);
    const steps = ['tickets'];

    if (session) {
        session.seatallocation && steps.push('seats');
        // cinema && cinema.candybar && steps.push('candybar');
    }
    
    if (state.checkoutData.points.length) {
        steps.push('payment-suggestions');
    }
    
    steps.push('payment');

    return {
        steps,
        step: getCheckoutStep(state)
    }
};

export default connect(
    mapStateToProps
)(StepIndicator);