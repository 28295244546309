import React from 'react';
import uniqid from 'uniqid';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import Button from './Button';
import FieldRow from './FieldRow';
import * as validators from '../utils/validation';
import Blocker from "./Blocker";
import { formatIeCode } from '../utils/strings';
import { asset } from '../utils/misc';
import { breakpoints } from '../utils/styles';
import BackButton from './common/BackButton';
import PageTitle from './common/PageTitle';

const getKeyValueChoices = props => Object.keys(props).map(key => ({id: key, name: props[key]}));
const getCinemasChoices = props => props.cinemas;
const getServicesChoices = props => getKeyValueChoices(props.config.contact.services);
const getTypesChoices = props => getKeyValueChoices(props.config.contact.types);

const FieldHintIcon = styled.em`
  cursor: pointer;
  opacity: 0.5;
`;

const FieldHintContent = styled.div`
  position: absolute;
  background: white;
  padding: 1.5em 2em 2em;
  border-radius: 0.5em;
  display: none;
  z-index: 100;
  right: 36px;
  top: -2em;
  box-shadow: 0 0 10px rgba(0,0,0,0.15);
  font-size: 0.75em;
  line-height: 1.35em;

  &:before {
    content: "";
    position: absolute;
    border-left: 10px solid white;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    right: -10px;
    top: 2em;
  }

  & > img {
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.15);
    width: 300px;
    max-width: 60vw;
  }
  
  & > p {
    color: #666;
    margin: 0 1em 1em;
    text-align: center;
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    right: auto;
    left: 36px;

    &:before {
      border-left: 0;
      border-right: 10px solid white;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      left: -10px;
      right: auto;
    }
  }

`;

const FieldHint = styled.span`
  position: absolute;
  right: 20px;
  bottom: 1.75em;

  &:hover ${FieldHintContent} {
    display: block;
  }
`;

const fields = {
  name: {label: 'Nombre', required: true, id: uniqid()},
  email: {label: 'Email', type: 'email', required: true, id: uniqid()},
  ie: {label: 'Cinemex Loop', id: uniqid(),formatterFn: formatIeCode},
  phone: {label: 'Teléfono', id: uniqid()},
  cellphone: {label: 'Celular', id: uniqid()},
  cinema: {label: 'Cine', placeholder: 'Selecciona un cine', getChoicesMethod: getCinemasChoices, id: uniqid()},
  service: {label: 'Servicio', placeholder: 'Selecciona un servicio', getChoicesMethod: getServicesChoices, id: uniqid()},
  type: {label: 'Tipo de mensaje', placeholder: 'Selecciona un tipo de mensaje', getChoicesMethod: getTypesChoices, id: uniqid()},
  message: {label: 'Mensaje', type: 'textarea', required: true, half: false, inputProps: {rows: 5}, id: uniqid()},
  terms: {label: <React.Fragment>He leído y acepto el <a href="/privacidadvisitantes" target="_blank">Aviso de Privacidad</a></React.Fragment>, type: 'checkbox', required: true, id: uniqid()},
};

const ieBin = '308';

class ContactPageContent extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);

    this.state = {
      name: {value: '', valid: null, validationFns: [validators.validateNotEmpty], validationMessage: 'Ingresa tu nombre.'},
      email: {value: '', valid: null, validationFns: [validators.validateEmail], validationMessage: 'Ingresa un email válido.'},
      ie: {value: ieBin, valid: null, validationFns: [validators.validateIeCode], validationMessage: 'Ingresa un número de miembro Cinemex Loop válido.'},
      phone: {value: '', valid: null},
      cellphone: {value: '', valid: null},
      cinema: {value: '', valid: null},
      service: {value: '', valid: null},
      type: {value: '', valid: null},
      message: {value: '', valid: null, validationFns: [validators.validateNotEmpty], validationMessage: 'Ingresa tu mensaje.'},
      terms: {value: false, valid: null, validationFns: [validators.validateTrue], validationMessage: 'Debes aceptar el Aviso de Privacidad para continuar.'},
    };
  }

  componentDidMount() {
    this.props.show();
  }

  render() {
    return <React.Fragment>
      <Helmet><title>Buzón Checkout - Cinemex</title></Helmet>
      {this.props.isSubmitted ? this.renderSubmitMessage() : this.renderForm()}
    </React.Fragment>
  }

  renderSubmitMessage() {
    return (
        <div className="row fade-in">
          <div className="col-12 col-lg-7">
            <p><i className="fal fa-envelope-open fa-4x text-primary"></i></p>
            <h1 className="h2">Muchas gracias por contactarte con nosotros.</h1>
            <p className="text-secondary mb-5">Ya hemos recibidos tus comentarios y consultas, te responderemos a la
              brevedad.</p>
            <Button to="/" outline primary large>Volver al inicio</Button>
          </div>
        </div>
    );
  }

  renderForm() {
    return (
        <React.Fragment>
          {this.props.isProcessing && <Blocker/>}

          <PageTitle backTo='/' title='Contáctate con nosotros'/>

          <form noValidate onSubmit={this.onSubmit} className={this.state.validated ? 'was-validated' : ''}>
            <div className="form-row">
              {Object.keys(fields).map(fieldName => {
                const field = fields[fieldName];
                const state = this.state[fieldName];

                return (
                    <div key={fieldName} className={field.half !== false ? 'col-md-6' : 'col-md-12'}>
                      <FieldRow label={field.label} placeholder={field.placeholder} type={field.type}
                                onChange={(e) => this.onChange(fieldName, e.target.value, e.target)}
                                onBlur={() => this.validate(fieldName)}
                                value={state.value}
                                valid={state.valid}
                                validationError={state.validationError}
                                inputProps={field.inputProps || {}}
                                choices={field.getChoicesMethod && field.getChoicesMethod(this.props)}
                                id={field.id}
                      />
                      {fieldName === 'ie' && <FieldHint>
                        <FieldHintIcon class="fal fa-question-circle"></FieldHintIcon>
                        <FieldHintContent>
                          <p>
                            Encuentra el número de miembro Cinemex Loop donde indica la imagen.
                          </p>
                          <img src={asset('/dist/images/ie-card-ref-num.png')} alt="" />
                        </FieldHintContent>
                      </FieldHint>}
                    </div>
                );
              })}
            </div>
            <div className="text-right">
              <Button submit>Enviar</Button>
            </div>
          </form>
        </React.Fragment>
    );
  }

  onChange(prop, value, element) {
    const field = fields[prop];
    const {formatterFn} = field;
    if (formatterFn) {
      value = formatterFn(value);
    }

    if (field.type === 'checkbox') {
      value = element.checked;
    }

    this.setState((state) => ({[prop]: Object.assign({}, state[prop], {value})}), () => this.validate(prop));
  }

  onSubmit(e) {
    e.preventDefault();

    const isValid = this.validateForm();

    if (!isValid) {
      this.setState(() => ({validated: true, valid: false}));
      return;
    }

    const params = {
      email: this.state.email.value,
      name: this.state.name.value,
      msg: this.state.message.value,
      cinema: this.state.cinema.value,
      ie: this.state.ie.value === ieBin ? '' : this.state.ie.value,
      phone: this.state.phone.value,
      cellphone: this.state.cellphone.value,
      service: this.state.service.value,
      type: this.state.type.value,
    };

    this.props.submit(params);
  }

  validateForm() {
    let requiredFields = ['name', 'email', 'message', 'terms'];

    let validForm = true;

    requiredFields.forEach(prop => {
      const {valid, validationError} = this.validateField(prop);
      this.setState((state) => ({[prop]: Object.assign({}, state[prop], {valid, validationError})}));
      validForm &= valid;
    });

    return validForm;
  }

  validate (prop) {
    const {valid, validationError} = this.validateField(prop);
    this.setState((state) => ({[prop]: Object.assign({}, state[prop], {valid, validationError})}));
  }

  validateField(prop) {
    const {value,validationFns,validationMessage} = this.state[prop];
    const {required} = fields[prop];
    let valid = true;

    if ((!validationFns || !validationFns.length) || (!value && !required) || (prop === 'ie' && value === ieBin)) {
      return {valid: null, validationError: ''};
    }

    validationFns && validationFns.forEach(fn => {
      valid &= fn(value);
    });

    valid = !!valid;
    const validationError = valid ? '' : validationMessage;

    return {valid, validationError};
  }
}

export default ContactPageContent;