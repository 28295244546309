import React from 'react';
import styled from 'styled-components';
import ShowtimeButton from './ShowtimeButton';
import { breakpoints } from '../utils/styles'
import ShowtimesGrid from './common/ShowtimesGrid';

const VersionShowtimesBlock = styled.div`
  margin-bottom: 1.5rem;
  
  &:last-child { margin-bottom: 0; }

  ${props => props.columns && `
    @media screen and (min-width: ${breakpoints.desktop}px) {
      display: grid;
      grid-template-columns: 2fr 5fr;
      column-gap: 1rem;
      border-bottom: 1px solid #ddd;
      padding-bottom: 1.5rem;
      margin-bottom: 2.5rem;

      &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
      }

      .h5 { font-size: 1.1rem; margin-top: 0.25em; }
    }
  `}
`;

class ShowtimesVersionsBlocks extends React.Component {
  render() {
    if (!this.props.movie) {
      return null;
    }

    return (
        <div className="container py-5">
          {this.props.versionsForDate.map(version => (
              <VersionShowtimesBlock key={version.id} columns={this.props.columns === true}>
                <h3 className="subtitle-2 mb-3">{version.label}</h3>
                <ShowtimesGrid movie={this.props.movie} version={version}/>
              </VersionShowtimesBlock>
          ))}
        </div>
    );
  }
}

export default ShowtimesVersionsBlocks;