import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BenefitsButton = styled.div`
    cursor: pointer;
    font-size: 0.8em;
    margin-top: 0.5em;
    margin-bottom: 2em;
    padding-left: 3em;
    position: relative;

    & i {
        font-size: 1.75em;
        left: 0;
        position: absolute;
        top: 48%;
        transform: translateY(-50%);
    }
    &, & > a {
        ${props => { return props.color === 'primary'
            ? 'color: var(--primary);'
            : `
                color: #555;
                body.theme-platinum & { color: var(--text-primary); }
            `
        }};
    }
    & > a { display: block; }
    & p {
        line-height: 1.5;
        margin: 0 !important;
    }
    & .small {
        color: ${props => { return props.color === 'primary' ? 'var(--primary)' : '#aaa'}};
    }
`;

const Subtitle = styled.p`
    /*color: #555;*/
    font-size: 1.15em;
    margin-bottom: 0 !important;
`;

function IconButton(props) {
    return <BenefitsButton color={props.color} onClick={props.onClick}>
        {props.to ? <Link to={props.to}>{renderContent(props)}</Link> : renderContent(props)}
    </BenefitsButton>;
}

function renderContent(props) {
    return <React.Fragment>
        {props.icon}
        <Subtitle>{props.title}</Subtitle>
        {props.small ? <p className="small">{props.small}</p> : ''}
    </React.Fragment>;
}

export default IconButton;