import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import CloseCross from "./CloseCross";
import BodyBlocker from "./BodyBlocker";
import SidebarPanel from "./SidebarPanel";
import {areaSelectorClose, areaSelectorSelectArea} from '../actions/creators/ui';
import {getAreaBillboardPermalink,getStateBillboardPermalink} from '../utils/urls';
import {sortByProperty} from '../utils/arrays';
import {getAreasById,getStates,getStatesById,getUiAreaSelectorOptions,isUiAreaSelectorOpen} from '../reducers';
import BackButton from './common/BackButton';

type Area = {
  id:number|string,
  name:string,
};

type StateEntity = {
  id:number,
  name:string,
  areas:Area[],
}

type Props = {
  areaId:number|string,
  areas:Area[],
  close:Function,
  history:any,
  states:StateEntity[],
  statesById:{[key:string]:StateEntity},
  onAreaSelect:Function,
  open:boolean,
  options:{
    allowFullState:boolean,
    context:string,
  },
};

type State = {
  areaId:number|string,
};

class AreaSelector extends React.Component<Props, State> {
  constructor(props:Props) {
    super(props);

    this.selectArea = this.selectArea.bind(this);

    this.state = {
      areaId: props.areaId || 0,
    };
  }

  getAreas():StateEntity[] {
    return this.props.states.map((state) => {
      const areas = Object.values(state.areas).sort(sortByProperty('name'));

      if (this.props.options.allowFullState !== false && areas.length > 1) {
        areas.splice(0, 0, {id: 'state-' + state.id, name: 'Todo ' + state.name});
      }

      return {
        id: state.id,
        name: state.name,
        areas,
      };
    });
  }

  selectArea(e) {
    let areaId = e.target.value;

    if (this.props.options.context !== 'landing') {
      const stateMatch = areaId.match(/^state-(\d+)$/);
      const currentPath = document.location.pathname;
      let newPath;

      if (stateMatch !== null) {
        areaId = parseInt(stateMatch[1], 10);
        const state = this.props.statesById[areaId];
        newPath = getStateBillboardPermalink(state);
      } else {
        areaId = parseInt(areaId, 10);
        const area = this.props.areas[areaId];
        newPath = getAreaBillboardPermalink(area);
        this.props.history.push(getAreaBillboardPermalink(area));
      }

      this.props.history.push(
          currentPath.replace(/^\/cartelera\/(zona|estado)-\d+\/[^\/]+/, newPath)
      );
    }

    this.props.onAreaSelect(areaId);
    this.props.close();
  }

  render() {
    if (!this.props.open) {
      return '';
    }

    return (
        <React.Fragment>
          <BodyBlocker />
          <SidebarPanel overBlocker={true}>
            <div className="container pad-y h-100">
              <h3 className="h5 mb-5">
                <BackButton label='Escoge una zona para ver su cartelera' onBackClick={(e) => this.props.close()}/>
              </h3>

              <form action="">
                <div className="form-group mb-5">
                  <label htmlFor="preferencesZona">Tu zona</label>
                  <select className="form-control" id="preferencesZona" onChange={this.selectArea} value={this.state.areaId}>
                    <option key="0" value="0">Selecciona tu zona</option>
                    {this.getAreas().map((state) => state.areas.length
                      ? <optgroup key={state.id} label={state.name}>{state.areas.map((area) => <option value={area.id} key={area.id}>{area.name}</option>)}</optgroup>
                      : ''
                    )}
                  </select>
                </div>
              </form>
            </div>
          </SidebarPanel>
        </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  areas: getAreasById(state),
  open: isUiAreaSelectorOpen(state),
  options: getUiAreaSelectorOptions(state),
  states: getStates(state),
  statesById: getStatesById(state),
});

const mapDispatchToProps = dispatch => ({
  onAreaSelect: areaId => dispatch(areaSelectorSelectArea(areaId)),
  close: () => dispatch(areaSelectorClose()),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AreaSelector));