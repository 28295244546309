import React from 'react';
import {connect} from 'react-redux';
import BillboardSidebarBase from './BillboardSidebarBase';
import UserAreaNav from '../UserAreaNav';
import {getAvailableFilters} from '../../utils/showtimes';
import {getCinemasByArea,getCinemasByState,getBootstrapFilters} from '../../reducers';

class BillboardSidebar extends React.Component {
  render() {
    return <BillboardSidebarBase
        heading={<UserAreaNav area={this.props.area}/>}
        cinemas={this.props.cinemas}
        baseUrl={this.props.baseUrl}
        {...this.props}
    />;
  }
}

const mapStateToProps = (state, props) => {
  const {urlParams, areaId, area, dataFull, isState} = props;

  const movies = dataFull ? dataFull.reduce((result, cinema) => result.concat(cinema.movies), [])
      : [];

  const versions = movies.reduce((result, movie) => result.concat(movie.versions), []);
  const availableFilters = getAvailableFilters(versions);
  const attributes = getBootstrapFilters(state);
  const filters = availableFilters.filter(filter => (filter in attributes) && !filter.match(/^lang_/));
  const languages = availableFilters.filter(filter => (filter in attributes) && filter.match(/^lang_/));

  return {
    area,
    attributes,
    cinemas: isState ? getCinemasByState(state, areaId) : getCinemasByArea(state, areaId),
    filters,
    languages,
    movies: uniqueMovies(movies),
    urlParams,
  };
};

const uniqueMovies = movies => {
  const result = [];
  const ids = [];

  movies.forEach(movie => {
    if (ids.indexOf(movie.id) < 0) {
      result.push(movie);
      ids.push(movie.id);
    }
  });

  return result;
};

export default connect(
    mapStateToProps
)(BillboardSidebar);