import React from 'react';
import DatesScroller from '../common/DatesScroller';
import styled, { css } from 'styled-components';
import { breakpoints } from '../../utils/styles';

const PaddedContainer = styled.div`
  padding: 1rem 0 1rem var(--pad-x);
`;

const customStyle = css`
  @media screen and (max-width: 991px) {
    body.theme-platinum & {
      &::-webkit-scrollbar-track { background: #3a080a; /* Color of the track */ }
      &::-webkit-scrollbar-thumb { background: #ac181c; /* Color of the thumb */ }
      &::-webkit-scrollbar-thumb:hover { background: #ac181c; /* Darker color on hover */ }
    }
  }
`;

const buttonCustomStyle = css`
  @media screen and (max-width: 991px) {
    body.theme-platinum & {
      --local-text-color: #FFFFFF !important;
      --local-border-color: #FFFFFF;
    }
  }
`;

class BillboardSidebarDates extends React.Component {
  constructor(props) {
    super(props);

    this.onDateSelect = this.onDateSelect.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return this.props.selectedDate !== nextProps.selectedDate
        || this.props.dates.join(',') !== nextProps.dates.join(',');
  }

  onDateSelect(date) {
    const dateYmd = date.replace(/-/g, '')
    
    this.props.onDateSelect(dateYmd);
  }

  render() {
    const {dates} = this.props;

    if (!dates || !dates.length) {
      return '';
    }

    return (
        <PaddedContainer>
          <DatesScroller dates={dates} selectedDate={this.props.selectedDate} onDateSelect={this.onDateSelect} customStyle={customStyle} buttonCustomStyle={buttonCustomStyle}/>
        </PaddedContainer>
    );
  }
}

export default BillboardSidebarDates;