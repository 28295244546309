import React from "react";
import Button from '../Button';
import FieldRow from '../FieldRow';
import FieldPasswordRow from '../FieldPasswordRow';
import * as validators from '../../utils/validation';
import { formatDateDmy } from "../../utils/dates";
import SignUpManualIEOptions from "./SignUpManualIEOptions";
import styled from "styled-components";
import SignUpTermsCheckbox from "./SignUpTermsCheckbox";
import { sortByName } from "../../utils/arrays";

const BenefitRow = styled.div`
    cursor: pointer;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;

const getCinemasChoices = props => props.cinemas.sort(sortByName);

const fields = {
    email: {
        label: "Email", required: true, validationFns: [validators.validateEmail], validationMessage: 'Ingresa un email válido.',
        type: 'email',
        disabled: true
    },
    firstName: {label: "Nombres", required: true, validationFns: [validators.validateNotEmpty], validationMessage: 'Ingresa tu nombre.'},
    lastName: {label: "Apellido paterno", required: true, validationFns: [validators.validateNotEmpty], validationMessage: 'Ingresa tu apellido paterno.'},
    motherLastName: {label: "Apellido materno", required: true, validationFns: [validators.validateNotEmpty], validationMessage: 'Ingresa tu apellido materno.'},
    phone: {
        label: "Teléfono",
        required: true,
        validationFns: [validators.validateNotEmpty, validators.validateNumber10Digits],
        validationMessage: 'Ingresa un número de teléfono de 10 dígitos.',
        type: 'number'
    },
    birthDate: {
        label: "Fecha de nacimiento",
        required: true,
        validationFns: [validators.validateBirthDate, validators.isAtLeast18],
        validationMessage: ['Ingresa tu fecha de nacimiento.', 'Debes ser mayor de 18 años.'],
        placeholder: 'dd/mm/yyyy',
        formatterFn: formatDateDmy,
    },
    gender: {
        label: "Género", required: true, validationFns: [validators.validateNotEmpty], validationMessage: 'Selecciona tu género.',
        placeholder: 'Selecciona tu género',
        choices: [
            {id: 'female', name: 'Femenino'},
            {id: 'male', name: 'Masculino'},
            {id: 'non-binary', name: 'No binarie'},
            {id: 'no-answer', name: 'Prefiero no decir'},
        ],
        expanded: true
    },
    cinema: {
        label: "Cine favorito",
        required: true,
        getChoicesMethod: getCinemasChoices,
        validationFns: [validators.validateNotEmpty],
        validationMessage: 'Selecciona tu cine favorito.',
        placeholder: 'Selecciona tu cine favorito',
    },
    password: {
        label: "Contraseña", required: true, validationFns: [validators.validatePassword], validationMessage: 'Ingresa una contraseña segura.',
        type: 'password-row',
    },
    passRepeat: {
        label: "Repetir contraseña", required: true, validationFns: [validators.validateNotEmpty], validationMessage: 'Repite la contraseña.',
        type: 'password'
    }
}

class SignUpManualUserInfo extends React.Component {

    constructor(props) {
        super(props);
    
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onIEOptionSelect = this.onIEOptionSelect.bind(this);
        this.onIEOptionsClose = this.onIEOptionsClose.bind(this);
        this.onIEOptionsOpen = this.onIEOptionsOpen.bind(this);
        this.onTermsChange = this.onTermsChange.bind(this);
    
        this.state = {
            email: {value: this.props.info.email, valid: null},
            firstName: {value: this.props.info.first_name || '', valid: null},
            lastName: {value: this.props.info.f_last_name || '', valid: null},
            motherLastName: {value: this.props.info.m_last_name || '', valid: null},
            phone: {value: this.props.info.phone || '', valid: null},
            birthDate: {value: this.props.info.birth_date || '', valid: null},
            gender: {value: this.props.info.gender || '', valid: null},
            cinema: {value: this.props.info.cinema_id ? this.props.info.cinema_id + '' : '', valid: null},
            password: {value: '', valid: null},
            passRepeat: {value: '', valid: null},
            sent: false,
            disagree: false,
            ieOption: {value: null, valid: null},
            ieOptionsOpen: false,
            terms: {value: false, valid: null}
        };

        this.handleApple();
    }

    componentDidUpdate() {
        this.handleApple();
    }

    handleApple() {
        if (!this.props.apple) return;

        delete fields.password;
        delete fields.passRepeat;
        fields.email.disabled = false;
    }
    
    onChange(prop, value) {
        const field = fields[prop];
        const { formatterFn } = field;
        if (formatterFn) {
            value = formatterFn(value);
        }
        this.setState((state) => ({[prop]: Object.assign({}, state[prop], {value})}), () => this.validate(prop));
    }

    onSubmit(e) {
        e.preventDefault();

        const isValid = this.validateForm();

        if (!isValid) {
            this.setState(() => ({validated: true, valid: false}));
            return;
        }
    
        const args = {
            email: this.state.email.value,
            first_name: this.state.firstName.value,
            f_last_name: this.state.lastName.value,
            m_last_name: this.state.motherLastName.value,
            phone: this.state.phone.value,
            birth_date: this.state.birthDate.value,
            gender: this.state.gender.value,
            cinema_id: this.state.cinema.value,
            password: this.state.password.value,
            token: this.props.info.token,
            sign_up_option: this.state.ieOption.value ? this.state.ieOption.value.id : null,
            terms: this.state.terms.value
        };

        this.props.onSubmit(args);
    }

    validateForm() {
        const requiredFields = Object.keys(fields).filter(field => fields[field].required);

        let validForm = true;
    
        requiredFields.forEach(prop => {
            const {valid, validationError} = this.validateField(prop);
            this.setState((state) => ({[prop]: Object.assign({}, state[prop], {valid, validationError})}));
            validForm = validForm && valid;
        });

        const ieOptions = this.props.info['sign-up-options'];
        if (ieOptions) {
            const ieOptionsValid = this.state.ieOption.value !== null;
            this.setState(state => ({ieOption: Object.assign({}, state.ieOption, {valid: ieOptionsValid})}));
            validForm = validForm && ieOptionsValid;
        }

        const termsValid = this.state.terms.value === true;
        this.setState(state => ({terms: Object.assign({}, state.terms, {valid: termsValid})}));
        validForm = validForm && termsValid;

        return validForm;
    }

    validate (prop) {
        const {valid, validationError} = this.validateField(prop);
        this.setState((state) => ({[prop]: Object.assign({}, state[prop], {valid, validationError})}));
    }

    validateField(prop) {
        const {value} = this.state[prop];
        const {validationFns,validationMessage} = fields[prop];
        const required = true;
        let msg;
        let valid = true;
    
        if ((!validationFns || !validationFns.length) || (!value && !required)) {
            return {valid: null, validationError: ''};
        }
    
        validationFns && validationFns.every((fn, i) => {
            const v = fn(value);
            valid = valid && v;
            if (!v && Array.isArray(validationMessage)) {
                msg = validationMessage[i];
                return false;
            }
            return true;
        });
      
        if (valid && prop === 'passRepeat') {
            valid = this.state.password.value === this.state.passRepeat.value;
            msg = valid ? '' : 'La contraseña no coincide.'
        }

        const validationError = valid ? '' : (msg || validationMessage);
    
        return {valid, validationError};
    }

    onIEOptionSelect(option) {
        this.setState(state => ({
            ieOption: Object.assign({}, {value: option, valid: true}),
            ieOptionsOpen: false,
        }));
    }

    onIEOptionsClose() {
        this.setState(state => ({ieOptionsOpen: false}));
    }

    onIEOptionsOpen() {
        this.setState(state => ({ieOptionsOpen: true}));
    }

    getIeOptionName() {
        if (!this.state.ieOption.value) return '';
        return this.state.ieOption.value.name;
    }

    onTermsChange(checked) {
        this.setState(state => ({terms: {value: checked, valid: checked === true}}));
    }

    render() {
        const ieOptions = this.props.info['sign-up-options'];
        const hasIeOptions = ieOptions && ieOptions.length;

        return <React.Fragment>
            <div className="row">
                <form noValidate onSubmit={this.onSubmit} className={this.state.validated ? 'was-validated' : ''} style={{width:'100%'}}>
                    {Object.keys(fields).map(fieldName => {
                        const field = fields[fieldName];
                        const state = this.state[fieldName];
                        const Element = field.type === 'password-row' ? FieldPasswordRow : FieldRow

                        return (
                            <div key={fieldName} className='col-md-12'>
                                <Element label={field.label} placeholder={field.placeholder} type={field.type === 'password-row' ? 'password' : field.type}
                                        onChange={(e) => this.onChange(fieldName, e.target.value, e.target)}
                                        onBlur={() => this.validate(fieldName)}
                                        onClick={field.onClick}
                                        value={state.value}
                                        valid={state.valid}
                                        validationError={state.validationError}
                                        inputProps={field.inputProps || {}}
                                        choices={field.choices || (field.getChoicesMethod && field.getChoicesMethod(this.props, this.state, fieldName, state.value))}
                                        id={field.id}
                                        required={field.required}
                                        expanded={field.expanded}
                                        disabled={field.disabled}
                                />
                            </div>
                        );
                    })}
                    {hasIeOptions ? <BenefitRow onClick={this.onIEOptionsOpen} className="col-md-12">
                        <FieldRow required label='Beneficio de bienvenida' placeholder='Selecciona tu beneficio de bienvenida de Cinemex Loop'
                                value={this.getIeOptionName()}
                                valid={this.state.ieOption.valid} disabled
                                validationError={this.state.ieOption.valid === false ? 'Selecciona una opción' : ''}
                                icon='fal fa-chevron-right'
                                />
                    </BenefitRow> : ''}
                    <div className="col-md-12">
                        <SignUpTermsCheckbox checked={this.state.terms.value} onChange={this.onTermsChange}
                            valid={this.state.terms.value === true}
                            validationError={this.state.terms.value !== true ? 'Debes aceptar los términos y condiciones' : ''}
                            />
                    </div>
                    <div className="col-12 mt-5">
                        <Button className="mb-2" submit primary block>Registrarme</Button>
                    </div>
                </form>
            </div>
            {hasIeOptions ? <SignUpManualIEOptions options={ieOptions} onIEOptionSelect={this.onIEOptionSelect} close={this.onIEOptionsClose} visible={this.state.ieOptionsOpen} /> : ''}
        </React.Fragment>;
    }

}

export default SignUpManualUserInfo;