import React from 'react';
import UserCinemaNavLinks from './UserCinemaNavLinks';

class UserAreaNav extends React.PureComponent {
  render() {
    return (
        <nav className={'container py-3 ' + (this.props.showInMobile === false ? 'd-none d-md-block' : '')}>
          <ul className="nav flex-nowrap justify-content-between align-items-center c-nav">
            <UserCinemaNavLinks areaSelector={true} cinemaSelector={false} area={this.props.area}/>
          </ul>
        </nav>
    );
  }
}

export default UserAreaNav;