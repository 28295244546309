import React from 'react';
import Sticky from 'react-stickynode';
import styled from 'styled-components';

const HeaderWrapper = styled.div`
  margin-left: calc(var(--pad-x) * -1);
  margin-right: calc(var(--pad-x) * -1);

  body.theme-platinum & {
    background: var(--primary-b);
    color: var(--text-primary);
  }
`;

const ShadowClipper = styled.div`
  overflow: hidden;
`;

const Header = styled.header`
  & .cinema-head-info { margin-bottom: 1em; }
  & h1,
  & hgroup {
    padding-left: var(--pad-x);
    padding-right: var(--pad-x);
  }
  
  ${props => props.sticky === Sticky.STATUS_FIXED && `
    width: 100%;
    background: var(--page-bg);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    margin-bottom: var(--pad-x);

    & .cinema-head-info { display: none; }

    body.theme-platinum & {
      background: var(--primary-b);
      box-shadow: 0 0 10px rgba(0,0,0,0.75);
      margin-bottom: 0;
    }
  `}
`;

class BillboardStickyHeader extends React.Component {
    render() {
        return (
            <HeaderWrapper>
                <Sticky top="#header" bottomBoundary="#the-body" innerZ="1031">{status => (
                    <ShadowClipper>
                        <Header className={this.props.className} sticky={status.status}>
                            {this.props.children}
                        </Header>
                    </ShadowClipper>
                )}</Sticky>
            </HeaderWrapper>
        )
    }
}

export default BillboardStickyHeader;