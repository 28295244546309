import React from "react";
import CouponsBlockCoupons from "./CouponsBlockCoupons";
import CouponWindow from "./CouponWindow";
import { CouponData } from "./interfaces";
import styled from "styled-components";
import NoContentMessage from "../../common/NoContentMessage";

const Count = styled.span`
    font-weight: 700;
`;

const Copy = styled.p`
    font-family: var(--ff-title);
    font-size: ${(25/12.6).toFixed(2)}rem;
    text-transform: uppercase;

    ${Count} { color: ${props => props.color }; }

    body.theme-market & {
        font-size: ${(16/12.6).toFixed(2)}rem;
        font-weight: 700;
    }

    body.theme-platinum & {
        font-size: ${(18/12.6).toFixed(2)}rem;
        text-transform: none;
    }
`;

function MyCoupons({coupons, onClose, setCoupon, availabilityColor}) {
    return <React.Fragment>
        <CouponWindow title="Mis Cupones" onClose={onClose}>
            <div className="pad-block pt-0">
                <CountMessage coupons={coupons} color={availabilityColor}/>
                <CouponsBlockCoupons coupons={coupons} onCouponClick={setCoupon} grid/>
            </div>
        </CouponWindow>
    </React.Fragment>;
}

function CountMessage({coupons, color}:{coupons: Array<CouponData>, color: string}) {
    const n = coupons.length;

    if (!n) return <NoContentMessage
        bigTitle="¡Ayyy!"
        icon="fal fa-eyes">
            <p style={{fontSize:'1.25em'}}>Parece que aún no has activado ningún cupón.</p>
    </NoContentMessage>;

    return <Copy color={color}>{1 === n
        ? <React.Fragment>Tienes <Count>un</Count> cupón activo.</React.Fragment>
        : <React.Fragment>Tienes <Count>{coupons.length}</Count> cupones activos.</React.Fragment>}</Copy>;
    
}

export default MyCoupons;