export const breakpoints = {
  desktopLarge: 1441,
  desktop: 992,
  tablet: 768,
  phone: 576,
};

export const zIndexes = {
  header: 1030,
  sidebar: 1035,
  dialogs: 1200,
};