import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import TwoColumnLayout from '../TwoColumnLayout';
import NowPageContent from './NowPageContent';
import NowPageSidebar from './NowPageSidebar';
import {nowFetch} from '../../actions/creators';
import {getCinema, getNowSessions} from '../../reducers';
import {getCinemaNowPermalink} from '../../utils/urls';

class NowPage extends React.Component {
  componentDidMount() {
    if (this.props.cinemaId) {
      this.fetch();
    }
  }

  componentDidUpdate(prevProps) {
    if ((this.props.selectedCinema && !prevProps.selectedCinema)
        || (this.props.selectedCinema.id !== prevProps.selectedCinema.id)) {
      this.props.history.push(getCinemaNowPermalink(this.props.selectedCinema));
    }

    if (this.props.cinemaId !== prevProps.cinemaId || this.props.timeLimit !== prevProps.timeLimit) {
      this.fetch();
    }
  }

  fetch() {
    this.props.fetch(this.props.cinemaId, this.props.timeLimit);
  }

  render() {
    return (
        <TwoColumnLayout
            MainContent={NowPageContent}
            noSidePadding={true}
            SidebarContent={NowPageSidebar}
            sidebarMobileToggle
            {...this.props}
        />
    );
  }
}

const mapStateToProps = (state, props) => {
  const cinemaId = props.match.params.id;
  const selectedCinema = getCinema(state, cinemaId);
  return {
    cinemaId,
    selectedCinema,
    sessions: getNowSessions(state, cinemaId),
    timeLimit: props.match.params.time_limit ? parseInt(props.match.params.time_limit.split('-')[1], 10) : 60,
    baseUrl: getCinemaNowPermalink(selectedCinema),
  };
};

const mapDispatchToProps = dispatch => ({
  fetch: (cinemaId, timeLimit) => dispatch(nowFetch(cinemaId, timeLimit)),
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(NowPage));