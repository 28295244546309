import React from 'react';
import {connect} from 'react-redux';
import Recaptcha from 'react-google-invisible-recaptcha';
import SidebarPanel from "../SidebarPanel";
import Button from '../Button';
import FieldRow from '../FieldRow';
import CloseCross from "../CloseCross";
import BodyBlocker from "../BodyBlocker";
import MasterpassButton from './MasterpassButton';
import PayPalButton from './PayPalButton';
import {displayNoTermsCheckedDialog, paymentMethodCancel, prevStep} from '../../actions/creators/checkout';
import {paymentMethods, payPalOneClickPayment, paymentFormSubmit} from '../../actions/creators/checkout/payment';
import {formatCc, formatCvv, getBrand} from '../../utils/creditCards';
import * as validators from '../../utils/validation';
import {getCheckoutMasterpassData,getCheckoutPaymentMethod,getCheckoutPaymentPostError,getUserInfo,isCheckoutCCRequired,isCheckoutIeNipRequired, isCheckoutTermsChecked, isPayPalSingleClickAvailable, isOrderCostZero} from '../../reducers';
import PayPalSingleClickButton from './PayPalSingleClickButton';
import TermsCheckbox from './TermsCheckbox';
import BackButton from '../common/BackButton';
import StripeElements from './Payment/StripeElements';
import CommonFields from './Payment/CommonFields';

const formatExpiry = v => {
  v = v.replace(/[^\d]/g, '');

  return v.substr(0, 2) + (v.length > 2 ? '/' + v.substr(2, 2) : '');
};

export const formatNip = v => v.replace(/[^\d]/g, '').substr(0, 4);

const getRequiredFieldsByPaymentMethod = method => {
  switch (method) {
    case 'moviecard': return ['movieCard', 'movieCardNip'];
    case 'credit-card': return ['cc', 'csc', 'expire'];
    case 'masterpass': return ['csc'];
    default: return [];
  }
};

class PaymentSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onResolve = this.onResolve.bind(this);
    this.validate = this.validate.bind(this);
    this.validateField = this.validateField.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.renderForm = this.renderForm.bind(this);

    this.state = {
      name: {value: '', valid: null, validationFns: [validators.validateNotEmpty], validationMessage: 'Ingresa tu nombre.'},
      email: {value: '', valid: null, validationFns: [validators.validateEmail], validationMessage: 'Ingresa un email válido.'},
      cc: {value: '', valid: null, validationFns: [validators.validateCc], validationMessage: 'Ingresa un número de tarjeta válido.'},
      csc: {value: '', valid: null, validationFns: [validators.validateCvv], validationMessage: 'Ingresa un código válido.'},
      expire: {value: '', valid: null, validationFns: [validators.validateExpiry], validationMessage: 'Ingresa una fecha válida, ingresando dos dígitos par el mes y dos dígitos para el año.'},
      nip: {value: '', valid: null, validationFns: [validators.validateIeNip], validationMessage: 'Ingresa un NIP válido.'},
      movieCard: {value: '', valid: null, validationFns: [validators.validateMovieCard], validationMessage: 'Ingresa un número de tarjeta válido.'},
      movieCardNip: {value: '', valid: null, validationFns: [validators.validateMovieCardNip], validationMessage: 'Ingresa un NIP válido.'},
    };
  }

  componentWillMount() {
    this.setState((state) => ({
      name: Object.assign({}, state.name, {value: this.props.userInfo ? (this.props.userInfo.first_name + ' ' + this.props.userInfo.last_name) : ''}),
      email: Object.assign({}, state.email, {value: this.props.userInfo ? this.props.userInfo.email : ''}),
    }));
  }

  componentDidUpdate(prevProps) {
    const hadInfo = !!prevProps.userInfo;
    const hasInfo = !!this.props.userInfo;
    const hasUserChanged = hasInfo && hadInfo && this.props.userInfo.id !== prevProps.userInfo.id;
    const shouldErase = hadInfo && !hasInfo;
    const shouldUpdate = (hasInfo && !hadInfo) || (hasUserChanged);

    if (shouldErase) {
      this.setState((state) => ({
        name: Object.assign({}, state.name, {value: ''}),
        email: Object.assign({}, state.email, {value: ''}),
      }));
    } else if (shouldUpdate) {
      this.setState((state) => ({
        name: Object.assign({}, state.name, {value: this.props.userInfo.first_name + ' ' + this.props.userInfo.last_name}),
        email: Object.assign({}, state.email, {value: this.props.userInfo.email}),
      }));
    }

    if (this.props.postError === true && prevProps.postError !== this.props.postError && this.recaptcha) {
      this.recaptcha.reset();
    }
  }

  onChange(prop, value) {
    this.setState((state) => ({[prop]: Object.assign({}, state[prop], {value: this.formatFieldValue(prop, value)})}), () => this.validate(prop));
  }

  formatFieldValue(field, value) {
    switch (field) {
      case 'cc': return formatCc(value);
      case 'csc': return formatCvv(value);
      case 'nip': return formatNip(value);
      case 'movieCard': return formatCc(value);
      case 'movieCardNip': return formatNip(value);
      case 'expire': return formatExpiry(value);
      default: return value;
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const isValid = this.validateForm();

    if (!isValid) {
      this.setState(() => ({validated: true, valid: false}));
      return;
    }

    if (!this.props.termsChecked) {
      this.props.displayNoTermsCheckedDialog();
      return;
    }

    this.recaptcha.execute();
  }

  onResolve() {
    this.props.paymentFormSubmit(this.getPostParams());
  }

  validateForm() {
    let requiredFields = [];

    if (this.props.method !== paymentMethods.masterPass) {
      requiredFields.push('name', 'email');
    }

    if (this.props.ccRequired) {
      requiredFields = requiredFields.concat(getRequiredFieldsByPaymentMethod(this.props.method));
    }

    if (this.props.nipRequired && !this.props.ccRequired) {
      requiredFields.push('nip');
    }

    let validForm = true;

    requiredFields.forEach(prop => {
      const {valid, validationError} = this.validateField(prop);
      this.setState((state) => ({[prop]: Object.assign({}, state[prop], {valid, validationError})}));
      validForm &= valid;
    });

    return validForm;
  }

  usingSmart() {
    return this.props.paymentGateway === 'smart';
  }

  getPostParams() {
    const expire = this.state.expire.value.split('/');
    const params = {
      name: this.state.name.value,
      email: this.state.email.value,
      cc: (this.state.cc.value || '').replace(/[^\d]/g, ''),
      csc: this.state.csc.value,
      nip: this.state.nip.value,
      'expire-month': '00',
      'expire-year': '2999',
      captcha: this.recaptcha.getResponse(),
    };

    if (this.props.method === paymentMethods.creditCard) {
      params['expire-month'] = expire[0];
      params['expire-year'] = '20' + expire[1];
    } else if (this.props.method === paymentMethods.movieCard) {
      params.cc = this.state.movieCard.value;
      params.moviecard_nip = this.state.movieCardNip.value;
    } else if (this.props.method === paymentMethods.masterPass) {
      params.name = this.props.masterpassData.cardholder;
      params.email = this.props.masterpassData.contact_email;
      params.cc = this.props.masterpassData.credit_card;
      params.csc = '000';
      params.masterpass = {
        key: this.props.masterpassData.key,
        token: this.props.masterpassData.token,
      }
    } else if (this.props.method === paymentMethods.payPal) {
      // params.payment_method = 'paypal';
      // params.paypal = {
      //   payment_id: this.props.paypalData.paymentID,
      //   payer_id: this.props.paypalData.payerID
      // }
    }

    return params;
  }

  validate (prop) {
    const {valid, validationError} = this.validateField(prop);
    this.setState((state) => ({[prop]: Object.assign({}, state[prop], {valid, validationError})}));
  }

  validateField(prop) {
    const {value,validationFns,validationMessage} = this.state[prop];
    let valid = true;

    validationFns && validationFns.forEach(fn => {
      valid &= fn(value);
    });

    valid = !!valid;
    const validationError = valid ? '' : validationMessage;

    return {valid, validationError};
  }

  renderForm() {
    if (this.props.ccRequired && this.props.method === 'credit-card' && this.props.paymentGateway === 'stripe-elements') {
      return <StripeElements/>;
    }

    return (
        <React.Fragment>
          <form noValidate onSubmit={this.onSubmit} className={this.state.validated ? 'was-validated' : ''}>
            <div className="container pad-y">
              {this.props.method === 'masterpass'
                ? this.renderMasterpassFormFields()
                : <CommonFields
                      onChange={this.onChange}
                      validate={this.validate}
                      email={this.state.email.value}
                      emailValid={this.state.email.valid}
                      emailError={this.state.email.validationError}
                      name={this.state.name.value}
                      nameValid={this.state.name.valid}
                      nameError={this.state.name.validationError}
                  />
              }

              {this.props.ccRequired && (
                  (this.props.method === 'credit-card' && this.renderCreditCardFormFields())
                  ||
                  (this.props.method === 'moviecard' && this.renderMovieCardFormFields())
              )}

              {this.props.nipRequired && !this.props.ccRequired && this.renderIeFormFields()}
              {(this.props.nipRequired && !this.props.ccRequired) || this.props.isOrderCostZero ? <TermsCheckbox/> : null}
            </div>

            <div className="container pt-3">
              {this.props.isOrderCostZero ? <p className="mb-3" style={{fontSize:"0.75em",lineHeight:"1em",opacity:0.75,color:'var(--primary)'}}>Esta transacción no tiene costo, y tus datos son registrados para enviarte la información de los boletos.</p> : ''}
              <Button submit primary large block>Finalizar</Button>
            </div>
            
          </form>
        </React.Fragment>
    );
  }

  renderMasterpassFormFields() {
    const data = this.props.masterpassData;

    return (
        <React.Fragment>
          <FieldRow type="email" label="Email"
                    value={data.contact_email}
          />
          <FieldRow label="Nombre y apellido"
                    value={data.cardholder}
          />
          <FieldRow label="Número de la tarjeta"
                      className={'card-' + data.card_brand}
                      value={data.credit_card}
          />
          <div className="row">
            <div className="col-6">
              <FieldRow label="CVV"
                        onChange={(e) => this.onChange('csc', e.target.value)}
                        onBlur={() => this.validate('csc')}
                        value={formatCvv(this.state.csc.value)}
                        valid={this.state.csc.valid}
                        validationError={this.state.csc.validationError}
              />
            </div>
          </div>
        </React.Fragment>
    );
  }

  renderCreditCardFormFields() {
    return (
        <React.Fragment>
          <FieldRow label="Número de la tarjeta"
                    className={'card-' + getBrand(this.state.cc.value)}
                    onChange={(e) => this.onChange('cc', e.target.value)}
                    onBlur={() => this.validate('cc')}
                    value={formatCc(this.state.cc.value)}
                    valid={this.state.cc.valid}
                    validationError={this.state.cc.validationError}
          />
          <div className="row">
            <div className="col-6">
              <FieldRow label="Expira" placeholder="mm/aa"
                        onChange={(e) => this.onChange('expire', e.target.value)}
                        onBlur={() => this.validate('expire')}
                        value={formatExpiry(this.state.expire.value)}
                        valid={this.state.expire.valid}
                        validationError={this.state.expire.validationError}
              />
            </div>
            <div className="col-6">
              <FieldRow label="CVV"
                        onChange={(e) => this.onChange('csc', e.target.value)}
                        onBlur={() => this.validate('csc')}
                        value={formatCvv(this.state.csc.value)}
                        valid={this.state.csc.valid}
                        validationError={this.state.csc.validationError}
              />
            </div>
          </div>
        </React.Fragment>
    );
  }
  
  renderMovieCardFormFields() {
    return (
        <React.Fragment>
          <FieldRow label="Número de MovieCard"
                    className="card-moviecard"
                    onChange={(e) => this.onChange('movieCard', e.target.value)}
                    onBlur={() => this.validate('movieCard')}
                    value={formatCc(this.state.movieCard.value)}
                    valid={this.state.movieCard.valid}
                    validationError={this.state.movieCard.validationError}
          />
          <div className="row">
            <div className="col-6">
              <FieldRow label="NIP MovieCard"
                        onChange={(e) => this.onChange('movieCardNip', e.target.value)}
                        onBlur={() => this.validate('movieCardNip')}
                        value={formatNip(this.state.movieCardNip.value)}
                        valid={this.state.movieCardNip.valid}
                        validationError={this.state.movieCardNip.validationError}
              />
            </div>
          </div>
        </React.Fragment>
    );
  }

  renderIeFormFields() {
    return (
        <React.Fragment>
          <div className="row">
            <div className="col-6">
              <FieldRow label="NIP Cinemex Loop"
                        onChange={(e) => this.onChange('nip', e.target.value)}
                        onBlur={() => this.validate('nip')}
                        value={formatNip(this.state.nip.value)}
                        valid={this.state.nip.valid}
                        validationError={this.state.nip.validationError}
              />
            </div>
          </div>
        </React.Fragment>
    );
  }

  renderContent() {
    if (this.props.method === paymentMethods.masterPass && !this.props.masterpassData) {
      return <MasterpassButton/>;
    }

    if (this.props.method === paymentMethods.payPal && !this.props.paypalData) {
      if (this.props.payPalSingleClick) {
        return <PayPalSingleClickButton onPayClick={this.props.onPayPalOneClickClick}/>
      } else {
        return <PayPalButton/>;
      }
    }

    return this.renderForm();
  }

  render() {
    return (
        <React.Fragment>
          <BodyBlocker/>

          <SidebarPanel overBlocker={true} noScroll={true}>
            <div className="container pt-5">
              <BackButton label='' onBackClick={this.props.ccRequired ? this.props.onCancel : this.props.prevStep}/>
            </div>
            {this.renderContent()}
            <Recaptcha
                ref={ ref => this.recaptcha = ref }
                locale="es"
                sitekey={window.localConfig.recaptcha.key}
                onResolved={ this.onResolve } />
          </SidebarPanel>
        </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
    postError: getCheckoutPaymentPostError(state),
    method: getCheckoutPaymentMethod(state),
    ccRequired: isCheckoutCCRequired(state),
    payPalSingleClick: isPayPalSingleClickAvailable(state),
    nipRequired: isCheckoutIeNipRequired(state),
    masterpassData: getCheckoutMasterpassData(state),
    userInfo: getUserInfo(state),
    paymentGateway: state.checkoutData.paymentGateway,
    paymentMethod: props.paymentMethod,
    termsChecked: isCheckoutTermsChecked(state),
    isOrderCostZero: isOrderCostZero(state),
});

const mapDispatchToProps = dispatch => ({
  displayNoTermsCheckedDialog: () => dispatch(displayNoTermsCheckedDialog()),
  onCancel: () => dispatch(paymentMethodCancel()),
  prevStep: () => dispatch(prevStep()),
  paymentFormSubmit: params => dispatch(paymentFormSubmit(params)),
  onPayPalOneClickClick: () => dispatch(payPalOneClickPayment())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentSidebar);