import React from "react";
import styled from "styled-components";

const Button = styled.button<{color: string, count: number}>`
    background: var(--primary);
    border: 0;
    border-radius: 0.5rem;
    color: #fff;
    cursor: pointer;
    font-size: 0.9em;
    line-height: 1.5em;
    outline: none;
    padding: 0.25em 0.75em;
    position: absolute;
    top: 0;
    right: 1rem;

    &:focus { outline: none; }

    & > i {
        font-size: 1.5em;
        margin-right: 0.25em;
        vertical-align: middle;
    }

    ${props => Number(props.count) > 0 && `
        &:after {
            background: ${props.color};
            border-radius: 1em;
            content: "";
            height: 0.65em;
            position: absolute;
            right: -0.25em;
            top: -0.25em;
            width: 0.65em;
            z-index: 1;
        }
    `}
`;

function ActiveCouponsButton({onClick, availabilityColor, count}) {
    return <Button onClick={onClick} color={availabilityColor} count={count}>
        <i className="fa-light fa-tag"></i>
        {count}
    </Button>;
}

export default ActiveCouponsButton;