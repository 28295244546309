import styled from "styled-components";

const FilterLabel = styled.span`
  display: inline-block;
  font-size: ${(11/12.6).toFixed(2)}rem;
  text-transform: uppercase;
  position: relative;
  margin-top: 0.25em;
`;

export default FilterLabel;