import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../../utils/styles";
import { CouponCategory } from "./interfaces";

interface ItemProps {
    color: string,
    selected:boolean,
};

const Item = styled.li<ItemProps>`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex: 0 0 20%;
    flex-direction: column;
    justify-content: center;
    margin-right: 1em;
    padding: 0.75rem 1rem;
    text-align: center;
    vertical-align: middle;

    ${props => props.selected
        ? `
            background: ${props.color};
            border: 1px solid ${props.color};
            box-shadow: 0px 2px 4px 0px #00000040;
            color: #FFFFFF;

            body.theme-platinum & {
                background: var(--primary);
                border-color: var(--primary);
                font-weight: 700;
            }
            `
        : 'border: 1px solid var(--text-primary);'}

    @media screen and (min-width: ${breakpoints.desktop}px) {
        flex: none;
        font-size: 0.7em;
        width: 6rem;
    }
`;

const Icon = styled.img`
    display: block;
    height: auto;
    margin: 0 auto;
    width: 4rem;
`;

const Label = styled.span`
    display: block;
    font-family: var(--ff-subtitle);
    font-size: ${(11/12.6).toFixed(2)}rem;
    hyphens: auto;
    line-height: 1;
    margin-top: 1em;
    text-transform: uppercase;
`;

const List = styled.ul`
    display: flex;
    gap: 0.25em;
    list-style-type: none;
    padding: 0;
    margin: 0 !important;
`;

const Container = styled.div`
    margin-bottom: 2em;
    overflow-x: auto;
    padding-bottom: 1em;
    width: 100%;
`;

interface Props {
    category?: string,
    categories: Array<CouponCategory>,
    setCategory: Function,
};

function addHyphens(str: string): string {
    let firstLen: number;

    switch (str.toLowerCase()) {
        case 'coleccionables':
            firstLen = 8;
            break;
        case 'entretenimiento':
            firstLen = 7;
            break;
        default:
            return str;
    }

    return str.substring(0, firstLen) + '\u00AD' + str.substring(firstLen);
}

function CouponsCategorySelector({categories, category, setCategory}: Props) {
    if (!categories || categories.length === 0) return null;

    return <Container>
        <List>
            {categories.map(cat => {
                const selected = cat.id === category;
                
                return <Item
                    key={cat.id}
                    color={cat.color}
                    onClick={() => setCategory(cat.id)}
                    selected={selected}
                >
                    <div>
                        <Icon src={selected ? cat.icon_selected : cat.icon}/>
                        <Label>{addHyphens(cat.name)}</Label>
                    </div>
                </Item>;
            })}
        </List>
    </Container>;
}

export default CouponsCategorySelector;