import React from 'react';
import {connect} from 'react-redux';
import MDSpinner from "react-md-spinner";
import {Helmet} from 'react-helmet';
import {getUserId,isUserPurchasesLoading,getUserPurchases} from '../../reducers';
import {purchaseHistoryFetch} from '../../actions/creators/user';
import {getPaymentString} from '../../utils/checkout';
import {parseSessionIsoDateForDisplay} from '../../utils/dates';
import Button from '../Button';
import NoContentMessage from '../common/NoContentMessage';
import RequireAuthMessage from './RequireAuthMessage';

class UserPurchases extends React.Component {
  componentDidMount() {
    this.props.purchaseHistoryFetch({future: this.props.future});
  }

  componentDidUpdate(prevProps) {
    if (this.props.future !== prevProps.future || this.props.userId !== prevProps.userId) {
      this.props.purchaseHistoryFetch({future: this.props.future});
    }
  }

  render() {
    if (this.props.isLoading) {
      return <div className="text-center mb-5"><MDSpinner singleColor="var(--primary)"/></div>;
    }

    return this.renderContent();
  }

  renderContent() {
    if (!this.props.userId) return null;
    
    return (
        <React.Fragment>
          <Helmet><title>Mis compras - Cinemex</title></Helmet>

          {this.renderTabs()}

          <div className="tab-content" id="pills-tabContent">
            {this.renderTicketsTab()}
            {this.renderCandybarTab()}
          </div>
        </React.Fragment>
    );
  }

  renderTabs() {
    return '';

    // return (
    //     <ul className="nav nav-pills mb-5" id="pills-tab" role="tablist">
    //       <li className="nav-item">
    //         <a className="nav-link active" id="pills-tickets-tab" data-toggle="pill" href="#history-tickets" role="tab" aria-controls="pills-tickets" aria-selected="true">Boletos</a>
    //       </li>
    //       <li className="nav-item">
    //         <a className="nav-link" id="pills-candybar-tab" data-toggle="pill" href="#history-candybar" role="tab" aria-controls="pills-candybar" aria-selected="false">Candy bar</a>
    //       </li>
    //     </ul>
    // );
  }

  renderTicketsTab() {
    return (
        <div className="tab-pane fade show active" id="history-tickets" role="tabpanel" aria-labelledby="pills-tickets-tab">
          {(!this.props.purchases || this.props.purchases.length === 0)
            ? this.renderNoPurchasesScreen()
            : this.renderPurchases()}
        </div>
    );
  }

  renderCandybarTab() {
    return (
        <div className="tab-pane fade" id="history-candybar" role="tabpanel" aria-labelledby="pills-candybar-tab">
          2
        </div>
    );
  }

  renderNoPurchasesScreen() {
    const title = this.props.userId
      ? this.props.future ? 'No tienes boletos activos en este momento.' : 'Aún no tienes compras.'
      : null;
    
    return <NoContentMessage
      icon='fa-light fa-ticket'
      title={title}
      >
        <p className='mb-5'>¿Qué esperas para vivir La Magia del Cine?</p>
        <Button blockSmall primary to="/">Ver Cartelera</Button>
    </NoContentMessage>;
  }

  renderPurchases() {
    return this.props.purchases.map(purchase => {
      const {movie} = purchase;
      const date = parseSessionIsoDateForDisplay(purchase.session.timestamp);
      return (
          <div className="row py-5 align-items-center">
            <div className="col-12 col-sm-7 mb-3 mb-sm-0">
              <div className="d-flex align-items-center">
                <img className="align-self-start w-25" src={movie.cover} alt=""/>
                <div className="ml-3">
                  <h1 className="title-1 w-75">{movie.name}</h1>
                  <span className="lead text-primary">{getPaymentString(purchase.payment.methods)}</span>
                </div>
              </div>
            </div>
            <div className="col-11 col-sm-4">
              <p className="mb-0">
                <strong>{purchase.confirmation_code}</strong><br/>
                {purchase.cinema.name}<br/>
                {date.format('dddd D [de] MMMM')}<br />
                {date.format('hh:mm A')}<br />
                {purchase.auditorium}<br />
                {movie.label}<br />
                {purchase.tickets.map(ticket => ticket.qty + ' ' + ticket.name).join(' + ')}
              </p>
            </div>
            {/*<div className="col-1">*/}
              {/*<a href="#" className="text-secondary"><i className="fal fa-trash-alt"></i></a>*/}
            {/*</div>*/}
          </div>
      );
    });
  }
}

const mapStateToProps = state => ({
  userId: getUserId(state),
  purchases: getUserPurchases(state),
  isLoading: isUserPurchasesLoading(state),
});

const mapDispatchToProps = dispatch => ({
  purchaseHistoryFetch: params => dispatch(purchaseHistoryFetch(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserPurchases);