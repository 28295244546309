import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { breakpoints } from "../../../utils/styles";
import BodyBlocker from "../../BodyBlocker";
import BackButton from "../../common/BackButton";
import CloseCross from "../../CloseCross";

interface WindowInterface {
    desktopModal?:boolean,
    open:boolean,
};

const Sticky = styled.div``;

const StyledContainer = styled.div<WindowInterface>`
    background: var(--page-bg);
    bottom: 0;
    right: -100%;
    height: 94vh;
    overflow-y: auto;
    position: fixed;
    width: 100%;
    z-index: 1000;
    transition: right 0.35s ease-out;

    ${props => props.open && 'right: 0;'}

    & .pad-block {
        padding: var(--pad-y) var(--pad-x);
    }

    @media screen and (min-width: ${breakpoints.desktop}px) {
        ${props => props.desktopModal
            ? `
                height: fit-content;
                max-height: 90vh;
                position: fixed;
                top: 50%;
                left: 50%;
                width: 600px;
                max-width: 80%;
                transform: translate(-50%,-50%);
                transition: none;
                z-index: 1050;
            }`
            : `
                bottom: auto;
                height: 100%;
                position: absolute;
                top: -2em;
                transition: right 0.25s ease-in-out;
            `
        }

        & ${Sticky} { ${props => props.desktopModal && `
            background: var(--page-bg);
            position: sticky;        
            top: -1px;
            z-index: 1;
        `} }
    }
`;

interface Props {
    children:React.ReactNode,
    desktopModal?:boolean,
    onClose?:React.MouseEventHandler,
    title?:string,
};

function CouponWindow(props:Props) {
    const ref = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (ref.current) {
                setOpen(true);
            }
        }, 10);

        return () => clearTimeout(timer);
    }, []);

    return <div ref={ref}>
        {props.desktopModal && <BodyBlocker className="d-none d-md-block" onClick={props.onClose}/>}
        <StyledContainer open={open} desktopModal={props.desktopModal}>
            {props.title && <Sticky className="container pad-y">
                <BackButton onBackClick={props.onClose} label={props.title}/>
            </Sticky>}
            {props.children}
        </StyledContainer>
    </div>;
}

// @todo Rename this component.
export default CouponWindow;