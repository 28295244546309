import React from 'react';
import {Link} from 'react-router-dom';
import {Helmet} from 'react-helmet';
import FullColumnLayout from './FullColumnLayout';
import { asset } from '../utils/misc';
import BackButton from './common/BackButton';

const PageContent = props => {
  return (
      <div className="container">
        <Helmet><title>{props.title || 'No encontrado'} - Cinemex</title></Helmet>

        <div className="row justify-content-md-center mt-4">

          <div className="col-12 mb-5">
            <BackButton backTo='/' label='Volver al home'/>
          </div>

          <div className="col-12 col-md-8 col-lg-6">
            {props.children || props.message}
          </div>

        </div>
      </div>
  );
};

export default props => <FullColumnLayout Content={PageContent} {...props} bgWhite />