import React from 'react';
import {Link} from 'react-router-dom';
import LinkButton from "../LinkButton";
import Timer from "./Timer";
import styled from 'styled-components';
import StepIndicator from './StepIndicator';

const Icon = styled.i`
  font-size: 2rem;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  max-width: 65%;
  
  body.theme-market & {
    font-size: ${(35/12.6).toFixed(2)}rem !important;
    font-weight: 900;
    line-height: 1.2;
  }
  
  body.theme-platinum & {
    color: var(--primary) !important;
    font-size: ${(28/12.6).toFixed(2)}rem !important;
    font-weight: 700;
    line-height: 1.2;
  }
`;

const StyledLink = styled(Link)`
  color: var(--text-primary);
`;

export default props => {
  let ButtonElement;

  if (props.to) {
    ButtonElement = StyledLink;
  } else {
    ButtonElement = LinkButton;
  }

  return (
      <div className="mb-4">
        <div className='position-relative'>
          {(props.onPrevClick || props.to) && <ButtonElement onClick={props.onPrevClick} to={props.to} className="pr-3">
              <Icon className="fal fa-arrow-left"></Icon>
          </ButtonElement>}

          {props.timer !== false && <Timer/>}
        </div>

        <TitleBox className='mt-3'>
          <Title className="title-1">
            {props.label}
          </Title>
          <div className='d-block d-md-none'>
            <StepIndicator/>
          </div>
        </TitleBox>
      </div>
  );
}